import { Component, OnInit, } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { switchMap } from 'rxjs';
import { saveAs } from 'file-saver';

import { ApiService, StateService, SnackBarService } from 'src/app/services';
import { INewItemResponse, INewQuotationCreatedResp, IQuotationItemSummary, IQuotationSummary, Role, User } from 'src/app/services/interfaces';


@Component({
  selector: 'app-homepage',
  templateUrl: './homepage.component.html',
  styleUrls: ['./homepage.component.scss']
})
export class HomePageComponent implements OnInit {
  /**
   * Item Id of newly created quotation.
   */
  private quotationId: number = null;

  /**
   * Item Id of newly created item.
   */
  private itemId: number = null;
  public user: User = null;
  private urlRole: string = null;

  public quotationsSummary: IQuotationSummary[] = null;
  public ordersSummary: IQuotationSummary[] = null;
  public inquiriesSummary: IQuotationSummary[] = null;
  public deleteProjectModal: boolean = false;
  public deleteItemModal: boolean = false;

  //open project by ID
  public projectId: string = null;

  // New Project(Design) parameters.
  public newDesignModal: boolean = false;
  public newProjectTitle: string = null;
  public newItemTitle: string = null;
  public newItemDesc: string = null;
  public newDesignValidationError: boolean = false;

  // Add Item parameters.
  public addItemDesignModal: boolean = false;
  public addItemTitle: string = null;
  public addItemDesc: string = null;
  public addItemValidationError: boolean = false;
  public addItemQuotationId: number = null;

  // Duplicate Item parameters.
  public duplicateItemId: number = null;
  public duplicateItemModal: boolean = false;
  public duplicateItemTitle: string = null;
  public duplicateItemDesc: string = null;
  public duplicateItemValidationError: boolean = false;

  // Duplicate Project parameters.
  public duplicateProjectId: number = null;
  public duplicateProjectModal: boolean = false;
  public duplicateProjectTitle: string = null;
  public duplicateProjectValidationError: boolean = false;

  // Guide assistant
  public guideModal: boolean = false;

  /** ID of `project or item` to delete */
  public deleteItemId: number = null;

  // Pagination
  public currentQuotePage: number = 1;
  public currentOrderPage: number = 1;
  public currentInquiryPage: number = 1;
  public totalPagesNumber: number = 0;
  public quotePages: number[] = [];
  public orderPages: number[] = [];
  public inquiryPages: number[] = [];

  // Send order
  public sendOrderModal: boolean = false;
  public sendOrderFormNotes: FormGroup = null;

  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private snackBarService: SnackBarService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { };

  public ngOnInit(): void {
    this.user = this.stateService.get('user');

    // Find base role of user
    if (this.user.roles.includes(Role.UserAdmin)) {
      this.urlRole = "useradmin";
    } else if (this.user.roles.includes(Role.User)) {
      this.urlRole = "user";
    };
    this.getQuotationSummary();
    this.getOrdersSummary();
    this.getInquiriesSummary();
  };
  public getCurrencySign(currency: string) {
    let sign: string = null;
    if (currency === 'USD') {
      sign = '$';
    } else if (currency === 'EUR') {
      sign = '€';
    };

    return sign;
  };
  public createNewDesign(): void {

    if (!this.newProjectTitle || !this.newItemTitle) {
      this.newDesignValidationError = true;
      return null;
    }


    const observable = this.apiService.createQuotation({ idLang: this.user.idLang, idCurrency: this.user.idCurrency, quotationTitle: this.newProjectTitle }).pipe(switchMap((resp: INewQuotationCreatedResp) => {
      this.quotationId = resp.quotationId;

      return this.apiService.createItem({ itemNumber: '1', itemStatus: 'blank', itemType: 'ST', quotationId: resp.quotationId, itemTitle: this.newItemTitle, itemDescription: this.newItemDesc })
    })).subscribe({
      next: (_newItemResp: INewItemResponse) => {
        // Save ID of created item.
        this.itemId = _newItemResp.itemId;
        // Show modal window to ask user if he wants go through guid help
        this.guideModal = true;
        this.cancelNewDesign();
        observable.unsubscribe();
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  public addItem(): void {

    if (!this.addItemTitle) {
      this.addItemValidationError = true;
      return null;
    }
    let newItemNumber = 1;

    //find number of items in current quote
    this.quotationsSummary.forEach((_quotationSummary: IQuotationSummary) => {
      if (_quotationSummary.quotationId === this.addItemQuotationId) {
        newItemNumber = _quotationSummary.items.length + 1;
      }
    });

    const observable = this.apiService.createItem({ itemNumber: newItemNumber.toString(), itemStatus: 'blank', itemType: 'ST', quotationId: this.addItemQuotationId, itemTitle: this.addItemTitle, itemDescription: this.addItemDesc }).subscribe({
      next: (_newItemResp: INewItemResponse) => {
        // Save ID of created item.
        this.itemId = _newItemResp.itemId;
        this.quotationId = this.addItemQuotationId;
        // Show modal window to ask user if he wants go through guid help
        this.guideModal = true;
        this.cancelAddItem();
        observable.unsubscribe();
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  public startGuide(): void {
    this.router.navigate([`${this.urlRole}/assistant`, this.quotationId, this.itemId, 'country']);
  }
  public navigateToItem(item: IQuotationItemSummary): void {
    const assistantUrl: string = `${this.urlRole}/assistant`;
    const configuratorUrl: string = `${this.urlRole}/configurator`;

    switch (item.itemStatus) {
      case 'blank':
      case '1': {
        this.router.navigate([assistantUrl, item.quotationId, item.itemId, 'country']);
        break;
      }
      case '2': {
        this.router.navigate([assistantUrl, item.quotationId, item.itemId, 'design_parameters']);
        break;
      }
      case '3': {
        this.router.navigate([assistantUrl, item.quotationId, item.itemId, 'he_type']);
        break;
      }
      case '4': {
        this.router.navigate([assistantUrl, item.quotationId, item.itemId, 'application']);
        break;
      }
      case '4co': {
        this.router.navigate([assistantUrl, item.quotationId, item.itemId, 'condensation_details']);
        break;
      }
      case '4ev': {
        this.router.navigate([assistantUrl, item.quotationId, item.itemId, 'evaporation_details']);
        break;
      }
      case '5': {
        this.router.navigate([assistantUrl, item.quotationId, item.itemId, 'cleanability']);
        break;
      }
      case '6': {
        this.router.navigate([assistantUrl, item.quotationId, item.itemId, 'restrictions']);
        break;
      }
      case 'skip_journey':
      case '7': {
        this.router.navigate([configuratorUrl, item.quotationId, item.itemId, 'duty']);
        break;
      }
      case '8': {
        this.router.navigate([configuratorUrl, item.quotationId, item.itemId, 'options']);
        break;
      }
      case '9': {
        this.router.navigate([configuratorUrl, item.quotationId, item.itemId, 'solution', item.mainSolutionId]);
        break;
      }

      default: {
        this.snackBarService.open('No details for this item.');
        break;
      }
    };

  };
  public cancelGuide(): void {

    const observable = this.apiService.skipGuide(this.itemId).subscribe({
      next: (itemId: number) => {
        observable.unsubscribe();
        this.router.navigate([`${this.urlRole}/configurator`, this.quotationId, this.itemId, 'duty']);
      },
      error: (error) => {
        this.snackBarService.open();
      }
    })


  }
  /** Method to close all delete modals and clear ID of item to delete. */
  public cancelDelete(): void {
    this.deleteProjectModal = false;
    this.deleteItemModal = false;
    this.deleteItemId = null;
  };
  /** Method to close all delete modals and clear ID of item to copy. */
  public cancelDuplicate(): void {
    this.duplicateItemModal = false;
    this.duplicateItemValidationError = false;
    this.duplicateItemTitle = null;
    this.duplicateItemDesc = null;
  };
  /** Method to close all delete modals and clear ID of project to copy. */
  public cancelProjectDuplicate(): void {
    this.duplicateProjectModal = false;
    this.duplicateProjectValidationError = false;
    this.duplicateProjectTitle = null;
  };
  /** Method to close new design modal and reset input values. */
  public cancelNewDesign(): void {
    this.newDesignModal = false;
    this.newProjectTitle = null;
    this.newItemTitle = null;
    this.newItemDesc = null;
    this.newDesignValidationError = false;
  }
  /** Method to close add item modal and reset input values. */
  public cancelAddItem(): void {
    this.addItemDesignModal = false;
    this.addItemTitle = null;
    this.addItemDesc = null;
    this.addItemValidationError = false;
  }
  /** Method to delete quotation */
  public deleteQuotation(): void {
    const observable = this.apiService.deleteQuotation(this.deleteItemId).subscribe({
      next: () => {
        this.cancelDelete();
        this.getQuotationSummary();

        observable.unsubscribe();
      },
      error: (error) => {
        this.cancelDelete();

        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  /** Method to delete item in quotation */
  public deleteItem(): void {
    const observable = this.apiService.deleteItem(this.deleteItemId).subscribe({
      next: () => {
        this.cancelDelete();
        this.getQuotationSummary();

        observable.unsubscribe();
      },
      error: (error) => {
        this.cancelDelete();

        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  /** Method to duplicate item in quotation */
  public duplicateItem(): void {
    if (!this.duplicateItemTitle) {
      this.duplicateItemValidationError = true;
      return null;
    }

    const observable = this.apiService.duplicateItem({ itemId: this.duplicateItemId, newItemTitle: this.duplicateItemTitle, newItemDescription: this.duplicateItemDesc }).subscribe({
      next: () => {
        this.cancelDuplicate();
        this.getQuotationSummary();

        observable.unsubscribe();
      },
      error: (error) => {
        this.cancelDuplicate();

        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  /** Method to duplicate project */
  public duplicateProject(): void {
    if (!this.duplicateProjectTitle) {
      this.duplicateProjectValidationError = true;
      return null;
    }

    const observable = this.apiService.duplicateProject(this.duplicateProjectId, this.duplicateProjectTitle).subscribe({
      next: () => {
        this.cancelProjectDuplicate();
        this.getQuotationSummary();

        observable.unsubscribe();
      },
      error: (error) => {
        this.cancelProjectDuplicate();

        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  public inItemQuantityChange(item: any, quotation: any, itemQuantity: number) {
    if (!itemQuantity || itemQuantity < 1) {
      this.snackBarService.open('Not valid quantity');
      return;
    }

    const observable = this.apiService.updateItem(item.itemId, { itemQuantity: itemQuantity }).subscribe({
      next: (_newItemResp: INewItemResponse) => {
        quotation.priceSummary = quotation.items.reduce((acc, x) => x.unit_price * x.itemQuantity + acc, 0);
        observable.unsubscribe();
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  /** Method to change page */
  public changeQuotePage(): void {
    this.getQuotationSummary();
  };
  public changeOrderPage(): void {
    this.getOrdersSummary();
  };
  public changeInquiryPage(): void {
    this.getInquiriesSummary();
  };
  public getDatasheet(item: IQuotationItemSummary): void {

    // If solution ID in item not found
    // Show message, that solution is not exist
    if (!item.mainSolutionId) {
      this.snackBarService.open('No solution in the item');
      return;
    };

    item.spinner = true;

    this.apiService.getPdfDatasheet(item.mainSolutionId).subscribe({
      next: (datasheetResp) => {
        item.spinner = false;
        saveAs(new Blob([datasheetResp], { type: "application/pdf;charset=utf-8" }), `Datasheet. Item ${item.itemId}. Solution ${item.mainSolutionId}.pdf`);
      },
      error: (error) => {
        item.spinner = false;
        this.snackBarService.open();
      }
    })

  };

  public generateQuoteReport(quotationId: number) {
    this.snackBarService.open('Coming soon...', 3);
  };

  public vmCloseSendOrderModal(): void {
    this.sendOrderModal = false;
    this.sendOrderFormNotes = null;
  };
  public vmOpenSendOrderModal(quotationId: any): void {
    this.sendOrderModal = true;
    this.quotationId = quotationId;
    // Crate form group
    this.sendOrderFormNotes = this.formBuilder.group({
      notes: [null, Validators.required]
    });

  };

  public vmSendOrder() {

    if (this.sendOrderFormNotes.invalid) {
      this.sendOrderFormNotes.markAsDirty();
      return;
    };

    this.apiService.sendOrder(this.quotationId, this.sendOrderFormNotes.value['notes']).subscribe({
      next: (response) => {
        this.vmCloseSendOrderModal();
        this.snackBarService.open('Order sent', 3);
      },
      error: (error) => {
        console.error('Error sending order', error);
        this.snackBarService.open('Error sending order');
      }
    });
  };

  protected getAvailability(days: string): number {
    let dayNumber = Number(days);
    return !(dayNumber % 7) ? Math.floor(dayNumber / 7) : dayNumber;
  };

  protected isWeek(days: string): boolean {
    let dayNumber = Number(days);

    return (dayNumber % 7) === 0;
  };

  /** Method to get quotation summary from API */
  private getQuotationSummary() {
    const observable = this.apiService.getQuotationsSummary(10, 'new', this.currentQuotePage).subscribe({
      next: (quotationsSummaryResponse) => {
        this.createQuotePages(quotationsSummaryResponse.pages);
        this.processQuotations(quotationsSummaryResponse.quotations);
        observable.unsubscribe();
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  private processQuotations(quotationsSummary: IQuotationSummary[]): void {

    // Convert dated for quotation.
    quotationsSummary.forEach((_quotationSummary: IQuotationSummary) => {

      const itemsTimes: string[] = _quotationSummary.items.map((_x) => _x.last_edit);
      const lastEditDate: string = itemsTimes.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];

      _quotationSummary.dateLastEdit = new Date(lastEditDate).toLocaleDateString('en-GB').replaceAll('/', '.');
      _quotationSummary.dateCreated = new Date(_quotationSummary.dateInsert).toLocaleDateString('en-GB').replaceAll('/', '.');

      // Calculate price from all items
      _quotationSummary.priceSummary = _quotationSummary.items.reduce((acc, x) => x.unit_price * x.itemQuantity + acc, 0);

      // Disable spinner for all items
      _quotationSummary.items.forEach((_item) => {
        _item.spinner = false;
      });

      // Sort items
      _quotationSummary.items = _quotationSummary.items.sort((a, b) => +a.itemNumber - +b.itemNumber);
    });

    // Sort quotations by date.
    this.quotationsSummary = quotationsSummary.sort((a, b) => {
      return new Date(b.dateUpdate).getTime() - new Date(a.dateUpdate).getTime();
    });

  };
  /** Method to create pages list */
  private createQuotePages(total: number): void {
    this.quotePages = [...Array(total).keys()].map((_x) => _x + 1);
  };
  private getInquiriesSummary() {
    const observable = this.apiService.getQuotationsSummary(5, 'inquired', this.currentInquiryPage).subscribe({
      next: (inquiriesSummaryResponse) => {
        this.createInquiryPages(inquiriesSummaryResponse.pages);
        this.processInquiries(inquiriesSummaryResponse.quotations);
        observable.unsubscribe();
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  private processInquiries(inquiriesSummary: IQuotationSummary[]): void {

    // Convert dated for quotation.
    inquiriesSummary.forEach((_inquirySummary: IQuotationSummary) => {

      const itemsTimes: string[] = _inquirySummary.items.map((_x) => _x.last_edit);
      const lastEditDate: string = itemsTimes.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];

      _inquirySummary.dateLastEdit = new Date(lastEditDate).toLocaleDateString('en-GB').replaceAll('/', '.');
      _inquirySummary.dateCreated = new Date(_inquirySummary.dateInsert).toLocaleDateString('en-GB').replaceAll('/', '.');

      // Calculate price from all items
      _inquirySummary.priceSummary = _inquirySummary.items.reduce((acc, x) => x.unit_price * x.itemQuantity + acc, 0);

      // Disable spinner for all items
      _inquirySummary.items.forEach((_item) => {
        _item.spinner = false;
      });

      // Sort items
      _inquirySummary.items = _inquirySummary.items.sort((a, b) => +a.itemNumber - +b.itemNumber);
    });

    // Sort quotations by date.
    this.inquiriesSummary = inquiriesSummary.sort((a, b) => {
      return new Date(b.dateUpdate).getTime() - new Date(a.dateUpdate).getTime();
    });

  };
  /** Method to create pages list */
  private createInquiryPages(total: number): void {
    this.inquiryPages = [...Array(total).keys()].map((_x) => _x + 1);
  };
  private getOrdersSummary() {
    const observable = this.apiService.getQuotationsSummary(5, 'ordered', this.currentOrderPage).subscribe({
      next: (ordersSummaryResponse) => {
        this.createOrderPages(ordersSummaryResponse.pages);
        this.processOrders(ordersSummaryResponse.quotations);
        observable.unsubscribe();
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };
  private processOrders(ordersSummary: IQuotationSummary[]): void {

    // Convert dated for quotation.
    ordersSummary.forEach((_quotationSummary: IQuotationSummary) => {

      const itemsTimes: string[] = _quotationSummary.items.map((_x) => _x.last_edit);
      const lastEditDate: string = itemsTimes.sort((a, b) => new Date(b).getTime() - new Date(a).getTime())[0];

      _quotationSummary.dateLastEdit = new Date(lastEditDate).toLocaleDateString('en-GB').replaceAll('/', '.');
      _quotationSummary.dateCreated = new Date(_quotationSummary.dateInsert).toLocaleDateString('en-GB').replaceAll('/', '.');

      // Calculate price from all items
      _quotationSummary.priceSummary = _quotationSummary.items.reduce((acc, x) => x.unit_price * x.itemQuantity + acc, 0);

      // Disable spinner for all items
      _quotationSummary.items.forEach((_item) => {
        _item.spinner = false;
      });

      // Sort items
      _quotationSummary.items = _quotationSummary.items.sort((a, b) => +a.itemNumber - +b.itemNumber);
    });

    // Sort quotations by date.
    this.ordersSummary = ordersSummary.sort((a, b) => {
      return new Date(b.dateUpdate).getTime() - new Date(a.dateUpdate).getTime();
    });

  };
  /** Method to create pages list */
  private createOrderPages(total: number): void {
    this.orderPages = [...Array(total).keys()].map((_x) => _x + 1);
  };

  protected readonly Role = Role;

  openProject() {
    if (this.projectId) {
      const observable = this.apiService.findQuotationByID(this.projectId).subscribe({
        next: (resp) => {
          observable.unsubscribe();
          this.navigateToItem(resp);
        },
        error: (error) => {
          this.snackBarService.open("Project not found");
        }
      })
    } else {
      this.snackBarService.open('Enter project ID');
    }
  }
}
