import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ApiService, SnackBarService, StateService } from 'src/app/services';

import { EApplicationType } from '../../../assistant-pages/application/application.component';
import { TPhysicalUnits, TPhysicalUnitSystems } from '../models';
import { IFluid } from 'src/app/services/interfaces';
import { forkJoin, Observable } from 'rxjs';

import { Delaunay } from 'd3-delaunay';

@Component({
  selector: 'app-custom-fluid',
  templateUrl: './custom-fluid.component.html',
  styleUrls: ['./custom-fluid.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CustomFluidComponent implements OnInit, OnChanges {
  /** CUstom fluid parameters */
  public customFluid = null;

  /** Flag for dropdown menu */
  public dropdownMenu: boolean = false;
  /** Flag for modal window with full form for custom fluid */
  public modalWindow: boolean = false;
  /** Flag for modal window to add temperature */
  public modalWindowAddTemperature: boolean = false;

  /** Application side */
  @Input()
  public appSide: 'warm' | 'cold' = null;
  /** Application type */
  @Input()
  public appType: EApplicationType = null;
  /** Duty parameters */
  @Input()
  public parameters: any = null;
  @Input()
  public unitSystems: { unitSystems: TPhysicalUnitSystems; units: TPhysicalUnits; } = null;
  @Input()
  public defaultUnitSystems: { [key: string]: number } = null;

  @Output()
  public onCustomFluidSaved: EventEmitter<IFluid> = new EventEmitter();
  @Output()
  public customFluidParameters: EventEmitter<any> = new EventEmitter();

  constructor(
    private stateService: StateService,
    private snackBarService: SnackBarService,
    private apiService: ApiService,
    private changeDetection: ChangeDetectorRef
  ) { };

  public ngOnInit() { };

  public ngOnChanges(changes: SimpleChanges): void { };

  /** Method to close all modal windows */
  public vmCloseModalWindow(): void {
    this.modalWindow = false;
    this.modalWindowAddTemperature = false;

    // Reset custom fluid parameters when closing modal windows.
    this.vmResetFluid();
  };

  /** Method to set default values for unitsystems and some parameters. */
  public vmCreateNewFluid() {

    this.vmResetFluid();

    // Save default unit systems for custom fluid parameter
    // Inlet
    this.customFluid.temperature.inlet.physicalUnitId = this.defaultUnitSystems.temperature;
    this.customFluid.density.inlet.physicalUnitId = this.defaultUnitSystems.density;
    this.customFluid.viscosity.inlet.physicalUnitId = this.defaultUnitSystems.viscosity;
    this.customFluid.thermal_conductivity.inlet.physicalUnitId = this.defaultUnitSystems.thermal_conductivity;
    this.customFluid.specific_heat_cap.inlet.physicalUnitId = this.defaultUnitSystems.specific_heat_cap;

    // Outlet
    this.customFluid.temperature.outlet.physicalUnitId = this.defaultUnitSystems.temperature;
    this.customFluid.density.outlet.physicalUnitId = this.defaultUnitSystems.density;
    this.customFluid.viscosity.outlet.physicalUnitId = this.defaultUnitSystems.viscosity;
    this.customFluid.thermal_conductivity.outlet.physicalUnitId = this.defaultUnitSystems.thermal_conductivity;
    this.customFluid.specific_heat_cap.outlet.physicalUnitId = this.defaultUnitSystems.specific_heat_cap;


    // If temperature values from duty exist
    // use it as initial values for temperature for custom fluid
    if (this.parameters.inletTemp.value.commonUnits) {
      this.customFluid.temperature.inlet.calculated = this.parameters.inletTemp.value.commonUnits;
      this.customFluid.temperature.inlet.entered = Number(this.convertToUser(this.customFluid.temperature.inlet.calculated, 'temperature', this.customFluid.temperature.inlet.physicalUnitId).toFixed(1));
    };
    if (this.parameters.outletTemp.value.commonUnits) {
      this.customFluid.temperature.outlet.calculated = this.parameters.outletTemp.value.commonUnits;
      this.customFluid.temperature.outlet.entered = Number(this.convertToUser(this.customFluid.temperature.outlet.calculated, 'temperature', this.customFluid.temperature.outlet.physicalUnitId).toFixed(1));
    };


    // For GAS Fluids need extra properties and functionality
    if (this.appType === EApplicationType.Gas) {
      this.customFluid.pressure.inlet.physicalUnitId = this.defaultUnitSystems.pressure;

      // If pressure values from duty exist
      // use it as initial values for pressure for custom fluid
      if (this.parameters.inletPressure.value.commonUnits) {
        this.customFluid.pressure.inlet.calculated = this.parameters.inletPressure.value.commonUnits;
        this.customFluid.pressure.inlet.entered = Number(this.convertToUser(this.customFluid.pressure.inlet.calculated, 'pressure', this.customFluid.pressure.inlet.physicalUnitId).toFixed(1));
      };

    };

  };

  /** Method to start creation of custom fluid from selected fluid. */
  public vmCreateNewFluidFromCurrent(): void {
    // If fluid not selected whow error.
    if (!this.parameters.liquid.name) {
      this.snackBarService.error('Select fluid first');
      return;
    };
    // Create custom fluid initial object.
    this.vmCreateNewFluid();

    // If temperatures not present
    // Show modal window to add it
    if ((!this.customFluid.temperature.inlet.calculated && this.customFluid.temperature.inlet.calculated !== 0) ||
      (!this.customFluid.temperature.outlet.calculated && this.customFluid.temperature.outlet.calculated !== 0)) {
      this.modalWindowAddTemperature = true;
      return;
    };

    // If not error, get properties values.
    this.vmGetFluidProperties();
  };

  /**
   * Method to get fluid properties values from API.
   * And calculate it value for each property by temperature.
   */
  public vmGetFluidProperties() {

    // Check that all fields have values
    this.customFluid.temperature.outlet.invalid = !!this.customFluid.temperature.outlet.calculated || this.customFluid.temperature.outlet.calculated === 0 ? false : true;
    this.customFluid.temperature.inlet.invalid = !!this.customFluid.temperature.inlet.calculated || this.customFluid.temperature.inlet.calculated === 0 ? false : true;

    // Get all validation values
    const validationFlags = [
      this.customFluid.temperature.inlet.invalid,
      this.customFluid.temperature.outlet.invalid,
    ];

    // If Application is GAS add extra property for validation
    if (this.appType === EApplicationType.Gas) {
      this.customFluid.pressure.inlet.invalid = !!this.customFluid.pressure.inlet.calculated || this.customFluid.pressure.inlet.calculated === 0 ? false : true;
      validationFlags.push(this.customFluid.pressure.inlet.invalid);
    };


    // If some fields not valid, show validation message
    if (validationFlags.some((_x) => _x === true)) {
      this.snackBarService.open('All fields must be filled in.');
      return;
    };


    // List of properties
    const propsList: string[] = ['density', 'viscosity', 'thermal_conductivity', 'specific_heat_cap'];

    /** List of api calls */
    const propsCallsList: { [key: string]: Observable<any> } = {};

    // Loop through properties list and make calls for each property.
    propsList.forEach((_key: string) => {

      // Prepare property name according to application type.
      const propName: string = this.appType === EApplicationType.Gas ? _key + '_tp' : _key;

      propsCallsList[_key] = this.apiService.getFluidPhysicalProperty(this.parameters.liquid.fluidId, propName);
    });

    forkJoin(propsCallsList).subscribe({
      next: (fluidPropsResp) => {

        propsList.forEach((_key: string) => {
          let propValueInlet: number = null;
          let propValueOutlet: number = null;
          let valuePrecision: number = 1;

          // Find values for each property
          // For different application type used different methods.
          if (this.appType === EApplicationType.Gas) {
            propValueInlet = this.findPropertyValueForGas(this.customFluid.temperature.inlet.calculated, this.customFluid.pressure.inlet.calculated, fluidPropsResp[_key]);
            propValueOutlet = this.findPropertyValueForGas(this.customFluid.temperature.outlet.calculated, this.customFluid.pressure.inlet.calculated, fluidPropsResp[_key]);
          } else {
            propValueInlet = this.findPropertyValue(this.customFluid.temperature.inlet.calculated, fluidPropsResp[_key]);
            propValueOutlet = this.findPropertyValue(this.customFluid.temperature.outlet.calculated, fluidPropsResp[_key]);
          }
          ;

          if (_key == 'viscosity' || _key == 'thermal_conductivity') {
            valuePrecision = 3;
          }

          this.customFluid[_key].inlet.calculated = propValueInlet;
          this.customFluid[_key].inlet.entered = Number(this.convertToUser(this.customFluid[_key].inlet.calculated, _key, this.customFluid[_key].inlet.physicalUnitId).toFixed(valuePrecision));

          this.customFluid[_key].outlet.calculated = propValueOutlet;
          this.customFluid[_key].outlet.entered = Number(this.convertToUser(this.customFluid[_key].outlet.calculated, _key, this.customFluid[_key].outlet.physicalUnitId).toFixed(valuePrecision));

        });

        this.modalWindowAddTemperature = false;
        this.modalWindow = true;
        this.changeDetection.markForCheck();

      },
      error: (error) => {
        this.snackBarService.error('Can`t get fluid properties.');
        this.vmCloseModalWindow();
      }
    });

  };

  /** Method to store value and change validation status when parameter of custom fluid changed. */
  public onCustomFluidParameterChange(parameter, propName: string, value: number | string): void {

    // If property exist it main object with data
    // If not, its parameter
    if (parameter.hasOwnProperty('name')) {

      if (!!value) {
        this.customFluid.name = value;
        this.customFluid.invalid = false;
      } else {
        this.customFluid.name = null;
        this.customFluid.invalid = true;
      }

    } else {

      if (propName === 'fluid_group') {
        parameter.value = value;
        parameter.invalid = !!value ? false : true;
      } else {
        if (!!value || value === 0) {
          parameter.entered = value;
          parameter.calculated = this.convertToSI(parameter.entered, propName, parameter.physicalUnitId);
          parameter.invalid = false;
        } else {
          parameter.entered = null;
          parameter.calculated = null;
          parameter.invalid = true;
        };
      };

    };

  };

  /** Method to change units for parameters for custom fluid. */
  public onCustomFluidUnitChange(parameter, propName: string, physicalUnitId: number): void {

    parameter.physicalUnitId = physicalUnitId;

    if (parameter.calculated) {
      parameter.entered = Number(this.convertToUser(parameter.calculated, propName, parameter.physicalUnitId).toFixed(1));
    };

  };

  /** Method to save custom fluid */
  public saveCustomFluid() {
    // Check that all fields have values
    Object.keys(this.customFluid).forEach((_key: string) => {

      if (_key === 'name' || _key === 'invalid') {
        this.customFluid.invalid = !!this.customFluid.name ? false : true;
      } else {
        if (_key === 'fluid_group') {
          this.customFluid.fluid_group.invalid = !!this.customFluid.fluid_group.value ? false : true;
        } else if (_key == 'pressure') {
          this.customFluid[_key].inlet.invalid = !!this.customFluid[_key].inlet.calculated || this.customFluid[_key].inlet.calculated === 0 ? false : true;
        } else if (_key !== 'pressure') {
          this.customFluid[_key].inlet.invalid = !!this.customFluid[_key].inlet.calculated || this.customFluid[_key].inlet.calculated === 0 ? false : true;
          this.customFluid[_key].outlet.invalid = !!this.customFluid[_key].outlet.calculated || this.customFluid[_key].outlet.calculated === 0 ? false : true;
        };
      };

    });

    // Get all validation values
    const validationFlags = [
      this.customFluid.invalid,
      this.customFluid.temperature.inlet.invalid,
      this.customFluid.density.inlet.invalid,
      this.customFluid.viscosity.inlet.invalid,
      this.customFluid.thermal_conductivity.inlet.invalid,
      this.customFluid.specific_heat_cap.inlet.invalid,
      this.customFluid.temperature.outlet.invalid,
      this.customFluid.density.outlet.invalid,
      this.customFluid.viscosity.outlet.invalid,
      this.customFluid.thermal_conductivity.outlet.invalid,
      this.customFluid.specific_heat_cap.outlet.invalid,
      this.customFluid.fluid_group.invalid
    ];

    // If Application is GAS add extra property for validation
    if (this.appType === EApplicationType.Gas) {
      validationFlags.push(this.customFluid.pressure.inlet.invalid);
    };


    // If some fields not valid, show validation message
    if (validationFlags.some((_x) => _x === true)) {
      this.snackBarService.open('All fields must be filled in.');
      return;
    };

    const data = {
      customName: this.customFluid.name,
      ItemId: this.stateService.get('itemId'),
      usageModel: {
        usage: this.appType === EApplicationType.Gas ? EApplicationType.Gas : EApplicationType.Liquid,
        productType: "ST"
      },
      fluidProperties: []
    };

    Object.keys(this.customFluid).forEach((_key: string) => {
      if (_key !== 'name' && _key !== 'invalid' && _key !== 'temperature' && _key !== 'fluid_group' && _key !== 'pressure') {
        Object.keys(this.customFluid[_key]).forEach((_innerKey: string) => {
          data.fluidProperties.push({
            physicalPropertyId: _key,
            propertyValue: this.customFluid[_key][_innerKey]?.calculated,
            dependency1Value: this.customFluid.temperature[_innerKey]?.calculated
          });
        });
      } else if (_key === 'fluid_group') {
        data.fluidProperties.push({
          physicalPropertyId: _key,
          propertyValue: this.customFluid[_key].value,
          dependency1Value: this.customFluid.temperature.inlet?.calculated
        });
        data.fluidProperties.push({
          physicalPropertyId: _key,
          propertyValue: this.customFluid[_key].value,
          dependency1Value: this.customFluid.temperature.outlet?.calculated
        });
      };
    });

    this.apiService.saveCustomFluid(data).subscribe({
      next: (customFluidResp) => {
        this.onCustomFluidSaved.emit(customFluidResp);
        this.customFluidParameters.emit(JSON.parse(JSON.stringify(this.customFluid)));
        this.vmCloseModalWindow();
      },
      error: (error) => {
        this.snackBarService.error('Can`t save this fluid.');
      }
    });

  };
  /**
   * Method to reset custom fluid object.
   */
  private vmResetFluid(): void {
    if (this.appType === EApplicationType.Liquid) {
      this.customFluid = {
        name: null,
        invalid: false,
        temperature: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }
        },
        density: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        viscosity: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        thermal_conductivity: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        specific_heat_cap: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        fluid_group: {
          value: null,
          invalid: false
        }
      };
    } else if (this.appType === EApplicationType.Gas) {
      this.customFluid = {
        name: null,
        invalid: false,
        temperature: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }
        },
        pressure: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }
        },
        density: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }
        },
        viscosity: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        thermal_conductivity: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        specific_heat_cap: {
          inlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          },
          outlet: {
            entered: null,
            calculated: null,
            physicalUnitId: null,
            invalid: false
          }

        },
        fluid_group: {
          value: null,
          invalid: false
        }
      };
    };
  };

  /**
    * Method to convert value `from users unit system to SI`.
    * @param value
    * @param propName physical property name. See `physicalPropsList property` for correct typing
    * @returns
    */
  private convertToSI(value: number, propName: string, physicalUnitId: number): number {
    const prop = this.unitSystems.units[propName].find((x) => x.physicalUnitId === physicalUnitId);
    if (value === 0 || !value) {
      return value;
    };

    return prop.addFactor + (value * prop.multFactor)

  };
  /**
   * Method to convert value `from SI unit system to users unit system`.
   * @param value
   * @param propName physical property name. See `physicalPropsList property` for correct typing
   * @returns
   */
  private convertToUser(value: number, propName: string, physicalUnitId: number): number {
    const prop = this.unitSystems.units[propName].find((x) => x.physicalUnitId === physicalUnitId);
    if (value === 0 || !value) {
      return value;
    };

    return (value - prop.addFactor) / prop.multFactor;

  };

  /** Method to find property value */
  private findPropertyValue(temperature, tableProps) {

    // List of all temperatures in table
    const tableTemperatures = tableProps.map((_x) => _x.dependency1Value);
    // Table min temperature
    const tableMinTemp = Math.min.apply(Math, tableTemperatures);
    // Table max temperature
    const tableMaxTemp = Math.max.apply(Math, tableTemperatures);


    let temperatureTableRecord = tableProps.find((_x) => _x.dependency1Value === temperature);
    let propertyValue = null;

    // 1. If record exist, take value.
    // 2. If record was no found in step 1, check if entered value is in RANGE of table temperatures.
    //    If true, find its value with linear interpolation.
    // 3. If value was not found in previous steps, check if entered value is out of range of table temperatures.
    //    If true, find closest temperature and take its value.

    if (!!temperatureTableRecord) {
      propertyValue = temperatureTableRecord.propertyValue;
    } else if (temperature >= tableMinTemp && temperature <= tableMaxTemp) {
      // Find closest temperatures for entered value
      const closestMinTemp = this.findClosestNumberSmaller(temperature, tableTemperatures);
      const closestMaxTemp = this.findClosestNumberBigger(temperature, tableTemperatures);

      const closestMinTempSHC = tableProps.find((_x) => _x.dependency1Value === closestMinTemp).propertyValue;
      const closestMaxTempSHC = tableProps.find((_x) => _x.dependency1Value === closestMaxTemp).propertyValue;

      propertyValue = closestMinTempSHC + (temperature - closestMinTemp) * (closestMaxTempSHC - closestMinTempSHC) / (closestMaxTemp - closestMinTemp);
    } else if (temperature > tableMaxTemp || temperature < tableMinTemp) {
      const closestTemp = this.findClosestNumber(temperature, tableTemperatures);
      propertyValue = tableProps.find((_x) => _x.dependency1Value === closestTemp).propertyValue;
    }
    return propertyValue;
  };

  /** Method to find property value for `GAS`*/
  private findPropertyValueForGas(temperature, pressure, tableProps): number {
    const points = [];
    const values = [];

    //check if tableProps first point has empty dependency2Value then it is custom fluid data without pressure data
    if (tableProps[0].dependency2Value === null) {
      // List of all temperatures in table
      const tableTemperatures = tableProps.map((_x) => _x.dependency1Value);
      // Table min temperature
      const tableMinTemp = Math.min.apply(Math, tableTemperatures);
      // Table max temperature
      const tableMaxTemp = Math.max.apply(Math, tableTemperatures);


      let temperatureTableRecord = tableProps.find((_x) => _x.dependency1Value === temperature);
      let propertyValue = null;

      // 1. If record exist, take value.
      // 2. If record was no found in step 1, check if entered value is in RANGE of table temperatures.
      //    If true, find its value with linear interpolation.
      // 3. If value was not found in previous steps, check if entered value is out of range of table temperatures.
      //    If true, find closest temperature and take its value.

      if (!!temperatureTableRecord) {
        propertyValue = temperatureTableRecord.propertyValue;
      } else if (temperature >= tableMinTemp && temperature <= tableMaxTemp) {
        // Find closest temperatures for entered value
        const closestMinTemp = this.findClosestNumberSmaller(temperature, tableTemperatures);
        const closestMaxTemp = this.findClosestNumberBigger(temperature, tableTemperatures);

        const closestMinTempSHC = tableProps.find((_x) => _x.dependency1Value === closestMinTemp).propertyValue;
        const closestMaxTempSHC = tableProps.find((_x) => _x.dependency1Value === closestMaxTemp).propertyValue;

        propertyValue = closestMinTempSHC + (temperature - closestMinTemp) * (closestMaxTempSHC - closestMinTempSHC) / (closestMaxTemp - closestMinTemp);
      } else if (temperature > tableMaxTemp || temperature < tableMinTemp) {
        const closestTemp = this.findClosestNumber(temperature, tableTemperatures);
        propertyValue = tableProps.find((_x) => _x.dependency1Value === closestTemp).propertyValue;
      }
      return propertyValue;
    }

    tableProps.forEach((_prop) => {
      // dependency1Value - temperature
      // dependency2Value - pressure
      points.push([_prop.dependency2Value, _prop.dependency1Value]);
      values.push(_prop.propertyValue);
      //heck if the (pressure, temperature) point is already a known point
      if (_prop.dependency1Value === temperature && _prop.dependency2Value == pressure) {
        return _prop.propertyValue;
      }
    });

    // Use Delaunay triangulation to interpolate
    const delaunay = Delaunay.from(points);

    // Retrieve the surrounding triangle from the triangulation
    const triangles = delaunay.triangles;

    // Iterate through the triangles that use the nearest point and find the one containing the (pressure, temp)
    for (let i = 0; i < triangles.length; i += 3) {
      const p0Index = triangles[i];
      const p1Index = triangles[i + 1];
      const p2Index = triangles[i + 2];

      const p0 = points[p0Index];
      const p1 = points[p1Index];
      const p2 = points[p2Index];

      // Check if the (pressure, temperature) point is inside this triangle
      if (this.isPointInTriangle(pressure, temperature, p0, p1, p2)) {
        const v0 = values[p0Index];
        const v1 = values[p1Index];
        const v2 = values[p2Index];

        // Perform barycentric interpolation within the triangle
        const barycentric = this.getBarycentricCoordinates(pressure, temperature, p0, p1, p2);
        return barycentric[0] * v0 + barycentric[1] * v1 + barycentric[2] * v2;
      }
    }

    // Fallback: if no triangle contains the point, return the value at the nearest point (basic nearest neighbor)
    // Find the triangle containing the point (inletPressure, temperature)
    const nearestPointIndex = delaunay.find(pressure, temperature);

    return values[nearestPointIndex];
  };

  private findClosestNumberSmaller(x: number, arr: number[]): number | null {
    const filteredArr = arr.filter((k) => k < x);
    const indexArr = filteredArr.map((k) => Math.abs(k - x));
    const min = Math.min(...indexArr);
    return filteredArr[indexArr.indexOf(min)];
  };

  private findClosestNumberBigger(x: number, arr: number[]): number | null {
    const filteredArr = arr.filter((k) => k > x);
    const indexArr = filteredArr.map((k) => Math.abs(k - x));
    const min = Math.min(...indexArr);
    return filteredArr[indexArr.indexOf(min)];
  };

  private findClosestNumber(x, arr) {
    const indexArr = arr.map((k) => {
      return Math.abs(k - x)
    })
    const min = Math.min.apply(Math, indexArr)
    return arr[indexArr.indexOf(min)]
  };
  // Helper function to check if a point (x, y) is inside a triangle defined by (p0, p1, p2)
  private isPointInTriangle(x: number, y: number, p0: number[], p1: number[], p2: number[]): boolean {
    const [x0, y0] = p0;
    const [x1, y1] = p1;
    const [x2, y2] = p2;

    const dX = x - x2;
    const dY = y - y2;
    const dX21 = x2 - x1;
    const dY12 = y1 - y2;
    const D = dY12 * (x0 - x2) + dX21 * (y0 - y2);
    const s = dY12 * dX + dX21 * dY;
    const t = (y2 - y0) * dX + (x0 - x2) * dY;

    if (D < 0) return s <= 0 && t <= 0 && s + t >= D;
    return s >= 0 && t >= 0 && s + t <= D;
  };

  // Helper function to calculate barycentric coordinates
  private getBarycentricCoordinates(x: number, y: number, p0: number[], p1: number[], p2: number[]): number[] {
    const detT = (p1[1] - p2[1]) * (p0[0] - p2[0]) + (p2[0] - p1[0]) * (p0[1] - p2[1]);
    const l1 = ((p1[1] - p2[1]) * (x - p2[0]) + (p2[0] - p1[0]) * (y - p2[1])) / detT;
    const l2 = ((p2[1] - p0[1]) * (x - p2[0]) + (p0[0] - p2[0]) * (y - p2[1])) / detT;
    const l3 = 1 - l1 - l2;
    return [l1, l2, l3];
  };

};
