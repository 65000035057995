import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService, ApiService, AuthService } from 'src/app/services';
import { Role, User } from 'src/app/services/interfaces';

@Component({
  selector: 'app-usermenu',
  templateUrl: './usermenu.component.html',
  styleUrls: ['./usermenu.component.scss']
})
export class UsermenuComponent implements OnInit {
  public openMenu: boolean = false;

  // Menu options that are shown if there is a corresponding role.
  public adminOption: boolean = false;
  public manageAccountsOption: boolean = false;
  public changeUsersOption: boolean = false;
  public users: User[] = null;
  public userPhotoUrl: string = null;

  public showUserSelection: boolean = false;

  constructor(private stateService: StateService, private router: Router, private authService: AuthService, private apiService: ApiService) {
    // epmty
  }

  public ngOnInit() {
    this.users = this.stateService.restore('users');
    this.changeUsersOption = this.users ? this.users.length > 1 : false;

    const user: User = this.stateService.restore('user');

    this.adminOption = user.roles.includes(Role.Administrator);
    this.manageAccountsOption = user.roles.includes(Role.User);

    // Photo of current selected user.
    this.apiService.getUserPhoto().subscribe({
      next: (UserPhotoBlob) => {
        const fileReader: FileReader = new FileReader();

        fileReader.onload = (e: any) => {
          this.userPhotoUrl = e.target.result;

        };
        fileReader.readAsDataURL(UserPhotoBlob);
      }
    });

  }

  public switchMenu(): void {
    this.openMenu = !this.openMenu;
  }

  public logOut(e: Event): void {
    e.preventDefault();
    this.stateService.remove('jwt');
    this.router.navigate(["/login"]);

  }

  /**
   * Method for changing user.
   * @param e 
   */
  public onChangeUser(e: Event): void {
    e.preventDefault();

    // Get new list of users for current account
    this.authService.changeUser().subscribe({
      next: () => {
        // Update list of users before showing them.
        this.users = this.stateService.restore('users');
        this.showUserSelection = true;
      }
    });
  }

  public selectUser(user: User): void {
    this.stateService.store("jwt", user.token, true);

    this.apiService.getUserProps().subscribe({
      next: (resp: { propertyKey: string; propertyValue: string; }[]) => {

        resp.forEach((_prop) => {
          user[_prop.propertyKey] = _prop.propertyValue;
        });

        // Save selected user
        this.stateService.store('user', user);

        if (user.roles.includes(Role.UserAdmin)) {

          if (this.router.url.match(/^\/useradmin\//gm)) {
            window.location.reload();
          } else {
            this.router.navigate(["/useradmin/home"]);
          }

        } else if (user.roles.includes(Role.User)) {
          if (this.router.url.match(/^\/user\//gm)) {
            window.location.reload();
          } else {
            this.router.navigate(["/user/home"]);
          }

        } else if (user.roles.includes(Role.Administrator)) {
          throw new Error('Administrator role not supported.');
        }

      },
      error: (error) => {
        // Save selected user
        this.stateService.store('user', user);

        if (user.roles.includes(Role.UserAdmin)) {

          if (this.router.url.match(/^\/useradmin\//gm)) {
            window.location.reload();
          } else {
            this.router.navigate(["/useradmin/home"]);
          }

        } else if (user.roles.includes(Role.User)) {

          if (this.router.url.match(/^\/user\//gm)) {
            window.location.reload();
          } else {
            this.router.navigate(["/user/home"]);
          }

        } else if (user.roles.includes(Role.Administrator)) {
          throw new Error('Administrator role not supported.');
        }
      }
    })
  };
}
