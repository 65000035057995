<!-- header start -->
<header class="header">

  <!-- top panel start-->
  <div class="top-panel">
    <ng-content></ng-content>
  </div>
  <!-- top panel end-->

  <!-- logo section start -->
  <div class="logo-section">
    <!-- logo container start -->
    <div class="logo-container">
      <a href="" (click)="navigate($event)">
        <img src="../../../assets/images/Zilex.png" alt="zilex.png">
      </a>
    </div>
    <!-- logo container end -->

  </div>
  <!-- logo section end -->

</header>
<!-- header end -->