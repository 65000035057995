<!-- heat-exchanger-360-view-comp start -->
<div class="heat-exchanger-360-view-comp">
  <!-- view-modal start -->
  <div class="view-modal">
    <!-- close start -->
    <svg class="close" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
      fill="#000" (click)="onClose()">
      <path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" />
    </svg>
    <!-- close end -->

    <!-- view-container start -->
    <div class="view360-container" *ngIf="imagesUrls">
      <!-- overlay start -->
      <div class="overlay" (mousedown)="onMouseDown($event)"></div>
      <!-- overlay end -->
      <!-- image start -->
      <img *ngFor="let url of imagesUrls; index as i" src="{{url}}" [ngClass]="{'hide': i+1 !== currentImage}">
      <!-- image end -->

      <svg class="rotate-icon" [ngClass]="{'hide': rotateIconHide}" xmlns="http://www.w3.org/2000/svg" height="48px"
        viewBox="0 -960 960 960" width="48px" fill="#666666">
        <path
          d="m357-167-43-43 80-81q-136-15-225-66T80-486q0-79 116.5-134.5T480-676q168 0 284 55.5T880-486q0 59-64 104t-170 70v-65q80-20 127-52t47-57q0-32-83.5-81T480-616q-172 0-256 49t-84 81q0 45 57.5 77.5T397-349l-83-81 43-43 153 152-153 154Z" />
      </svg>
    </div>
    <!-- view-container end -->

  </div>
  <!-- view-modal end -->
</div>
<!-- heat-exchanger-360-view-comp end -->