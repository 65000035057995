<!-- configurator component start -->

<div class="configurator-comp">
  <!-- header start -->
  <app-header>
    <app-usermenu></app-usermenu>
  </app-header>
  <!-- header end -->

  <router-outlet></router-outlet>

  <!-- footer start -->
  <app-footer></app-footer>
  <!-- footer end -->
</div>

<!-- configurator component end -->