import { Component, OnInit } from '@angular/core';
import { forkJoin, switchMap } from 'rxjs';
import { ApiService, StateService, LanguageService, SnackBarService } from 'src/app/services';
import { ICountryResp, ISubuser, IUserProfile, User } from 'src/app/services/interfaces';

@Component({
  selector: 'app-user-settings',
  templateUrl: './user-settings.component.html',
  styleUrls: ['./user-settings.component.scss']
})
export class UserSettingsComponent implements OnInit {

  /** User profile */
  public userProfile: IUserProfile = null;
  public subusers: ISubuser[] = null;
  public userPhotoUrl: any = null;
  public userPhotoFile: File = null;

  // Special props, that not saved in User Profile.
  public languageProp: string = null;
  public currencyProp: string = null;
  public unitSystemProp: string = null;
  public defaultCountryProp: string = null;

  public validationError: boolean = false;
  public emptyUserNameError: boolean = false;
  public emptyUserNameMsg: string = null;
  public userExistError: boolean = false;

  private userProfileChanged: boolean = false;
  private userPhotoChanged: boolean = false;

  public unitSystemsList: { isActive: number; systemNameId: number; systemAbreviation: string; unitSystemId: number; systemName: string; }[] = null;
  public countries: ICountryResp[] & { title?: string; } = null;

  constructor(private stateService: StateService, private apiService: ApiService, private languageService: LanguageService, private snackBarService: SnackBarService) {
    // empty
  };

  public ngOnInit(): void {

    // Get User Profile, User Photo, Subusers, Special User Props and UnitSystems from API.
    forkJoin({
      userProfile: this.apiService.getUser(),
      subusers: this.apiService.getUserSubusers(),
      userProps: this.apiService.getUserProps(),
      unitSystems: this.apiService.getUnitSystems()
    }).subscribe({
      next: ({ userProfile, subusers, userProps, unitSystems }) => {
        // Save current User Profiles and Sub users
        this.userProfile = userProfile;
        this.subusers = subusers;

        // Take current user
        // and update his props with new values
        const user: User = this.stateService.restore('user');

        userProps.forEach((_prop) => {
          user[_prop.propertyKey] = _prop.propertyValue
        });

        // Update current user data
        // by data from user profile
        user.name = userProfile.name;
        user.surname = userProfile.surname;

        // Save current user props
        this.languageProp = user.idLang;
        this.currencyProp = user.idCurrency;
        this.unitSystemProp = user.unitSystem;
        this.defaultCountryProp = user.defaultCountry;


        // Save user with updated properies
        this.stateService.store('user', user);

        this.getUserProfilePhoto();

        // Apply language setting after user update.
        const lang: string = this.languageService.applyAvailableLanguage();

        // unit systems translations Ids
        const translationIds = unitSystems.map((x) => x.systemNameId);

        // Get translations
        let translationsObservable = null;

        // Some times translation can be missed in selected language.
        // In this case need to get translation in English language.
        // Need to get APi call for current and default language translations.
        if (lang !== 'en') {
          translationsObservable = {
            cyrrentLangTranslation: this.apiService.getTranslation(translationIds, lang.toUpperCase()),
            defaultLangTranslation: this.apiService.getTranslation(translationIds, 'EN'),
          }
        } else {
          translationsObservable = {
            cyrrentLangTranslation: this.apiService.getTranslation(translationIds, lang.toUpperCase()),
          }
        }


        forkJoin(translationsObservable).subscribe({
          next: ({ cyrrentLangTranslation, defaultLangTranslation }) => {
            // Create list of available unit sustems
            this.unitSystemsList = unitSystems.map((_system) => {
              // Try to find translation in selected language
              let translation = cyrrentLangTranslation.find((_translation) => _translation.translationId === _system.systemNameId);

              // If translation not found in selected language
              // Take translation from default language
              if (!translation) {
                translation = defaultLangTranslation.find((_translation) => _translation.translationId === _system.systemNameId);
              }


              const newSystem: any = Object.assign({}, _system);
              newSystem.systemName = translation.value;
              return newSystem;

            });
          },
          error: () => {
            this.snackBarService.open();
          }
        })

        let contriesList: ICountryResp[] = null;

        const countries$ = this.apiService.getCountries().pipe(switchMap((countries: ICountryResp[]) => {
          contriesList = countries;
          const ids: number[] = countries.map((_country) => _country.countryNameId);

          return forkJoin({
            userLang: this.apiService.getTranslation(ids, lang.toUpperCase()),
            defaultLang: this.apiService.getTranslation(ids, 'EN'),
          });

        })).subscribe({
          next: ({ userLang, defaultLang }) => {

            this.countries = contriesList.map((_country) => {

              const userLangTranslation = userLang.find((_x) => _x.translationId === _country.countryNameId)?.value;
              const defaultLangTranslation = defaultLang.find((_x) => _x.translationId === _country.countryNameId)?.value;
              const title: string = userLangTranslation || defaultLangTranslation;

              return Object.assign({ title }, _country);
            }).sort((a, b) => a.title.localeCompare(b.title));
            countries$.unsubscribe();
          },
          error: (error) => {
            this.snackBarService.open();
            countries$.unsubscribe();
          }
        })
      },
      error: (error) => {
        this.snackBarService.open();
      }
    });

  };

  public onUserProfileChange(): void {
    this.userProfileChanged = true;
  };

  public saveUserSettings(): void {
    // Reset validation flags
    this.validationError = false;
    this.emptyUserNameError = false;
    this.emptyUserNameMsg = null;
    this.userExistError = false;

    // If Name or Surename empty show error
    if (!this.userProfile.name) {
      this.validationError = true;
      this.emptyUserNameError = true;
      this.emptyUserNameMsg = 'Please enter Name';
      return;
    };
    if (!this.userProfile.surname) {
      this.validationError = true;
      this.emptyUserNameError = true;
      this.emptyUserNameMsg = 'Please enter Surname';
      return;
    };

    const _subusers = this.subusers.filter((_subuser) => _subuser.userId !== this.userProfile.userId);

    if (_subusers.some((_subuser) => _subuser.name === this.userProfile.name && _subuser.surname === this.userProfile.surname)) {
      this.validationError = true;
      this.userExistError = true;
      return;
    };

    const apiCallsList = [
      this.apiService.updateUserProfile(this.userProfile),
      this.apiService.updateUserProps('idLang', this.languageProp.toUpperCase()),
      this.apiService.updateUserProps('idCurrency', this.currencyProp),
      this.apiService.updateUserProps('unitSystem', this.unitSystemProp),
      this.apiService.updateUserProps('defaultCountry', this.defaultCountryProp)
    ];

    // If user photo was changed, save it.
    if (this.userPhotoChanged) {
      apiCallsList.push(this.apiService.updateUserPhoto(this.userPhotoFile));
    };


    //Save new values to user profile
    forkJoin(apiCallsList).subscribe({
      next: () => {
        window.location.reload();
      },
      error: () => {
        this.snackBarService.open();
      }
    })



  };

  public onUserPhotoChange(e: Event): void {
    const file: File = e.target['files'][0];
    const maxBytes: number = 2097152;

    if (file.size <= maxBytes) {
      this.userPhotoChanged = true;
      this.userPhotoFile = file;

      this.previewPhotoFile();
    } else {
      this.snackBarService.open('File size is too big.');
    };

  };

  /**
   * Method to upload `File image to FileReader` and display it as `Base64`
   * @param updateUserBase64 Flag to update `existing Base64 image string with new image Base64 in User object.` Default value is `false`.
   */
  public previewPhotoFile(updateUserBase64 = false) {
    const fileReader: FileReader = new FileReader();

    fileReader.onload = (e: any) => {
      this.userPhotoUrl = e.target.result;

      if (updateUserBase64) {
        const user: User = this.stateService.restore('user');

        // Get only string part
        const base64Str = this.userPhotoUrl.substr(this.userPhotoUrl.indexOf(',') + 1);
        user.photo = base64Str;
        this.stateService.store('user', user);
      }

    };
    fileReader.readAsDataURL(this.userPhotoFile);

  };

  public getUserProfilePhoto() {
    this.apiService.getUserPhoto().subscribe({
      next: (userPhotoBlob) => {
        // Save current profile photo
        this.userPhotoFile = new File([userPhotoBlob], 'user_photo');

        // Show user photo from API call
        // And update photo that was saved in User profile in session storage.
        this.previewPhotoFile(true);
      },
      error: (error) => {
        if (error.status === 404 && error.statusText === 'Not Found') {
          // Photo not existing
          // Dont do anything
        }
      }
    })
  }
}

