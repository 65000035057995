<!-- user settings component start -->
<div class="user-settings-comp">
  <!-- header start -->
  <app-header>
    <app-usermenu></app-usermenu>
  </app-header>
  <!-- header end -->

  <!-- main container start -->
  <div class="main-container">
    <!-- progressbar start -->
    <app-progressbar></app-progressbar>
    <!-- progressbar end -->

    <!-- title start -->
    <div class="title">{{'userSettingsPage.pageTitle'|translate}}</div>
    <!-- title end -->

    <!-- registration form start -->
    <form class="settings-form" *ngIf="userProfile">

      <!-- profile photo block start -->
      <div class="profile-photo-block">
        <!-- profile photo start -->
        <div class="profile-photo">
          <img src="{{userPhotoUrl || '../../../assets/images/user_icon.png'}}" alt="user photo">
        </div>
        <!-- profile photo end -->

        <!-- file upload block start -->
        <div class="file-upload-block">
          <!-- input file start -->
          <input type="file" name="profile_photo" id="profile_photo" class="profile_photo_input"
            (change)="onUserPhotoChange($event)" hidden>
          <!-- input file end -->

          <!-- input file label start -->
          <label for="profile_photo" class="profile_photo_label">{{'userSettingsPage.fields.chooseImage' | translate}}</label>
          <!-- input file label end -->

        </div>
        <!-- file upload block end -->

      </div>
      <!-- profile photo block end -->

      <!-- field start -->
      <div class="field">
        <label for="name">{{'userSettingsPage.fields.name' | translate}}:</label>
        <input type="text" name="name" id="name" [(ngModel)]="userProfile.name" (change)="onUserProfileChange()"
          required>
      </div>
      <!-- field end -->

      <!-- field start -->
      <div class="field">
        <label for="surname">{{'userSettingsPage.fields.surname' | translate}}:</label>
        <input type="text" name="surname" id="surname" [(ngModel)]="userProfile.surname"
          (change)="onUserProfileChange()" required>
      </div>
      <!-- field end -->

      <!-- field start -->
      <div class="field">
        <label for="phone">{{'userSettingsPage.fields.phone' | translate}}:</label>
        <input type="tel" name="phone" id="phone" [(ngModel)]="userProfile.phone" (change)="onUserProfileChange()"
          required>
      </div>
      <!-- field end -->

      <!-- field start -->
      <div class="field-select">
        <label for="language">{{'userSettingsPage.fields.language'|translate}}:</label>
        <select id="language" name='language' [(ngModel)]="languageProp">
          <option [value]="'DE'">Deutsch</option>
          <option [value]="'EN'">English</option>
        </select>
      </div>
      <!-- field end -->

      <!-- field start -->
      <div class="field-select">
        <label for="currency">{{'userSettingsPage.fields.currency'|translate}}:</label>
        <select id="currency" name='currency' [(ngModel)]="currencyProp">
          <option [value]="'EUR'">EUR</option>
<!--          <option [value]="'USD'">USD</option>-->
        </select>
      </div>
      <!-- field end -->

      <!-- field start -->
      <div class="field-select">
        <label for="unitSystem">{{'userSettingsPage.fields.unitSystem'|translate}}:</label>
        <select id="unitSystem" name="unitSystem" [(ngModel)]="unitSystemProp">
          <option *ngFor="let unitSystem of unitSystemsList" [value]="unitSystem.unitSystemId">{{unitSystem.systemName}}
            ({{unitSystem.systemAbreviation}})</option>
        </select>
      </div>
      <!-- field end -->

      <!-- field start -->
      <div class="field-select">
        <label for="country">{{'userSettingsPage.fields.defaultCountry'|translate}}:</label>
        <select id="country" name="defaultCountry" [(ngModel)]="defaultCountryProp">
          <option *ngFor="let country of countries" [value]="country.countryId">{{country.title}}</option>
        </select>
      </div>
      <!-- field end -->

      <!-- buttons block start -->
      <div class="buttons-block">
        <!-- button start -->
        <button type="submit" class="btn btn-register" (click)="saveUserSettings()">{{'userSettingsPage.fields.save' | translate}}</button>
        <!-- button end -->
      </div>
      <!-- buttons block end -->


      <!-- validation block start -->
      <div class="validation-block" *ngIf="validationError">
        <!-- validation message start -->
        <div class="validationMsg" *ngIf="emptyUserNameError">{{emptyUserNameMsg}}</div>
        <!-- validation message end -->
        <!-- validation message start -->
        <div class="validationMsg" *ngIf="userExistError">{{'accountManagementPage.userExistsError' | translate}}</div>
        <!-- validation message end -->
      </div>
      <!-- validation block end -->

    </form>
    <!-- registration form end -->


  </div>
  <!-- main container end -->

</div>
<!-- user settings component end -->
