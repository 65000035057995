import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgForm } from '@angular/forms';

import { StateService } from '../../services/state.service';
import { AuthService, ApiService} from '../../services';
import { LoginModel, Role, User } from 'src/app/services/interfaces';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  public users: User[] = null;
  public showUserSelection: boolean = false;
  public invalidLogin: boolean = false;
  public credentials: LoginModel = { username: '', password: '' };

  constructor(private router: Router, private authService: AuthService, private stateService: StateService, private apiService: ApiService) { }

  public ngOnInit(): void {
    // empty
  }

  public login(form: NgForm) {
    if (form.valid) {

      this.authService.singin(this.credentials)
        .subscribe({
          next: (response: User[]) => {
            this.users = response;
            if (response.length >= 2) {
              this.showUserSelection = true;
            } else {
              this.selectUser(response[0]);
            }
          },
          error: (err: any) => this.invalidLogin = true
        })
    }
  }

  public selectUser(user: User) {

    this.stateService.store("jwt", user.token, true);

    this.apiService.getUserProps().subscribe({
      next: (resp: { propertyKey: string; propertyValue: string; }[]) => {

        resp.forEach((_prop) => {
          user[_prop.propertyKey] = _prop.propertyValue;
        });

        // Save selected user
        this.stateService.store('user', user);
        this.navigate(user);

      },
      error: (error) => {
        // Save selected user
        this.stateService.store('user', user);
        this.navigate(user);
      }
    })

  }

  private navigate(user: User): void {

    if (user.roles.includes(Role.UserAdmin)) {
      this.router.navigate(["/useradmin/home"]);
    } else if (user.roles.includes(Role.User)) {
      this.router.navigate(["/user/home"]);
    } else if (user.roles.includes(Role.Administrator)) {
      throw new Error('Administrator role not supported.');
    }

  }
}