import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from './state.service';
import { User } from './interfaces';
@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  constructor(private translate: TranslateService, private stateService: StateService) {
    // empty
  }
  public setDefaultLang(lang: string): void {
    this.translate.setDefaultLang(lang);
    this.stateService.store('deviceLang', lang, true);
  }
  public useLang(lang: string): void {
    this.translate.use(lang);
  }

  public applyAvailableLanguage(): string {

    let deviceLang: string = this.stateService.restore('deviceLang');
    const user: User = this.stateService.restore('user');
    let lang: string = null;


    if (user && user.idLang) {
      this.translate.use(user.idLang.toLowerCase());
      lang = user.idLang.toLowerCase();
    } else if (deviceLang) {
      this.translate.use(deviceLang)
      lang = deviceLang;
    };

    // Save selected lang
    if (lang) {
      this.stateService.set('lang', lang);
    };


    return lang;

  }
}
