import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, switchMap } from 'rxjs';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { INewRequirementsResponse, Role, User } from 'src/app/services/interfaces';

@Component({
  selector: 'app-heat-exchanger-type',
  templateUrl: './heat-exchanger-type.component.html',
  styleUrls: ['./heat-exchanger-type.component.scss']
})
export class AssistantHETypeComponent implements OnInit {
  private quotationId: number = null;
  private itemId: number = null;
  private user: User = null;
  private existingRequirement: INewRequirementsResponse = null;

  public heatExchangerType: string = null;

  public warningMessage: boolean = false;

  constructor(private apiService: ApiService, private route: ActivatedRoute, private router: Router, private snackBarService: SnackBarService, private stateService: StateService) {
    // empty
  }

  public ngOnInit(): void {
    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];
    this.user = this.stateService.get('user');

    this.apiService.getItemRequirements(this.itemId).subscribe({
      next: (requirementsResp) => {
        this.existingRequirement = requirementsResp.find((_x) => _x.requirementType === 'he_type');

        if (this.existingRequirement) {
          // this.existingRequirement.requirementValue = JSON.parse(this.existingRequirement.requirementValue);
          this.heatExchangerType = this.existingRequirement.requirementValue.he_type;
        }
      }
    })
  };

  public selectHEExchanger(type: string): void {
    this.heatExchangerType = this.heatExchangerType === type ? null : type;
    this.warningMessage = false;
  };

  public back() {
    this.router.navigate([this.quotationId, this.itemId, 'design_parameters'], { relativeTo: this.route.parent });
  };
  public skipGuide(): void {

    const observable = this.apiService.skipGuide(this.itemId).subscribe({
      next: (itemId: number) => {
        let url: string = null;
        if (this.user.roles.includes(Role.UserAdmin)) {
          url = 'useradmin/configurator';
        } else {
          url = 'user/configurator';
        }
        observable.unsubscribe();
        this.router.navigate([url, this.quotationId, this.itemId, 'duty']);
      },
      error: () => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    })

  };

  public onContinue() {

    if (!this.heatExchangerType) {
      this.warningMessage = true;
      return;
    };

    let apiCall = null;

    if (this.existingRequirement) {
      apiCall = this.apiService.updateItemRequirements(this.existingRequirement.requirementId, { requirementType: 'he_type', requirementValue: { "he_type": this.heatExchangerType, "sub_type": null, "sub_type2": null } });
    } else {
      apiCall = this.apiService.saveItemRequirements(this.itemId, { requirementType: 'he_type', requirementValue: { "he_type": this.heatExchangerType, "sub_type": null, "sub_type2": null } });
    }

    const observable = apiCall.pipe(switchMap(() => {
      return this.apiService.updateItem(this.itemId, { quotationId: this.quotationId, itemStatus: '3', itemType: 'ST', itemNumber: '1' });
    })).subscribe({
      next: () => {
        observable.unsubscribe();
        this.router.navigate([this.quotationId, this.itemId, 'application'], { relativeTo: this.route.parent });
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });


  };

}
