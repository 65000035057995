import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { forkJoin, switchMap } from 'rxjs';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { IHEProductType, INewRequirementsResponse, User } from 'src/app/services/interfaces';
import { TPhysicalUnits, TPhysicalUnitSystems } from '../applications';

@Component({
  selector: 'app-duty-side-menu',
  templateUrl: './duty-side-menu.component.html',
  styleUrls: ['./duty-side-menu.component.scss']
})
export class DutySideMenuSettingsComponent implements OnInit {
  private user: User = null;
  private lang: string = null;
  private quotationId: number = null;
  private itemId: number = null;


  private requirements: INewRequirementsResponse[] = null;
  private countryRequirement: INewRequirementsResponse = null;
  private designParameterRequirement: INewRequirementsResponse = null;
  private cleanabilityRequirement: INewRequirementsResponse = null;
  private heTypeRequirement: INewRequirementsResponse = null;
  private appRequirement: INewRequirementsResponse = null;

  public productTypes: IHEProductType[] = null;
  // Units
  public unitSystems: { unitSystems: TPhysicalUnitSystems; units: TPhysicalUnits; } = null;

  // Menu switchers
  public menuSwitchClose: boolean = true;
  public heTypeMenuSwitch: boolean = true;
  public appMenuSwitch: boolean = true;
  public materialMenuSwitch: boolean = true;
  public cleanabilityMenuSwitch: boolean = true;
  public designParametersMenuSwitch: boolean = true;
  public designCodeMenuSwitch: boolean = true;

  /** HE Main Type */
  public heType: EHEType = null;
  /** HE First Sub Type */
  public heSubType1: EHEType = null;
  /** HE Second Sub Type */
  public heSubType2: EHEType = null;

  /** Application type on warm side */
  public warmAppType: EApplicationType = null;
  /** Condensation sub type application */
  public coSteamControlled: boolean = null;
  /** Condensation sub type application */
  public coCondensateControlled: boolean = null;

  /** Application sub type on warm side */
  public warmAppSubType: EApplicationType = null;
  /** Application type on cold side */
  public coldAppType: EApplicationType = null;
  /** Application sub type on cold side */
  public coldAppSubType: EApplicationType = null;
  /** Evaporation sub type application */
  public evProcessSteam: boolean = null;
  /** Evaporation sub type application */
  public evCleanSteam: boolean = null;

  // Cleanability
  public warmFoulingListSwitcher: boolean = false;
  public coldFoulingListSwitcher: boolean = false;

  /** Cleanability parameters */
  public cleanability = {
    warm: {
      selected: false,
      foulingValue: {
        common: null,
        user: null
      },
      foulingPhysicalUnitId: null
    },
    cold: {
      selected: false,
      foulingValue: {
        common: null,
        user: null
      },
      foulingPhysicalUnitId: null
    }
  };
  /** Fouling options for cleanability */
  public foulingOptions: { common: { name: string; value: number; }[], warm: { name: string; value: number; }[], cold: { name: string; value: number; }[] } = {
    common: [],
    warm: [],
    cold: []
  };
  /** Design parameters */
  public designParameters = {
    warm: {
      pressure: {
        commonUnits: null,
        userUnits: null,
        physicalUnitId: null
      },
      temperature: {
        commonUnits: null,
        userUnits: null,
        physicalUnitId: null
      }
    },
    cold: {
      pressure: {
        commonUnits: null,
        userUnits: null,
        physicalUnitId: null
      },
      temperature: {
        commonUnits: null,
        userUnits: null,
        physicalUnitId: null
      }
    }
  }

  /** Design Code */
  public designCode: string = null;

  @Output()
  public applicationChanged: EventEmitter<string> = new EventEmitter();
  @Output()
  public designParameterChanged: EventEmitter<boolean> = new EventEmitter();

  @Input()
  public disabled: boolean = false;

  constructor(private stateService: StateService, private apiService: ApiService, private route: ActivatedRoute, private snackBarService: SnackBarService) { }

  public ngOnInit() {

    this.quotationId = this.route.snapshot.params['quotationId'];
    this.itemId = this.route.snapshot.params['itemId'];
    this.user = this.stateService.get('user');
    this.lang = this.stateService.get('lang').toUpperCase();

    this.vmGetProductTypes();
  };

  public vmMenuSwitcher() {
    if (!this.disabled) {
      this.menuSwitchClose = !this.menuSwitchClose;
    };
  };

  /** Method to save selected `HE Type` */
  public onHETypeChange(event: any): void {
    const heType: EHEType = event.target.value;

    switch (heType) {
      case EHEType.ShellAndTube:
      case EHEType.PlateHex:
      case EHEType.Undecided: {
        this.heType = heType;
        this.heSubType1 = null;
        this.heSubType2 = null;
        break;
      };
      case EHEType.Vertical:
      case EHEType.Horizontal: {
        this.heType = EHEType.ShellAndTube;
        this.heSubType2 = null;

        if (this.heSubType1 === heType) {
          this.heSubType1 = null;
        } else {
          this.heSubType1 = heType;
        };

        break;
      };
      case EHEType.PlateFrame:
      case EHEType.Brazed: {
        this.heType = EHEType.PlateHex;
        this.heSubType2 = null;

        if (this.heSubType1 === heType) {
          this.heSubType1 = null;
        } else {
          this.heSubType1 = heType;
        };

        break;
      };
      case EHEType.Industry:
      case EHEType.Food: {
        this.heType = EHEType.PlateHex;
        this.heSubType1 = EHEType.PlateFrame;

        if (this.heSubType2 === heType) {
          this.heSubType2 = null;
        } else {
          this.heSubType2 = heType;
        };
        break;
      };
      case EHEType.CopperBrazed:
      case EHEType.NickelBrazed: {
        this.heType = EHEType.PlateHex;
        this.heSubType1 = EHEType.Brazed;

        if (this.heSubType2 === heType) {
          this.heSubType2 = null;
        } else {
          this.heSubType2 = heType;
        };
        break;
      };
      default: {
        this.snackBarService.open('Unknown Heat Exchanger type.');
      };
    };


    this.apiService.updateItemRequirements(this.heTypeRequirement.requirementId, { requirementType: 'he_type', requirementValue: { he_type: this.heType, sub_type: this.heSubType1, sub_type2: this.heSubType2 } })
      .subscribe({
        next: () => {
          this.snackBarService.open('New HE Type parameters saved.');
        },
        error: (error) => {
          this.snackBarService.open('New HE Type parameters not saved.');
        }
      });

  };
  /** Method to saved selected `Application` */
  public onApplicationChange(emitEvent: boolean = true): void {
    const validApplications: string[] = ['LILI', 'COLI', 'LIEV', 'COEV', 'LIGA', 'GALI', 'GAGA', 'COGA', 'GAEV']
    const currentApplication: string = `${this.warmAppType}${this.coldAppType}`;

    // If selected application dont ahve sub types
    // remove previously selected sub type for selected application side.
    if (this.warmAppType === EApplicationType.Liquid || this.warmAppType === EApplicationType.Gas) {
      this.warmAppSubType = null;
    };
    if (this.coldAppType === EApplicationType.Liquid || this.coldAppType === EApplicationType.Gas) {
      this.coldAppSubType = null;
    };


    if (validApplications.some((_x: string) => _x === currentApplication)) {
      const data = { warm: [this.warmAppType], cold: [this.coldAppType], sub_application_warm: this.warmAppSubType, sub_application_cold: this.coldAppSubType };

      this.apiService.updateItemRequirements(this.appRequirement.requirementId, { requirementType: "application", requirementValue: data })
        .subscribe({
          next: (resp) => {
            this.snackBarService.open('New application saved.');
            if (emitEvent) {
              this.applicationChanged.emit('Application changed.');
            }
          },
          error: (error) => {
            this.snackBarService.open('New application not saved.');
          }
        });

    } else {
      this.snackBarService.open('Selected application is not possible.', 1.5);
    };

  };
  /**
   * Method change value of application sub type.
   * @param side
   * @param value
   */
  public onApplicationSubTypeChange(side: string, value: EApplicationType): void {
    // If saved subtype have save value as selected
    // clear value.
    if (side === 'cold') {
      if (this.coldAppSubType === value) {
        this.coldAppSubType = null;
      } else {
        this.coldAppSubType = value;
      };
    };

    if (side === 'warm') {
      if (this.warmAppSubType === value) {
        this.warmAppSubType = null;
      } else {
        this.warmAppSubType = value;
      };
    };

    this.onApplicationChange();

  }

  /**
   * Method to process changes in cleanability checkbox.
   * @param e
   */
  public onCleanabilityChange(e: any) {
    this.warmFoulingListSwitcher = false;
    this.coldFoulingListSwitcher = false;
    this.saveCleanabilityRequirement();
  };
  /**
   * Method to save value from input, when changing fouling parameter.
   * @param side
   * @param value
   */
  public onFoulingValueChange(side: string, value: string) {
    if (side === 'warm' && value) {
      this.cleanability.warm.foulingValue.user = +value;
    } else if (side === 'warm' && !value) {
      this.cleanability.warm.foulingValue.user = null;
    };

    if (side === 'cold' && !!value) {
      this.cleanability.cold.foulingValue.user = +value;
    } else if (side === 'cold' && !value) {
      this.cleanability.cold.foulingValue.user = null;
    };

    // Conver user value to common SI value
    this.cleanability.warm.foulingValue.common = this.convertToSI(this.cleanability.warm.foulingValue.user, 'fouling', this.cleanability.warm.foulingPhysicalUnitId);
    this.cleanability.cold.foulingValue.common = this.convertToSI(this.cleanability.cold.foulingValue.user, 'fouling', this.cleanability.cold.foulingPhysicalUnitId);
    // Reset dropdown menu
    this.warmFoulingListSwitcher = false;
    this.coldFoulingListSwitcher = false;

    this.saveCleanabilityRequirement();

  };
  public onFoulingValueSelect(side: string, item: { name: string; value: number; }) {

    if (side === 'warm') {
      if (!!item) {
        this.cleanability.warm.foulingValue.user = item.name;
      } else {
        this.cleanability.warm.foulingValue.user = null;
      };
      this.cleanability.warm.foulingValue.common = this.convertToSI(item.value, 'fouling', this.cleanability.warm.foulingPhysicalUnitId);
    };

    if (side === 'cold') {
      if (!!item) {
        this.cleanability.cold.foulingValue.user = item.name;
      } else {
        this.cleanability.cold.foulingValue.user = null;
      };
      this.cleanability.cold.foulingValue.common = this.convertToSI(item.value, 'fouling', this.cleanability.cold.foulingPhysicalUnitId);
    };


    // Reset dropdown menu
    this.warmFoulingListSwitcher = false;
    this.coldFoulingListSwitcher = false;

    this.saveCleanabilityRequirement();

  };
  public onFoulingUnitChange(side: string, physicalUnitId: number): void {

    this.cleanability[side].foulingPhysicalUnitId = physicalUnitId;

    if (this.cleanability[side].foulingValue.common && typeof this.cleanability[side].foulingValue.user !== 'string') {
      this.cleanability[side].foulingValue.user = this.convertToUser(this.cleanability[side].foulingValue.common, 'fouling', this.cleanability[side].foulingPhysicalUnitId, true);
    };
    this.convertFoulingDefaultOptions();

  };

  /**
   * Method to save new value when `Design Parameter` changed.
   * @param value
   */
  public onDesignParameterChange(side: string, paramName: string, value: number): void {
    if (paramName == 'pressure' && !value) {
      this.snackBarService.open('Design pressure cannot be empty.');
      return;
    }
    if (paramName == 'temperature' && !value) {
      this.snackBarService.open('Design temperature cannot be empty.');
      return;
    }

    if (side === 'warm' && paramName == 'pressure' && value) {
      this.designParameters.warm.pressure.userUnits = +value;
      this.designParameters.warm.pressure.commonUnits = Number(this.convertToSI(this.designParameters.warm.pressure.userUnits, 'pressure', this.designParameters.warm.pressure.physicalUnitId).toFixed(1));
    } else if (side === 'cold' && paramName == 'pressure' && value) {
      this.designParameters.cold.pressure.userUnits = +value;
      this.designParameters.cold.pressure.commonUnits = Number(this.convertToSI(this.designParameters.cold.pressure.userUnits, 'pressure', this.designParameters.cold.pressure.physicalUnitId).toFixed(1));
    } else if (side === 'warm' && paramName == 'temperature' && value) {
      this.designParameters.warm.temperature.userUnits = +value;
      this.designParameters.warm.temperature.commonUnits = Number(this.convertToSI(this.designParameters.warm.temperature.userUnits, 'temperature', this.designParameters.warm.temperature.physicalUnitId).toFixed(1));
    } else if (side === 'cold' && paramName == 'temperature' && value) {
      this.designParameters.cold.temperature.userUnits = +value;
      this.designParameters.cold.temperature.commonUnits = Number(this.convertToSI(this.designParameters.cold.temperature.userUnits, 'temperature', this.designParameters.cold.temperature.physicalUnitId).toFixed(1));
    }

    const data = {
      warm: {
        max_pressure: this.designParameters.warm.pressure.commonUnits,
        max_temperature: this.designParameters.warm.temperature.commonUnits
      },
      cold: {
        max_pressure: this.designParameters.cold.pressure.commonUnits,
        max_temperature: this.designParameters.cold.temperature.commonUnits
      }
    };

    //verify entered values
    if (data.warm.max_temperature > 300 || data.cold.max_temperature > 300) {
      this.snackBarService.open('Design temperature is too high.');
      return;
    }
    if (data.warm.max_pressure > 5000000 || data.cold.max_pressure > 5000000) {
      this.snackBarService.open('Design pressure is too high.');
      return;
    }

    let observable = this.apiService.updateItemRequirements(this.designParameterRequirement.requirementId, { requirementType: 'design_parameters', requirementValue: data });

    observable
      .subscribe({
        next: (resp) => {
          this.snackBarService.open('New design parameters saved.');
          this.designParameterChanged.emit(true);
        },
        error: (error) => {
          this.snackBarService.open('New design parameters not saved.');
          throw error;
        }
      });

  };

  public onDesignParameterUnitChange(propName: string, physicalUnitId: number) {
    // Save new unit system selected for current parameter.
    // If parameter have SI value,  convert it to value in unit selected by user
    // If not dont do anything.
    let side = 'warm';
    this.designParameters[side][propName].physicalUnitId = physicalUnitId;
    this.designParameters[side][propName].physicalUnitId = physicalUnitId;

    if (this.designParameters[side][propName].commonUnits) {
      this.designParameters[side][propName].userUnits = Number(this.convertToUser(this.designParameters[side][propName].commonUnits, propName, this.designParameters[side][propName].physicalUnitId).toFixed(1));
    };
    side = 'cold';
    this.designParameters[side][propName].physicalUnitId = physicalUnitId;
    this.designParameters[side][propName].physicalUnitId = physicalUnitId;

    if (this.designParameters[side][propName].commonUnits) {
      this.designParameters[side][propName].userUnits = Number(this.convertToUser(this.designParameters[side][propName].commonUnits, propName, this.designParameters[side][propName].physicalUnitId).toFixed(1));
    };
  };

  /**
   * Method to save new value when `Design Code` changed.
   * @param value
   */
  public onDesignCodeChange(value: string): void {
    this.designCode = value;

    let observable = null;


    if (this.countryRequirement) {
      observable = this.apiService.updateItemRequirements(this.countryRequirement.requirementId, { requirementType: 'country', requirementValue: { country: this.countryRequirement.requirementValue.country, regulatory: this.designCode } });
    } else {
      observable = this.apiService.saveItemRequirements(this.itemId, { requirementType: 'country', requirementValue: { country: this.countryRequirement.requirementValue.country, regulatory: this.designCode } });
    }

    observable
      .subscribe({
        next: (resp) => {
          this.snackBarService.open('New design code parameter saved.');
        },
        error: (error) => {
          this.snackBarService.open('New design code parameter not saved.');
          throw error;
        }
      });

  };

  /**
   * Method to convert value `from users unit system to SI`.
   * @param value
   * @param propName physical property name. See `physicalPropsList property` for correct typing
   * @returns
   */
  private convertToSI(value: number, propName: string, physicalUnitId: number): number {
    const prop = this.unitSystems.units[propName].find((x) => x.physicalUnitId === physicalUnitId);
    if (value === 0 || !value) {
      return value;
    };

    return prop.addFactor + (value * prop.multFactor)

  };
  /**
   * Method to convert value `from SI unit system to users unit system`.
   * @param value
   * @param propName physical property name. See `physicalPropsList property` for correct typing
   * @returns
   */
  private convertToUser(value: number, propName: string, physicalUnitId: number, round: boolean = false, precision: number = 2): number {
    const prop = this.unitSystems.units[propName].find((x) => x.physicalUnitId === physicalUnitId);
    if (value === 0 || !value) {
      return value;
    };

    const result: number = (value - prop.addFactor) / prop.multFactor;

    return round ? this.vmAdjustValuePrecision(result, precision) : result;
  };

  /**
   * Method to process existing `cleanability requirement`.
   */
  private processDesignParameterRequirement() {
  };

  /**
     * Method to process existing `cleanability requirement` and 'design parameters requirement'.
     */
  private processCleanabilityAndDesignRequirement() {

    // Check if cleanability were selected on assistant page.
    this.cleanability.warm.selected = this.cleanabilityRequirement.requirementValue.warm || false;
    this.cleanability.cold.selected = this.cleanabilityRequirement.requirementValue.cold || false;

    forkJoin({
      foulingValues: this.apiService.getShellAndTubeFouling(),
      units: this.apiService.getAllUnitSystemData(['fouling', 'pressure', 'temperature'])
    }).subscribe({
      next: ({ foulingValues, units }) => {

        this.foulingOptions.common = Object.keys(foulingValues).map((_key: string) => ({ name: _key, value: foulingValues[_key] }));
        this.unitSystems = units;

        // Create default unit for each prop
        // 1. Find unit system saved in user profile
        // 2. Find unit system with unitSystemId 1
        // If user unit system present save it physicalUnitId, else save physicalUnitId from unit system with unitSystemId 1
        const defaultUnits = {};

        ['fouling', 'pressure', 'temperature'].forEach((_propName: string) => {
          const userUnitSystem = units.unitSystems[_propName].find((_x) => _x.unitSystemId === +this.user.unitSystem);
          const firstUnitSystem = units.unitSystems[_propName].find((_x) => _x.unitSystemId === 1);

          if (userUnitSystem) {
            defaultUnits[_propName] = userUnitSystem.physicalUnitId;
          } else {
            defaultUnits[_propName] = firstUnitSystem.physicalUnitId;
          };

        });

        // Find default unit system
        this.cleanability.warm.foulingPhysicalUnitId = defaultUnits['fouling'];
        this.cleanability.cold.foulingPhysicalUnitId = defaultUnits['fouling'];


        // Check if fouling value exist.
        if (this.cleanabilityRequirement.requirementValue.warm_fouling || this.cleanabilityRequirement.requirementValue.warm_fouling === 0) {
          const value: number = this.cleanabilityRequirement.requirementValue.warm_fouling;

          this.cleanability.warm.foulingValue.common = value;
          this.cleanability.warm.foulingValue.user = this.convertToUser(value, 'fouling', this.cleanability.warm.foulingPhysicalUnitId, true);
        };
        if (this.cleanabilityRequirement.requirementValue.cold_fouling || this.cleanabilityRequirement.requirementValue.cold_fouling === 0) {
          const value: number = this.cleanabilityRequirement.requirementValue.cold_fouling;

          this.cleanability.cold.foulingValue.common = value;
          this.cleanability.cold.foulingValue.user = this.convertToUser(value, 'fouling', this.cleanability.cold.foulingPhysicalUnitId, true);
        };
        this.convertFoulingDefaultOptions();

        // Find default unit system
        this.designParameters.warm.pressure.physicalUnitId = defaultUnits['pressure'];
        this.designParameters.cold.pressure.physicalUnitId = defaultUnits['pressure'];
        this.designParameters.warm.temperature.physicalUnitId = defaultUnits['temperature'];
        this.designParameters.cold.temperature.physicalUnitId = defaultUnits['temperature'];


        // set values
        this.designParameters.warm.pressure.commonUnits = this.designParameterRequirement.requirementValue.warm.max_pressure;
        this.designParameters.warm.pressure.userUnits = Number(this.convertToUser(this.designParameters.warm.pressure.commonUnits, 'pressure', this.designParameters.warm.pressure.physicalUnitId).toFixed(1));

        this.designParameters.cold.pressure.commonUnits = this.designParameterRequirement.requirementValue.cold.max_pressure;
        this.designParameters.cold.pressure.userUnits = Number(this.convertToUser(this.designParameters.cold.pressure.commonUnits, 'pressure', this.designParameters.cold.pressure.physicalUnitId).toFixed(1));

        this.designParameters.warm.temperature.commonUnits = this.designParameterRequirement.requirementValue.warm.max_temperature;
        this.designParameters.warm.temperature.userUnits = Number(this.convertToUser(this.designParameters.warm.temperature.commonUnits, 'temperature', this.designParameters.warm.temperature.physicalUnitId).toFixed(1));

        this.designParameters.cold.temperature.commonUnits = this.designParameterRequirement.requirementValue.cold.max_temperature;
        this.designParameters.cold.temperature.userUnits = Number(this.convertToUser(this.designParameters.cold.temperature.commonUnits, 'temperature', this.designParameters.cold.temperature.physicalUnitId).toFixed(1));

      },
      error: (error) => {
        this.snackBarService.open('Can`t get cleanability fouling data.');
      }
    });

  };
  /**
   * Method to convert default fouling options from API, according to unit system selected by user.
   */
  private convertFoulingDefaultOptions() {

    this.foulingOptions.warm = this.foulingOptions.common.map((_item) => {
      return {
        name: _item.name,
        value: this.convertToUser(_item.value, 'fouling', this.cleanability.warm.foulingPhysicalUnitId, true)
      }
    });
    this.foulingOptions.cold = this.foulingOptions.common.map((_item) => {
      return {
        name: _item.name,
        value: this.convertToUser(_item.value, 'fouling', this.cleanability.cold.foulingPhysicalUnitId, true)
      }
    });
  };
  private saveCleanabilityRequirement() {

    const data = {
      warm: this.cleanability.warm.selected,
      warm_fouling: this.cleanability.warm.foulingValue.common,
      cold: this.cleanability.cold.selected,
      cold_fouling: this.cleanability.cold.foulingValue.common,
    };

    this.apiService.updateItemRequirements(this.cleanabilityRequirement.requirementId, { requirementType: 'cleanability', requirementValue: data }).subscribe({
      next: (resp) => {
        this.snackBarService.open('New cleanability parameters saved.');
      },
      error: (error) => {
        this.snackBarService.open('New cleanability parameters not saved.');
      }

    })

  };

  private vmGetProductTypes() {
    this.apiService.getHEProductTypes().pipe(
      switchMap((_productTypes) => {
        this.productTypes = _productTypes.sort(_x=>_x.sortIndex);

        const ids: number[] = _productTypes.map((_x) => _x.translationId);
        return this.apiService.getTranslation(ids);
      })
    ).subscribe({
      next: (translations) => {

        this.productTypes.forEach((_item) => {

          const title1: string = translations.find((_x) => _x.translationId === _item.translationId && _x.languageId === this.lang)?.value;
          const title2: string = translations.find((_x) => _x.translationId === _item.translationId && _x.languageId === 'EN')?.value;
          _item.title = title1 || title2;
        });

        this.vmGetRequirements();

      },
      error: (error) => {
        this.snackBarService.open();
      }
    });
  };

  private vmGetRequirements() {
    this.apiService.getItemRequirements(this.itemId).subscribe({
      next: (requirements: INewRequirementsResponse[]) => {

        this.requirements = requirements;
        this.heTypeRequirement = requirements.find((_x) => _x.requirementType === 'he_type');
        this.appRequirement = requirements.find((_x) => _x.requirementType === 'application');
        this.cleanabilityRequirement = requirements.find((_x) => _x.requirementType === 'cleanability');
        this.countryRequirement = requirements.find((_x) => _x.requirementType === 'country');
        this.designParameterRequirement = requirements.find((_x) => _x.requirementType === 'design_parameters');

        // Process HE Type requirement
        // this.heTypeRequirement.requirementValue = JSON.parse(this.heTypeRequirement.requirementValue);
        this.heType = this.heTypeRequirement.requirementValue.he_type;
        this.heSubType1 = this.heTypeRequirement.requirementValue.sub_type || null;
        this.heSubType2 = this.heTypeRequirement.requirementValue.sub_type2 || null;

        // Process Application requirement
        // this.appRequirement.requirementValue = JSON.parse(this.appRequirement.requirementValue);

        // Saved main application type
        this.warmAppType = this.appRequirement.requirementValue.warm[0];
        this.coldAppType = this.appRequirement.requirementValue.cold[0];

        // Save application sub type if exist
        this.warmAppSubType = this.appRequirement.requirementValue?.sub_application_warm || null;
        this.coldAppSubType = this.appRequirement.requirementValue?.sub_application_cold || null;

        // Process country requirement
        // this.countryRequirement.requirementValue = JSON.parse(this.countryRequirement.requirementValue);
        this.designCode = this.countryRequirement.requirementValue.regulatory;

        // Process cleanability and design parameter requirements
        this.processCleanabilityAndDesignRequirement();
      },
      error: (error) => {
        this.snackBarService.open('Can`t get requirements for side menu.');
        throw error;
      }
    });
  };

  /**
    * Method to round value.
    * @param value
    * @param precision
    * @param maxDecimals
    * @returns
  */
  private vmAdjustValuePrecision(value: number, precision: number, maxDecimals: number = null) {
    function vmGetNumberOfSignificantDigitsAfterZero(value: number, precision: number, maxDecimals: number): number {
      if (value === 0 || !isFinite(value) || isNaN(value)) {
        return 0
      }

      const lvAbsValue: number = Math.abs(value);
      const lvLog: number = Math.trunc(Math.log10(lvAbsValue));
      let lvResult: number = precision - lvLog;

      if (Math.trunc(lvAbsValue) > 0) {
        lvResult--;
      };
      if (lvResult < 0) {
        lvResult = 0;
      };

      if (maxDecimals !== null && lvResult > maxDecimals) {
        lvResult = maxDecimals;
      };

      return lvResult;
    };

    const lvNumberOfSignificantDigitsAfterZero: number = vmGetNumberOfSignificantDigitsAfterZero(value, precision, maxDecimals);

    const lvMultKoef: number = Math.pow(10, lvNumberOfSignificantDigitsAfterZero);

    let lvResult: number = lvMultKoef * value;
    lvResult = Math.round(lvResult);
    lvResult = lvResult / lvMultKoef;

    return lvResult;
  };

};

enum EHEType {
  Undecided = 'NA',
  ShellAndTube = 'ST',
  Vertical = 'VE',
  Horizontal = 'HO',
  PlateHex = 'PH',
  PlateFrame = 'plate_frame',
  Industry = 'industry',
  Food = 'food',
  Brazed = 'brazed',
  CopperBrazed = 'copper_brazed',
  NickelBrazed = 'nickel_brazed',
  Coated = 'coated'

};

enum EApplicationType {
  Liquid = 'LI',
  Condensation = 'CO',
  Evaporation = 'EV',
  Gas = 'GA',
  ProcessSteam = 'process_steam',
  CleanSteam = 'clean_steam',
  SteamControlled = 'steam_controlled',
  CondensateControlled = 'condensate_controlled'

}
