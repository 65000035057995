import { Component, ViewChild, ElementRef, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-progressbar',
  templateUrl: './progressbar.component.html',
  styleUrls: ['./progressbar.component.scss']
})
export class ProgressBarComponent implements AfterViewInit {


  @ViewChild('progressBar')
  public progressBar: ElementRef = null;

  @Input()
  public progressStep: number

  public ngAfterViewInit(): void {

    const width = (100 / 9) * this.progressStep || 0;

    this.progressBar.nativeElement.style.width = width + '%';
  }


}
