import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';

import { AuthService, StateService, ApiService, SnackBarService } from '../../services';
import { ICountryResp, IRegistrationCredentials } from 'src/app/services/interfaces';
import { forkJoin, switchMap } from 'rxjs';

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.scss']
})
export class RegistrationComponent implements OnInit {

  public lang: string = null;
  public formCredentials: IRegistrationCredentials = {
    name: null,
    surname: null,
    mail: null,
    phone: null,
    securityData: null,
    companyName: null,
    vatNumber: null,
    discount: null,
    market: null,
    webAddress: null,
    idLang: null,
    country: null,
    city: null,
    postcode: null,
    address: null,
    CrmId: null
  };
  public countries: ICountryResp[] & { title?: string; } = null;
  public marketsList = {
    'en': ['Brewing & Beverage Industry', 'Chemistry & Petrochemicals', 'Energy Production', 'Engineering Offices', 'Food Industry', 'HVAC', 'Marine', 'Oil & Gas', 'Pharmaindustrie', 'Plant Manufacturing', 'Plastics Engineering', 'Renewable Energy', 'Sales & Trade', 'Wood and Paper Industry'],
    'de': ['Brau- und Getränke', 'Chemie', 'Energieversorgung', 'Planungsbüros', 'Lebensmittel', 'TGA', 'Marine', 'Öl- und Gasindustrie', 'Pharma', 'Anlagenbau', 'Kunststofftechnik', 'Erneuerbare Energie', 'Vertrieb & Handel', 'Holz- und Papierindustrie']
  };
  public countryCodes: { countryCode: string; phoneCode: string; }[] = null;
  public selectedCountryCode: { countryCode: string; phoneCode: string; } = null;

  public apiErrorMessage: string = null;
  public allFieldsRequired: boolean = false;
  public registrationSuccessful: boolean = false;
  public confirmPassword: string = null;
  public passwordNotValid: boolean = null;
  public passwordsNotEqual: boolean = null;
  public emailNotValid: boolean = null;
  public paswordRegexp: RegExp = /^(?=.*[a-zA-Z\d])(?=.*[!@#$%^&*.]).{6,}$/gm;
  public emailRegexp: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/gm;

  constructor(
    private authService: AuthService,
    private stateService: StateService,
    private router: Router,
    private apiService: ApiService,
    private snackBarService: SnackBarService
  ) {
    // empty
  };

  public ngOnInit(): void {
    this.lang = this.stateService.restore('deviceLang');
    this.getCountries();
  };
  /**
   * This method need to prevent trigger cancel button when enter `enter key`.
   * @param event
   */
  public preventEnterKeyTigger(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
  /** Method to select new language, when user change it in menu. */
  public vmOnLanguageChange(value: string) {
    this.lang = this.stateService.restore('deviceLang');
    this.getCountries();
  };
  /**
   * Method to get information by VAT number when user leaves filed.
   * @param e
   */
  public vmOnVatEnter(e: Event) {

    if (!this.formCredentials.vatNumber) {
      return;
    };

    this.authService.getCompany(this.formCredentials.vatNumber).subscribe({
      next: (companyData) => {

        this.formCredentials.companyName = companyData.name;
        this.formCredentials.city = companyData.city;
        this.formCredentials.postcode = companyData.zip;
        this.formCredentials.webAddress = companyData.website;
        this.formCredentials.address = companyData.address;
        this.formCredentials.CrmId = companyData.id?.toString() || '';

        this.formCredentials.country = this.countries.find((_item) => _item.countryCode === companyData.country)?.countryId;

        this.selectedCountryCode = this.countryCodes.find((_item) => _item.countryCode === companyData.country);

        const currentPhoneCode: string = companyData.phone.slice(0, this.selectedCountryCode.phoneCode.length);

        if (currentPhoneCode === this.selectedCountryCode.phoneCode) {
          this.formCredentials.phone = companyData.phone.slice(this.selectedCountryCode.phoneCode.length);
        } else {
          this.formCredentials.phone = companyData.phone.slice(1);
        };

      }
    })
  };

  /**
   * Method to submit registration form.
   * @param form
   */
  public submit(form: NgForm) {
    this.resetFlags();

    // Get language selected on device.
    // Use English as fallback if deviceLang will be empty
    this.formCredentials.idLang = this.stateService.restore('deviceLang') || 'en';

    if (!this.allFormFieldsFilled()) {
      this.allFieldsRequired = true;
    };

    if (this.formCredentials.securityData && this.confirmPassword) {
      this.passwordsNotEqual = this.formCredentials.securityData !== this.confirmPassword;
    };

    if (this.formCredentials.securityData) {
      this.passwordNotValid = !this.formCredentials.securityData.match(this.paswordRegexp);
    };

    if (this.formCredentials.mail) {
      this.emailNotValid = !this.formCredentials.mail.match(this.emailRegexp);
    };

    if (form.valid) {
      const payload = Object.assign({}, this.formCredentials);
      payload.phone = `${this.selectedCountryCode.phoneCode}${payload.phone}`;
      payload.CrmId = '' + payload.CrmId;

      this.authService.registration(payload).subscribe({
        next: () => {
          this.registrationSuccessful = true;
        },
        error: (error) => {
          if (typeof error.error === "object") {
            let message = '';
            Object.keys(error.error.errors).forEach((_key: string) => {

              error.error.errors[_key].forEach((_str) => {
                message = `${message} ${_str}`;
              })

            });
            this.apiErrorMessage = message;

          } else {
            this.apiErrorMessage = error.error;
          }

        }
      });
    }

  };

  /**
   * Method to navigate to login page, by click on `cancel button`.
   */
  public cancel(e: PointerEvent): void {
    e.preventDefault();
    this.router.navigate(["/login"]);
  };

  private resetFlags(): void {
    this.allFieldsRequired = false;
    this.passwordsNotEqual = null;
    this.passwordNotValid = null;
    this.emailNotValid = null;
  };

  /**
   * Method to check that all fields have values.
   */
  private allFormFieldsFilled(): boolean {

    const relevantFields = Object.entries(this.formCredentials)
      .filter(([key, _]) => key !== 'discount')
      .map(([_, value]) => value);

    const allObjectFieldsFilled: boolean = relevantFields.every(item => item !== null && item !== undefined);

    return allObjectFieldsFilled && !!this.confirmPassword && !!this.selectedCountryCode;

  };

  private getCountries() {
    forkJoin({
      countries: this.apiService.getCountries(),
      countryCodes: this.apiService.getCountryCodes()
    }).pipe(
      switchMap(({ countries, countryCodes }) => {
        this.countries = countries;
        this.countryCodes = countryCodes.sort((a, b) => {
          return a.countryCode > b.countryCode ? 1 : -1;
        });

        const ids: number[] = countries.map((_x) => _x.countryNameId);
        return forkJoin({
          userLang: this.apiService.getTranslation(ids, this.lang.toUpperCase()),
          defaultLang: this.apiService.getTranslation(ids, 'EN'),
        });

      })
    ).subscribe({
      next: ({ userLang, defaultLang }) => {

        this.countries = this.countries.map((_country: ICountryResp) => {

          const userLangTranslation = userLang.find((_x) => _x.translationId === _country.countryNameId)?.value;
          const defaultLangTranslation = defaultLang.find((_x) => _x.translationId === _country.countryNameId)?.value;
          const title: string = userLangTranslation || defaultLangTranslation;
          return Object.assign({ title }, _country);
        });
      },
      error: (error) => {
        this.snackBarService.open();
      }
    });
  };

};

