import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, forkJoin, switchMap } from 'rxjs';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { INewItemResponse, INewRequirementsResponse, IQuotation, Role, User } from 'src/app/services/interfaces';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss']
})
export class AssistantApplicationComponent implements OnInit {

  private quotationId: number = null;
  private itemId: number = null;
  private user: User = null;
  private existingRequirement: INewRequirementsResponse = null;

  public warmApplicationValue: EApplicationType = EApplicationType.Liquid;
  public coldApplicationValue: EApplicationType = EApplicationType.Liquid;

  public quotationStatus: boolean = false;

  constructor(private apiService: ApiService, private snackBarService: SnackBarService, private route: ActivatedRoute, private router: Router, private stateService: StateService) {
    // empty
  }

  public ngOnInit(): void {

    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];
    this.user = this.stateService.get('user');

    this.apiService.getItemRequirements(this.itemId).subscribe({
      next: (requirementsResp) => {

        const requirement: any = requirementsResp.find((_x) => _x.requirementType === 'application');

        if (requirement) {

          this.existingRequirement = requirement;

          this.warmApplicationValue = requirement.requirementValue.warm[0];
          this.coldApplicationValue = requirement.requirementValue.cold[0];
        };

      }
    });

    this.apiService.getQuotationStatus(this.quotationId).subscribe({
      next: (quotationStatus: IQuotation) => {
        this.quotationStatus = quotationStatus.quotationStatus === 'ordered';
      }
    });

  };
  public back() {
    this.router.navigate([this.quotationId, this.itemId, 'he_type'], { relativeTo: this.route.parent });
  };
  public skipGuide(): void {

    const observable = this.apiService.skipGuide(this.itemId).subscribe({
      next: (itemId: number) => {
        let url: string = null;
        if (this.user.roles.includes(Role.UserAdmin)) {
          url = 'useradmin/configurator';
        } else {
          url = 'user/configurator';
        }
        observable.unsubscribe();
        this.router.navigate([url, this.quotationId, this.itemId, 'duty']);
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    })

  };
  public onContinue(): void {
    const validApplications: string[] = ['LILI', 'COLI', 'LIEV', 'COEV', 'LIGA', 'GALI', 'GAGA', 'COGA', 'GAEV'];
    const currentApplication: string = `${this.warmApplicationValue}${this.coldApplicationValue}`;

    if (validApplications.every((_x: string) => _x !== currentApplication)) {
      this.snackBarService.open('Selected application is not possible.');
      return;
    };

    let data = null;
    let savedWarmApp: EApplicationType = null;
    let savedColdApp: EApplicationType = null;

    if (this.existingRequirement) {

      savedWarmApp = this.existingRequirement.requirementValue.warm[0];
      savedColdApp = this.existingRequirement.requirementValue.cold[0];

      data = this.existingRequirement.requirementValue;

      data.warm = [this.warmApplicationValue];
      data.cold = [this.coldApplicationValue];

      if (this.warmApplicationValue !== EApplicationType.Condensation && savedWarmApp === EApplicationType.Condensation) {
        data.sub_application_warm = null;
      };

      if (this.coldApplicationValue !== EApplicationType.Evaporation && savedColdApp === EApplicationType.Evaporation) {
        data.sub_application_cold = null;
      };

    } else {
      data = { warm: [this.warmApplicationValue], cold: [this.coldApplicationValue] };
    };

    let apiCall = null;

    if (this.existingRequirement) {
      apiCall = this.apiService.updateItemRequirements(this.existingRequirement.requirementId, { requirementType: "application", requirementValue: data });
    } else {
      apiCall = this.apiService.saveItemRequirements(this.itemId, { requirementType: "application", requirementValue: data });
    }

    const observable = apiCall.pipe(switchMap(() => {
      return this.apiService.updateItem(this.itemId, { quotationId: this.quotationId, itemStatus: '4', itemType: 'ST', itemNumber: '1' });
    })).subscribe({
      next: () => {
        observable.unsubscribe();

        if (this.warmApplicationValue === EApplicationType.Condensation && this.coldApplicationValue != EApplicationType.Evaporation) {
          this.router.navigate([this.quotationId, this.itemId, 'condensation_details'], { relativeTo: this.route.parent });
        } else if (this.coldApplicationValue === EApplicationType.Evaporation) {
          this.router.navigate([this.quotationId, this.itemId, 'evaporation_details'], { relativeTo: this.route.parent });
        } else {
          this.router.navigate([this.quotationId, this.itemId, 'cleanability'], { relativeTo: this.route.parent });
        };

      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });

  };

}

export enum EApplicationType {
  Liquid = 'LI',
  Condensation = 'CO',
  Evaporation = 'EV',
  Gas = 'GA',
  ProcessSteam = 'process_steam',
  CleanSteam = 'clean_steam',
  SteamControlled = 'steam_controlled',
  CondensateControlled = 'condensate_controlled'

}
