<!-- user menu start -->
<div class="usermenu-comp">

  <!-- menu block start -->
  <div class="menu-block" (click)="switchMenu();">
    <!-- menu icon start -->
    <img class="menu-icon" src="../../../assets/images/menu_icon.png" alt="menu_icon.png">
    <!-- menu icon end -->

    <!-- profile img start -->
    <img class="profile-img" src="{{userPhotoUrl || '../../../assets/images/user_icon.png'}}" alt="user photo">
    <!-- profile img end -->

  </div>
  <!-- menu block end -->

  <!-- dropdown menu start -->
  <div class="dropdown-menu" [ngClass]="{'active': openMenu}">
    <!-- items list start -->
    <ul class="items-list">
      <!-- item start -->
      <li class="item" *ngIf="adminOption">
        <!-- link start -->
        <a href="/admin" rel="noopener noreferrer" class="link">{{'usermenu.admin' | translate}}</a>
        <!-- link end -->
      </li>
      <!-- item end -->
      <!-- item start -->
      <li class="item" *ngIf="manageAccountsOption">
        <!-- link start -->
        <a href="/accounts" rel="noopener noreferrer" class="link">{{'usermenu.manageAccounts' | translate}}</a>
        <!-- link end -->
      </li>
      <!-- item end -->
      <!-- item start -->
      <li class="item">
        <!-- link start -->
        <a href="/settings" rel="noopener noreferrer" class="link">{{'usermenu.settings' | translate}}</a>
        <!-- link end -->
      </li>
      <!-- item end -->
      <!-- item start -->
      <li class="item" *ngIf="changeUsersOption">
        <!-- link start -->
        <a href="#" rel="noopener noreferrer" class="link" (click)="onChangeUser($event)">{{'usermenu.changeUser' |
          translate}}</a>
        <!-- link end -->
      </li>
      <!-- item end -->
      <!-- item start -->
      <li class="item">
        <!-- link start -->
        <a href="#" target="_blank" rel="noopener noreferrer" class="link" (click)="logOut($event)">{{'usermenu.logOut'
          | translate}}</a>
        <!-- link end -->
      </li>
      <!-- item end -->
    </ul>
    <!-- items list end -->
  </div>
  <!-- dropdown menu end -->

</div>
<!-- user menu end -->

<!-- user section start -->
<div class="user-section" *ngIf="showUserSelection">
  <!-- overlay start -->
  <div class="users-block">
    <!-- arrow left start -->
    <div class="arrow arrow-left"></div>
    <!-- arrow left end -->

    <!-- users wrapper start -->
    <div class="users-wrapper" [ngClass]="{'center': users.length === 2}">
      <!-- user start -->
      <div class="user" *ngFor="let user of users" (click)="selectUser(user);">
        <!-- users profile image start -->
        <div class="image">
          <img src="../../../assets/images/user_icon.png" alt="user photo" *ngIf="!user.photo">
          <img src="data:application/octet-stream;base64,{{user.photo}}" alt="user photo" *ngIf="user.photo">
        </div>
        <!-- users profile image end -->
        <!-- users name start -->
        <div class="name">{{user.name}} {{user.surname}}</div>
        <!-- users name end -->
      </div>
      <!-- user end -->
    </div>
    <!-- users wrapper end -->

    <!-- arrow left start -->
    <div class="arrow arrow-right"></div>
    <!-- arrow left end -->
  </div>
  <!-- overlay end -->
</div>
<!-- user section end -->
