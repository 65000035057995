<!-- tubesheets-infoc-comp start -->
<div class="tubesheets-info-comp">

  <!-- header start -->
  <app-header>
    <app-usermenu></app-usermenu>
  </app-header>
  <!-- header end -->

  <!-- main-container start -->
  <div class="main-container">

    <!-- progressbar start -->
    <app-progressbar></app-progressbar>
    <!-- progressbar end -->

    <!-- chart-container start -->
    <div class="chart-container">
      <canvas #canvas></canvas>
    </div>
    <!-- chart-container end -->


  </div>
  <!-- main-container end -->

</div>
<!-- tubesheets-infoc-comp end -->