import { Component, OnInit } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService, SnackBarService } from 'src/app/services';

@Component({
  selector: 'app-setup-password',
  templateUrl: './setup-password.component.html',
  styleUrls: ['./setup-password.component.scss']
})
export class SetupPasswordComponent implements OnInit {
  private code: string = null;

  public password: string = null;
  public confirmPassword: string = null;

  public passwordRegexp: RegExp = /^(?=.*[a-zA-Z\d])(?=.*[!@#$%^&*.]).{6,}$/gm;

  public forgotPasswordFormSubmited: boolean = false;

  constructor(private router: Router, private route: ActivatedRoute, private authService: AuthService, private snackBarService: SnackBarService) { }

  // Validation errors
  public passwordsNotEqual: boolean = false;
  public passwordNotValid: boolean = false;
  public allFieldsRequired: boolean = false;


  public ngOnInit(): void {

    // Get query param
    this.code = this.route.snapshot.queryParams['code'];
  }

  public cancel(): void {
    this.router.navigate(["/login"]);
  }

  public submitSetupPassword(form: NgForm): void {
    this.passwordsNotEqual = false;
    this.passwordNotValid = false;
    this.allFieldsRequired = false;


    if (!this.password || !this.confirmPassword) {
      this.allFieldsRequired = true;
      return;
    }

    this.password = this.password.trim()
    this.confirmPassword = this.confirmPassword.trim();

    if (this.password && this.confirmPassword) {
      this.passwordsNotEqual = this.password !== this.confirmPassword;

      if (this.passwordsNotEqual) {
        form.form.controls['password'].setErrors({ 'incorrect': true });
        form.form.controls['confirmPassword'].setErrors({ 'incorrect': true });
      }else{
        form.form.controls['password'].setErrors(null);
        form.form.controls['confirmPassword'].setErrors(null);
      }

    }

    if (!this.password || !this.password.match(this.passwordRegexp)) {
      this.passwordNotValid = true;
      form.form.controls['password'].setErrors({ 'incorrect': true });
    }else{
      form.form.controls['password'].setErrors(null);
    }

    if (form.valid) {

      this.authService.setupPassword(this.password, this.code).subscribe({
        next: () => {
          this.snackBarService.open('Password set successfully', 2).subscribe({
            next: () => {
              this.router.navigate(['login']);
            }
          });
        },
        error: (error) => {
          this.snackBarService.error(error);
        }
      })

    }

  }
}
