import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';

import { AuthService } from '../services';

export const authGuard: CanActivateFn = () => {

  const authService: AuthService = inject(AuthService);
  const router: Router = inject(Router);

  if(authService.isAuthenticated()) {
    return true;
  } else {
    router.navigate(["login"]);
    return false;
  }
};