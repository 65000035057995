<!-- application component start -->
<div class="application-comp">
  <!-- main container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">{{'journey.back'|translate}}</button>
      <button class="skip margin-left-auto" (click)="skipGuide()">{{'journey.skipAssistant'|translate}}</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="4"></app-progressbar>
    <!-- progressbar end -->

    <!-- main title start -->
    <div class="main-title">
      {{'applicationPage.title'|translate}}
    </div>
    <!-- main title end -->


    <!-- application section start -->
    <div class="application-section" [ngClass]="{'disabled-overlay': quotationStatus}">

      <div class="main-container">
        <!-- application block start -->
        <div class="application">

          <!-- side start-->
          <div class="side side-warm">

            <!-- title start -->
            <div class="title">{{'commonLabels.warmSide'|translate}}</div>
            <!-- title end -->

            <!-- items list start -->
            <div class="items-list">
              <!-- row start -->
              <div class="row">
                <!-- item start -->
                <label class="item" [ngClass]="{'active': warmApplicationValue === 'CO'}">
                  <img src="../../../assets/images/condensation_warm.png" alt="condensation_warm.png" class="item-img">
                  <input type="radio" name="warmApplication" value="CO" [(ngModel)]="warmApplicationValue">
                </label>
                <!-- item end -->
              </div>
              <!-- row end -->

              <!-- row start -->
              <div class="row main">
                <!-- item start -->
                <label class="item" [ngClass]="{'active': warmApplicationValue === 'LI'}">
                  <img src="../../../assets/images/liquid_warm.png" alt="liquid_warm.png" class="item-img">
                  <input type="radio" name="warmApplication" value="LI" [(ngModel)]="warmApplicationValue">
                </label>
                <!-- item end -->
              </div>
              <!-- row end -->

              <!-- row start -->
              <div class="row">
                <!-- item start -->
                <label class="item" [ngClass]="{'active': warmApplicationValue === 'GA'}">
                  <img src="../../../assets/images/gas_warm.png" alt="gas_warm.png" class="item-img">
                  <input type="radio" name="warmApplication" value="GA" [(ngModel)]="warmApplicationValue">
                </label>
                <!-- item end -->
              </div>
              <!-- row end -->
            </div>
            <!-- items list end -->

          </div>
          <!-- side end-->

          <!-- selected block start -->
          <div class="selected-block">

            <!-- warm title start -->
            <div class="warm-title">
              <ng-container [ngSwitch]="warmApplicationValue">

                <ng-container *ngSwitchCase="'LI'">
                  {{'applicationPage.LI'|translate}}
                </ng-container>

                <ng-container *ngSwitchCase="'GA'">
                  {{'applicationPage.GA'|translate}}
                </ng-container>

                <ng-container *ngSwitchCase="'CO'">
                  {{'applicationPage.CO'|translate}}
                </ng-container>

                <ng-container *ngSwitchCase="'EV'">
                  {{'applicationPage.EV'|translate}}
                </ng-container>

              </ng-container>
            </div>
            <!-- warm title end -->

            <!-- cold title start -->
            <div class="cold-title">
              <ng-container [ngSwitch]="coldApplicationValue">

                <ng-container *ngSwitchCase="'LI'">
                  {{'applicationPage.LI'|translate}}
                </ng-container>

                <ng-container *ngSwitchCase="'GA'">
                  {{'applicationPage.GA'|translate}}
                </ng-container>

                <ng-container *ngSwitchCase="'CO'">
                  {{'applicationPage.CO'|translate}}
                </ng-container>

                <ng-container *ngSwitchCase="'EV'">
                  {{'applicationPage.EV'|translate}}
                </ng-container>

              </ng-container>
            </div>
            <!-- cold title end -->

            <!-- btn-new start -->
            <button type="button" class="btn" (click)="onContinue()">{{'journey.continue'|translate}}</button>
            <!-- btn-new end -->
          </div>
          <!-- selected block end -->

          <!-- side start-->
          <div class="side side-cold">

            <!-- title start -->
            <div class="title">{{'commonLabels.coldSide'|translate}}</div>
            <!-- title end -->

            <!-- items list start -->
            <div class="items-list">
              <!-- row start -->
              <div class="row">
                <!-- item start -->
                <label class="item" [ngClass]="{'active': coldApplicationValue === 'EV'}">
                  <img src="../../../assets/images/evaporation_cold.png" alt="evaporation_cold.png" class="item-img">
                  <input type="radio" name="coldApplication" value="EV" [(ngModel)]="coldApplicationValue">
                </label>
                <!-- item end -->
              </div>
              <!-- row end -->

              <!-- row start -->
              <div class="row main">
                <!-- item start -->
                <label class="item" [ngClass]="{'active': coldApplicationValue === 'LI'}">
                  <img src="../../../assets/images/liquid_cold.png" alt="liquid_cold.png" class="item-img">
                  <input type="radio" name="coldApplication" value="LI" [(ngModel)]="coldApplicationValue">
                </label>
                <!-- item end -->
              </div>
              <!-- row end -->

              <!-- row start -->
              <div class="row">
                <!-- item start -->
                <label class="item" [ngClass]="{'active': coldApplicationValue === 'GA'}">
                  <img src="../../../assets/images/gas_cold.png" alt="gas_cold.png" class="item-img">
                  <input type="radio" name="coldApplication" value="GA" [(ngModel)]="coldApplicationValue">
                </label>
                <!-- item end -->
              </div>
              <!-- row end -->
            </div>
            <!-- items list end -->
          </div>
          <!-- side end-->

        </div>
        <!-- application block end -->
      </div>

    </div>
    <!-- application section end -->

  </div>
  <!-- main container end -->
</div>
<!-- application component end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="4"></app-zilo-hints>
<!-- zilo-hints end -->
