<!-- size-restrictions-comp start -->
<div class="size-restrictions-comp">
  <!-- main-container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">Back</button>
      <button class="skip margin-left-auto" (click)="skipGuide()">Skip Assistant</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="6"></app-progressbar>
    <!-- progressbar end -->

    <!-- page-title start -->
    <div class="page-title">Are there restrictions in the installation space?</div>
    <!-- page-title end -->

    <!-- parameters-container start -->
    <div class="parameters-container" *ngIf="units">

      <!-- column start -->
      <div class="column column-labels">
        <!-- label start -->
        <div class="parameter-main-label">Yes, maximum …</div>
        <!-- label end -->
        <!-- label start -->
        <div class="parameter-main-label">No</div>
        <!-- label end -->
      </div>
      <!-- column end -->

      <!-- column start -->
      <div class="column">
        <!-- column-title start -->
        <div class="column-title">
          Length
        </div>
        <!-- column-title end -->

        <!-- parameter-input start -->
        <input type="number" class="parameter-input" name="length" [ngModel]="parameters.length.userUnits"
          (ngModelChange)="onInputParamChange('length',$event)">
        <!-- parameter-input end -->

        <!-- parameter-radio start -->
        <div class="parameter-radio-block">
          <input type="radio" id="radioLength" name="radioLength" value="999999999" [(ngModel)]="parameters.length.radio"
            (change)="onRadioInputChange('length',$event)">
          <label for="radioLength"></label>
        </div>
        <!-- parameter-radio end -->

      </div>
      <!-- column end -->

      <!-- column start -->
      <div class="column">
        <!-- column-title start -->
        <div class="column-title">
          Width
        </div>
        <!-- column-title end -->

        <!-- parameter-input start -->
        <input type="number" class="parameter-input" name="width" [ngModel]="parameters.width.userUnits"
          (ngModelChange)="onInputParamChange('width',$event)">
        <!-- parameter-input end -->

        <!-- parameter-radio start -->
        <div class="parameter-radio-block">
          <input type="radio" id="radioWidth" name="radioWidth" value="999999999" [(ngModel)]="parameters.width.radio"
            (change)="onRadioInputChange('width',$event)">
          <label for="radioWidth"></label>
        </div>
        <!-- parameter-radio end -->

      </div>
      <!-- column end -->

      <!-- column start -->
      <div class="column">
        <!-- column-title start -->
        <div class="column-title">
          Height
        </div>
        <!-- column-title end -->

        <!-- parameter-input start -->
        <input type="number" class="parameter-input" name="height" [ngModel]="parameters.height.userUnits"
          (ngModelChange)="onInputParamChange('height',$event)">
        <!-- parameter-input end -->

        <!-- parameter-radio start -->
        <div class="parameter-radio-block">
          <input type="radio" id="radioHeight" name="radioHeight" value="999999999" [(ngModel)]="parameters.height.radio"
            (change)="onRadioInputChange('height',$event)">
          <label for="radioHeight"></label>
        </div>
        <!-- parameter-radio end -->

      </div>
      <!-- column end -->

      <!-- column start -->
      <div class="column column-unit">
        <!-- parameter-unit-select start -->
        <select name="unit" id="unit" class="parameter-unit-select" [ngModel]="selectedPhysicalUnitId"
          (ngModelChange)="onUnitChange($event)">
          <option *ngFor="let unit of units.units.length" [ngValue]="unit.physicalUnitId">{{unit.unitSign}}</option>
        </select>
        <!-- parameter-unit-select end -->
      </div>
      <!-- column end -->

    </div>
    <!-- parameters-container end -->

    <!-- erro-message  start -->
    <div class="error-message" *ngIf="error">{{errorMessage}}</div>
    <!-- erro-message  end -->

    <!-- btn-new start -->
    <button type="button" class="btn" (click)="onContinue()">continue</button>
    <!-- btn-new end -->

  </div>
  <!-- main-container end -->
</div>
<!-- size-restrictions-comp end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="6"></app-zilo-hints>
<!-- zilo-hints end -->
