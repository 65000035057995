import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StateService {

  private _state: InternalStateType = {};

  constructor() { }

  public get state() {
    return Object.assign({}, this._state);
  }

  public get(key: string) {
    return this._state.hasOwnProperty(key) ? this._state[key] : null;
  }

  public set(key: string, value: any) {
    return this._state[key] = value;
  }

  public store(key: string, value: any, permanent: boolean = false) {

    const item = { value };
    if (permanent) {
      localStorage.setItem(key, JSON.stringify(item));
    } else {
      sessionStorage.setItem(key, JSON.stringify(item));
    }

    this.set(key, value);
  }

  public restore(key: string) {
    const value = sessionStorage.getItem(key) || localStorage.getItem(key);
    if (!!value) {

      const item = JSON.parse(value);
      this.set(key, item.value);
      return item.value;

    } else {

      return;
    }
  }

  /**
   * Remove item from `localstorage`.
   * @param key 
   */
  public remove(key: string) {
    localStorage.removeItem(key);
  }

  /**
   * Delete property from `state`.
   * @param key 
   */
  public delete(key: string) {
    delete this._state[key];
  }

}

export type InternalStateType = {
  [key: string]: any
};
