<!-- viewer3d-comp start -->
<div class="viewer3d-comp">
  <!-- view-modal start -->
  <div class="view-modal">

    <!-- top-buttons-container start-->
    <div class="top-buttons-container">

      <!-- download start -->
      <svg class="download-btn" xmlns="http://www.w3.org/2000/svg" enable-background="new 0 0 24 24" height="24px"
        viewBox="0 0 24 24" width="24px" fill="#23a08c" (click)="vmSaveModel()">
        <g>
          <rect fill="none" height="24" width="24" />
        </g>
        <g>
          <path
            d="M18,15v3H6v-3H4v3c0,1.1,0.9,2,2,2h12c1.1,0,2-0.9,2-2v-3H18z M17,11l-1.41-1.41L13,12.17V4h-2v8.17L8.41,9.59L7,11l5,5 L17,11z" />
        </g>
      </svg>
      <!-- download end -->


      <!-- close start -->
      <svg class="close-btn" xmlns="http://www.w3.org/2000/svg" height="20px" viewBox="0 -960 960 960" width="20px"
        fill="#000" (click)="onClose()">
        <path d="m291-240-51-51 189-189-189-189 51-51 189 189 189-189 51 51-189 189 189 189-51 51-189-189-189 189Z" />
      </svg>
      <!-- close end -->

    </div>
    <!-- top-buttons-container end-->

    <!-- drawing-container start -->
    <div class="drawing-container" id="drawing-container"></div>
    <!-- drawing-container end -->

  </div>
  <!-- view-modal end -->
</div>
<!-- viewer3d-comp end -->