<!-- gas-comp start -->
<div class="gas-comp" [ngClass]="{'cold-side': side==='cold'}" *ngIf="!!unitSystems">
  <!-- parameter start -->
  <div class="parameter-combobox">

    <!-- label start -->
    <label for="gas-{{side}}" class="parameter-label">Fluid</label>
    <!-- label end -->

    <!-- parameter input block start -->
    <div class="parameter-input-block">

      <!-- input field start -->
      <input class="parameter-input-field" type="text" name="gas-{{side}}" id="fluid-{{side}}" [ngModel]="liquid.name"
        [disabled]="true" />
      <!-- input field end -->

      <!-- actions and units block start -->
      <div class="actions-and-units-block">

        <!-- open icon start -->
        <div class="open-icon" (click)="treeMenu = !treeMenu">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
          </svg>
        </div>
        <!-- open icon end -->

      </div>
      <!-- actions and units block end -->

    </div>
    <!-- parameter input block end -->

    <!-- dropdown tree menu start -->
    <div class="dropdown-tree-menu" *ngIf="treeMenu">
      <mat-tree [dataSource]="treeData" [treeControl]="treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
          This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="onLiquidSelect(node)">
          {{node.name}}
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle
          (click)="onLiquidSelect(node)">
          <div class="mat-tree-node">
            <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'folder_open' : 'folder'}}
              </mat-icon>
            </button>
            {{node.name}}
          </div>
          <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
          <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
    <!-- dropdown tree menu end -->


  </div>
  <!-- parameter end-->

  <!-- parameter start -->
  <div class="parameter">

    <!-- label start -->
    <label for="massFlowRate-{{side}}" class="parameter-label">Mass flow rate</label>
    <!-- label end -->

    <!-- mass flow rate parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.massFlowRate.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="massFlowRate-{{side}}" id="massFlowRate-{{side}}"
        [ngModel]="parameters.massFlowRate.value.userUnits" (ngModelChange)="onParameterChange('massFlowRate',$event)">
      <!-- input field end -->

      <!-- parameter-unit-select start -->
      <select name="massFlowRate-unit-{{side}}" id="massFlowRate-unit-{{side}}" class="parameter-unit-select"
        [ngModel]="parameters.massFlowRate.unitSystemId"
        (ngModelChange)="onUnitChange('massFlowRate','mass_flow_rate',$event)">
        <option *ngFor="let unit of unitSystems.mass_flow_rate" [ngValue]="unit.unitSystemId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- mass flow rate parameter input block end -->

  </div>
  <!-- parameter end-->

  <!-- parameter start -->
  <div class="parameter">

    <!-- label start -->
    <label for="inletPressure-{{side}}" class="parameter-label">Inlet pressure</label>
    <!-- label end -->

    <!-- parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.inletPressure.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="inletPressure-{{side}}" id="inletPressure-{{side}}"
        [ngModel]="parameters.inletPressure.value.userUnits"
        (ngModelChange)="onParameterChange('inletPressure',$event)">
      <!-- input field end -->
      <!-- parameter-unit-select start -->
      <select name="inletPressure-unit-{{side}}" id="inletPressure-unit-{{side}}" class="parameter-unit-select"
        [ngModel]="parameters.inletPressure.unitSystemId"
        (ngModelChange)="onUnitChange('inletPressure','pressure',$event)">
        <option *ngFor="let unit of unitSystems.pressure" [ngValue]="unit.unitSystemId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- parameter input block end -->

  </div>
  <!-- parameter end-->

  <!-- parameter start -->
  <div class="parameter">

    <!-- label start -->
    <label for="inletTemperature-{{side}}" class="parameter-label">Inlet temperature</label>
    <!-- label end -->

    <!-- inlet temperatureparameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.inletTemp.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="inletTemperature-{{side}}" id="inletTemperature-{{side}}"
        [ngModel]="parameters.inletTemp.value.userUnits" (ngModelChange)="onParameterChange('inletTemp',$event)">
      <!-- input field end -->
      <!-- parameter-unit-select start -->
      <select name="inletTemperature-unit-{{side}}" id="inletTemperature-unit-{{side}}" class="parameter-unit-select"
        [ngModel]="parameters.inletTemp.unitSystemId" (ngModelChange)="onUnitChange('inletTemp','temperature',$event)">
        <option *ngFor="let unit of unitSystems.temperature" [ngValue]="unit.unitSystemId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- inlet temperatureparameter input block end -->

  </div>
  <!-- parameter end-->

  <!-- outlet temperature parameter start -->
  <div class="parameter" *ngIf="!extraDuty">

    <!-- label start -->
    <label for="outletTemperature-{{side}}" class="parameter-label">Outlet temperature</label>
    <!-- label end -->

    <!-- parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.outletTemp.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="outletTemperature-{{side}}"
        id="outletTemperature-{{side}}" [ngModel]="parameters.outletTemp.value.userUnits"
        (ngModelChange)="onParameterChange('outletTemp',$event)">
      <!-- input field end -->

      <!-- parameter-unit-select start -->
      <select name="outletTemperature-unit-{{side}}" id="outletTemperature-unit-{{side}}" class="parameter-unit-select"
        [ngModel]="parameters.outletTemp.unitSystemId"
        (ngModelChange)="onUnitChange('outletTemp','temperature',$event)">
        <option *ngFor="let unit of unitSystems.temperature" [ngValue]="unit.unitSystemId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- parameter input block end -->

  </div>
  <!-- outlet temperature parameter end-->

  <!-- max pressure drop parameter start -->
  <div class="parameter" *ngIf="!extraDuty">

    <!-- label start -->
    <label for="maxPressureDrop-{{side}}" class="parameter-label">Max pressure drop</label>
    <!-- label end -->

    <!-- parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.maxPressureDrop.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="maxPressureDrop-{{side}}" id="maxPressureDrop-{{side}}"
        [ngModel]="parameters.maxPressureDrop.value.userUnits"
        (ngModelChange)="onParameterChange('maxPressureDrop',$event)">
      <!-- input field end -->
      <!-- parameter-unit-select start -->
      <select name="maxPressureDrop-unit-{{side}}" id="maxPressureDrop-unit-{{side}}" class="parameter-unit-select"
        [ngModel]="parameters.maxPressureDrop.unitSystemId"
        (ngModelChange)="onUnitChange('maxPressureDrop','pressure',$event)">
        <option *ngFor="let unit of unitSystems.pressure" [ngValue]="unit.unitSystemId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- parameter input block end -->

  </div>
  <!-- max pressure drop parameter end-->
</div>
<!-- gas-comp end -->