import { Component, AfterViewInit, Input, Output, EventEmitter, HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-heat-exchanger-view',
  templateUrl: './heat-exchanger-view.component.html',
  styleUrls: ['./heat-exchanger-view.component.scss']
})
export class HeatExchangerViewComponent implements AfterViewInit {

  private imagesAmount: number = 30;
  private isDragging: boolean = false;
  private startX: number = 0;

  public imagesUrls: string[] = null;
  public currentImage: number = 1;
  public rotateIconHide: boolean = false;

  @Input()
  public modelId: number = null;
  @Input()
  public modelName: string = null;

  @Output()
  public close: EventEmitter<boolean> = new EventEmitter();

  @HostListener('document:mousemove', ['$event'])
  public onMouseMove(event: MouseEvent): void {
    if (this.isDragging) {
      const currentX = event.clientX;
      if (currentX > this.startX) {
        this.startX = currentX;
        this.nextImage();
      } else if (currentX < this.startX) {
        this.startX = currentX;
        this.previousImage();
      }
    }
  };

  @HostListener('document:mouseup')
  public onMouseUp(): void {
    this.isDragging = false;
    this.rotateIconHide = false;
  };

  constructor() { };
  public ngAfterViewInit(): void {
    if(this.modelName.startsWith('SFB')){
      this.imagesUrls = [...new Array(this.imagesAmount).keys()].map((_value, _index: number) => `${environment.base}/public_images/sfb/example_brazed_plate_${_index + 1}.png`);
    }else if(this.modelName.startsWith('SF')){
      this.imagesUrls = [...new Array(this.imagesAmount).keys()].map((_value, _index: number) => `${environment.base}/public_images/sf/plate_heat_${_index + 1}.png`);

    }else{
    this.imagesUrls = [...new Array(this.imagesAmount).keys()].map((_value, _index: number) => `${environment.base}/public_images/models/${this.modelId}/${_index + 1}.png`);
    }
  };
  public onMouseDown(event: MouseEvent): void {
    this.isDragging = true;
    this.rotateIconHide = true;
    this.startX = event.clientX;
  };

  public onClose(): void {
    this.close.emit(true);
  }

  private nextImage(): void {
    if (this.currentImage === 30) {
      this.currentImage = 1;
    } else {
      this.currentImage = this.currentImage + 1;
    };
  };

  private previousImage(): void {
    if (this.currentImage === 1) {
      this.currentImage = 30;
    } else {
      this.currentImage = this.currentImage - 1;
    }
  };
}
