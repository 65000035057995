<!-- design-parameters-comp start -->
<div class="design-parameters-comp">

  <!-- main-container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">Back</button>
      <button class="skip margin-left-auto" (click)="skipGuide()">Skip Assistant</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="2"></app-progressbar>
    <!-- progressbar end -->

    <!-- page-title start -->
    <div class="page-title">Please enter your maximum design parameters.</div>
    <!-- page-title end -->

    <!-- parameters-container start -->
    <div class="parameters-container" *ngIf="units">

      <!-- sidestart -->
      <div class="side warm">

        <!-- side-title start -->
        <div class="side-title">WARM SIDE</div>
        <!-- side-title end -->

        <!-- parameter-block start -->
        <div class="parameter-block">

          <!-- parameter-label start -->
          <label for="warmPressure" class="parameter-label">Max. pressure</label>
          <!-- parameter-label end -->

          <!-- parameter-input start -->
          <input type="number" id="warmPressure" class="parameter-input" [ngModel]="parameters.warm.pressure.userUnits"
            (ngModelChange)="onInputChange('warm','pressure',$event)">
          <!-- parameter-input end -->

          <!-- parameter-unit-select start -->
          <select name="warmPressureUnit" id="warmPressureUnit" class="parameter-unit-select"
            [ngModel]="parameters.warm.pressure.physicalUnitId" (ngModelChange)="onUnitChange('warm','pressure',$event)">
            <option *ngFor="let unit of units.units.pressure" [ngValue]="unit.physicalUnitId">{{unit.unitSign}}</option>
          </select>
          <!-- parameter-unit-select end -->

        </div>
        <!-- parameter-block end -->
        <!-- parameter-block start -->
        <div class="parameter-block">

          <!-- parameter-label start -->
          <label for="warmTemp" class="parameter-label">Max. temperature</label>
          <!-- parameter-label end -->

          <!-- parameter-input start -->
          <input type="number" id="warmTemp" class="parameter-input" [ngModel]="parameters.warm.temperature.userUnits"
            (ngModelChange)="onInputChange('warm','temperature',$event)">
          <!-- parameter-input end -->

          <!-- parameter-unit-select start -->
          <select name="warmTempUnit" id="warmTempUnit" class="parameter-unit-select"
            [ngModel]="parameters.warm.temperature.physicalUnitId"
            (ngModelChange)="onUnitChange('warm','temperature',$event)">
            <option *ngFor="let unit of units.units.temperature" [ngValue]="unit.physicalUnitId">{{unit.unitSign}}</option>
          </select>
          <!-- parameter-unit-select end -->
        </div>
        <!-- parameter-block end -->

      </div>
      <!-- side end -->

      <!-- sidestart -->
      <div class="side cold">

        <!-- side-title start -->
        <div class="side-title">COLD SIDE</div>
        <!-- side-title end -->

        <!-- parameter-block start -->
        <div class="parameter-block">

          <!-- parameter-input start -->
          <input type="number" id="coldPressure" class="parameter-input" [ngModel]="parameters.cold.pressure.userUnits"
            (ngModelChange)="onInputChange('cold','pressure',$event)">
          <!-- parameter-input end -->

          <!-- parameter-unit-select start -->
          <select name="coldPressureUnit" id="coldPressureUnit" class="parameter-unit-select"
            [ngModel]="parameters.cold.pressure.physicalUnitId" (ngModelChange)="onUnitChange('cold','pressure',$event)">
            <option *ngFor="let unit of units.units.pressure" [ngValue]="unit.physicalUnitId">{{unit.unitSign}}</option>
          </select>
          <!-- parameter-unit-select end -->

        </div>
        <!-- parameter-block end -->
        <!-- parameter-block start -->
        <div class="parameter-block">

          <!-- parameter-input start -->
          <input type="number" id="coldTemp" class="parameter-input" [ngModel]="parameters.cold.temperature.userUnits"
            (ngModelChange)="onInputChange('cold','temperature',$event)">
          <!-- parameter-input end -->

          <!-- parameter-unit-select start -->
          <select name="coldTempUnit" id="coldTempUnit" class="parameter-unit-select"
            [ngModel]="parameters.cold.temperature.physicalUnitId"
            (ngModelChange)="onUnitChange('cold','temperature',$event)">
            <option *ngFor="let unit of units.units.temperature" [ngValue]="unit.physicalUnitId">{{unit.unitSign}}</option>
          </select>
          <!-- parameter-unit-select end -->

        </div>
        <!-- parameter-block end -->

      </div>
      <!-- side end -->

    </div>
    <!-- parameters-container end -->

    <!-- erro-message  start -->
    <div class="error-message" *ngIf="noParamsEnetered">Please enter all design parameters</div>
    <!-- erro-message  end -->
    <!-- erro-message  start -->
    <div class="error-message" *ngIf="temperatureIncorrect">Max temperature is too high</div>
    <!-- erro-message  end -->
    <!-- erro-message  start -->
    <div class="error-message" *ngIf="pressureIncorrect">Max pressure is too high</div>
    <!-- erro-message  end -->

    <!-- btn-new start -->
    <button type="button" class="btn" (click)="onContinue()">continue</button>
    <!-- btn-new end -->

  </div>
  <!-- main-container end -->

</div>
<!-- design-parameters-comp end -->
 
<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="2"></app-zilo-hints>
<!-- zilo-hints end -->