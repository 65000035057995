<!-- registration component start -->

<ng-container>
  <!-- header start -->
  <app-header>
    <app-language-menu (onLanguageChange)="vmOnLanguageChange($event)"></app-language-menu>
  </app-header>
  <!-- header end -->

  <!-- registration section start -->
  <div class="registration-section">
    <!-- main container start -->
    <div class="main-container">
      <!-- registration form start -->
      <form #registrationForm="ngForm" class="registration-form" (submit)="submit(registrationForm)"
        *ngIf="!registrationSuccessful">

        <!-- field start -->
        <div class="field field-tips">
          <label for="companyName">{{'registration.form.fields.companyName' | translate}}:</label>

          <!-- input-wrapper start -->
          <div class="input-wrapper">
            <span class="tips">Please include the legal form (e.g. Company XY Ltd.)</span>
            <input type="text" name="companyName" id="companyName" [(ngModel)]="formCredentials.companyName"
              (keydown)="preventEnterKeyTigger($event)" required>
          </div>
          <!-- input-wrapper end -->

        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="firstName">{{'registration.form.fields.firstName' | translate}}:</label>
          <input type="text" name="firstName" id="firstName" [(ngModel)]="formCredentials.name"
            (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="surname">{{'registration.form.fields.lastName' | translate}}:</label>
          <input type="text" name="surname" id="surname" [(ngModel)]="formCredentials.surname"
            (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- field start -->
        <div class="field">
          <label for="mail">{{'registration.form.fields.email' | translate}}:</label>
          <input type="email" name="mail" id="mail" [(ngModel)]="formCredentials.mail"
            patterns="^[^\s@]+@[^\s@]+\.[^\s@]+$" (keydown)="preventEnterKeyTigger($event)" required>
        </div>
        <!-- field end -->

        <!-- invalid message start -->
        <div class="invalidMsg" *ngIf="allFieldsRequired">
          {{'registration.form.validationMsg.allFieldsRequired'|translate}}</div>
        <!-- invalid message end -->

        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="completeCaptcha">
          {{'registration.form.validationMsg.completeCaptcha'|translate}}</div>
        <!-- not equal passwords message end -->



        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="emailNotValid">{{'registration.form.validationMsg.emailNotValid'|translate}}
        </div>
        <!-- not equal passwords message end -->

        <!-- invalid message start -->
        <div class="invalidMsg" *ngIf="apiErrorMessage">{{apiErrorMessage}}</div>
        <!-- invalid message end -->

        <div class="field">
          <!-- reCAPTCHA -->
          <re-captcha
            siteKey="6LceYYkqAAAAAPkwqCI7hPKepygkU80TI1Z08N4D"
            (resolved)="onCaptchaResolved($event)">
          </re-captcha>
        </div>

        <!-- buttons block start -->
        <div class="buttons-block">
          <!-- button start -->
          <button class="btn btn-cancel" (click)="cancel($event);">{{'registration.form.buttons.cancel' |
            translate}}</button>
          <!-- button end -->
          <!-- button start -->
          <button type="submit" class="btn btn-register">{{'registration.form.buttons.register' | translate}}</button>
          <!-- button end -->
        </div>
        <!-- buttons block end -->
      </form>
      <!-- registration form end -->

      <!-- registration successful message start -->
      <div class="msg-after-reg" *ngIf="registrationSuccessful">We will review your registration data and send you an
        email when the account will be
        active</div>
      <!-- registration successful message end -->

    </div>
    <!-- main container end -->
  </div>
  <!-- registration section end -->

</ng-container>

<!-- registration component end -->
