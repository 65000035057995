import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { forkJoin, switchMap } from 'rxjs';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { ICountryResp, INewRequirementsResponse, Role, User } from 'src/app/services/interfaces';

@Component({
  selector: 'app-assistant-country',
  templateUrl: './country.component.html',
  styleUrls: ['./country.component.scss']
})
export class AssistantCountryComponent implements OnInit {
  private quotationId: number = null;
  private itemId: number = null;
  private user: User = null;
  private existingRequirement: INewRequirementsResponse = null;


  public countries: ICountryResp[] & { title?: string; } = null;
  public selectedCountry: number = null;
  public countryNotSelected: boolean = false;

  constructor(private apiService: ApiService, private stateService: StateService, private snackBarService: SnackBarService, private route: ActivatedRoute, private router: Router,) { }

  public ngOnInit(): void {
    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];
    this.user = this.stateService.get('user');

    const _userLang: string = this.user.idLang;
    let contriesList: ICountryResp[] = null;

    const countries$ = this.apiService.getCountries().pipe(switchMap((countries: ICountryResp[]) => {
      contriesList = countries;
      const ids: number[] = countries.map((_country) => _country.countryNameId);

      return forkJoin({
        userLang: this.apiService.getTranslation(ids, _userLang),
        defaultLang: this.apiService.getTranslation(ids, 'EN'),
        itemReqs: this.apiService.getItemRequirements(this.itemId)
      });

    })).subscribe({
      next: ({ userLang, defaultLang, itemReqs }) => {

        this.countries = contriesList.map((_country) => {

          const userLangTranslation = userLang.find((_x) => _x.translationId === _country.countryNameId)?.value;
          const defaultLangTranslation = defaultLang.find((_x) => _x.translationId === _country.countryNameId)?.value;
          const title: string = userLangTranslation || defaultLangTranslation;

          return Object.assign({ title }, _country);
        }).sort((a, b) => a.title.localeCompare(b.title));
        if (this.user.defaultCountry)
          this.selectedCountry = Number(this.user.defaultCountry);
        this.existingRequirement = itemReqs.find((_req) => _req.requirementType === 'country');
        if (this.existingRequirement) {
          //this.existingRequirement.requirementValue = JSON.parse(this.existingRequirement.requirementValue);
          this.selectedCountry = this.existingRequirement.requirementValue.country;
        };
        countries$.unsubscribe();
      },
      error: (error) => {
        this.snackBarService.open();
        countries$.unsubscribe();
      }
    })

  };

  public onContinue(): void {
    // If country not selected
    // show error and return
    if (!this.selectedCountry) {
      this.countryNotSelected = true;
      return;
    };

    let observable = null;

    const country = this.countries.find((_x) => _x.countryId === +this.selectedCountry);

    if (this.existingRequirement) {
      observable = this.apiService.updateItemRequirements(this.existingRequirement.requirementId, { requirementType: 'country', requirementValue: { country: country.countryId, regulatory: country.regulatory } });
    } else {
      observable = this.apiService.saveItemRequirements(this.itemId, { requirementType: 'country', requirementValue: { country: country.countryId, regulatory: country.regulatory } });
    }

    observable.pipe(switchMap(() => {
      return this.apiService.updateItem(this.itemId, { quotationId: this.quotationId, itemStatus: '1', itemType: 'ST', itemNumber: '1' });
    })).subscribe({
      next: () => {
        this.router.navigate([this.quotationId, this.itemId, 'design_parameters'], { relativeTo: this.route.parent });
      },
      error: (error) => {
        this.snackBarService.open();
      }
    });


  };

  public skipGuide(): void {

    const observable = this.apiService.skipGuide(this.itemId).subscribe({
      next: (itemId: number) => {
        let url: string = null;
        if (this.user.roles.includes(Role.UserAdmin)) {
          url = 'useradmin/configurator';
        } else {
          url = 'user/configurator';
        }
        observable.unsubscribe();
        this.router.navigate([url, this.quotationId, this.itemId, 'duty']);
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    })

  };

}
