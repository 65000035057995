import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { catchError, forkJoin, interval, of, Subscription, switchMap, tap } from 'rxjs';

import * as THREE from "three";
import { DxfViewer } from "src/dxf-viewer/dxf-viewer.js";
import { saveAs } from 'file-saver';

import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { ISolution, ISolutionConfigComponent, ISolutionConfigComponents, Role, User } from 'src/app/services/interfaces';
import { environment } from "../../../environments/environment";

@Component({
  selector: 'app-solution',
  templateUrl: './solution.component.html',
  styleUrls: ['./solution.component.scss']
})
export class SolutionComponent implements OnInit, OnDestroy {
  private quotationId: number = null;
  private itemId: number = null;
  private lang: string = null;
  private user: User = null;

  private physicalPropsNameList: string[] = ['length', 'weight', 'pressure', 'pressure_drop', 'temperature', 'mass_flow_rate', 'heat_exchange_rate', 'area'];
  private regulatoryText = {
    1: 'AD 2000',
    2: 'ASME Sec. VIII Div.1',
    3: 'Australian Standard',
    4: 'EN 13445',
  };

  public previousPageUrl: string = null;
  public backToHomePageUrl: string = null;
  public extraDutyUrl: string = null;
  public physicalPropsUserUnitSystemData: { [key: string]: { unitSign: string; uniTitle: string; addFactor: number; multFactor: number; } } = null;

  /**Solution data */
  public solutionId: number = null;
  public solution: ISolution = null;
  public solutionComponents: ISolutionConfigComponents = null;
  public dxfViewerFV: DxfViewer = null;
  public dxfViewerSV: DxfViewer = null;

  public dxfViewerFVLayers: LayerInfo[] = null;
  public dxfViewerSVLayers: LayerInfo[] = null;

  /** Parsed solution name */
  public solutionName: string[] = null;
  public solutionProductType: string = null;
  public solutionTemaType: string = null;
  public userCurrency: string = null;
  public admin: boolean = null;
  public solutionDrawing: ArrayBuffer = null;

  // Modal flags
  public view360Modal: boolean = false;
  public sendOrderModal: boolean = false;
  public orderCreatedModal: boolean = false;

  public tableParameters: ITableParameters = {
    length: null,
    width: null,
    height: null,
    weight: null,
    imageSrc: null,
    excessArea: null,
    shellSidePressureDrop: null,
    tubeSidePressureDrop: null,
    deliveryTime: null,
    deliveryTimeIsWeek: null,
    price: null,
    optionsPrice: null,
    netOptionsPrice: null,
    discountValue: null,
    discountPercent: null,
    totalPrice: null,
  };
  public pheParameters: IPheParameters = {
    frame: null,
    plateArrangement: null,
    designCombination: null,
    effArea: null,
    excessArea: null,
    pressureDropRealOnHotSide: null,
    pressureDropRealOnColdSide: null,
    image: null,
  };
  public materialParameters: IMaterialParameters = {
    shellMaterial: { name: null, description: null },
    tubeSheetMaterial: { name: null, description: null },
    tubeMaterial: { name: null, description: null },
    headMaterial: { name: null, description: null },
    supportsMaterial: { name: null, description: null },
    steamDrumMaterial: { name: null, description: null },
    regulatory: null,
    ped: null,
    module: null,
  };
  public sideParameters: { warm: ISideParameters; cold: ISideParameters; overdesign: number; type: string; } = {
    warm: {
      fluidName: null,
      inletTemp: null,
      outletTemp: null,
      workPressure: null,
      maxPressureDrop: null,
      maxPressure: null,
      maxTemperature: null,
      massFlowRate: null
    },
    cold: {
      fluidName: null,
      inletTemp: null,
      outletTemp: null,
      workPressure: null,
      maxPressureDrop: null,
      maxPressure: null,
      maxTemperature: null,
      massFlowRate: null
    },
    overdesign: null,
    type: null
  };
  public extraDuties: ILoadcase[] = null;

  public gaskets: ISolutionConfigComponent = null;

  // Select boxes for warm/cold parameters
  public warmInletPort: ISolutionConfigComponent = null;
  public warmInletFlangePN: ISolutionConfigComponent = null;
  public warmInletFlange: ISolutionConfigComponent = null;
  public warmOutletPort: ISolutionConfigComponent = null;
  public warmOutletFlangePN: ISolutionConfigComponent = null;
  public warmOutletFlange: ISolutionConfigComponent = null;

  public coldInletPort: ISolutionConfigComponent = null;
  public coldInletFlangePN: ISolutionConfigComponent = null;
  public coldInletFlange: ISolutionConfigComponent = null;
  public coldOutletPort: ISolutionConfigComponent = null;
  public coldOutletFlangePN: ISolutionConfigComponent = null;
  public coldOutletFlange: ISolutionConfigComponent = null;

  public evaporationExtraPorts: IExtraPorts = {
    safetyValvePort: {
      PortPipeSafetyValve: null,
      PortPnSafetyValve: null,
      PortFlangeSafetyValve: null,
      rowVisible: false
    },
    drainPort: {
      PortPipeDrain: null,
      PortPnDrain: null,
      PortFlangeDrain: null,
      rowVisible: false
    },
    temperaturePort: {
      PortPipeTemp: null,
      PortPnTemp: null,
      PortFlangeTemp: null,
      rowVisible: false
    },
    pressurePort: {
      PortPipePressure: null,
      PortPnPressure: null,
      PortFlangePressure: null,
      rowVisible: false
    },
    levelIndicatorPort: {
      PortPipeLevelIndicator: null,
      PortPnLevelIndicator: null,
      PortFlangeLevelIndicator: null,
      rowVisible: false
    },
    desalinationPort: {
      PortPipeDesalination: null,
      PortPnDesalination: null,
      PortFlangeDesalination: null,
      rowVisible: false
    },
  }

  // Options select boxes
  public surfaceWarm: ISolutionConfigComponent = null;
  public surfaceCold: ISolutionConfigComponent = null;
  //public insulationThickness: ISolutionConfigComponent = null;
  public insulationMaterial: ISolutionConfigComponent = null;
  public insulationCover: ISolutionConfigComponent = null;

  // Options radio buttons
  public drainVent: ISolutionConfigComponent = null;
  public elbow: ISolutionConfigComponent = null;
  public feet: ISolutionConfigComponent = null;

  public liftingLugs: ISolutionConfigComponent = null;
  public notifiedBody: ISolutionConfigComponent = null;
  public condensateBottle: ISolutionConfigComponent = null;

  public headType: ISolutionConfigComponent = null;

  public datasheetSpinner: boolean = false;
  public datasheetSpinnerInternal: boolean = false;
  public drawing3DSpinner: boolean = false;
  public quotationStatus: boolean = false;

  // Send Order
  public sendOrderFormNotes: FormGroup = null;

  @ViewChild('dxfContainerFV', { static: false }) dxfContainerFV: ElementRef<HTMLElement>;
  @ViewChild('dxfContainerSV', { static: false }) dxfContainerSV: ElementRef<HTMLElement>;

  constructor(
    private stateService: StateService,
    private apiService: ApiService,
    private route: ActivatedRoute,
    private router: Router,
    private snackBarService: SnackBarService,
    private formBuilder: FormBuilder) { };

  public ngOnInit() {
    this.user = this.stateService.get('user');
    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];
    this.solutionId = +this.route.snapshot.params['solutionId'];
    this.lang = this.stateService.get('lang').toUpperCase();
    this.admin = this.user.roles.includes(Role.Administrator);

    const userUnitSystem: string = this.user.unitSystem;
    const userCurrency: string = this.user.idCurrency;

    if (userCurrency === 'USD') {
      this.userCurrency = '$';
    } else if (userCurrency === 'EUR') {
      this.userCurrency = '€';
    };

    if (this.user.roles.includes(Role.UserAdmin)) {
      this.backToHomePageUrl = "/useradmin/home";
      this.extraDutyUrl = `/useradmin/configurator/${this.quotationId}/${this.itemId}/${this.solutionId}/extra-duty`;
    } else if (this.user.roles.includes(Role.User)) {
      this.backToHomePageUrl = "/user/home";
      this.extraDutyUrl = `/user/configurator/${this.quotationId}/${this.itemId}/${this.solutionId}/extra-duty`;
    };


    this.apiService.getUnitSystemData(userUnitSystem, this.physicalPropsNameList).subscribe({
      next: (unitSystemProps) => {
        this.physicalPropsUserUnitSystemData = unitSystemProps;
        this.getSolutionData();
      },
      error: (error: HttpErrorResponse) => {
        this.snackBarService.open();
      }
    });

    this.apiService.getQuotationStatus(this.quotationId).subscribe({
      next: (quotationStatus) => {
        this.quotationStatus = quotationStatus.quotationStatus === 'ordered';
      },
      error: () => {
        this.snackBarService.error();
      }
    });
  };
  public ngOnDestroy() {

    if (!!this.dxfViewerFV || !!this.dxfViewerSV) {
      this.dxfViewerFV.Destroy();
      this.dxfViewerSV.Destroy();

      this.dxfViewerFV = null;
      this.dxfViewerSV = null;
    };

  };
  public vmBack() {
    const user = this.stateService.get('user');
    let baseRole: string = null;
    // Find base role of user
    if (user.roles.includes(Role.UserAdmin)) {
      baseRole = "useradmin";
    } else if (user.roles.includes(Role.User)) {
      baseRole = "user";
    };
    this.router.navigate([`${baseRole}/configurator`, this.quotationId, this.itemId, 'options']);
  };

  public vmGoToHomePage(): void {
    const user = this.stateService.get('user');
    let baseRole: string = null;
    // Find base role of user
    if (user.roles.includes(Role.UserAdmin)) {
      baseRole = "useradmin";
    } else if (user.roles.includes(Role.User)) {
      baseRole = "user";
    };

    this.router.navigate([`${baseRole}/home`]);
  }

  public preparePheData() {
    this.pheParameters.frame = this.solution.solutionData.mechanical_data.Frame;
    this.pheParameters.plateArrangement = this.solution.solutionData.mechanical_data.PlateArrangement;
    this.pheParameters.designCombination = this.solution.solutionData.mechanical_data.DesignCombination;
    this.pheParameters.effArea = this.convertToUser(this.solution.solutionData.mechanical_data.EffArea, 'area', true);
    this.pheParameters.excessArea = this.solution.solutionData.mechanical_data.ExcessArea;
    this.pheParameters.pressureDropRealOnHotSide = this.convertToUser(this.solution.solutionData.mechanical_data.PressureDropRealOnHotSide, 'pressure_drop', true);
    this.pheParameters.pressureDropRealOnColdSide = this.convertToUser(this.solution.solutionData.mechanical_data.PressureDropRealOnColdSide, 'pressure_drop', true);
    if (this.solution.solutionData.mechanical_data.Type.startsWith("SFB")) {
      this.pheParameters.image = `${environment.base}/public_images/sketches/${this.solution.solutionData.mechanical_data.Type}.png`;
    } else {
      this.pheParameters.image = `${environment.base}/public_images/sketches/${this.solution.solutionData.mechanical_data.Type}${this.solution.solutionData.mechanical_data.Frame}.png`;
    }
  }

  public extractMaterialTranslationIds(material: { MaterialNameId: number; MaterialDescId: number | null }): number[] {
    if(material) {
      return material.MaterialDescId !== null ? [material.MaterialNameId, material.MaterialDescId] : [material.MaterialNameId];
    }else{
      return [];
    }
  }

  public prepareMaterialData() {

    const ids: number[] = [
      ...this.extractMaterialTranslationIds(this.solution.solutionData.mechanical_data.ShellMaterial),
      ...this.extractMaterialTranslationIds(this.solution.solutionData.mechanical_data.TubesheetMaterial),
      ...this.extractMaterialTranslationIds(this.solution.solutionData.mechanical_data.TubeMaterial),
      ...this.extractMaterialTranslationIds(this.solution.solutionData.mechanical_data.HeadMaterial),
      ...this.extractMaterialTranslationIds(this.solution.solutionData.mechanical_data.SupportsMaterial),
      ...this.extractMaterialTranslationIds(this.solution.solutionData.mechanical_data.SteamDrumMaterial),
      this.solution.solutionData.mechanical_data.ModuleTranslationId,
      this.solution.solutionData.mechanical_data.PEDCategoryNameTranslationId,
    ].filter(id => id != null);

    // const calls = ids.map((_id) => this.apiService.getRawMaterial(_id));
    // let rawMaterials = null;
    // forkJoin(calls).pipe(switchMap((_rawMaterials) => {
    //   rawMaterials = _rawMaterials;
    //
    //   const translationIds = _rawMaterials.reduce((acc, next) => {
    //     if(next.materialNameId)
    //       acc.push(next.materialNameId);
    //     if(next.materialDescId)
    //       acc.push(next.materialDescId);
    //     return acc;
    //
    //   }, []);
    //   return forkJoin({
    //     userLang: this.apiService.getTranslation(translationIds, this.lang),
    //     defaultLang: this.apiService.getTranslation(translationIds, 'EN')
    //   });
    // })).

    forkJoin({
      userLang: this.apiService.getTranslation(ids, this.lang),
      defaultLang: this.apiService.getTranslation(ids, 'EN')
    }).subscribe({
      next: (_translations) => {
        const getTranslation = (id: number) =>
          _translations.userLang.find(_x => _x.translationId === id)?.value ||
          _translations.defaultLang.find(_x => _x.translationId === id)?.value || '';
        // rawMaterials.forEach((_rawMaterial) => {
        //   const name = _translations.userLang.find((_x) => _x.translationId === _rawMaterial.materialNameId)?.value || _translations.defaultLang.find((_x) => _x.translationId === _rawMaterial.materialNameId)?.value;
        //   const description = _translations.userLang.find((_x) => _x.translationId === _rawMaterial.materialDescId)?.value || _translations.defaultLang.find((_x) => _x.translationId === _rawMaterial.materialDescId)?.value;
        //
        //   _rawMaterial.name = name;
        //   _rawMaterial.description = description;
        //
        // });
        this.materialParameters.shellMaterial.name = getTranslation(this.solution.solutionData.mechanical_data.ShellMaterial.MaterialNameId);
        this.materialParameters.shellMaterial.description = getTranslation(this.solution.solutionData.mechanical_data.ShellMaterial.MaterialDescId);

        this.materialParameters.tubeSheetMaterial.name = getTranslation(this.solution.solutionData.mechanical_data.TubesheetMaterial.MaterialNameId);
        this.materialParameters.tubeSheetMaterial.description = getTranslation(this.solution.solutionData.mechanical_data.TubesheetMaterial.MaterialDescId);

        this.materialParameters.tubeMaterial.name = getTranslation(this.solution.solutionData.mechanical_data.TubeMaterial.MaterialNameId);
        this.materialParameters.tubeMaterial.description = getTranslation(this.solution.solutionData.mechanical_data.TubeMaterial.MaterialDescId);

        this.materialParameters.headMaterial.name = getTranslation(this.solution.solutionData.mechanical_data.HeadMaterial.MaterialNameId);
        this.materialParameters.headMaterial.description = getTranslation(this.solution.solutionData.mechanical_data.HeadMaterial.MaterialDescId);

        this.materialParameters.supportsMaterial.name = getTranslation(this.solution.solutionData.mechanical_data.SupportsMaterial.MaterialNameId);
        this.materialParameters.supportsMaterial.description = getTranslation(this.solution.solutionData.mechanical_data.SupportsMaterial.MaterialDescId);

        if (this.solution.solutionData.mechanical_data.SteamDrumMaterial) {
          this.materialParameters.steamDrumMaterial.name = getTranslation(this.solution.solutionData.mechanical_data.SteamDrumMaterial.MaterialNameId);
          this.materialParameters.steamDrumMaterial.description = getTranslation(this.solution.solutionData.mechanical_data.SteamDrumMaterial.MaterialDescId);
        }

        this.materialParameters.regulatory = this.regulatoryText[this.solution.solutionData.mechanical_data.ItemRequirements.Regulatory];

        if(this.solution.solutionData.mechanical_data.ModuleTranslationId){
          this.materialParameters.module = getTranslation(this.solution.solutionData.mechanical_data.ModuleTranslationId);
        }else{
          this.materialParameters.module = this.solution.solutionData.mechanical_data.Module;
        }

        if(this.solution.solutionData.mechanical_data.PEDCategoryNameTranslationId){
          this.materialParameters.ped = getTranslation(this.solution.solutionData.mechanical_data.PEDCategoryNameTranslationId);
        }else{
          this.materialParameters.ped = this.solution.solutionData.mechanical_data.PEDCategoryName;
        }

      },
      error: (error) => { this.snackBarService.open(); }
    });

  };
  public prepareSolutionDxf() {
    forkJoin({
      fv: this.apiService.getSolutionDrawings(this.solution.solutionId, 'FV'),
      sv: this.apiService.getSolutionDrawings(this.solution.solutionId, 'SV')
    }).subscribe({
      next: ({ fv, sv }) => {
        const options: any = {
          clearColor: new THREE.Color("#fff"),
          autoResize: true,
          colorCorrection: true,
          sceneOptions: {
            wireframeMesh: true
          }
        };

        this.dxfViewerFV = new DxfViewer(this.dxfContainerFV.nativeElement, options);
        this.dxfViewerSV = new DxfViewer(this.dxfContainerSV.nativeElement, options);

        const loadOptionsFV: any = {
          url: URL.createObjectURL(new Blob([fv])),
          fonts: ['/assets/fonts/BarlowCondensed-Regular.ttf']
        };
        const loadOptionsSV: any = {
          url: URL.createObjectURL(new Blob([sv])),
          fonts: ['/assets/fonts/BarlowCondensed-Regular.ttf']
        };

        Promise.all([this.dxfViewerFV.Load(loadOptionsFV), this.dxfViewerSV.Load(loadOptionsSV)]).then(() => {
          this.dxfViewerFVLayers = this.dxfViewerFV.GetLayers();
          this.dxfViewerSVLayers = this.dxfViewerSV.GetLayers();

          this.updateDxfLayers();
        }).catch((error) => {
          this.snackBarService.open('DXF files cannot be previewed.');
          throw error;
        })

      },
      error: (error) => {
        this.snackBarService.open('DXF files not loaded.');
      }
    })
  };

  public prepareSideParameters() {
    forkJoin({
      userLang: this.apiService.getTranslation([this.solution.solutionData.mechanical_data.MainDuty.FluidHot.NameId, this.solution.solutionData.mechanical_data.MainDuty.FluidCold.NameId], this.lang),
      defaultLang: this.apiService.getTranslation([this.solution.solutionData.mechanical_data.MainDuty.FluidHot.NameId, this.solution.solutionData.mechanical_data.MainDuty.FluidCold.NameId], 'EN')
    }).subscribe({
      next: (translations) => {
        // Warm
        this.sideParameters.warm.fluidName = translations.userLang.find((_x) => _x.translationId === this.solution.solutionData.mechanical_data.MainDuty.FluidHot.NameId)?.value ||
          translations.defaultLang.find((_x) => _x.translationId === this.solution.solutionData.mechanical_data.MainDuty.FluidHot.NameId)?.value;
        if (this.sideParameters.warm.fluidName === undefined) {
          this.sideParameters.warm.fluidName = this.solution.solutionData.mechanical_data.MainDuty.FluidHot.Name;
        }

        this.sideParameters.warm.inletTemp = this.convertToUser(this.solution.solutionData.mechanical_data.MainDuty.InletTempHot, 'temperature', true, 3);
        this.sideParameters.warm.outletTemp = this.convertToUser(this.solution.solutionData.mechanical_data.MainDuty.OutletTempHot, 'temperature', true, 3);
        this.sideParameters.warm.maxTemperature = this.convertToUser(this.solution.solutionData.mechanical_data.ItemRequirements.DesignParameters.warm.max_temperature, 'temperature', true, 3);

        this.sideParameters.warm.workPressure = this.convertToUser(this.solution.solutionData.mechanical_data.MainDuty.WorkPressureHot, 'pressure', true);
        this.sideParameters.warm.maxPressure = this.convertToUser(this.solution.solutionData.mechanical_data.ItemRequirements.DesignParameters.warm.max_pressure, 'pressure', true);

        this.sideParameters.warm.maxPressureDrop = this.solution.solutionData.mechanical_data.HotSideOnShell ?
          this.convertToUser(this.solution.solutionData.mechanical_data.ShellSidePressureDrop, 'pressure_drop', true, 3) :
          this.convertToUser(this.solution.solutionData.mechanical_data.TubeSidePressureDrop, 'pressure_drop', true, 3);

        this.sideParameters.warm.massFlowRate = this.convertToUser(this.solution.solutionData.mechanical_data.MainDuty.MassFlowHot, 'mass_flow_rate', true);

        // cold
        this.sideParameters.cold.fluidName = translations.userLang.find((_x) => _x.translationId === this.solution.solutionData.mechanical_data.MainDuty.FluidCold.NameId)?.value ||
          translations.defaultLang.find((_x) => _x.translationId === this.solution.solutionData.mechanical_data.MainDuty.FluidCold.NameId)?.value;

        if (this.sideParameters.cold.fluidName === undefined) {
          this.sideParameters.cold.fluidName = this.solution.solutionData.mechanical_data.MainDuty.FluidCold.Name;
        }

        this.sideParameters.cold.inletTemp = this.convertToUser(this.solution.solutionData.mechanical_data.MainDuty.InletTempCold, 'temperature', true, 3);
        this.sideParameters.cold.outletTemp = this.convertToUser(this.solution.solutionData.mechanical_data.MainDuty.OutletTempCold, 'temperature', true, 3);
        this.sideParameters.cold.maxTemperature = this.convertToUser(this.solution.solutionData.mechanical_data.ItemRequirements.DesignParameters.cold.max_temperature, 'temperature', true, 3);

        this.sideParameters.cold.workPressure = this.convertToUser(this.solution.solutionData.mechanical_data.MainDuty.WorkPressureCold, 'pressure', true);
        this.sideParameters.cold.maxPressure = this.convertToUser(this.solution.solutionData.mechanical_data.ItemRequirements.DesignParameters.cold.max_pressure, 'pressure', true);

        this.sideParameters.cold.maxPressureDrop = this.solution.solutionData.mechanical_data.HotSideOnShell ?
          this.convertToUser(this.solution.solutionData.mechanical_data.TubeSidePressureDrop, 'pressure_drop', true, 3) :
          this.convertToUser(this.solution.solutionData.mechanical_data.ShellSidePressureDrop, 'pressure_drop', true, 3);

        this.sideParameters.cold.massFlowRate = this.convertToUser(this.solution.solutionData.mechanical_data.MainDuty.MassFlowCold, 'mass_flow_rate', true);

        this.sideParameters.overdesign = this.vmAdjustValuePrecision(this.solution.solutionData.mechanical_data.MainDuty.ExcessAreaPercent, 3);
        this.sideParameters.type = this.solution.solutionData.mechanical_data.MainDuty.$type;
      },
      error: (error) => {
        this.snackBarService.open();
      }
    });
  };
  private prepareExtraDuties() {

    const extraDuties = this.solution.solutionData.mechanical_data.ExtraDuties;
    const extraDutiesResults = this.solution.solutionData.mechanical_data.ExtraDutiesResults;

    // Check if ExtraDuties is empty
    if (!extraDuties || Object.keys(extraDuties).length === 0) {
      return;
    }

    const data: ILoadcase[] = [];

    const fluidTranslationIds: number[] = [];
    Object.keys(extraDuties).forEach((_key: string) => {

      fluidTranslationIds.push(extraDuties[_key].FluidHot.NameId);
      fluidTranslationIds.push(extraDuties[_key].FluidCold.NameId);
    });

    forkJoin({
      userLang: this.apiService.getTranslation(fluidTranslationIds, this.lang),
      defaultLang: this.apiService.getTranslation(fluidTranslationIds, 'EN'),
    }).subscribe({
      next: (translations) => {

        Object.keys(extraDuties).forEach((_key: string) => {

          const extraDuty = extraDuties[_key];
          const extraDutyResult = extraDutiesResults[_key];

          const loadcase: ILoadcase = {
            name: null,
            heatExchangeRate: null,
            overdesign: null,
            requirementId: null,
            warm: {
              fluidName: null,
              inletTemp: null,
              outletTemp: null,
              workPressure: null,
              maxPressureDrop: null,
              massFlowRate: null,
              maxPressure: null, // not required for this item
              maxTemperature: null, // not required for this item
            },
            cold: {
              fluidName: null,
              inletTemp: null,
              outletTemp: null,
              workPressure: null,
              maxPressureDrop: null,
              massFlowRate: null,
              maxPressure: null, // not required for this item
              maxTemperature: null, // not required for this item
            },
            warnings: null
          };

          loadcase.name = extraDuty.Name;
          loadcase.requirementId = +_key;

          loadcase.warnings = extraDutyResult.Warnings;

          loadcase.heatExchangeRate = this.convertToUser(extraDuty.HeatRate, 'heat_exchange_rate', true);
          //loadcase.overdesign = extraDutyResult.ExcessArea.toFixed(0);

          // Warm
          loadcase.warm.fluidName = translations.userLang.find((_x) => _x.translationId === extraDuty.FluidHot.NameId)?.value ||
            translations.defaultLang.find((_x) => _x.translationId === extraDuty.FluidHot.NameId)?.value;
          if (loadcase.warm.fluidName === undefined) {
            loadcase.warm.fluidName = extraDuty.FluidHot.Name;
          }

          // Temperature
          loadcase.warm.inletTemp = this.convertToUser(extraDuty.InletTempHot, 'temperature', true, 3);
          loadcase.warm.outletTemp = this.convertToUser(extraDuty.OutletTempHot, 'temperature', true, 3);

          // Mass Flow Rate
          loadcase.warm.massFlowRate = this.convertToUser(extraDuty.MassFlowHot, 'mass_flow_rate', true);

          // Working Pressure
          loadcase.warm.workPressure = this.convertToUser(extraDuty.WorkPressureHot, 'pressure', true);

          // Pressure Drop
          loadcase.warm.maxPressureDrop = this.solution.solutionData.mechanical_data.HotSideOnShell ?
            this.convertToUser(extraDutyResult.ShellSidePressureDrop, 'pressure_drop', true) :
            this.convertToUser(extraDutyResult.TubeSidePressureDrop, 'pressure_drop', true);


          // Cold
          loadcase.cold.fluidName = translations.userLang.find((_x) => _x.translationId === extraDuty.FluidCold.NameId)?.value ||
            translations.defaultLang.find((_x) => _x.translationId === extraDuty.FluidCold.NameId)?.value;
          if (loadcase.cold.fluidName === undefined) {
            loadcase.cold.fluidName = extraDuty.FluidCold.Name;
          }

          // Temperature
          loadcase.cold.inletTemp = this.convertToUser(extraDuty.InletTempCold, 'temperature', true, 3);
          loadcase.cold.outletTemp = this.convertToUser(extraDuty.OutletTempCold, 'temperature', true, 3);

          // Mass Flow Rate
          loadcase.cold.massFlowRate = this.convertToUser(extraDuty.MassFlowCold, 'mass_flow_rate', true);

          // Working Pressure
          loadcase.cold.workPressure = this.convertToUser(extraDuty.WorkPressureCold, 'pressure', true);

          // Pressure Drop
          loadcase.cold.maxPressureDrop = this.solution.solutionData.mechanical_data.HotSideOnShell ?
            this.convertToUser(extraDutyResult.TubeSidePressureDrop, 'pressure_drop', true, 3) :
            this.convertToUser(extraDutyResult.ShellSidePressureDrop, 'pressure_drop', true, 3);

          data.push(loadcase);

        });
        this.extraDuties = data;

      },
      error: (error) => {
        this.snackBarService.open('Can`t get Loadcases.');
      }
    });

  }
  public prepareSelectBoxes() {

    const hotSideOnShell: boolean = this.solution.solutionData.mechanical_data.HotSideOnShell;
    const components: ISolutionConfigComponents = JSON.parse(JSON.stringify(this.solutionComponents));

    let translationIds: number[] = [];

    Object.keys(components).forEach((_key: string) => {
      components[_key].options.forEach((_option) => {
        if (_option.hasOwnProperty('translationId')) {
          translationIds.push(_option.translationId);
        };
      });
    });

    // Remove duplicated
    translationIds = [...new Set(translationIds)];

    forkJoin({
      userLang: this.apiService.getTranslation(translationIds, this.lang),
      defaultLang: this.apiService.getTranslation(translationIds, 'EN'),
    }).subscribe({
      next: (translations) => {

        Object.keys(components).forEach((_key: string) => {
          components[_key].options.forEach((_option) => {
            if (_option.hasOwnProperty('translationId')) {
              _option.name = translations.userLang.find((_x) => _x.translationId === _option.translationId)?.value ||
                translations.defaultLang.find((_x) => _x.translationId === _option.translationId)?.value;
            };
          });
        });

        // Warm
        this.warmInletPort = hotSideOnShell ? components.ShellPortPipeIn : components.FrontHeadPortPipeIn;
        this.warmInletFlangePN = hotSideOnShell ? components.ShellPortPnIn : components.FrontHeadPortPnIn;
        this.warmInletFlange = hotSideOnShell ? components.ShellPortFlangeIn : components.FrontHeadPortFlangeIn;

        this.warmOutletPort = hotSideOnShell ? components.ShellPortPipeOut : components.HeadPortPipeOut;
        this.warmOutletFlangePN = hotSideOnShell ? components.ShellPortPnOut : components.HeadPortPnOut;
        this.warmOutletFlange = hotSideOnShell ? components.ShellPortFlangeOut : components.HeadPortFlangeOut;

        // Cold
        this.coldInletPort = hotSideOnShell ? components.FrontHeadPortPipeIn : components.ShellPortPipeIn;
        this.coldInletFlangePN = hotSideOnShell ? components.FrontHeadPortPnIn : components.ShellPortPnIn;
        this.coldInletFlange = hotSideOnShell ? components.FrontHeadPortFlangeIn : components.ShellPortFlangeIn;

        this.coldOutletPort = hotSideOnShell ? components.HeadPortPipeOut : components.ShellPortPipeOut;
        this.coldOutletFlangePN = hotSideOnShell ? components.HeadPortPnOut : components.ShellPortPnOut;
        this.coldOutletFlange = hotSideOnShell ? components.HeadPortFlangeOut : components.ShellPortFlangeOut;

        // Evaporation Extra Ports

        // safetyValvePort
        if (components.PortPipeSafetyValve && components.PortPnSafetyValve && components.PortFlangeSafetyValve) {

          this.evaporationExtraPorts.safetyValvePort.PortPipeSafetyValve = components.PortPipeSafetyValve;
          this.evaporationExtraPorts.safetyValvePort.PortPipeSafetyValve.selectedValue =
            typeof components.PortPipeSafetyValve.selectedValue === 'string' && !!+components.PortPipeSafetyValve.selectedValue ?
              +components.PortPipeSafetyValve.selectedValue : components.PortPipeSafetyValve.selectedValue;

          this.evaporationExtraPorts.safetyValvePort.PortPnSafetyValve = components.PortPnSafetyValve;

          this.evaporationExtraPorts.safetyValvePort.PortFlangeSafetyValve = components.PortFlangeSafetyValve;
          this.evaporationExtraPorts.safetyValvePort.PortFlangeSafetyValve.selectedValue =
            typeof components.PortFlangeSafetyValve.selectedValue === 'string' && !!+components.PortFlangeSafetyValve.selectedValue ?
              +components.PortFlangeSafetyValve.selectedValue : components.PortFlangeSafetyValve.selectedValue;

          this.evaporationExtraPorts.safetyValvePort.rowVisible =
            (components.PortPipeSafetyValve && components.PortPipeSafetyValve.visible) &&
            (components.PortPnSafetyValve && components.PortPnSafetyValve.visible) &&
            (components.PortFlangeSafetyValve && components.PortFlangeSafetyValve.visible);
        };

        // drainPort
        if (components.PortPipeDrain && components.PortPnDrain && components.PortFlangeDrain) {

          this.evaporationExtraPorts.drainPort.PortPipeDrain = components.PortPipeDrain;
          this.evaporationExtraPorts.drainPort.PortPipeDrain.selectedValue =
            typeof components.PortPipeDrain.selectedValue === 'string' && !!+components.PortPipeDrain.selectedValue ?
              +components.PortPipeDrain.selectedValue : components.PortPipeDrain.selectedValue;

          this.evaporationExtraPorts.drainPort.PortPnDrain = components.PortPnDrain;

          this.evaporationExtraPorts.drainPort.PortFlangeDrain = components.PortFlangeDrain;
          this.evaporationExtraPorts.drainPort.PortFlangeDrain.selectedValue =
            typeof components.PortFlangeDrain.selectedValue === 'string' && !!+components.PortFlangeDrain.selectedValue ?
              +components.PortFlangeDrain.selectedValue : components.PortFlangeDrain.selectedValue;

          this.evaporationExtraPorts.drainPort.rowVisible =
            (components.PortPipeDrain && components.PortPipeDrain.visible) &&
            (components.PortPnDrain && components.PortPnDrain.visible) &&
            (components.PortFlangeDrain && components.PortFlangeDrain.visible);

        };

        // temperaturePort
        if (components.PortPipeTemp && components.PortPnTemp && components.PortFlangeTemp) {

          this.evaporationExtraPorts.temperaturePort.PortPipeTemp = components.PortPipeTemp;
          this.evaporationExtraPorts.temperaturePort.PortPipeTemp.selectedValue =
            typeof components.PortPipeTemp.selectedValue === 'string' && !!+components.PortPipeTemp.selectedValue ?
              +components.PortPipeTemp.selectedValue : components.PortPipeTemp.selectedValue;

          this.evaporationExtraPorts.temperaturePort.PortPnTemp = components.PortPnTemp;

          this.evaporationExtraPorts.temperaturePort.PortFlangeTemp = components.PortFlangeTemp;
          this.evaporationExtraPorts.temperaturePort.PortFlangeTemp.selectedValue =
            typeof components.PortFlangeTemp.selectedValue === 'string' && !!+components.PortFlangeTemp.selectedValue ?
              +components.PortFlangeTemp.selectedValue : components.PortFlangeTemp.selectedValue;

          this.evaporationExtraPorts.temperaturePort.rowVisible =
            (components.PortPipeTemp && components.PortPipeTemp.visible) &&
            (components.PortPnTemp && components.PortPnTemp.visible) &&
            (components.PortFlangeTemp && components.PortFlangeTemp.visible);

        };

        // pressurePort
        if (components.PortPipePressure && components.PortPnPressure && components.PortFlangePressure) {
          this.evaporationExtraPorts.pressurePort.PortPipePressure = components.PortPipePressure;
          this.evaporationExtraPorts.pressurePort.PortPipePressure.selectedValue =
            typeof components.PortPipePressure.selectedValue === 'string' && !!+components.PortPipePressure.selectedValue ?
              +components.PortPipePressure.selectedValue : components.PortPipePressure.selectedValue;

          this.evaporationExtraPorts.pressurePort.PortPnPressure = components.PortPnPressure;

          this.evaporationExtraPorts.pressurePort.PortFlangePressure = components.PortFlangePressure;
          this.evaporationExtraPorts.pressurePort.PortFlangePressure.selectedValue =
            typeof components.PortFlangePressure.selectedValue === 'string' && !!+components.PortFlangePressure.selectedValue ?
              +components.PortFlangePressure.selectedValue : components.PortFlangePressure.selectedValue;

          this.evaporationExtraPorts.pressurePort.rowVisible =
            (components.PortPipePressure && components.PortPipePressure.visible) &&
            (components.PortPnPressure && components.PortPnPressure.visible) &&
            (components.PortFlangePressure && components.PortFlangePressure.visible);
        };

        // levelIndicatorPort
        if (components.PortPipeLevelIndicator && components.PortPnLevelIndicator && components.PortFlangeLevelIndicator) {
          this.evaporationExtraPorts.levelIndicatorPort.PortPipeLevelIndicator = components.PortPipeLevelIndicator;
          this.evaporationExtraPorts.levelIndicatorPort.PortPipeLevelIndicator.selectedValue =
            typeof components.PortPipeLevelIndicator.selectedValue === 'string' && !!+components.PortPipeLevelIndicator.selectedValue ?
              +components.PortPipeLevelIndicator.selectedValue : components.PortPipeLevelIndicator.selectedValue;

          this.evaporationExtraPorts.levelIndicatorPort.PortPnLevelIndicator = components.PortPnLevelIndicator;

          this.evaporationExtraPorts.levelIndicatorPort.PortFlangeLevelIndicator = components.PortFlangeLevelIndicator;
          this.evaporationExtraPorts.levelIndicatorPort.PortFlangeLevelIndicator.selectedValue =
            typeof components.PortFlangeLevelIndicator.selectedValue === 'string' && !!+components.PortFlangeLevelIndicator.selectedValue ?
              +components.PortFlangeLevelIndicator.selectedValue : components.PortFlangeLevelIndicator.selectedValue;

          this.evaporationExtraPorts.levelIndicatorPort.rowVisible =
            (components.PortPipeLevelIndicator && components.PortPipeLevelIndicator.visible) &&
            (components.PortPnLevelIndicator && components.PortPnLevelIndicator.visible) &&
            (components.PortFlangeLevelIndicator && components.PortFlangeLevelIndicator.visible);
        };

        // desalinationPort
        if (components.PortPipeDesalination && components.PortPnDesalination && components.PortFlangeDesalination) {
          this.evaporationExtraPorts.desalinationPort.PortPipeDesalination = components.PortPipeDesalination;
          this.evaporationExtraPorts.desalinationPort.PortPipeDesalination.selectedValue =
            typeof components.PortPipeDesalination.selectedValue === 'string' && !!+components.PortPipeDesalination.selectedValue ?
              +components.PortPipeDesalination.selectedValue : components.PortPipeDesalination.selectedValue;

          this.evaporationExtraPorts.desalinationPort.PortPnDesalination = components.PortPnDesalination;

          this.evaporationExtraPorts.desalinationPort.PortFlangeDesalination = components.PortFlangeDesalination;
          this.evaporationExtraPorts.desalinationPort.PortFlangeDesalination.selectedValue =
            typeof components.PortFlangeDesalination.selectedValue === 'string' && !!+components.PortFlangeDesalination.selectedValue ?
              +components.PortFlangeDesalination.selectedValue : components.PortFlangeDesalination.selectedValue;

          this.evaporationExtraPorts.desalinationPort.rowVisible =
            (components.PortPipeDesalination && components.PortPipeDesalination.visible) &&
            (components.PortPnDesalination && components.PortPnDesalination.visible) &&
            (components.PortFlangeDesalination && components.PortFlangeDesalination.visible);
        };

      },
      error: (error) => {
        this.snackBarService.open();
      }
    });




  };

  public prepareOptions() {
    const components: ISolutionConfigComponents = JSON.parse(JSON.stringify(this.solutionComponents));

    let translationIds: number[] = [];

    Object.keys(components).forEach((_key: string) => {
      components[_key].options.forEach((_option) => {
        if (_option.hasOwnProperty('translationId')) {
          translationIds.push(_option.translationId);
        };
      });
    });

    // Remove duplicated
    translationIds = [...new Set(translationIds)];

    forkJoin({
      userLang: this.apiService.getTranslation(translationIds, this.lang),
      defaultLang: this.apiService.getTranslation(translationIds, 'EN'),
    }).subscribe({
      next: (translations) => {

        Object.keys(components).forEach((_key: string) => {
          components[_key].options.forEach((_option) => {
            if (_option.hasOwnProperty('translationId')) {
              _option.name = translations.userLang.find((_x) => _x.translationId === _option.translationId)?.value ||
                translations.defaultLang.find((_x) => _x.translationId === _option.translationId)?.value;
            };
          });
        });

        // Save data for Surface Treatment option
        // By default selected value is string, if selected value present convert it to number.
        this.surfaceWarm = components.SurfaceWarm;
        this.surfaceWarm.selectedValue = this.surfaceWarm.selectedValue ? +this.surfaceWarm.selectedValue : this.surfaceWarm.selectedValue;

        this.surfaceCold = components.SurfaceCold;
        this.surfaceCold.selectedValue = this.surfaceCold.selectedValue ? +this.surfaceCold.selectedValue : this.surfaceCold.selectedValue;

        this.drainVent = components?.DrainVent;
        this.elbow = components?.Elbow;
        this.feet = components?.Feet;

        this.liftingLugs = components?.LiftingLugs;
        this.notifiedBody = components?.NotifiedBody;
        this.condensateBottle = components?.CondensateBottle;

        // this.insulationThickness = components.InsulationThickness;
        //
        // if (this.insulationThickness.selectedValue && !isNaN(+this.insulationThickness.selectedValue)) {
        //   this.insulationThickness.selectedValue = +this.insulationThickness.selectedValue;
        // };

        this.insulationMaterial = components.InsulationMaterial;
        if (this.insulationMaterial.selectedValue && !isNaN(+this.insulationMaterial.selectedValue)) {
          this.insulationMaterial.selectedValue = +this.insulationMaterial.selectedValue;
        };

        this.insulationCover = components.InsulationCover;
        if (this.insulationCover.selectedValue && !isNaN(+this.insulationCover.selectedValue)) {
          this.insulationCover.selectedValue = +this.insulationCover.selectedValue;
        };

        this.headType = components.HeadType;

        this.gaskets = components.Gaskets;
        if (this.gaskets.selectedValue && !isNaN(+this.gaskets.selectedValue)) {
          this.gaskets.selectedValue = +this.gaskets.selectedValue;
        };

        this.getModelHeadImages();

      },
      error: (error) => {
        this.snackBarService.open();
      }
    });

  };

  public prepareTableData() {

    // Size
    if (this.solution.solutionData.mechanical_data.OrientationName === 'HO') {
      this.tableParameters.length = this.convertToUser(this.solution.solutionData.mechanical_data.TotalLength, 'length', true, 3);
      this.tableParameters.height = this.convertToUser(this.solution.solutionData.mechanical_data.Height, 'length', true, 3);
    } else {
      this.tableParameters.length = this.convertToUser(this.solution.solutionData.mechanical_data.Height, 'length', true, 3);
      this.tableParameters.height = this.convertToUser(this.solution.solutionData.mechanical_data.TotalLength, 'length', true, 3);
    }
    this.tableParameters.width = this.convertToUser(this.solution.solutionData.mechanical_data.Width, 'length', true, 3);

    this.tableParameters.weight = this.convertToUser(this.solution.solutionData.mechanical_data.Weight, 'weight', true, 3);
    this.tableParameters.excessArea = this.vmAdjustValuePrecision(this.solution.solutionData.mechanical_data.ExcessArea, 2);

    // Price
    this.tableParameters.price = +this.solution.solutionData.mechanical_data.Price.toFixed(0);
    this.tableParameters.optionsPrice = +this.solution.solutionData.mechanical_data.OptionsPrice.toFixed(0);
    this.tableParameters.netOptionsPrice = +this.solution.solutionData.mechanical_data.NetOptionsPrice.toFixed(0);
    this.tableParameters.discountPercent = +this.solution.solutionData.mechanical_data.DiscountPercent?.toFixed(0) || 0;
    let discountValue = -1 * (this.solution.solutionData.mechanical_data.Price + this.solution.solutionData.mechanical_data.OptionsPrice) * this.solution.solutionData.mechanical_data.DiscountPercent / 100;
    this.tableParameters.discountValue = +discountValue.toFixed(0);
    this.tableParameters.totalPrice = +(this.solution.solutionData.mechanical_data.Price + this.solution.solutionData.mechanical_data.OptionsPrice + discountValue + this.solution.solutionData.mechanical_data.NetOptionsPrice).toFixed(0);

    // Pressure
    if (!!this.solution.solutionData.mechanical_data.TubeSidePressureDrop || this.solution.solutionData.mechanical_data.TubeSidePressureDrop === 0) {
      this.tableParameters.tubeSidePressureDrop = this.convertToUser(this.solution.solutionData.mechanical_data.TubeSidePressureDrop, 'pressure_drop', true, 3);
    };
    if (!!this.solution.solutionData.mechanical_data.ShellSidePressureDrop || this.solution.solutionData.mechanical_data.ShellSidePressureDrop === 0) {
      this.tableParameters.shellSidePressureDrop = this.convertToUser(this.solution.solutionData.mechanical_data.ShellSidePressureDrop, 'pressure_drop', true, 3);
    };

    if (this.solution.solutionData.mechanical_data.DeliveryTimeDays) {
      this.tableParameters.deliveryTimeIsWeek = (this.solution.solutionData.mechanical_data.DeliveryTimeDays % 7) === 0;
      this.tableParameters.deliveryTime = !(this.solution.solutionData.mechanical_data.DeliveryTimeDays % 7) ? Math.floor(this.solution.solutionData.mechanical_data.DeliveryTimeDays / 7) : this.solution.solutionData.mechanical_data.DeliveryTimeDays;
    }

    if (this.solution.solutionData.mechanical_data.Name.startsWith("SFB")) {
      this.tableParameters.imageSrc = `${environment.base}/public_images/sfb/example_brazed_plate_1.png`
    } else if (this.solution.solutionData.mechanical_data.Name.startsWith("SF")) {
      this.tableParameters.imageSrc = `${environment.base}/public_images/sf/plate_heat_1.png`
    } else {
      this.tableParameters.imageSrc = `${environment.base}/public_images/models/${this.solution.solutionData.mechanical_data.ID}/1.png`
    }
  };

  public onCheckboxChange(component: ISolutionConfigComponent, value: any) {
    // Save previous and new value.
    const prevValue = component.selectedValue;
    component.selectedValue = value.target.checked;

    this.apiService.saveSolutionConfigComponent(this.solutionId, component.componentName, `${component.selectedValue}`).subscribe({
      next: () => {
        this.getSolutionData();
      },
      error: (error: HttpErrorResponse) => {
        if (error.error?.title) {
          this.snackBarService.open(error.error.title);
        } else if (typeof error.error === 'string') {
          this.snackBarService.open(error.error);
        } else {
          this.snackBarService.open();
        };
        // If Api Call failed, return previous value of component.
        component.selectedValue = prevValue;
      }
    });

  };

  public onSelectChange(component: ISolutionConfigComponent, value: number | string) {
    // Save previous and new value.
    const prevValue = component.selectedValue;
    component.selectedValue = value;

    this.apiService.saveSolutionConfigComponent(this.solutionId, component.componentName, `${component.selectedValue}`).subscribe({
      next: () => {
        this.getSolutionData();
      },
      error: (error: HttpErrorResponse) => {
        if (error.error?.title) {
          this.snackBarService.open(error.error.title);
        } else if (typeof error.error === 'string') {
          this.snackBarService.open(error.error);
        } else {
          this.snackBarService.open();
        };
        // If Api Call failed, return previous value of component.
        component.selectedValue = prevValue;
      }
    });

  };

  /**
   * Method to convert value `from SI unit system to users unit system`.
   * @param value
   * @param propName physical property name. See `physicalPropsNameList property` for correct typing
   * @param round If true, value will be rounded
   * @param precision precision of rounding, `default value 2`
   * @returns
  */
  public convertToUser(value: number, propName: string, round = false, precision = 2): number {
    const prop = this.physicalPropsUserUnitSystemData[propName];
    if (value === 0 || !value) {
      return value;
    };

    const result: number = (value - prop.addFactor) / prop.multFactor;

    return round ? this.vmAdjustValuePrecision(result, precision) : result;

  };

  public getDatasheet(): void {
    this.datasheetSpinner = true;
    this.apiService.getPdfDatasheet(this.solutionId).subscribe({
      next: (datasheetResp) => {
        this.datasheetSpinner = false;
        this.savePdf(datasheetResp);
      },
      error: (error) => {
        this.datasheetSpinner = false;
        this.snackBarService.open();
      }
    })

  };
  public getDatasheetInternal(): void {
    this.datasheetSpinnerInternal = true;
    this.apiService.getPdfDatasheetInternal(this.solutionId).subscribe({
      next: (datasheetResp) => {
        this.datasheetSpinnerInternal = false;
        this.savePdf(datasheetResp);
      },
      error: (error) => {
        this.datasheetSpinnerInternal = false;
        this.snackBarService.open();
      }
    })

  };
  public vmCloseSendOrderModal(): void {
    this.sendOrderModal = false;
    this.sendOrderFormNotes = null;
  };
  public vmOpenSendOrderModal(): void {
    if (this.quotationStatus) {
      return;
    };

    this.sendOrderModal = true;

    // Crate form group
    this.sendOrderFormNotes = this.formBuilder.group({
      notes: [null, Validators.required]
    });

  };
  public vmSendOrder() {
    if (this.sendOrderFormNotes.invalid) {
      this.sendOrderFormNotes.markAsDirty();
      return;
    };

    this.apiService.sendOrder(this.quotationId, this.sendOrderFormNotes.value['notes']).subscribe({
      next: (response) => {
        this.vmCloseSendOrderModal();
        this.orderCreatedModal = true;
      },
      error: (error) => {
        console.error('Error sending order', error);
        this.snackBarService.open('Error sending order');
      }
    });

  };

  public deleteExtraDuty(requirementId: number) {
    this.apiService.deleteItemRequirement(requirementId).subscribe({
      next: () => {
        window.location.reload();
      },
      error: (error) => {
        this.snackBarService.open();
      }
    });
  };
  public getSolutionDrawing() {
    let subscription: Subscription = null;
    this.drawing3DSpinner = true;

    subscription = this.apiService.getSolution3DDrawing(this.solutionId).pipe(
      tap(response => {
        if (response.status === 200) {
          this.solutionDrawing = response.body;
          this.drawing3DSpinner = false;
          subscription.unsubscribe(); // Stop retrying on success
        } else if (response.status === 202) {
          subscription.unsubscribe(); // Stop retrying
          this.retryApiCall();
        };
      })
    ).subscribe({
      error: (error) => {
        this.drawing3DSpinner = false;
        this.snackBarService.open('Solution drawing not loaded.');
        subscription.unsubscribe(); // Stop retrying on error
      }
    });
  }

  private retryApiCall() {
    let subscription: Subscription = null;

    subscription = interval(5000).pipe(
      switchMap(() => this.apiService.getSolution3DDrawing(this.solutionId)),
      tap(response => {
        if (response.status === 200) {
          this.solutionDrawing = response.body;
          this.drawing3DSpinner = false;
          subscription.unsubscribe(); // Stop retrying on success
        }
      })
    ).subscribe({
      error: (error) => {
        this.drawing3DSpinner = false;
        this.snackBarService.open('Solution drawing not loaded.');
        subscription.unsubscribe(); // Stop retrying on error
      }
    });
  }

  private saveHtml(data: string) {
    saveAs(new Blob([data], { type: "text/html;charset=utf-8" }), `Datasheet. Item ${this.solution.itemId}. Solution ${this.solutionId}.html`);
  };
  private savePdf(data: ArrayBuffer) {
    saveAs(new Blob([data], { type: "application/pdf;charset=utf-8" }), `Datasheet. Item ${this.solution.itemId}. Solution ${this.solutionId}.pdf`);
  };
  private updateDxfLayers() {

    // Update layers visibility for FV view.
    this.dxfViewerFVLayers.forEach((_layer) => {

      switch (_layer.name) {
        case 'Socket': {
          if (this.drainVent && this.drainVent.visible) {
            this.dxfViewerFV.ShowLayer(_layer.name, this.drainVent.selectedValue);
          } else {
            this.dxfViewerFV.ShowLayer(_layer.name, false);
          };
          break;
        };
        case 'Elbow_with_flange': {
          if (this.elbow && this.elbow.visible) {
            this.dxfViewerFV.ShowLayer(_layer.name, this.elbow.selectedValue);
          } else {
            this.dxfViewerFV.ShowLayer(_layer.name, false);
          };
          break;
        };
        case 'Lifting_Lugs': {
          if (this.liftingLugs && this.liftingLugs.visible) {
            this.dxfViewerFV.ShowLayer(_layer.name, this.liftingLugs.selectedValue);
          } else {
            this.dxfViewerFV.ShowLayer(_layer.name, false);
          };
          break;
        };
        case 'Bottle': {
          if (this.condensateBottle && this.condensateBottle.visible) {
            this.dxfViewerFV.ShowLayer(_layer.name, this.condensateBottle.selectedValue);
          } else {
            this.dxfViewerFV.ShowLayer(_layer.name, false);
          };
          break;
        };
        case 'Feet': {
          if (this.feet && this.feet.visible) {
            this.dxfViewerFV.ShowLayer(_layer.name, this.feet.selectedValue);
          } else {
            this.dxfViewerFV.ShowLayer(_layer.name, false);
          };
          break;
        };
      };


    });

    // Update layers visibility for SV view.
    this.dxfViewerSVLayers.forEach((_layer) => {

      switch (_layer.name) {
        case 'Socket': {
          if (this.drainVent && this.drainVent.visible) {
            this.dxfViewerSV.ShowLayer(_layer.name, this.drainVent.selectedValue);
          } else {
            this.dxfViewerSV.ShowLayer(_layer.name, false);
          };
          break;
        };
        case 'Elbow_with_flange': {
          if (this.elbow && this.elbow.visible) {
            this.dxfViewerSV.ShowLayer(_layer.name, this.elbow.selectedValue);
          } else {
            this.dxfViewerSV.ShowLayer(_layer.name, false);
          };
          break;
        };
        case 'Lifting_Lugs': {
          if (this.liftingLugs && this.liftingLugs.visible) {
            this.dxfViewerSV.ShowLayer(_layer.name, this.liftingLugs.selectedValue);
          } else {
            this.dxfViewerSV.ShowLayer(_layer.name, false);
          };
          break;
        };
        case 'Bottle': {
          if (this.condensateBottle && this.condensateBottle.visible) {
            this.dxfViewerSV.ShowLayer(_layer.name, this.condensateBottle.selectedValue);
          } else {
            this.dxfViewerSV.ShowLayer(_layer.name, false);
          };
          break;
        };
      };


    });

  };
  /**
   * Method to get image of heads.
   */
  private getModelHeadImages() {
    this.headType.options.forEach((_type) => {
      this.apiService.getHeadImage(this.solution.solutionData.mechanical_data.ID, <string>_type.name).subscribe({
        next: (imageBlob) => {
          if (imageBlob) {
            const fileReader: FileReader = new FileReader();

            fileReader.onload = (e: any) => {
              _type.image = e.target.result;

            };
            fileReader.readAsDataURL(imageBlob);
          };
        }
      });

    });

  };
  /**
   * Method to get `solution and solution components data`.
   */
  private getSolutionData() {
    forkJoin({
      solution: this.apiService.getSolution(this.solutionId),
      components: this.apiService.getSolutionConfigComponents(this.solutionId)
    }).pipe(switchMap(({ solution, components }) => {
      solution.solutionData.mechanical_data = JSON.parse(solution.solutionData.mechanical_data as any);

      this.solution = JSON.parse(JSON.stringify(solution));

      this.solutionProductType = solution.solutionData.type;

      this.solutionName = solution.solutionData.mechanical_data.Name.split(' ');
      this.solutionTemaType = solution.solutionData.mechanical_data.TemaType;

      this.solutionComponents = JSON.parse(JSON.stringify(components));

      const translationIds: number[] = [];

      Object.keys(this.solutionComponents).forEach((_key) => {
        this.solutionComponents[_key].componentName = _key;

        // Some options dont have name, but have ID of translation
        // Get translation for this option name.
        if (this.solutionComponents[_key].options.length > 0) {
          this.solutionComponents[_key].options.forEach((_option) => {
            if (typeof _option.name === 'number') {
              _option.translationId = _option.name;
              translationIds.push(_option.translationId);
            };
          });
        };

      });

      return this.apiService.getTranslation(translationIds, this.lang);
    })).subscribe({
      next: (_translations) => {

        // Prepare names for options that have translation ID instead of name.
        Object.keys(this.solutionComponents).forEach((_key) => {

          if (this.solutionComponents[_key].options.length > 0) {
            this.solutionComponents[_key].options.forEach((_option) => {
              if (typeof _option.name === 'number') {
                _option.name = _translations.find((_x) => _x.translationId === _option.translationId)?.value || _option.name;
              };
            });
          };

        });

        this.prepareSideParameters();
        if (this.solutionProductType != 'BrazedHeatExchanger' && this.solutionProductType != 'PlateHeatExchanger') {
          this.prepareSolutionDxf();
          this.prepareMaterialData();
          this.prepareExtraDuties();
          this.prepareSelectBoxes();
          this.prepareOptions();
          this.prepareTableData();
        } else {
          this.preparePheData();
          this.prepareTableData();
        };

      },
      error: (error) => {
        this.snackBarService.open('Solution cannot be loaded.');
      }
    });
  };

  /**
   * Method to round value.
   * @param value
   * @param precision
   * @param maxDecimals
   * @returns
   */
  private vmAdjustValuePrecision(value: number, precision: number, maxDecimals: number = null) {
    function vmGetNumberOfSignificantDigitsAfterZero(value: number, precision: number, maxDecimals: number): number {
      if (value === 0 || !isFinite(value) || isNaN(value)) {
        return 0
      }

      const lvAbsValue: number = Math.abs(value);
      const lvLog: number = Math.trunc(Math.log10(lvAbsValue));
      let lvResult: number = precision - lvLog;

      if (Math.trunc(lvAbsValue) > 0) {
        lvResult--;
      };
      if (lvResult < 0) {
        lvResult = 0;
      };

      if (maxDecimals !== null && lvResult > maxDecimals) {
        lvResult = maxDecimals;
      };

      return lvResult;
    };


    const lvNumberOfSignificantDigitsAfterZero: number = vmGetNumberOfSignificantDigitsAfterZero(value, precision, maxDecimals);

    const lvMultKoef: number = Math.pow(10, lvNumberOfSignificantDigitsAfterZero);

    let lvResult: number = lvMultKoef * value;
    lvResult = Math.round(lvResult);
    lvResult = lvResult / lvMultKoef;

    return lvResult;
  };
}

interface ITableParameters {
  length: number;
  width: number;
  height: number;
  weight: number;
  excessArea: number;
  shellSidePressureDrop: number;
  tubeSidePressureDrop: number;
  price: number;
  optionsPrice: number;
  netOptionsPrice: number;
  discountPercent: number;
  discountValue: number;
  totalPrice: number;
  deliveryTime: number;
  deliveryTimeIsWeek: boolean;
  imageSrc: any;
};

interface IMaterialParameters {
  shellMaterial: { name: string; description: string; };
  tubeSheetMaterial: { name: string; description: string; };
  tubeMaterial: { name: string; description: string; };
  headMaterial: { name: string; description: string; };
  supportsMaterial: { name: string; description: string; };
  steamDrumMaterial: { name: string; description: string; };
  regulatory: string;
  ped: string;
  module: string;
};

interface IPheParameters {
  frame: string;
  plateArrangement: string;
  designCombination: string;
  effArea: number;
  excessArea: number;
  pressureDropRealOnHotSide: number;
  pressureDropRealOnColdSide: number;
  image: string;
};
interface ISideParameters {
  fluidName: string;
  inletTemp: number;
  outletTemp: number;
  workPressure: number;
  maxPressureDrop: number;
  maxPressure: number;
  maxTemperature: number;
  massFlowRate: number;
};
interface ILoadcase {
  name: string;
  heatExchangeRate: number;
  overdesign: number;
  warm: ISideParameters;
  cold: ISideParameters;
  warnings: { Type: string; Message: string; }[],
  requirementId: number;
};
interface IExtraPorts {
  safetyValvePort: {
    PortPipeSafetyValve: ISolutionConfigComponent;
    PortPnSafetyValve: ISolutionConfigComponent;
    PortFlangeSafetyValve: ISolutionConfigComponent;
    rowVisible: boolean;
  },
  drainPort: {
    PortPipeDrain: ISolutionConfigComponent;
    PortPnDrain: ISolutionConfigComponent;
    PortFlangeDrain: ISolutionConfigComponent;
    rowVisible: boolean;
  },
  temperaturePort: {
    PortPipeTemp: ISolutionConfigComponent;
    PortPnTemp: ISolutionConfigComponent;
    PortFlangeTemp: ISolutionConfigComponent;
    rowVisible: boolean;
  },
  pressurePort: {
    PortPipePressure: ISolutionConfigComponent;
    PortPnPressure: ISolutionConfigComponent;
    PortFlangePressure: ISolutionConfigComponent;
    rowVisible: boolean;
  },
  levelIndicatorPort: {
    PortPipeLevelIndicator: ISolutionConfigComponent;
    PortPnLevelIndicator: ISolutionConfigComponent;
    PortFlangeLevelIndicator: ISolutionConfigComponent;
    rowVisible: boolean;
  },
  desalinationPort: {
    PortPipeDesalination: ISolutionConfigComponent;
    PortPnDesalination: ISolutionConfigComponent;
    PortFlangeDesalination: ISolutionConfigComponent;
    rowVisible: boolean;
  },
}

type LayerInfo = {
  name: string,
  displayName: string,
  color: number
};
