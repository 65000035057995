import { Component, OnInit } from '@angular/core';
import { ApiService, StateService } from '../services';
import { ActivatedRoute } from '@angular/router';
import { INews } from '../services/interfaces';

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit {

  public news: any = null;
  constructor(private stateService: StateService, private route: ActivatedRoute, private apiService: ApiService) { };

  public ngOnInit(): void {

    const lang: string = this.stateService.get('lang');
    const newsId: string = this.route.snapshot.params['newsId'];

    this.apiService.getNews(lang.toLocaleUpperCase(), newsId).subscribe({
      next: (news: INews) => {
        // Add local date
        news.newsDateLocal = new Date(news.newsDate).toLocaleDateString('de-De').replaceAll('/', '.');
        this.news = news;
      }
    });

  }

}
