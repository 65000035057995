import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import * as guards from './guards';
import * as auth from './auth'
import * as shared from './shared'
import { NewsComponent } from './news/news.component';

const routes: Routes = [
  { path: '', redirectTo: 'login', pathMatch: 'full' },
  { path: 'login', component: auth.LoginComponent },
  { path: 'registration', component: auth.RegistrationComponent },
  { path: 'forgotpassword', component: auth.ForgotPasswordComponent },
  { path: 'resetpassword', component: auth.ResetPasswordComponent },
  { path: 'settings', component: shared.UserSettingsComponent, canActivate: [guards.authGuard] },
  { path: 'accounts', component: shared.AccountsManagementComponent, canActivate: [guards.authGuard] },
  { path: 'news/:newsId', component: NewsComponent, canActivate: [guards.authGuard] },
  { path: 'tubesheets', component: shared.TubesheetsInfoComponent, canActivate: [guards.authGuard] },
  {
    path: 'useradmin',
    loadChildren: () => import('./useradmin/useradmin.module').then((m) => m.UserAdminModule),
    canActivate: [guards.authGuard]
  },
  {
    path: 'user',
    loadChildren: () => import('./user/user.module').then((m) => m.UserModule),
    canActivate: [guards.authGuard]
  },
  {
    path: 'admin',
    loadChildren: () => import('./admin/admin.module').then((m) => m.AdminModule),
    canActivate: [guards.authGuard]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
