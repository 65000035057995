import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, forkJoin, of, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';

import { environment } from 'src/environments/environment';

import { StateService } from './state.service';
import {
  IAutoSelectedOption,
  IFluid,
  IFluidClass,
  INewItemResponse,
  INewQuotationCreatedResp,
  INewRequirementsResponse,
  INews,
  ISubuser,
  ITranslation,
  IUserProfile,
  IUser,
  ISolution,
  IQuotationSummary,
  ICountryResp,
  ISolutionConfigComponents,
  IZiloHint,
  IZiloHintExtended,
  IStModel,
  ICompany,
  IStTubeLayout,
  IStBaffleLayout,
  IStModelImages,
  IStModelDrawing,
  IStModelHead,
  IStModelEquipRule,
  IStModelSpecialApplication,
  IStModelApplication,
  IDictionaryData,
  IPhysicalProperty,
  IPhysicalUnit,
  IUnitUsage,
  IUnitSystem,
  IHEProductType,
  IRestrictedProductType,
  IQuotationItemSummary,
  IStTubeAndBaffleLayout,
  IAdminCountry,
  IQuotation,
  IBodyFlanges,
  IBodyFlangesRaw,
  ITubeSheetRaw,
  IGasketPricesRaw,
  IRawMaterialClases
} from './interfaces';
import { TFluidProps, TPhysicalUnits, TPhysicalUnitSystems } from '../shared';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private baseUrl: string = environment.base;
  private cacheCompaniesDetailes: { [key: number | string]: ICompany } = {};

  constructor(private http: HttpClient, private stateService: StateService, private jwtHelper: JwtHelperService) { };

  // QUOTATIONS
  /**
   * Method to get current quotations.
   * @param status
   * @param size
   * @param page
   * @returns
   */
  public getQuotations(status?: string, size?: number, page: number = 1): Observable<any> {
    const url: string = this.baseUrl + "/api/quotations/" + page;

    let body = new HttpParams();

    if (status) {
      body = body.append('status', status);
    }

    if (size) {
      body = body.append('pageSize', size);
    }

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: body
    }

    return this.http.get(url, httpOptions)
  }
  /**
   * Method to get quotations for homepage.
   * @param size
   * @param status
   * @param page
   * @returns
   */
  public getQuotationsSummary(size?: number, status?: string, page: number = 1): Observable<{ currentPage: number; pages: number; quotations: IQuotationSummary[] }> {
    const url: string = this.baseUrl + "/api/quotations/summary/" + page;

    let body = new HttpParams();

    if (size) {
      body = body.append('pageSize', size);
    }
    if (status) {
      body = body.append('status', status);
    }

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: body
    }

    return this.http.get<{ currentPage: number; pages: number; quotations: IQuotationSummary[] }>(url, httpOptions);
  }
  public getAdminQuotationsSummary(userId: number, size?: number, status?: string, page: number = 1): Observable<{ currentPage: number; pages: number; quotations: IQuotationSummary[] }> {
    const url: string = this.baseUrl + "/api/quotations/administrator/summary/" + userId + "/" + page;

    let body = new HttpParams();

    if (size) {
      body = body.append('pageSize', size);
    }
    if (status) {
      body = body.append('status', status);
    }

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: body
    }

    return this.http.get<{ currentPage: number; pages: number; quotations: IQuotationSummary[] }>(url, httpOptions);
  }
  public getQuotationStatus(quotationId: number) {
    const url: string = `${this.baseUrl}/api/quotations/status/${quotationId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    return this.http.get<IQuotation>(url, httpOptions)
  }
  public findQuotationByID(quotationNumber: string): Observable<IQuotationItemSummary> {
    const url: string = this.baseUrl + "/api/quotations/get_quotation_by_number/" + quotationNumber;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.get<IQuotationItemSummary>(url, httpOptions);
  }
  public createQuotation(data: { idLang: string; idCurrency: string; quotationTitle: string; quotationId?: number; quotationNumber?: string; quotationStatus?: string; }): Observable<INewQuotationCreatedResp> {

    const url: string = this.baseUrl + "/api/quotations";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.post<INewQuotationCreatedResp>(url, data, httpOptions)
  }
  public deleteQuotation(quotationId: number): Observable<any> {

    const url: string = this.baseUrl + "/api/quotations/" + quotationId;

    return this.http.delete(url)
  };
  public sendOrder(quotationId: any, notes?: string) {
    const url: string = `${this.baseUrl}/api/quotations/order/${quotationId} `;

    return this.http.post(url, JSON.stringify(notes), { responseType: 'text', headers: new HttpHeaders({ "Content-Type": "application/json" }) });
  };
  public sendInquire(quotationId: any, notes?: string) {
    const url: string = `${this.baseUrl}/api/quotations/inquiry/${quotationId} `;

    return this.http.post(url, JSON.stringify(notes), { responseType: 'text', headers: new HttpHeaders({ "Content-Type": "application/json" }) });
  };

  // ITEMS
  public getItems(quotationId: any): Observable<INewItemResponse[]> {
    const url: string = this.baseUrl + "/api/items/" + quotationId;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.get<INewItemResponse[]>(url, httpOptions)
  }
  public createItem(data: { itemNumber: string; itemType: string; quotationId: number; itemStatus: string; itemTitle: string; itemDescription: string; }): Observable<INewItemResponse> {

    const url: string = this.baseUrl + "/api/items";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.post<INewItemResponse>(url, data, httpOptions)
  }
  public updateItem(itemId: any, data: { quotationId?: number; itemStatus?: string; itemNumber?: string; itemType?: string; itemTitle?: string; itemQuantity?: number }): Observable<INewItemResponse> {

    const url: string = this.baseUrl + "/api/items/" + itemId;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.put<INewItemResponse>(url, data, httpOptions)
  }
  public deleteItem(itemId: number): Observable<any> {

    const url: string = this.baseUrl + "/api/items/" + itemId;

    return this.http.delete(url);
  };
  public duplicateItem(data: { itemId: number; newItemTitle: string; newItemDescription: string; }): Observable<any> {

    const url: string = this.baseUrl + "/api/items/duplicate";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.post(url, data, httpOptions)
  };
  public duplicateProject(quotationId: number, newQuotationTitle: string): Observable<any> {

    const url: string = this.baseUrl + "/api/quotations/duplicate";

    let body = new HttpParams();
    body = body.append('quotationId', quotationId);
    body = body.append('newQuotationTitle', newQuotationTitle);

    return this.http.post(url, null, { params: body });
  };
  public skipGuide(itemId: number) {
    const url: string = `${this.baseUrl}/api/items/skip_journey/${itemId} `;
    return this.http.post(url, {});
  }

  // ITEM REQUIREMENTS
  /** Save requirement */
  public saveItemRequirements(itemId: any, data: { requirementType: string; requirementValue: any; }): Observable<INewRequirementsResponse> {

    const url: string = this.baseUrl + "/api/items/requirements/" + itemId;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.post<INewRequirementsResponse>(url, data, httpOptions)
  }
  /** Update existing requirement */
  public updateItemRequirements(requirementId: any, data: { requirementType: string; requirementValue: any; }): Observable<any> {

    const url: string = this.baseUrl + "/api/items/requirements/" + requirementId;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.put(url, data, httpOptions)
  }
  public getItemRequirements(itemId: any): Observable<INewRequirementsResponse[]> {

    const url: string = this.baseUrl + "/api/items/requirements/" + itemId;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.get<INewRequirementsResponse[]>(url, httpOptions)
  };
  public deleteItemRequirement(requirementId: number): Observable<any> {

    const url: string = this.baseUrl + "/api/items/requirements/" + requirementId;

    return this.http.delete(url);
  };

  // USER
  /**Method to get user profile */
  public getUser(): Observable<IUserProfile> {
    const url: string = this.baseUrl + "/api/user";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.get<IUserProfile>(url, httpOptions)
  };
  /**Method to get subusers */
  public getUserSubusers(): Observable<ISubuser[]> {
    const url: string = this.baseUrl + "/api/user/subusers";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<ISubuser[]>(url, httpOptions)
  };
  /** Save new subuser */
  public saveSubuser(data: any): Observable<any> {

    const url: string = this.baseUrl + "/api/user/subusers";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.post<any>(url, data, httpOptions)
  };

  /** Update current subuser */
  public updateSubuser(data: any): Observable<any> {

    const url: string = this.baseUrl + "/api/user/subusers";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.put<any>(url, data, httpOptions)
  };
  /**Method to get user profile photo */
  public getUserPhoto(): Observable<any> {
    const url: string = this.baseUrl + "/api/user/photo";


    return this.http.get(url, { responseType: 'blob' })
  };
  /**Method to get user props */
  public getUserProps(): Observable<any> {
    const url: string = this.baseUrl + "/api/user/property";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.get(url, httpOptions)
  };
  /**
   * method to update existing property with new value.
   * @param propName
   * @param propValue
   * @returns
   */
  public updateUserProps(propName: string, propValue: string): Observable<any> {
    const url: string = `${this.baseUrl}/api/user/property/${propName}/${propValue}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.put(url, httpOptions);
  };

  public updateUserProfile(userProfile: IUserProfile): Observable<any> {
    const url: string = `${this.baseUrl}/api/user/`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.put(url, JSON.stringify(userProfile), httpOptions);
  };

  public updateUserPhoto(file: File) {

    const url: string = `${this.baseUrl}/api/user/photo`;

    const form: FormData = new FormData();
    form.append('formFile', file);

    return this.http.put(url, form);
  };

  public activateUserAccount(userId: number) {
    const url: string = `${this.baseUrl}/api/administrator/users/activate/${userId}`;

    return this.http.post(url, null);

  };

  public moveAccount(account_to_move, move_to_account) {

    const url: string = this.baseUrl + `/api/administrator/users/moveaccount/${account_to_move}/${move_to_account}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.post(url, null, httpOptions)
  }

  // Fluid
  public getFluidClasses(): Observable<IFluidClass[]> {
    const url: string = this.baseUrl + "/api/fluid/classes";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.get<IFluidClass[]>(url, httpOptions)
  }

  public getFluids(applicationType: string, productType = 'ST', itemId?: number): Observable<IFluid[]> {
    const url: string = `${this.baseUrl}/api/fluid/${applicationType}/${productType}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: null
    };

    if (itemId) {
      httpOptions.params = new HttpParams().append('item_id', itemId);
    };

    return this.http.get<IFluid[]>(url, httpOptions)
  }
  public saveCustomFluid(data: any): Observable<IFluid> {

    const url: string = this.baseUrl + "/api/fluid/custom";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.post<IFluid>(url, data, httpOptions)
  };

  public getFluidPhysicalProperty(fluidId: number, physicalProperty: string): Observable<TFluidProps[]> {
    const url: string = `${this.baseUrl}/api/fluid/properties/${fluidId}/${physicalProperty}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.get<TFluidProps[]>(url, httpOptions);
  }

  // Translations
  public getTranslation(translationIds: number[], language?: string): Observable<ITranslation[]> {
    const url: string = `${this.baseUrl}/api/translation`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    const data: any = { translationIds };

    if (!!language) {
      data.language = language;
    };

    return this.http.post<ITranslation[]>(url, JSON.stringify(data), httpOptions);
  };

  // Add new translation
  public addTranslation(language: string, data: any): Observable<ITranslation> {
    const url = `${this.baseUrl}/api/translation/${language}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.post<ITranslation>(url, data, httpOptions);
  };

  // Edit translation
  public updateTranslation(translationId: number, language: string, value: string, isActive?: number): Observable<any> {

    const url = `${this.baseUrl}/api/translation/${translationId}/${language}`;
    let params = new HttpParams();

    if (isActive || isActive === 0) {
      params = params.set('isActive', isActive);
    }

    return this.http.put<any>(url, JSON.stringify(value), {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: params
    });
  };

  // Unit System
  /**
   * Method return full object of unit physical props, for selected unitSystem and physical props.
   * @param unitSystemId
   * @param physicalPropsList
   * @returns
   */
  public getUnitSystemData(unitSystemId: string, physicalPropsList: string[]) {
    const url: string = `${this.baseUrl}/api/unitsystem/unitsusage/${unitSystemId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    let unitUsageResponse = null;
    let physicalUnitsResponse = null;

    return this.http.post<{ isActive: number; physicalPropertyId: string; physicalUnitId: number; unitSystemId: number; unitUsageId: number; }[]>(url, physicalPropsList, httpOptions)
      .pipe(
        switchMap((_unitUsageResponse) => {
          // Save response
          unitUsageResponse = _unitUsageResponse;
          const physicalIds: number[] = _unitUsageResponse.map((x) => x.physicalUnitId);
          const urlphysicalUnitsUrl: string = `${this.baseUrl}/api/unitsystem/physicalunits`;

          return this.http.post<{ isActive: number; addFactor: number; multFactor: number; physicalUnitId: number; unitNameId: number; unitSign: number; }[]>(urlphysicalUnitsUrl, JSON.stringify(physicalIds), httpOptions);
        }),
        switchMap((_physicalUnitsResponse) => {
          // Save response
          physicalUnitsResponse = _physicalUnitsResponse;
          const lang = this.stateService.get('lang');

          const translationIds: number[] = _physicalUnitsResponse.reduce((acc, next) => {
            acc.push(next.unitSign);
            acc.push(next.unitNameId);
            return acc;
          }, []);

          // Some times translation can be missed in selected language.
          // In this case need to get translation in English language.
          // Need to get APi call for current and default language translations.
          let translationsObservable = null;

          if (lang !== 'en') {
            translationsObservable = {
              cyrrentLangTranslation: this.getTranslation(translationIds, lang.toUpperCase()),
              defaultLangTranslation: this.getTranslation(translationIds, 'EN'),
            }
          } else {
            translationsObservable = {
              cyrrentLangTranslation: this.getTranslation(translationIds, lang.toUpperCase()),
            }
          }
          return forkJoin(translationsObservable);
        }),
        switchMap(({ cyrrentLangTranslation, defaultLangTranslation }) => {
          let data = {};
          physicalPropsList.forEach((_name: string) => {

            const unit = unitUsageResponse.find((x) => x.physicalPropertyId === _name);
            const physicalUnit = physicalUnitsResponse.find((x) => x.physicalUnitId === unit.physicalUnitId);

            let physicalUnitTitle: string = cyrrentLangTranslation.find((x) => x.translationId === physicalUnit.unitNameId)?.value;
            let physicalUnitSign: string = cyrrentLangTranslation.find((x) => x.translationId === physicalUnit.unitSign)?.value;

            // If translation not found in selected language
            // Take translation from default language
            if (!physicalUnitTitle) {
              physicalUnitTitle = defaultLangTranslation.find((x) => x.translationId === physicalUnit.unitNameId).value;
            };
            if (!physicalUnitSign) {
              physicalUnitSign = defaultLangTranslation.find((x) => x.translationId === physicalUnit.unitSign).value;
            }

            data[_name] = {
              unitTitle: physicalUnitTitle,
              unitSign: physicalUnitSign,
              addFactor: physicalUnit.addFactor,
              multFactor: physicalUnit.multFactor,
            }

          });
          return of(data);
        })
      );
  }
  /**
   * Method return full object of all possible units for selected.physical props.
   * @param physicalPropsList
   * @returns
   */
  public getAllUnitSystemData(physicalPropsList: string[]): Observable<{ unitSystems: TPhysicalUnitSystems; units: TPhysicalUnits }> {
    const url: string = `${this.baseUrl}/api/unitsystem/unitsusage/all`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    let unitUsageResponse = null;
    let physicalUnitsResponse = null;

    return this.http.post<{ isActive: number; physicalPropertyId: string; physicalUnitId: number; unitSystemId: number; unitUsageId: number; }[]>(url, physicalPropsList, httpOptions)
      .pipe(
        switchMap((_unitUsageResponse) => {
          // Save response
          unitUsageResponse = _unitUsageResponse;

          const physicalIds: number[] = _unitUsageResponse.map((x) => x.physicalUnitId);
          const urlphysicalUnitsUrl: string = `${this.baseUrl}/api/unitsystem/physicalunits`;

          return this.http.post<{ isActive: number; addFactor: number; multFactor: number; physicalUnitId: number; unitNameId: number; unitSign: number; }[]>(urlphysicalUnitsUrl, JSON.stringify(physicalIds), httpOptions);
        }),
        switchMap((_physicalUnitsResponse) => {
          // Save response
          physicalUnitsResponse = _physicalUnitsResponse;
          const lang = this.stateService.get('lang');

          const translationIds: number[] = _physicalUnitsResponse.reduce((acc, next) => {
            acc.push(next.unitSign);
            acc.push(next.unitNameId);
            return acc;
          }, []);

          // Some times translation can be missed in selected language.
          // In this case need to get translation in English language.
          // Need to get APi call for current and default language translations.
          let translationsObservable = null;

          if (lang !== 'en') {
            translationsObservable = {
              cyrrentLangTranslation: this.getTranslation(translationIds, lang.toUpperCase()),
              defaultLangTranslation: this.getTranslation(translationIds, 'EN'),
            }
          } else {
            translationsObservable = {
              cyrrentLangTranslation: this.getTranslation(translationIds, lang.toUpperCase()),
            }
          }
          return forkJoin(translationsObservable);
        }),
        switchMap((translations: any) => {
          const defaultLangTranslation = translations.defaultLangTranslation;
          const cyrrentLangTranslation = translations.cyrrentLangTranslation;

          let data = {
            unitSystems: {},
            units: {}
          };
          physicalPropsList.forEach((_name: string) => {

            let unitIds: number[] = unitUsageResponse.filter((x) => x.physicalPropertyId === _name).map((_x) => _x.physicalUnitId);
            unitIds = [...new Set(unitIds)];

            let units = unitUsageResponse.filter((x) => x.physicalPropertyId === _name);

            units.forEach((_unit) => {
              const physicalUnit = physicalUnitsResponse.find((x) => x.physicalUnitId === _unit.physicalUnitId);

              let physicalUnitTitle: string = cyrrentLangTranslation.find((x) => x.translationId === physicalUnit.unitNameId)?.value;
              let physicalUnitSign: string = cyrrentLangTranslation.find((x) => x.translationId === physicalUnit.unitSign)?.value;

              // If translation not found in selected language
              // Take translation from default language
              if (!physicalUnitTitle) {
                physicalUnitTitle = defaultLangTranslation.find((x) => x.translationId === physicalUnit.unitNameId).value;
              };
              if (!physicalUnitSign) {
                physicalUnitSign = defaultLangTranslation.find((x) => x.translationId === physicalUnit.unitSign).value;
              }
              const unitSystemItem = {
                unitTitle: physicalUnitTitle,
                unitSign: physicalUnitSign,
                addFactor: physicalUnit.addFactor,
                multFactor: physicalUnit.multFactor,
                unitSystemId: _unit.unitSystemId,
                physicalUnitId: physicalUnit.physicalUnitId
              };
              const unitItem = {
                unitTitle: physicalUnitTitle,
                unitSign: physicalUnitSign,
                addFactor: physicalUnit.addFactor,
                multFactor: physicalUnit.multFactor,
                physicalUnitId: physicalUnit.physicalUnitId
              };

              if (data.unitSystems[_name]) {
                data.unitSystems[_name].push(unitSystemItem)
              } else {
                data.unitSystems[_name] = [unitSystemItem];
              };


              if (data.units[_name]) {
                const existingUnit = data.units[_name].find((_x) => _x.physicalUnitId === unitItem.physicalUnitId);
                if (!existingUnit) {
                  data.units[_name].push(unitItem);
                };
              } else {
                data.units[_name] = [unitItem];
              };

            });
            console.groupEnd();
          });
          return of(data);
        })
      );
  }
  /**
   * Method return list of available unit systems.
   */
  public getUnitSystems() {
    const url: string = `${this.baseUrl}/api/unitsystem`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.get<IUnitSystem[]>(url, httpOptions);
  }

  // News
  /**method to get list of news */
  public getNewsList(lang: string, page: number, pageSize: number = 2): Observable<{ currentPage: number; news: INews[]; page: number; }> {
    const url: string = `${this.baseUrl}/api/news/${lang}/${page}`;
    let params = new HttpParams();
    params = params.append('pageSize', pageSize);

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params
    }

    return this.http.get<{ currentPage: number; news: INews[]; page: number; }>(url, httpOptions)
      .pipe(
        map((newsResp) => {

          newsResp.news.forEach((_news) => {
            _news.newsDateLocal = new Date(_news.newsDate).toLocaleDateString('de-De').replaceAll('/', '.');
          });
          return newsResp
        })
      );
  }

  /**Method to get selected news by `newsId` */
  public getNews(lang: string, newsId: string): Observable<INews> {
    const url: string = `${this.baseUrl}/api/news/`;
    let params = new HttpParams();
    params = params.append('id_lang', lang);
    params = params.append('id', newsId);

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params
    }

    return this.http.get<INews>(url, httpOptions);
  }

  // Autoselect
  public startAutoselect(itemId: number, warmMaterialId?: number, coldMaterialId?: number) {

    const url: string = this.baseUrl + `/api/autoselection/${itemId}`;

    let body = new HttpParams();

    if (warmMaterialId) {
      body = body.append('warm_side_material', warmMaterialId);
      body = body.append('cold_side_material', coldMaterialId);
    };




    return this.http.post(url, null, { params: body });

  }

  public createSolution(itemId: number, optionId: string): Observable<number> {

    const url: string = this.baseUrl + `/api/autoselection/create_solution/${itemId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: new HttpParams().set('autoselection_id', optionId)
    }

    return this.http.post<number>(url, null, httpOptions);

  }

  public getAutoSelectedOptions(itemId: number): Observable<{ status: string; message: string; foundSolutions: IAutoSelectedOption[]; }> {

    const url: string = this.baseUrl + `/api/autoselection/${itemId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.get<{ status: string; message: string; foundSolutions: IAutoSelectedOption[]; }>(url);

  }

  public getOptionImage(modelId: number) {

    const url: string = `${this.baseUrl}/api/st_model/photo/${modelId}`;


    return this.http.get(url, { responseType: 'blob' }).pipe(
      catchError((error) => {
        if (error.status === 404) {
          return of(null)
        }
        return throwError(() => error);
      })
    );

  }

  public getHeadImage(modelId: number, head_type: string) {

    const url: string = `${this.baseUrl}/api/st_model/head/image/${modelId}`;

    let body = new HttpParams();

    body = body.append('head_type', head_type);

    return this.http.get(url, { responseType: 'blob', params: body }).pipe(
      catchError((error) => {
        if (error.status === 404) {
          return of(null)
        }
        return throwError(() => error);
      })
    );

  }

  // Solutions
  /**
   * Method to get solution by `solution ID`.
   * @param solitionId
   * @returns
   */
  public getSolution(solutionId: number) {
    const url: string = this.baseUrl + `/api/solutions/solution/${solutionId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    return this.http.get<ISolution>(url, httpOptions);
  };
  /**
   * Method to get solution by `item ID`.
   * @param solitionId
   * @returns
   */
  public getSolutionByItemId(itemId: number) {
    const url: string = this.baseUrl + `/api/solutions/${itemId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    return this.http.get<ISolution[]>(url, httpOptions);
  };
  /**
   * Method to get solution configuration components data.
   * @param solitionId
   * @returns
   */
  public getSolutionConfigComponents(solutionId: number) {
    const url: string = this.baseUrl + `/api/solutions/configuration/${solutionId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    return this.http.get<ISolutionConfigComponents>(url, httpOptions);
  };
  public startExtraDutyCalculation(dutyId: number) {
    const url: string = this.baseUrl + `/api/solutions/calculate_extra_duty/${dutyId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    return this.http.post<{ type: string; message: string }[]>(url, httpOptions);
  };
  /**
   * Method to get solution drawing by `model ID`.
   * @param modelId
   * @returns
   */
  public getSolution3DDrawing(modelId: number) {
    const url: string = this.baseUrl + `/api/solutions/3d_drawing/${modelId}`;

    return this.http.get(url, { observe: 'response', responseType: 'arraybuffer', headers: new HttpHeaders({ "Content-Type": "application/octet-stream" }) });
  };


  public getHtmlDatasheet(solutionId: number) {
    const url: string = this.baseUrl + `/api/datasheet/${solutionId}`;

    let params: HttpParams = new HttpParams();

    params = params.append('formatType', 'Html');

    return this.http.get(url, { responseType: 'text', headers: new HttpHeaders({ "Content-Type": "application/html" }), params });
  };
  public getPdfDatasheet(solutionId: number) {
    const url: string = this.baseUrl + `/api/datasheet/${solutionId}`;

    let params: HttpParams = new HttpParams();

    params = params.append('formatType', 'Pdf');

    return this.http.get(url, { responseType: 'arraybuffer', headers: new HttpHeaders({ "Content-Type": "application/pdf" }), params });
  };
  public getPdfDatasheetInternal(solutionId: number) {
    const url: string = this.baseUrl + `/api/datasheet/internal/${solutionId}`;

    let params: HttpParams = new HttpParams();

    params = params.append('formatType', 'Pdf');

    return this.http.get(url, { responseType: 'arraybuffer', headers: new HttpHeaders({ "Content-Type": "application/pdf" }), params });
  };
  public getHtmlDatasheetInternal(solutionId: number) {
    const url: string = this.baseUrl + `/api/datasheet/internal/${solutionId}`;

    let params: HttpParams = new HttpParams();

    params = params.append('formatType', 'Html');

    return this.http.get(url, { responseType: 'text', headers: new HttpHeaders({ "Content-Type": "application/html" }), params });
  };
  public getSolutionDrawings(solutionId: number, drawingType: string) {
    const url: string = this.baseUrl + "/api/solutions/dxf_drawing";

    let params = new HttpParams();
    params = params.append('solution_id', solutionId);
    params = params.append('drawing_type', drawingType);

    const httpOptions: any = {
      params,
      responseType: 'blob'
    };

    return this.http.get(url, httpOptions);
  };

  /**
   * Method to save new data for solution configuration component.
   */
  public saveSolutionConfigComponent(solutionId: number, componentName: string, componentValue: string) {
    const url: string = this.baseUrl + `/api/solutions/configuration/${solutionId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    };

    return this.http.post(url, { componentName, componentValue }, httpOptions);
  }

  // Assistant
  public getCountries() {
    const url: string = `${this.baseUrl}/api/country`;
    return this.http.get<ICountryResp[]>(url);
  }
  public getCountryCodes() {
    const url: string = `${this.baseUrl}/api/country_codes`;
    return this.http.get<{ countryCode: string; phoneCode: string; }[]>(url);
  }



  // *** ADMIN API METHODS START ***

  /**Method to get users */
  public getUsers(): Observable<IUser[]> {
    const url: string = this.baseUrl + "/api/administrator/users";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<IUser[]>(url, httpOptions)
  };

  /**Method to get Companies */
  public getCompanies() {
    const url: string = `${this.baseUrl}/api/administrator/company`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.get<ICompany[]>(url, httpOptions).pipe(
      map((response) => {
        for(let company of response){
          this.cacheCompaniesDetailes[company.companyId] = company
        }
        return response;
      })
    )
  };

  /**Method to get Company */
  public getCompany(companyId: any) {
    const url: string = `${this.baseUrl}/api/administrator/company/${companyId}`;

    if (this.cacheCompaniesDetailes[companyId]) {
      return of(this.cacheCompaniesDetailes[companyId]);
    };

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.get<ICompany>(url, httpOptions).pipe(
      map((response) => {
        this.cacheCompaniesDetailes[companyId] = response;
        return response;
      })
    )
  };

  /** Update current user */
  public updateUser(data: any): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/users/${data.userId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.put<any>(url, data, httpOptions)
  };

  /** Update compuny */
  public updateCompany(data: any): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/company/" + data.companyId;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }

    return this.http.put<any>(url, data, httpOptions).pipe(
      map((response) => {
        if (this.cacheCompaniesDetailes[data.companyId]) {
          delete this.cacheCompaniesDetailes[data.companyId];
        };
        return response;
      })
    );
  };

  /** Get fluid Classes*/
  public getFluid() {
    const url: string = this.baseUrl + "/api/fluid/classes";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get(url, httpOptions)
  };

  /** Post fluid class */
  public addFluidClass(data: any): Observable<any> {
    const url: string = this.baseUrl + "/api/fluid/classes";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.post<any>(url, data, httpOptions);
  }

  /** Update fluid */
  public updateFluid(fluidId: number, data: any): Observable<any> {
    const url = `${this.baseUrl}/api/fluid/system/${fluidId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.put<any>(url, data, httpOptions);
  }

  /** add Fluid  */
  public addFluid(data: any): Observable<any> {
    const url = `${this.baseUrl}/api/fluid/system`;
    return this.http.post<any>(url, data, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    });
  }

  /** Put fluid class*/
  public editFluidClass(fluidClassId: number, data: any): Observable<any> {
    const url = `${this.baseUrl}/api/fluid/classes/${fluidClassId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.put<any>(url, data, httpOptions);
  }

  /** delete fluid  class*/
  public deleteFluidClass(fluidClassId: number): Observable<any> {
    const url = `${this.baseUrl}/fluid/classes/${fluidClassId}`;

    return this.http.delete<any>(url, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    });
  }

  /** Get fluid Properties */
  public getFluidProperties(fluidId: any): Observable<any> {
    const url: string = `${this.baseUrl}/api/fluid/properties/${fluidId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<any>(url, httpOptions);
  }

  /** Get fluids By ClassId  */
  public getFluidsByClassId(fluidClassId: any): Observable<any> {
    const url: string = `${this.baseUrl}/api/fluid/${fluidClassId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<any>(url, httpOptions);
  }

  /** Get user by ID */
  public getUserById(userID: any): Observable<any> {
    const url: string = `${this.baseUrl}/api/administrator/users/${userID}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<any>(url, httpOptions);
  }

  /** get Physica lProperty Info */
  public getPhysicalPropertyInfo(propertyIds: any): Observable<any> {
    const url = `${this.baseUrl}/api/unitsystem/physicalproperties`;
    const body = propertyIds;

    return this.http.post<any>(url, body, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    });
  }

  public updateFluidProperty(fluidPropValueId: number, requestData: any): Observable<any> {
    const url = `${this.baseUrl}/api/fluid/properties/${fluidPropValueId}`;
    return this.http.put<any>(url, requestData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  // get Physical Unit Info
  public getPhysicalUnitInfo(unitIds: number[]): Observable<IPhysicalUnit[]> {
    const url = `${this.baseUrl}/api/unitsystem/physicalunits`;
    return this.http.post<IPhysicalUnit[]>(url, unitIds, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }

  /** get Unit*/
  public getUnit(): Observable<IPhysicalUnit[]> {
    const url = `${this.baseUrl}/api/unitsystem/physicalunits`;
    return this.http.get<IPhysicalUnit[]>(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    });
  };

  public updateUnit(data) {
    const url: string = `${this.baseUrl}/api/unitsystem/physicalunits/${data.physicalUnitId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };

  public saveUnit(data) {
    const url: string = `${this.baseUrl}/api/unitsystem/physicalunits/new`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  /** get Physical Properties ComboBox */
  public getPhysicalPropertiesComboBox(): Observable<any> {
    const url = `${this.baseUrl}/api/fluid/propertyusage`;
    return this.http.get<any>(url, {
      headers: new HttpHeaders({
        "Content-Type": "application/json"
      })
    });
  }

  public addFluidProperty(fluidId: number, requestData: any): Observable<any> {

    const url = `${this.baseUrl}/api/fluid/properties/${fluidId}`;
    return this.http.post(url, requestData);
  }

  // Get usage data by fluidId
  public getUsageByFluidId(fluidId: number): Observable<any> {

    const url = `${this.baseUrl}/api/fluid/usages/${fluidId}`;
    return this.http.get<any>(url);
  }

  // Add new usage data for fluidId
  public addUsageByFluid(fluidId: number, usageData: any): Observable<any> {

    const url = `${this.baseUrl}/api/fluid/usages/${fluidId}`;
    return this.http.post<any>(url, usageData, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      })
    });
  }

  // Update usage data by fluidUsageId
  public updateFluidUsage(fluidUsageId: number, usageData: { usage: string, productType: string }): Observable<any> {
    const url = `${this.baseUrl}/api/fluid/usages/${fluidUsageId}`;

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
    };

    return this.http.put(url, usageData, httpOptions);
  }


  public getDictionaryData(dictionaryId: number) {
    const url: string = `${this.baseUrl}/api/dictionary/data/${dictionaryId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    };

    return this.http.get<IDictionaryData[]>(url, httpOptions);
  }

  /**Method to get roles for a user */
  public getUserRoles(userId: any) {

    const url: string = this.baseUrl + "/api/administrator/users/" + userId + "/roles";
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }
    return this.http.get(url, httpOptions);
  }

  /**Method to add role to a user */
  public addUserRole(userId: number, roleData: any) {
    const url: string = this.baseUrl + `/api/administrator/users/${userId}/roles`;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    };

    return this.http.post(url, roleData, httpOptions);
  }

  /**Method to delete role from a user */
  public deleteUserRole(userId: number, roleId: number) {
    const url: string = this.baseUrl + "/api/administrator/users/" + userId + "/roles/" + roleId;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.delete(url, httpOptions);
  };
  /**Method to delete user */
  public deleteUser(userId: number) {
    const url: string = this.baseUrl + "/api/administrator/users/" + userId;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.delete(url, httpOptions);
  };

  /**Method to get list of possible roles */
  public getRoles() {
    const url: string = this.baseUrl + "/api/administrator/roles";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get(url, httpOptions);
  };

  // ShellAndTube
  public getShellAndTubeRawMaterials() {
    const url: string = `${this.baseUrl}/api/st_model/raw_materials`;
    return this.http.get<{ rawMaterialId: number, materialNameId: number }[]>(url);
  };
  public getShellAndTubeRawMaterialsDefault(ids: number[]) {
    const url: string = `${this.baseUrl}/api/st_model/raw_material_defaults`;
    return this.http.post<{ rawMaterialId: number, fluidClassId: number }[]>(url, ids);
  };
  public getShellAndTubeFouling() {
    const url: string = `${this.baseUrl}/api/st_model/fouling`;
    return this.http.get<any[]>(url);
  };
  public getHEProductTypes() {
    const url: string = `${this.baseUrl}/api/st_model/product_types`;
    return this.http.get<IHEProductType[]>(url);
  };

  // *** ST Model ***

  public getStModels() {
    const url: string = `${this.baseUrl}/api/administrator/st_model`;
    return this.http.get<IStModel[]>(url);
  };
  public updateStModel(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model/${data.stModelId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };

  public createStModel(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  // *** ST TUBE LAYOUTS START ***

  public getStTubeLayouts(stModelId: number, page: number, orderByFieldName?: string, isDescending: boolean = false, pageSize: number = 10) {
    const url: string = `${this.baseUrl}/api/administrator/st_tube_layouts/by_model_id/${stModelId}`;

    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    if (orderByFieldName) {
      params = params.append('orderByFieldName', orderByFieldName);
    };
    if (isDescending) {
      params = params.append('isDescending', isDescending);
    };

    return this.http.get<{ currentPage: number; pages: number; entities: IStTubeLayout[]; }>(url, { params });
  };
  public updateStTubeLayouts(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_tube_layouts/${data.tubeLayoutId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public saveStTubeLayouts(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_tube_layouts`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  // *** ST TUBE LAYOUTS END   ***

  // *** ST BAFFLE LAYOUTS START ***

  public getStBaffleLayouts(stModelId: number, page: number, orderByFieldName?: string, isDescending: boolean = false, pageSize: number = 10) {
    const url: string = `${this.baseUrl}/api/administrator/st_baffle_layout/by_model_id/${stModelId}`;

    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);

    if (orderByFieldName) {
      params = params.append('orderByFieldName', orderByFieldName);
    };
    if (isDescending) {
      params = params.append('isDescending', isDescending);
    };

    return this.http.get<{ currentPage: number; pages: number; entities: IStBaffleLayout[]; }>(url, { params });
  };
  public updateStBaffleLayouts(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_baffle_layout/${data.baffleLayoutId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public saveStBaffleLayouts(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_baffle_layout`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  // *** ST BAFFLE LAYOUTS END   ***

  // *** ST TUBES IN WINDOW START ***

  public getTubeLayoutByBaffleId(baffleLayoutId: number) {
    const url: string = `${this.baseUrl}/api/administrator/st_tubes_in_window/by_baffle_layout_id/${baffleLayoutId}`;

    return this.http.get<IStTubeAndBaffleLayout[]>(url);
  };
  public updateTubeLayoutByTubeInWindowId(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_tubes_in_window/${data.tubesInWindowId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public saveStTubeAndBaffleLayout(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_tubes_in_window`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  // *** ST TUBES IN WINDOW END   ***

  // *** ST MODEL IMAGE START ***

  public getStModelImagesById(stModelId: number) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_image/by_model_id/${stModelId}`;

    let params = new HttpParams();

    return this.http.get<IStModelImages[]>(url,);
  };
  public updateStModelImagesItem(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_image/${data.stModelImageId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public saveStModelImageFile(file: File, stModelImageId: number) {

    const url: string = `${this.baseUrl}/api/administrator/st_model_image/file/${stModelImageId}`;

    const form: FormData = new FormData();
    form.append('formFile', file);

    return this.http.put(url, form);
  };
  public saveStModelImageItem(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_image`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post<IStModelImages>(url, data, httpOptions);
  };

  // *** ST MODEL IMAGE END   ***

  // *** ST MODEL HEADS START ***

  public getStModelHeadsById(stModelId: number) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_head/by_model_id/${stModelId}`;

    let params = new HttpParams();

    return this.http.get<IStModelHead[]>(url,);
  };
  public updateStModelHeadItem(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_head/${data.stModelHeadId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public saveStModelHeadImageFile(file: File, stModelHeadId: number) {

    const url: string = `${this.baseUrl}/api/administrator/st_model_head/file/${stModelHeadId}`;

    const form: FormData = new FormData();
    form.append('formFile', file);

    return this.http.put(url, form);
  };
  public saveStModelHeadItem(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_head`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post<IStModelHead>(url, data, httpOptions);
  };

  // *** ST MODEL HEADS END ***

  // *** ST MODEL DRAWING START ***

  public getStModelDrawingById(stModelId: number) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_drawing/by_model_id/${stModelId}`;

    let params = new HttpParams();

    return this.http.get<IStModelDrawing[]>(url,);
  };
  public updateStModelDrawingItem(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_drawing/${data.stModelDrawingId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public saveStModelDxfFile(file: File, stModelDrawingId: number) {

    const url: string = `${this.baseUrl}/api/administrator/st_model_drawing/file/${stModelDrawingId}`;

    const form: FormData = new FormData();
    form.append('formFile', file);

    return this.http.put(url, form);
  };
  public saveStModelDrawingItem(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_drawing`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post<IStModelDrawing>(url, data, httpOptions);
  };

  // *** ST MODEL DRAWING END ***

  // *** ST EQUIP RULES START ***

  public getStModelEquipRuleById(stModelId: number) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_equip_rule/by_model_id/${stModelId}`;

    let params = new HttpParams();

    return this.http.get<IStModelEquipRule[]>(url, { params });
  };
  public updateStModelEquipRule(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_equip_rule/${data.stEquipRuleId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public saveStModelEquipRule(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_equip_rule`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post<IStModelEquipRule>(url, data, httpOptions);
  };

  // *** ST EQUIP RULES END   ***

  // *** ST MODEL CUSTOM APPLICATION START ***

  public getStModelSpecialApplicationsById(stModelId: number) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_custom_application/by_model_id/${stModelId}`;

    let params = new HttpParams();

    return this.http.get<IStModelSpecialApplication[]>(url, { params });
  };
  public updateStModelSpecialApplication(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_custom_application/${data.modelCustAppId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public saveStModelSpecialApplication(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_custom_application`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post<IStModelSpecialApplication>(url, data, httpOptions);
  };

  // *** ST MODEL CUSTOM APPLICATION END   ***

  // *** ST MODEL FLUID STATE START ***

  public getStModelApplicationsById(stModelId: number) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_fluid_states_admi/by_model_id/${stModelId}`;

    let params = new HttpParams();

    return this.http.get<IStModelApplication[]>(url, { params });
  };
  public updateStModelApplication(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_fluid_states_admi/${data.flAdmisId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public saveStModelApplication(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_model_fluid_states_admi`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post<IStModelSpecialApplication>(url, data, httpOptions);
  };

  // *** ST MODEL FLUID STATES END   ***

  // Raw Materials
  public getRawMaterial(rawMaterialId: number) {
    const url: string = `${this.baseUrl}/api/raw_materials/${rawMaterialId}`;
    return this.http.get<{
      rawMaterialId: number;
      materialClassId: number;
      materialNameId: number;
      materialDescId: number;
      isActive: number;
      dateInsert: string;
      userInsert: number;
      dateUpdate: string;
      userUpdate: number;
    }>(url);
  }

  // *** ADMIN API METHODS END ***

  //  *** Zilo START ***

  /**
   * Method to get hint for Zilo for selected status.
   * @param itemStatus item status
   */
  public getZiloHint(itemStatus: number | string) {
    const url: string = this.baseUrl + "/api/zilo/item_step/" + itemStatus;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<IZiloHint>(url, httpOptions);
  };
  /**
   * Method to get hint for Zilo for selected status.
   * @param itemStatus item status
   */
  public getZiloHints() {
    const url: string = this.baseUrl + "/api/zilo";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<IZiloHintExtended[]>(url, httpOptions);
  };

  public updateZiloHint(data: { ziloHintId: number, isActive: number; itemStep: string; hintTextId: number; }) {

    const url = `${this.baseUrl}/api/zilo/${data.ziloHintId}`;
    return this.http.put<any>(url, data, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    });
  };

  public addZiloHint(data: { isActive: number; itemStep: string; hintTextId: number; }) {
    const url = `${this.baseUrl}/api/zilo`;

    return this.http.post<any>(url, data, {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    });
  };

  //  *** Zilo END ***

  //  *** Properties and Units start ***

  // *** Properties start ***

  public getPhysicalProperties() {
    const url: string = `${this.baseUrl}/api/unitsystem/physicalproperties`;

    let params = new HttpParams();

    return this.http.get<IPhysicalProperty[]>(url, { params });
  };

  public updatePhysicalProperty(data) {
    const url: string = `${this.baseUrl}/api/unitsystem/physicalproperties`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };

  public savePhysicalProperty(data) {
    const url: string = `${this.baseUrl}/api/unitsystem/physicalproperties/new`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  // *** Properties end ***

  //  *** Units Usage start ***
  public getUnitsUsage(data: string[]) {
    const url: string = `${this.baseUrl}/api/unitsystem/unitsusage/all`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post<IUnitUsage[]>(url, data, httpOptions);
  };

  public updateUnitUsageItem(data) {
    const url: string = `${this.baseUrl}/api/unitsystem/unitsusage/${data.unitUsageId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };

  public saveUnitUsageItem(data) {
    const url: string = `${this.baseUrl}/api/unitsystem/unitsusage/new`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  //  *** Units Usage end ***

  //  *** Unit Systems start ***
  public updateUnitSystem(data) {
    const url: string = `${this.baseUrl}/api/unitsystem/${data.unitSystemId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };

  public saveUnitSystem(data) {
    const url: string = `${this.baseUrl}/api/unitsystem`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  //  *** Unit Systems end ***

  //  *** Properties and Units end ***

  // *** Statistics start ***
  public getStatistics() {
    const url: string = `${this.baseUrl}/api/statistics`;

    return this.http.get<{ [key: string]: string; }[]>(url);
  };
  // *** Statistics end ***

  // *** Restricted Product Types start ***
  public getRestrictedProductTypes(userId: any) {
    const url: string = `${this.baseUrl}/api/administrator/guauseraccess/${userId}/1`;

    return this.http.get<IRestrictedProductType[]>(url);
  };

  public saveRestrictedProductType(data) {
    const url: string = `${this.baseUrl}/api/administrator/guauseraccess`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  public deleteRestrictedProductType(userId: number, codeObject: number, valueObject: string) {
    const url: string = `${this.baseUrl}/api/administrator/guauseraccess`;

    let params = new HttpParams();
    params = params.append('user_id', userId);
    params = params.append('code_object', codeObject);
    params = params.append('value_object', valueObject);

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params
    };
    return this.http.delete(url, httpOptions);
  };
  // *** Restricted Product Types end ***

  //  SETTINGS
  public getSettings(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/settings";

    return this.http.get<any>(url);
  }
  public updateSettings(data) {
    const url: string = `${this.baseUrl}/api/administrator/settings/${data.settingId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  // PRICE start
  public getPriceData(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/pr_price_tag";

    return this.http.get<any>(url);
  }
  public updatePrice(data) {
    const url: string = `${this.baseUrl}/api/administrator/pr_price_tag/${data.priceTagId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createPrice(data) {
    const url: string = `${this.baseUrl}/api/administrator/pr_price_tag`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  public getValuesData(price_tag_id): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/pr_price_tag_values/by_price_tag_id/" + price_tag_id;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<any>(url, httpOptions)
  }
  public updateValues(data) {

    const url: string = `${this.baseUrl}/api/administrator/pr_price_tag_values/${data.priceTagValueId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createValues(data) {
    const url: string = `${this.baseUrl}/api/administrator/pr_price_tag_values`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  // PRICE end
  // *** Administrator Countries start ***

  public getAdminCountries() {

    const url: string = this.baseUrl + "/api/administrator/country";

    return this.http.get<IAdminCountry[]>(url);
  };

  public updateAdminCountry(data) {
    const url: string = `${this.baseUrl}/api/administrator/country/${data.countryId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };

  public saveAdminCountry(data) {
    const url: string = `${this.baseUrl}/api/administrator/country`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  // *** Administrator Countries end ***

  // DELIVERY TIME start
  public getDeliveryData(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_delivery_time";

    return this.http.get<any>(url);
  }
  public updateDeliveryTime(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_delivery_time/${data.deliveryTimeId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createDeliveryTime(data) {
    const url: string = `${this.baseUrl}/api/administrator/st_delivery_time`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  // DELIVERY TIME END

  // TUBE MATERIALS START
  public getListTubeMaterials(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/raw_materials/tube_materials";

    return this.http.get<any>(url);
  }
  // TUBE MATERIALS END


  //** COMPONETNS START  ***** */

  //** Shell Tubes start
  public getShellTubes(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_shell_tube";

    return this.http.get<any>(url);
  }
  public updateShellTubes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_shell_tube/${data.shellTubeId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createShellTubes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_shell_tube`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  //** Shell Tubes end

  //**Tubes start */
  public getTubes(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_tube";

    return this.http.get<any>(url);
  }
  public updateTubes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_tube/${data.stTlId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createTubes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_tube`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  //**Tubes end */

  //**Body Flanges start */
  public getBodyFlanges(page: number, orderByFieldName?: string, isDescending: boolean = false, pageSize: number = 10) {
    const url: string = `${this.baseUrl}/api/administrator/st_body_flange`;

    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    if (orderByFieldName) {
      params = params.append('orderByFieldName', orderByFieldName);
    };
    if (isDescending) {
      params = params.append('isDescending', isDescending);
    };
    return this.http.get<{ currentPage: number; pages: number; entities: IBodyFlangesRaw[] }>(url, { params });
  };
  public updateBodyFlanges(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_body_flange/${data.bodyFlangeId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createBodyFlanges(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_body_flange`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  //**Body Flanges end */

  /*** Base Plates start ***/
  public getBasePlates(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_base_plate";

    return this.http.get<any>(url);
  }
  public updateBasePlates(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_base_plate/${data.basePlateId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createBasePlates(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_base_plate`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /**Base Plates end */

  /** Baffles start */
  public getBaffles(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_baffle";

    return this.http.get<any>(url);
  }
  public updateBaffles(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_baffle/${data.baffleId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createBaffles(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_baffle`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  /** Baffles end */


  /**Dictionary start */
  public getDictionary() {

    const url: string = this.baseUrl + "/api/administrator/dictionary";

    return this.http.get<any>(url);
  }
  public createDictionary(data) {

    const url: string = `${this.baseUrl}/api/administrator/dictionary`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  public updateDictionary(data) {
    const url: string = `${this.baseUrl}/api/administrator/dictionary/${data.dictionaryId}`;

    const params = new HttpParams()
      .set('name', data.dictionaryName)
      .set('description', data.description);

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: params
    };

    return this.http.put(url, null, httpOptions);
  }


  public createDictionaryData(data) {

    const url: string = `${this.baseUrl}/api/administrator/dictionary/data`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  public updateDictionaryData(data) {

    const url: string = `${this.baseUrl}/api/administrator/dictionary/data`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  /**Dictionary end   */

  /** Steam Drums start */
  public getSteamDrums(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_steam_drum";

    return this.http.get<any>(url);
  }
  public updateSteamDrums(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum/${data.steamDrumId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createSteamDrums(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  /** Steam Drums end */

  /** Torispherical Heads start */
  public getTorisphericalHeads(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_torisph_head";

    return this.http.get<any>(url);
  }
  public updateTorisphericalHeads(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_torisph_head/${data.headId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createTorisphericalHeads(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_torisph_head`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  /** Torispherical Heads end */

  //**Tubesheets start */
  public getTubesheets(page: number, orderByFieldName?: string, isDescending: boolean = false, pageSize: number = 10) {

    const url: string = `${this.baseUrl}/api/administrator/st_tubesheet`;

    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    if (orderByFieldName) {
      params = params.append('orderByFieldName', orderByFieldName);
    };
    if (isDescending) {
      params = params.append('isDescending', isDescending);
    };
    return this.http.get<{ currentPage: number; pages: number; entities: ITubeSheetRaw[] }>(url, { params });
  };
  public updateTubesheets(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_tubesheet/${data.tubesheetId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createTubesheets(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_tubesheet`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  //**Tubesheets end */

  /** Allowed inner tubes for shell tubes start */
  public getAllowedTubes(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_shell_tube_model_admissibility";

    return this.http.get<any>(url);
  }
  public updateAllowedTubes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_shell_tube_model_admissibility/${data.admissibilityId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createAllowedTubes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_shell_tube_model_admissibility`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  /** Allowed inner tubes for shell tubes end */

  /** Insulation  start*/
  public getInsulation(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_insulation";

    return this.http.get<any>(url);
  }
  public updateInsulation(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_insulation/${data.insulationId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createInsulation(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_insulation`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };

  /** Insulation end */

  /** Material-dependent  start*/
  public getMaterialDependent(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_material_option";

    return this.http.get<any>(url);
  }
  public updateMaterialDependent(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_material_option/${data.materialOptionId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createMaterialDependent(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_material_option`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /**  Material-dependent  end */

  /** Shell-dependent  start*/
  public getShellDependent(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_shell_option";

    return this.http.get<any>(url);
  }
  public updateShellDependent(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_shell_option/${data.shellOptionId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createShellDependent(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_shell_option`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /**  Shell-Dependent end */

  /** Surface Option  start*/
  public getSurfaceOption(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_surface_option";

    return this.http.get<any>(url);
  }
  public updateSurfaceOption(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_surface_option/${data.surfaceOptionId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createSurfaceOption(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_surface_option`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /**  Surface Option end */

  /** Port Option  start*/
  public getPortOption(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_port_option";

    return this.http.get<any>(url);
  }
  public updatePortOption(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_option/${data.portOptionId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createPortOption(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_option`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /**  Port Option end */

  /** Generic Option  start*/
  public getGenericOption(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_generic_option";

    return this.http.get<any>(url);
  }
  public updateGenericOption(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_generic_option/${data.genericOptionId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createGenericOption(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_generic_option`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /**  Generic Option end */

  /** Allowed Pressures  start*/
  public getAllowedPressures(id): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum_pressure/by_steam_drum_id/${id}`;

    return this.http.get<any>(url);
  }
  public updateAllowedPressures(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum_pressure/${data.steamDrumPressureId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createAllowedPressures(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum_pressure`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Allowed Pressures end */

  /** Extra Ports  start*/
  public getExtraPort(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_steam_drum_extra_port";

    return this.http.get<any>(url);
  }
  public updateExtraPort(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum_extra_port/${data.steamDrumExtraPortId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createExtraPort(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum_extra_port`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Extra Ports end */
  /** Heads  start*/
  public getSteamDrumHead(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_steam_drum_head";

    return this.http.get<any>(url);
  }
  public updateSteamDrumHead(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum_head/${data.sdHeadId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createSteamDrumHead(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum_head`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Heads end */

  /** Saddles  start*/
  public getSteamDrumSaddles(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_steam_drum_saddle";

    return this.http.get<any>(url);
  }
  public updateSteamDrumSaddles(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum_saddle/${data.steamDrumSaddleId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createSteamDrumSaddles(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_steam_drum_saddle`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** SteamDrum Saddles end */

  /** Gaskets Materials  start*/
  public getGasketMaterials(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/ga_material";

    return this.http.get<any>(url);
  }
  public updateGasketMaterial(data) {

    const url: string = `${this.baseUrl}/api/administrator/ga_material/${data.gaMaterialId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createGasketMaterial(data) {

    const url: string = `${this.baseUrl}/api/administrator/ga_material`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  public saveGasketMaterialImageFile(file: File, id: number) {

    const url: string = `${this.baseUrl}/api/administrator/ga_material/file/${id}`;

    const form: FormData = new FormData();
    form.append('formFile', file);

    return this.http.put(url, form);
  };
  /** Gaskets Materials end */

  /** Gaskets Dimensions  start*/
  public getGasketDimensions(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/ga_dimensions";

    return this.http.get<any>(url);
  }
  public updateGasketDimensions(data) {

    const url: string = `${this.baseUrl}/api/administrator/ga_dimensions/${data.gaDimensionId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createGasketDimensions(data) {

    const url: string = `${this.baseUrl}/api/administrator/ga_dimensions`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Gaskets Dimensions end */

  /** Gaskets Addon  start*/
  public getGasketAddon(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/ga_addon_price";

    return this.http.get<any>(url);
  }
  public updateGasketAddon(data) {

    const url: string = `${this.baseUrl}/api/administrator/ga_addon_price/${data.gaAddonPriceId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createGasketAddon(data) {

    const url: string = `${this.baseUrl}/api/administrator/ga_addon_price`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Gaskets Addon end */

  /**Gasket Prices */
  public getGasketPrices(page: number, orderByFieldName?: string, isDescending: boolean = false, pageSize: number = 10) {

    const url: string = `${this.baseUrl}/api/administrator/ga_price`;

    let params = new HttpParams();
    params = params.append('page', page);
    params = params.append('pageSize', pageSize);
    if (orderByFieldName) {
      params = params.append('orderByFieldName', orderByFieldName);
    };
    if (isDescending) {
      params = params.append('isDescending', isDescending);
    };
    return this.http.get<{ currentPage: number; pages: number; entities: IGasketPricesRaw[] }>(url, { params });
  };
  public updateGasketPrices(data) {

    const url: string = `${this.baseUrl}/api/administrator/ga_price/${data.gaPriceId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createGasketPrices(data) {

    const url: string = `${this.baseUrl}/api/administrator/ga_price`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Gasket Prices end */

  /** Pressure Ratings  start*/
  public getPressureRatings(): Observable<any> {

    const url: string = this.baseUrl + "/api/administrator/st_pressure_rating";

    return this.http.get<any>(url);
  }
  public updatePressureRatings(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_pressure_rating/${data.pressureRatingId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createPressureRatings(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_pressure_rating`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Pressure Ratings  end */
  /** Pressure Ratings Data  start*/
  public getPressureRatingsData(pressureRatingId): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/st_pressure_rating_data/by_pressure_rating_id/${pressureRatingId}`;

    return this.http.get<any>(url);
  }
  public updatePressureRatingsData(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_pressure_rating_data/${data.pressureRatingDataId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createPressureRatingsData(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_pressure_rating_data`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Pressure Ratings Data  end */

  public getShellAndTubeRawMaterialsClasses(): Observable<IRawMaterialClases[]> {
    const url: string = `${this.baseUrl}/api/raw_materials/classes`;
    return this.http.get<IRawMaterialClases[]>(url);
  };

  /** Velocity Limits  start*/
  public getVelocityLimits(): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/st_max_velocity`;

    return this.http.get<any>(url);
  }
  public updateVelocityLimits(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_max_velocity/${data.maxVelocityId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createVelocityLimits(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_max_velocity`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Velocity Limits  end */

  /** Port Types  start*/
  public getPortTypes(): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/st_port_type`;

    return this.http.get<any>(url);
  }
  public updatePortTypes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_type/${data.portTypeId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createPortTypes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_type`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Port Types  end */
  /** Port Pipes  start*/
  public getPortPipes(): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/st_port_pipe`;

    return this.http.get<any>(url);
  }
  public updatePortPipes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_pipe/${data.portPipeId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createPortPipes(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_pipe`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Port Pipes  end */
  /** Allowed Shell Tubes  start*/
  public getPortAllowShellTube(id): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/st_port_pipe_admissibility/by_port_pipe_id/${id}`;

    return this.http.get<any>(url);
  }
  public updatePortAllowShellTube(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_pipe_admissibility/${data.portPipeAdmissibilityId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createPortAllowShellTube(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_pipe_admissibility`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Allowed Shell Tubes  end */

  /** Pressures  start*/
  public getPortPressures(id): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/st_port_pipe_pressure/by_port_pipe_admis_id/${id}`;

    return this.http.get<any>(url);
  }
  public updatePortPressures(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_pipe_pressure/${data.portPipePressureId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createPortPressures(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_pipe_pressure`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Pressures  end */

  /** Port Flanges  start*/
  public getPortFlanges(): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/st_port_flange`;

    return this.http.get<any>(url);
  }
  public updatePortFlanges(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_flange/${data.portFlangeId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createPortFlanges(data) {

    const url: string = `${this.baseUrl}/api/administrator/st_port_flange`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Port Flanges  end */

  /** Default Material  start*/
  public getDefaultMaterial(fluidClassId): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/rm_material_fluid_defaults/by_fluid_class_id/${fluidClassId}`;

    return this.http.get<any>(url);
  }
  public updateDefaultMaterial(data) {

    const url: string = `${this.baseUrl}/api/administrator/rm_material_fluid_defaults/${data.materialFluidDefaultId}`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.put(url, data, httpOptions);
  };
  public createDefaultMaterial(data) {

    const url: string = `${this.baseUrl}/api/administrator/rm_material_fluid_defaults`;
    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
    }
    return this.http.post(url, data, httpOptions);
  };
  /** Default Material end */


  /** RAW MATERIALS  */

  /** Post material class */
  public getRawMaterials() {

    const url: string = `${this.baseUrl}/api/raw_materials`;

    return this.http.get<any>(url);
  };

  /** Get material classes */
  public getMaterialsClasses() {

    const url: string = this.baseUrl + "/api/raw_materials/classes";

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get(url, httpOptions)
  };

  /** Get materials by ID */
  public getMaterialsByClassId(materialClassId: any): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/raw_materials/by_class/${materialClassId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<any>(url, httpOptions);
  }
  
  /** Post material class */
  public addMaterialClass(classNameId: number, parentClassId?: number) {

    const url: string = this.baseUrl + "/api/administrator/raw_materials/classes";

    let params = new HttpParams().set("classNameId", classNameId);

    if (parentClassId) {
      params = params.set("parentClassId", parentClassId);
    }

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: params
    };

    return this.http.post(url, null, httpOptions);
  }

  /** Put (edit) material class*/
  public editMaterialClass(materialClassId: number, classNameId?: number, parentClassId?: number) {

    const url: string = `${this.baseUrl}/api/administrator/raw_materials/classes/${materialClassId}`;

    let params = new HttpParams().set("classNameId", classNameId);

    if (parentClassId) {
      params = params.set("parentClassId", parentClassId);
    }

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" }),
      params: params
    };

    return this.http.put(url, null, httpOptions);
  }
  
  /** Get material properties */
  public getMaterialProperties(rawMaterialId: any): Observable<any> {

    const url: string = `${this.baseUrl}/api/administrator/raw_materials/property_values/${rawMaterialId}`;

    const httpOptions = {
      headers: new HttpHeaders({ "Content-Type": "application/json" })
    }

    return this.http.get<any>(url, httpOptions);
  }

  /** Post (add) material */
  public addMaterial( data): Observable<any> {
                      
    const url = `${this.baseUrl}/api/administrator/raw_materials`;

    let params = new HttpParams()
      .set('materialClassId', data.materialClassId)
      .set('name', data.materialNameId)
      .set('description', data.materialDescId);

    console.log("Sending request to:", url, "with params:", params.toString());

    return this.http.post<any>(url, null, {  
      headers: new HttpHeaders({ "Content-Type": "application/json"  }),
      params: params 
    });
  }
  
  /** Put (edit) material */
  public updateMaterial(materialId: number, data: any): Observable<any> {

    const params = new HttpParams()
    .set('materialClassId', data.materialClassId)
    .set('name', data.materialNameId)
    .set('description', data.materialDescId)
    .set('isActive', data.isActive);

    const url = `${this.baseUrl}/api/administrator/raw_materials/${materialId}`;

    const httpOptions = {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      params: params
    };

    return this.http.put<any>(url, null, httpOptions); 
  }

  /** Post (app) material property*/
  public addPropertyValue(data): Observable<any> {

    const url = `${this.baseUrl}/api/administrator/raw_materials/property_values`;

    return this.http.post<any>(url, data, {
      headers: new HttpHeaders({ "Content-Type": "application/json"})
    });
  }

  /** Put (edit) material property*/
  public updateMaterialProperty(materialPropValueId: number, requestData: any): Observable<any> {

    const url = `${this.baseUrl}/api/administrator/raw_materials/property_values/${materialPropValueId}`;

    return this.http.put<any>(url, requestData, {
      headers: new HttpHeaders({ 'Content-Type': 'application/json' })
    });
  }
  
  /** END RAW MATERIALS  */
}

