import { Component, HostListener } from '@angular/core';
import { NgForm } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService, SnackBarService } from 'src/app/services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  public email: string = null;
  public emailRegexp: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/gm;
  public forgotPasswordFormSubmited: boolean = false;

  constructor(private router: Router, private authService: AuthService, private snackBarService: SnackBarService) { }

  // Validation errors
  public emailNotValid: boolean = false;

  @HostListener('document:keyup.enter', ['$event']) onKeyUpHandler(event: KeyboardEvent) {
    event.preventDefault();
    this.submitForgotPassword();
  }

  public cancel(e): void {
    e.preventDefault();
    this.router.navigate(["/login"]);
  }

  public submitForgotPassword(e?): void {
    if(e){
      e.preventDefault();
    };

    this.emailNotValid = false;

    // If email not entered or not valid 
    // Show error message
    if (!this.email || !this.email.match(this.emailRegexp)) {
      this.emailNotValid = true;
      return;
    }

    this.authService.forgotPassword(this.email).subscribe({
      next: () => {
        this.forgotPasswordFormSubmited = true;
      },
      error: (error) => {
        this.snackBarService.open();
      }
    })
  }
};