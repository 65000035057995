<!-- language menu component start -->
<ng-container>

  <!-- menu component start -->
  <div class="menu-component">
    <!-- language start -->
    <div class="language" (click)="switchMenu()">{{currentLang}}</div>
    <!-- language end -->

    <!-- dropdown menu start -->
    <div class="dropdown-menu" [ngClass]="{'active': openMenu}">
      <!-- language button start -->
      <button class="lang-btn" *ngFor="let lang of languages" (click)="selectLanguage(lang)">{{lang}}</button>
      <!-- language button end -->
    </div>
    <!-- dropdown menu end -->

  </div>
  <!-- menu component end -->

</ng-container>

<!-- language menu component end -->