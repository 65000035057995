import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { INewRequirementsResponse, Role, User } from 'src/app/services/interfaces';
import { TPhysicalUnits, TPhysicalUnitSystems } from '../../duty/applications';
import { forkJoin, switchMap } from 'rxjs';

@Component({
  selector: 'app-size-restrictions',
  templateUrl: './size-restrictions.component.html',
  styleUrls: ['./size-restrictions.component.scss']
})
export class AssistantSizeRestrictionsComponent implements OnInit {
  private quotationId: number = null;
  private itemId: number = null
  private user: User = null;
  private existingRequirement: INewRequirementsResponse = null;

  public units: { unitSystems: TPhysicalUnitSystems; units: TPhysicalUnits; } = null;
  public parameters = {
    length: {
      commonUnits: null,
      userUnits: null,
      radio: '999999999'
    },
    width: {
      commonUnits: null,
      userUnits: null,
      radio: '999999999'
    },
    height: {
      commonUnits: null,
      userUnits: null,
      radio: '999999999'
    }
  };

  public selectedPhysicalUnitId: number = null;

  public error: boolean = false;
  public errorMessage: string = null;


  constructor(private stateService: StateService, private apiService: ApiService, private snackBarService: SnackBarService, private route: ActivatedRoute, private router: Router) { };

  public ngOnInit(): void {
    this.user = this.stateService.get('user');
    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];

    const observable = forkJoin({
      _units: this.apiService.getAllUnitSystemData(['length']),
      itemReqs: this.apiService.getItemRequirements(this.itemId),
    }).subscribe({
      next: ({ _units, itemReqs }) => {
        observable.unsubscribe();
        this.units = _units;

        // Find default unit
        const userUnitSystem = _units.unitSystems['length'].find((_x) => _x.unitSystemId === +this.user.unitSystem);
        const firstUnitSystem = _units.unitSystems['length'].find((_x) => _x.unitSystemId === 1);

        let defaultPhysicalUnitId: number = userUnitSystem ? userUnitSystem.physicalUnitId : firstUnitSystem.physicalUnitId;

        this.selectedPhysicalUnitId = defaultPhysicalUnitId;

        this.existingRequirement = itemReqs.find((_req) => _req.requirementType === 'size_restrictions');

        if (this.existingRequirement) {


          const length = this.existingRequirement.requirementValue.length;
          const width = this.existingRequirement.requirementValue.width;
          const height = this.existingRequirement.requirementValue.height;

          if (length !== 999999999) {
            this.parameters.length.commonUnits = length;
            this.parameters.length.userUnits = Number(this.convertToUser(length, 'length', this.selectedPhysicalUnitId).toFixed(1));
            this.parameters.length.radio = null;
          };
          if (width !== 999999999) {
            this.parameters.width.commonUnits = width;
            this.parameters.width.userUnits = Number(this.convertToUser(width, 'length', this.selectedPhysicalUnitId).toFixed(1));
            this.parameters.width.radio = null;
          };
          if (height !== 999999999) {
            this.parameters.height.commonUnits = height;
            this.parameters.height.userUnits = Number(this.convertToUser(height, 'length', this.selectedPhysicalUnitId).toFixed(1));
            this.parameters.height.radio = null;
          };


        };

      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    })

  };
  public back() {
    this.router.navigate([this.quotationId, this.itemId, 'cleanability'], { relativeTo: this.route.parent });
  };
  public skipGuide(): void {

    const observable = this.apiService.skipGuide(this.itemId).subscribe({
      next: (itemId: number) => {
        let url: string = null;
        if (this.user.roles.includes(Role.UserAdmin)) {
          url = 'useradmin/configurator';
        } else {
          url = 'user/configurator';
        }
        observable.unsubscribe();
        this.router.navigate([url, this.quotationId, this.itemId, 'duty']);
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    })

  };
  public onInputParamChange(paramName: string, value: number): void {

    if (!!value || value === 0) {
      this.parameters[paramName].userUnits = +value;
      this.parameters[paramName].commonUnits = this.convertToSI(+value, 'length', this.selectedPhysicalUnitId);
      this.parameters[paramName].radio = null;
    } else {
      this.parameters[paramName].userUnits = null;
      this.parameters[paramName].commonUnits = null;
      this.parameters[paramName].radio = '999999999';
    };

  };
  public onRadioInputChange(paramName: string, e: any): void {

    this.parameters[paramName].userUnits = null;
    this.parameters[paramName].commonUnits = null;

  };
  public onUnitChange(physicalUnitId: number): void {

    // Save new unit system selected for current parameter.
    // If parameter have SI value,  convert it to value in unit selected by user
    // If not dont do anything.
    this.selectedPhysicalUnitId = physicalUnitId;

    if (this.parameters.length.commonUnits) {
      this.parameters.length.userUnits = Number(this.convertToUser(this.parameters.length.commonUnits, 'length', this.selectedPhysicalUnitId).toFixed(1));
    };
    if (this.parameters.width.commonUnits) {
      this.parameters.width.userUnits = Number(this.convertToUser(this.parameters.width.commonUnits, 'length', this.selectedPhysicalUnitId).toFixed(1));
    };
    if (this.parameters.height.commonUnits) {
      this.parameters.height.userUnits = Number(this.convertToUser(this.parameters.height.commonUnits, 'length', this.selectedPhysicalUnitId).toFixed(1));
    };

  };

  public onContinue(): void {

    this.error = false;
    this.errorMessage = null;

    if (!!this.parameters.length.commonUnits && this.parameters.length.commonUnits <= 0) {
      this.error = true;
      this.errorMessage = "Not valid maximum length";
      return;
    };

    if (!!this.parameters.width.commonUnits && this.parameters.width.commonUnits <= 0) {
      this.error = true;
      this.errorMessage = "Not valid maximum width";
      return;
    };

    if (!!this.parameters.height.commonUnits && this.parameters.height.commonUnits <= 0) {
      this.error = true;
      this.errorMessage = "Not valid maximum height";
      return;
    };

    const length = this.parameters.length.commonUnits || Number(this.parameters.length.radio);
    const width = this.parameters.width.commonUnits || Number(this.parameters.width.radio);
    const height = this.parameters.height.commonUnits || Number(this.parameters.height.radio);

    let observable = null;

    if (this.existingRequirement) {
      observable = this.apiService.updateItemRequirements(this.existingRequirement.requirementId, { requirementType: 'size_restrictions', requirementValue: { length, width, height } });
    } else {
      observable = this.apiService.saveItemRequirements(this.itemId, { requirementType: 'size_restrictions', requirementValue: { length, width, height } });
    };

    observable = observable.pipe(switchMap(() => {
      return this.apiService.updateItem(this.itemId, { quotationId: this.quotationId, itemStatus: '6', itemType: 'ST', itemNumber: '1' });
    })).subscribe({
      next: () => {
        let url: string = null;
        if (this.user.roles.includes(Role.UserAdmin)) {
          url = 'useradmin/configurator';
        } else {
          url = 'user/configurator';
        };
        observable.unsubscribe();
        this.router.navigate([url, this.quotationId, this.itemId, 'duty']);
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });

  };

  /**
  * Method to convert value `from users unit system to SI`.
  * @param value
  * @param propName physical property name. See `physicalPropsNameList property` for correct typing
  * @returns
  */
  private convertToSI(value: number, propName: string, physicalUnitId: number): number {
    const prop = this.units.units[propName].find((x) => x.physicalUnitId === physicalUnitId);
    if (value === 0 || !value) {
      return value;
    };

    return prop.addFactor + (value * prop.multFactor)

  };

  /**
   * Method to convert value `from SI unit system to users unit system`.
   * @param value
   * @param propName physical property name. See `physicalPropsNameList property` for correct typing
   * @returns
   */
  private convertToUser(value: number, propName: string, physicalUnitId: number): number {
    const prop = this.units.units[propName].find((x) => x.physicalUnitId === physicalUnitId);
    if (value === 0 || !value) {
      return value;
    };

    return (value - prop.addFactor) / prop.multFactor;

  };
}
