<!-- duty-side-menu-comp start -->
<div class="duty-side-menu-comp" [ngClass]="{'close': menuSwitchClose}">
  <!-- menu-wrapper start-->
  <div class="menu-wrapper">
    <!-- menu-settings-container start -->
    <div class="menu-settings-container">

      <!-- menu-title start -->
      <div class="menu-title">YOUR SETTINGS:</div>
      <!-- menu-title end -->

      <!-- he-type-settings-container start -->
      <div class="he-type-settings-container">

        <!-- he-type-settings-title start -->
        <div class="he-type-settings-title">
          <!-- text start -->
          <div class="text">HE Type:</div>
          <!-- text end -->

          <!-- arrow-block start -->
          <div class="arrow-block" (click)="heTypeMenuSwitch = !heTypeMenuSwitch">
            <img src="../../../../assets/icons/select_arrow_black.png" alt="" [ngClass]="{'close': !heTypeMenuSwitch}">
          </div>
          <!-- arrow-block end -->

        </div>
        <!-- he-type-settings-title end -->

        <!-- settings-wrapepr start -->
        <div class="settings-wrapper" *ngIf="heTypeMenuSwitch">
          <!-- setting-block start -->
          <div class="setting-block">
            <!-- main start -->
            <div class="setting" [ngClass]="{'disabled': heType !=='ST'}">
              <!-- input radio start -->
              <input type="radio" name="heType" id="st" value="ST" [(ngModel)]="heType"
                (change)="onHETypeChange($event)">
              <!-- input radio end -->

              <!-- label start -->
              <label for="st">
                <!-- circle start -->
                <div class="circle"></div>
                <!-- circle end -->

                <!-- icon start -->
                <img src="../../../../assets/icons/duty_menu_shell_and_tube_icon.png" alt="">
                <!-- icon end -->

                <!-- text start -->
                <div class="text">Shell & Tube</div>
                <!-- text end -->
              </label>
              <!-- label end -->
            </div>
            <!-- main end -->

            <div class="setting-block  sub-setting">
              <!-- sub-setting start -->
              <div class="setting" [ngClass]="{'disabled': heSubType1 !=='VE'}">
                <!-- input radio start -->
                <input type="checkbox" name="vertical" id="vertical" value="VE" [checked]="heSubType1 === 'VE'"
                  (change)="onHETypeChange($event)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="vertical">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->

                  <!-- text start -->
                  <div class="text">vertical</div>
                  <!-- text end -->
                </label>
                <!-- label end -->
              </div>
              <!-- sub-setting end -->

              <!-- sub-setting start -->
              <div class="setting" [ngClass]="{'disabled': heSubType1 !=='HO'}">
                <!-- input radio start -->
                <input type="checkbox" name="horizontal" id="horizontal" value="HO" [checked]="heSubType1 === 'HO'"
                  (change)="onHETypeChange($event)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="horizontal">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->

                  <!-- text start -->
                  <div class="text">horizontal</div>
                  <!-- text end -->
                </label>
                <!-- label end -->
              </div>
              <!-- sub-setting end -->

            </div>
          </div>
          <!-- setting-block end -->

          <!-- setting-block start -->
          <div class="setting-block">
            <!-- main start -->
            <div class="setting" [ngClass]="{'disabled': heType !=='PH'}">
              <!-- input radio start -->
              <input type="radio" name="heType" id="plate_hex" value="PH" [(ngModel)]="heType"
                (change)="onHETypeChange($event)">
              <!-- input radio end -->

              <!-- label start -->
              <label for="plate_hex">
                <!-- circle start -->
                <div class="circle"></div>
                <!-- circle end -->

                <!-- icon start -->
                <img src="../../../../assets/icons/duty_menu_plate_icon.png" alt="">
                <!-- icon end -->

                <!-- text start -->
                <div class="text">Plate HEX</div>
                <!-- text end -->
              </label>
              <!-- label end -->
            </div>
            <!-- main end -->

            <!-- setting-block start -->
            <div class="setting-block  sub-setting">
              <!-- sub-setting start -->
              <div class="setting" [ngClass]="{'disabled': heSubType1 !=='plate_frame'}">
                <!-- input radio start -->
                <input type="checkbox" name="plate_frame" id="plate_frame" value="plate_frame"
                  [checked]="heSubType1==='plate_frame'" (change)="onHETypeChange($event)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="plate_frame">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->

                  <!-- text start -->
                  <div class="text">Plate & Frame</div>
                  <!-- text end -->
                </label>
                <!-- label end -->
              </div>
              <!-- sub-setting end -->
              <!-- setting-block start -->
              <div class="setting-block sub-setting" *ngIf="false">
                <!-- sub-setting start -->
                <div class="setting" [ngClass]="{'disabled': heSubType2 !=='industry'}">
                  <!-- input radio start -->
                  <input type="checkbox" name="plate_industry" id="plate_industry" value="industry"
                    [checked]="heSubType2==='industry'" (change)="onHETypeChange($event)">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label for="plate_industry">
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->

                    <!-- text start -->
                    <div class="text">Industry</div>
                    <!-- text end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- sub-setting end -->
                <!-- sub-setting start -->
                <div class="setting" [ngClass]="{'disabled': heSubType2 !=='food'}">
                  <!-- input radio start -->
                  <input type="checkbox" name="plate_food" id="plate_food" value="food" [checked]="heSubType2==='food'"
                    (change)="onHETypeChange($event)">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label for="plate_food">
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->

                    <!-- text start -->
                    <div class="text">Food</div>
                    <!-- text end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- sub-setting end -->
              </div>
              <!-- setting-block end -->

            </div>
            <!-- setting-block end -->

            <!-- setting-block start -->
            <div class="setting-block  sub-setting">
              <!-- sub-setting start -->
              <div class="setting" [ngClass]="{'disabled': heSubType1 !=='brazed'}">
                <!-- input radio start -->
                <input type="checkbox" name="plate_brazed" id="plate_brazed" value="brazed"
                  [checked]="heSubType1==='brazed'" (change)="onHETypeChange($event)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="plate_brazed">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->

                  <!-- text start -->
                  <div class="text">Brazed</div>
                  <!-- text end -->
                </label>
                <!-- label end -->
              </div>
              <!-- sub-setting end -->

              <!-- setting-block start -->
              <div class="setting-block sub-setting" *ngIf="false">
                <!-- sub-setting start -->
                <div class="setting" [ngClass]="{'disabled': heSubType2 !=='copper_brazed'}">
                  <!-- input radio start -->
                  <input type="checkbox" name="plate_copper_brazed" id="plate_copper_brazed" value="copper_brazed"
                    [checked]="heSubType2==='copper_brazed'" (change)="onHETypeChange($event)">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label for="plate_copper_brazed">
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->

                    <!-- text start -->
                    <div class="text">copper brazed</div>
                    <!-- text end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- sub-setting end -->

                <!-- sub-setting start -->
                <div class="setting" [ngClass]="{'disabled': heSubType2 !=='nickel_brazed'}">
                  <!-- input radio start -->
                  <input type="checkbox" name="nickel_brazed" id="plate_nickel_brazed" value="nickel_brazed"
                    [checked]="heSubType2==='nickel_brazed'" (change)="onHETypeChange($event)">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label for="plate_nickel_brazed">
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->

                    <!-- text start -->
                    <div class="text">nickel brazed</div>
                    <!-- text end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- sub-setting end -->

              </div>
              <!-- setting-block end -->

            </div>
            <!-- setting-block end -->
          </div>
          <!-- setting-block end -->

          <!-- setting-block start -->
          <div class="setting-block">
            <!-- main start -->
            <div class="setting" [ngClass]="{'disabled': heType !=='NA'}">
              <!-- input radio start -->
              <input type="radio" name="heType" id="undecided" value="NA" [(ngModel)]="heType"
                (change)="onHETypeChange($event)">
              <!-- input radio end -->

              <!-- label start -->
              <label for="undecided">
                <!-- circle start -->
                <div class="circle"></div>
                <!-- circle end -->

                <!-- icon start -->
                <img src="../../../../assets/icons/duty_menu_undecided_icon.png" alt="">
                <!-- icon end -->

                <!-- text start -->
                <div class="text">Undecided</div>
                <!-- text end -->
              </label>
              <!-- label end -->
            </div>
            <!-- main end -->
          </div>
          <!-- setting-block end -->
        </div>
        <!-- settings-wrapepr end -->

      </div>
      <!-- he-type-settings-container end -->

      <!-- side-settings-container start -->
      <div class="side-settings-container">
        <!-- side-settings-title start -->
        <div class="side-settings-title">
          <!-- text start -->
          <div class="text">
            <span class="warm">WARM/</span>
            <span class="cold">COLD SIDE:</span>
          </div>
          <!-- text end -->

          <!-- arrow-block start -->
          <div class="arrow-block" (click)="appMenuSwitch = !appMenuSwitch">
            <img src="../../../../assets/icons/select_arrow_black.png" alt="" [ngClass]="{'close': !appMenuSwitch}">
          </div>
          <!-- arrow-block end -->

        </div>
        <!-- side-settings-title end -->

        <!-- settings-wrapepr start -->
        <div class="settings-wrapper" *ngIf="appMenuSwitch">
          <!-- setting-block start -->
          <div class="setting-block" [ngClass]="{'disabled': warmAppType !== 'LI' && coldAppType !== 'LI'}">
            <!-- setting start -->
            <div class="setting">

              <!-- input-container start -->
              <div class="input-container warm">
                <!-- input radio start -->
                <input type="radio" name="warmAppType" id="liquid_warm" value="LI" [(ngModel)]="warmAppType"
                  (change)="onApplicationChange($event.target.value)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="liquid_warm">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->
                </label>
                <!-- label end -->
              </div>
              <!-- input-container end -->

              <!-- input-container start -->
              <div class="input-container cold">
                <!-- input radio start -->
                <input type="radio" name="coldAppType" id="liquid_cold" value="LI" [(ngModel)]="coldAppType"
                  (change)="onApplicationChange($event.target.value)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="liquid_cold">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->
                </label>
                <!-- label end -->
              </div>
              <!-- input-container end -->

              <!-- icon start -->
              <img src="../../../../assets/icons/duty_menu_liquid_icon.png" alt="">
              <!-- icon end -->

              <!-- text start -->
              <div class="text">Liquid</div>
              <!-- text end -->

            </div>
            <!-- setting end -->
          </div>
          <!-- setting-block end -->

          <!-- setting-block start -->
          <div class="setting-block" [ngClass]="{'disabled': warmAppType !== 'GA' && coldAppType !== 'GA'}">
            <!-- setting start -->
            <div class="setting">

              <!-- input-container start -->
              <div class="input-container warm">
                <!-- input radio start -->
                <input type="radio" name="warmAppType" id="gas_warm" value="GA" [(ngModel)]="warmAppType"
                  (change)="onApplicationChange($event.target.value)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="gas_warm">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->
                </label>
                <!-- label end -->
              </div>
              <!-- input-container end -->

              <!-- input-container start -->
              <div class="input-container cold">
                <!-- input radio start -->
                <input type="radio" name="coldAppType" id="gas_cold" value="GA" [(ngModel)]="coldAppType"
                  (change)="onApplicationChange($event.target.value)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="gas_cold">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->
                </label>
                <!-- label end -->
              </div>
              <!-- input-container end -->

              <!-- icon start -->
              <img src="../../../../assets/icons/duty_menu_gas_icon.png" alt="">
              <!-- icon end -->

              <!-- text start -->
              <div class="text">Gas</div>
              <!-- text end -->

            </div>
            <!-- setting end -->
          </div>
          <!-- setting-block end -->

          <!-- setting-block start -->
          <div class="setting-block" [ngClass]="{'disabled': coldAppType !== 'EV'}">
            <!-- setting start -->
            <div class="setting">

              <!-- input-container start -->
              <div class="input-container warm invisible">
                <!-- input radio start -->
                <input type="radio">
                <!-- input radio end -->

                <!-- label start -->
                <label>
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->
                </label>
                <!-- label end -->
              </div>
              <!-- input-container end -->

              <!-- input-container start -->
              <div class="input-container cold">
                <!-- input radio start -->
                <input type="radio" name="coldAppType" id="evaporation" value="EV" [(ngModel)]="coldAppType"
                  (change)="onApplicationChange($event.target.value)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="evaporation">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->
                </label>
                <!-- label end -->
              </div>
              <!-- input-container end -->

              <!-- icon start -->
              <img src="../../../../assets/icons/duty_menu_evaporation_icon.png" alt="">
              <!-- icon end -->

              <!-- text start -->
              <div class="text">Evaporation</div>
              <!-- text end -->

            </div>
            <!-- setting end -->

            <!-- setting-block start -->
            <div class="setting-block sub-setting" [ngClass]="{'disabled': coldAppType !== 'EV'}">
              <!-- setting start -->
              <div class="setting">

                <!-- input-container start -->
                <div class="input-container warm invisible">
                  <!-- input radio start -->
                  <input type="radio">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label>
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- input-container end -->

                <!-- input-container start -->
                <div class="input-container">
                  <!-- input radio start -->
                  <input type="radio" name="coldAppSubType" id="process_steam" value="process_steam"
                    [(ngModel)]="coldAppSubType" (click)="onApplicationSubTypeChange('cold','process_steam')">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label for="process_steam">
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- input-container end -->

                <!-- text start -->
                <label for="process_steam" class="text">Process steam</label>
                <!-- text end -->

              </div>
              <!-- setting end -->
            </div>
            <!-- setting-block end -->

            <!-- setting-block start -->
            <div class="setting-block sub-setting" [ngClass]="{'disabled': coldAppType !== 'EV'}">
              <!-- setting start -->
              <div class="setting">

                <!-- input-container start -->
                <div class="input-container warm invisible">
                  <!-- input radio start -->
                  <input type="radio">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label>
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- input-container end -->

                <!-- input-container start -->
                <div class="input-container">
                  <!-- input radio start -->
                  <input type="radio" name="coldAppSubType" id="clean_steam" value="clean_steam"
                    [(ngModel)]="coldAppSubType" (click)="onApplicationSubTypeChange('cold','clean_steam')">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label for="clean_steam">
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- input-container end -->

                <!-- text start -->
                <label for="clean_steam" class="text">Clean steam</label>
                <!-- text end -->

              </div>
              <!-- setting end -->
            </div>
            <!-- setting-block end -->

          </div>
          <!-- setting-block end -->

          <!-- setting-block start -->
          <div class="setting-block" [ngClass]="{'disabled': warmAppType !== 'CO'}">
            <!-- setting start -->
            <div class="setting">

              <!-- input-container start -->
              <div class="input-container warm">
                <!-- input radio start -->
                <input type="radio" name="warmAppType" id="condensation" value="CO" [(ngModel)]="warmAppType"
                  (change)="onApplicationChange($event.target.value)">
                <!-- input radio end -->

                <!-- label start -->
                <label for="condensation">
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->
                </label>
                <!-- label end -->
              </div>
              <!-- input-container end -->

              <!-- input-container start -->
              <div class="input-container cold invisible">
                <!-- input radio start -->
                <input type="radio">
                <!-- input radio end -->

                <!-- label start -->
                <label>
                  <!-- circle start -->
                  <div class="circle"></div>
                  <!-- circle end -->
                </label>
                <!-- label end -->
              </div>
              <!-- input-container end -->

              <!-- icon start -->
              <img src="../../../../assets/icons/duty_menu_condensation_icon.png" alt="">
              <!-- icon end -->

              <!-- text start -->
              <div class="text">Condensation</div>
              <!-- text end -->

            </div>
            <!-- setting end -->

            <!-- setting-block start -->
            <div class="setting-block sub-setting" [ngClass]="{'disabled': warmAppType !== 'CO'}">
              <!-- setting start -->
              <div class="setting">

                <!-- input-container start -->
                <div class="input-container cold invisible">
                  <!-- input radio start -->
                  <input type="radio">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label>
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- input-container end -->

                <!-- input-container start -->
                <div class="input-container">
                  <!-- input radio start -->
                  <input type="radio" name="warmAppSubType" id="steam_controlled" value="steam_controlled"
                    [(ngModel)]="warmAppSubType" (click)="onApplicationSubTypeChange('warm','steam_controlled')">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label for="steam_controlled">
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- input-container end -->

                <!-- text start -->
                <label for="steam_controlled" class="text">Steam controlled</label>
                <!-- text end -->

              </div>
              <!-- setting end -->
            </div>
            <!-- setting-block end -->

            <!-- setting-block start -->
            <div class="setting-block sub-setting" [ngClass]="{'disabled': warmAppType !== 'CO'}">
              <!-- setting start -->
              <div class="setting">

                <!-- input-container start -->
                <div class="input-container cold invisible">
                  <!-- input radio start -->
                  <input type="radio">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label>
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- input-container end -->

                <!-- input-container start -->
                <div class="input-container">
                  <!-- input radio start -->
                  <input type="radio" name="warmAppSubType" id="condensate_controlled" value="condensate_controlled"
                    [(ngModel)]="warmAppSubType" (click)="onApplicationSubTypeChange('warm','condensate_controlled')">
                  <!-- input radio end -->

                  <!-- label start -->
                  <label for="condensate_controlled">
                    <!-- circle start -->
                    <div class="circle"></div>
                    <!-- circle end -->
                  </label>
                  <!-- label end -->
                </div>
                <!-- input-container end -->

                <!-- text start -->
                <label for="condensate_controlled" class="text">Condensate controlled</label>
                <!-- text end -->

              </div>
              <!-- setting end -->
            </div>
            <!-- setting-block end -->

          </div>
          <!-- setting-block end -->
        </div>
        <!-- settings-wrapepr end -->

      </div>
      <!-- side-settings-container end -->

      <!-- clean-settings start -->
      <div class="clean-settings-container">

        <!-- clean-settings-title start -->
        <div class="clean-settings-title">
          <!-- text start -->
          <div class="text">Cleanability:</div>
          <!-- text end -->

          <!-- arrow-block start -->
          <div class="arrow-block" (click)="cleanabilityMenuSwitch = !cleanabilityMenuSwitch">
            <img src="../../../../assets/icons/select_arrow_black.png" alt=""
              [ngClass]="{'close': !cleanabilityMenuSwitch}">
          </div>
          <!-- arrow-block end -->

        </div>
        <!-- clean-settings-title end -->

        <!-- settings-wrapepr start -->
        <div class="settings-wrapper" *ngIf="cleanabilityMenuSwitch">
          <!-- settings-block start -->
          <div class="setting-block">

            <!-- setting start -->
            <div class="setting" [ngClass]="{'disabled': !cleanability.warm.selected}">
              <!-- input radio start -->
              <input type="checkbox" name="cleanabilityWarm" id="cleanabilityWarm"
                [(ngModel)]="cleanability.warm.selected" (change)="onCleanabilityChange($event)">
              <!-- input radio end -->

              <!-- label start -->
              <label for="cleanabilityWarm">
                <!-- circle start -->
                <div class="circle"></div>
                <!-- circle end -->

                <!-- text start -->
                <div class="text">Easy to clean on warm side</div>
                <!-- text end -->
              </label>
              <!-- label end -->
            </div>
            <!-- setting end -->

            <!-- fouling-block start -->
            <div class="fouling-block">

              <!-- text start -->
              <div class="text">Fouling:</div>
              <!-- text end -->

              <!-- input-field-block start -->
              <div class="input-block">

                <!-- input start -->
                <input type="text" name="foulingValueWarm" id="foulingValueWarm"
                  [(ngModel)]="cleanability.warm.foulingValue.user"
                  (change)="onFoulingValueChange('warm',$event.target.value)">
                <!-- input end -->

                <!-- arrow-block start -->
                <div class="arrow-block" (click)="warmFoulingListSwitcher = !warmFoulingListSwitcher;">
                  <img src="../../../../assets/icons/select_arrow_blue.png" alt="">
                </div>
                <!-- arrow-block end -->

                <!-- dropdown-list start -->
                <div class="dropdown-list" *ngIf="warmFoulingListSwitcher">
                  <!-- item start -->
                  <div class="item" *ngFor="let item of foulingOptions.warm"
                    (click)="onFoulingValueSelect('warm',item)">
                    {{item.name}}: {{item.value}}</div>
                  <!-- item end -->
                </div>
                <!-- dropdown-list end -->

              </div>
              <!-- input-field-block end -->

              <!-- units-block start -->
              <div class="units-block" *ngIf="unitSystems">
                <!-- parameter-unit-select start -->
                <select name="foulingWarmUnit" id="foulingWarmUnit" class="parameter-unit-select"
                  [ngModel]="cleanability.warm.foulingUnitSystemId"
                  (ngModelChange)="onFoulingUnitChange('warm',$event)">
                  <option *ngFor="let unit of unitSystems.fouling" [ngValue]="unit.unitSystemId">{{unit.unitSign}}
                  </option>
                </select>
                <!-- parameter-unit-select end -->
              </div>
              <!-- units-block end -->

            </div>
            <!-- fouling-block end -->

          </div>
          <!-- settings-block end -->

          <!-- settings-block start -->
          <div class="setting-block">

            <!-- setting start -->
            <div class="setting" [ngClass]="{'disabled': !cleanability.cold.selected}">
              <!-- input radio start -->
              <input type="checkbox" name="cleanabilityCold" id="cleanabilityCold"
                [(ngModel)]="cleanability.cold.selected" (change)="onCleanabilityChange($event)">
              <!-- input radio end -->

              <!-- label start -->
              <label for="cleanabilityCold">
                <!-- circle start -->
                <div class="circle"></div>
                <!-- circle end -->

                <!-- text start -->
                <div class="text">Easy to clean on cold side</div>
                <!-- text end -->
              </label>
              <!-- label end -->
            </div>
            <!-- setting end -->

            <!-- fouling-block start -->
            <div class="fouling-block">

              <!-- text start -->
              <div class="text">Fouling:</div>
              <!-- text end -->

              <!-- input-field-block start -->
              <div class="input-block">

                <!-- input start -->
                <input type="text" name="foulingValueCold" id="foulingValueCold"
                  [(ngModel)]="cleanability.cold.foulingValue.user"
                  (change)="onFoulingValueChange('cold',$event.target.value)">
                <!-- input end -->

                <!-- arrow-block start -->
                <div class="arrow-block" (click)="coldFoulingListSwitcher =!coldFoulingListSwitcher;">
                  <img src="../../../../assets/icons/select_arrow_blue.png" alt="">
                </div>
                <!-- arrow-block end -->

                <!-- dropdown-list start -->
                <div class="dropdown-list" *ngIf="coldFoulingListSwitcher">
                  <!-- item start -->
                  <div class="item" *ngFor="let item of foulingOptions.cold"
                    (click)="onFoulingValueSelect('cold',item)">
                    {{item.name}}: {{item.value}}</div>
                  <!-- item end -->
                </div>
                <!-- dropdown-list end -->

              </div>
              <!-- input-field-block end -->

              <!-- units-block start -->
              <div class="units-block" *ngIf="unitSystems">
                <!-- parameter-unit-select start -->
                <select name="foulingColdUnit" id="foulingColdUnit" class="parameter-unit-select"
                  [ngModel]="cleanability.cold.foulingUnitSystemId"
                  (ngModelChange)="onFoulingUnitChange('cold',$event)">
                  <option *ngFor="let unit of unitSystems.fouling" [ngValue]="unit.unitSystemId">{{unit.unitSign}}
                  </option>
                </select>
                <!-- parameter-unit-select end -->
              </div>
              <!-- units-block end -->

            </div>
            <!-- fouling-block end -->

          </div>
          <!-- settings-block end -->
        </div>
        <!-- settings-wrapepr end -->

      </div>
      <!-- clean-settings end -->

      <!-- design-parameters-container start -->
      <div class="design-parameters-container">

        <!-- design-settings-title start -->
        <div class="design-parameters-title">
          <!-- text start -->
          <div class="text">Design Parameters</div>
          <!-- text end -->

          <!-- arrow-block start -->
          <div class="arrow-block" (click)="designParametersMenuSwitch = !designParametersMenuSwitch">
            <img src="../../../../assets/icons/select_arrow_black.png" alt=""
              [ngClass]="{'close': !designParametersMenuSwitch}">
          </div>
          <!-- arrow-block end -->

        </div>
        <!-- design-settings-title end -->


        <!-- settings-wrapepr start -->
        <div class="settings-wrapper" *ngIf="designParametersMenuSwitch">
          <div class="text">
            <span class="warm">WARM/</span>
            <span class="cold">COLD</span>
          </div>
          <!-- setting-block start -->
          <div class="setting-block">
            <div class="parameter-block">
              <!-- parameter-label start -->
              <label for="warmPressure" class="parameter-label">Max. pressure:</label>
              <!-- parameter-label end -->
              <div class="side warm">
                <!-- parameter-input start -->
                <input type="number" id="warmPressure" class="parameter-input"
                  [ngModel]="designParameters.warm.pressure.userUnits"
                  (ngModelChange)="onDesignParameterChange('warm','pressure',$event)">
                <!-- parameter-input end -->
              </div>
              <div class="side cold">
                <!-- parameter-input start -->
                <input type="number" id="coldPressure" class="parameter-input"
                  [ngModel]="designParameters.cold.pressure.userUnits"
                  (ngModelChange)="onDesignParameterChange('cold','pressure',$event)">
                <!-- parameter-input end -->
              </div>
              <!-- parameter-unit-select start -->
              <div class="units-block" *ngIf="unitSystems">
                <select name="coldPressureUnit" id="coldPressureUnit" class="parameter-unit-select"
                  [ngModel]="designParameters.cold.pressure.unitSystemId"
                  (ngModelChange)="onDesignParameterUnitChange('pressure',$event)">
                  <option *ngFor="let unit of unitSystems.pressure" [ngValue]="unit.unitSystemId">{{unit.unitSign}}
                  </option>
                </select>
              </div>
              <!-- parameter-unit-select end -->
            </div>

            <div class="parameter-block">
              <!-- parameter-label start -->
              <label for="warmTemperature" class="parameter-label">Max. temp:</label>
              <!-- parameter-label end -->
              <div class="side warm">
                <!-- parameter-input start -->
                <input type="number" id="warmTemperature" class="parameter-input"
                  [ngModel]="designParameters.warm.temperature.userUnits"
                  (ngModelChange)="onDesignParameterChange('warm','temperature',$event)">
                <!-- parameter-input end -->
              </div>
              <div class="side cold">
                <!-- parameter-input start -->
                <input type="number" id="coldTemperature" class="parameter-input"
                  [ngModel]="designParameters.cold.temperature.userUnits"
                  (ngModelChange)="onDesignParameterChange('cold','temperature',$event)">
                <!-- parameter-input end -->
              </div>

              <!-- parameter-unit-select start -->
              <div class="units-block" *ngIf="unitSystems">
                <select name="warmTemperatureUnit" id="warmTemperatureUnit" class="parameter-unit-select"
                  [ngModel]="designParameters.warm.temperature.unitSystemId"
                  (ngModelChange)="onDesignParameterUnitChange('temperature',$event)">
                  <option *ngFor="let unit of unitSystems.temperature" [ngValue]="unit.unitSystemId">{{unit.unitSign}}
                  </option>
                </select>
              </div>
              <!-- parameter-unit-select end -->
            </div>

          </div>
          <!-- setting-block end -->
        </div>
        <!-- settings-wrapepr end -->

      </div>
      <!-- design-parameters-container end -->

      <!-- design-code-container start -->
      <div class="design-code-container">

        <!-- design-settings-title start -->
        <div class="design-settings-title">
          <!-- text start -->
          <div class="text">Design Code:</div>
          <!-- text end -->

          <!-- arrow-block start -->
          <div class="arrow-block" (click)="designCodeMenuSwitch = !designCodeMenuSwitch">
            <img src="../../../../assets/icons/select_arrow_black.png" alt=""
              [ngClass]="{'close': !designCodeMenuSwitch}">
          </div>
          <!-- arrow-block end -->

        </div>
        <!-- design-settings-title end -->

        <!-- settings-wrapepr start -->
        <div class="settings-wrapper" *ngIf="designCodeMenuSwitch">
          <!-- setting-block start -->
          <div class="setting-block">
            <!-- setting start -->
            <div class="setting" [ngClass]="{'disabled': designCode !=='AD_2000'}">
              <!-- input radio start -->
              <input type="radio" name="designCode" id="ad2000" value="AD_2000" [ngModel]="designCode"
                (ngModelChange)="onDesignCodeChange($event)">
              <!-- input radio end -->

              <!-- label start -->
              <label for="ad2000">
                <!-- circle start -->
                <div class="circle"></div>
                <!-- circle end -->

                <!-- text start -->
                <div class="text">AD 2000</div>
                <!-- text end -->
              </label>
              <!-- label end -->
            </div>
            <!-- setting end -->
            <!-- setting start -->
            <div class="setting" [ngClass]="{'disabled': designCode !=='EN_13445'}">
              <!-- input radio start -->
              <input type="radio" name="designCode" id="en13445" value="EN_13445" [ngModel]="designCode"
                (ngModelChange)="onDesignCodeChange($event)">
              <!-- input radio end -->

              <!-- label start -->
              <label for="en13445">
                <!-- circle start -->
                <div class="circle"></div>
                <!-- circle end -->

                <!-- text start -->
                <div class="text">EN 13445</div>
                <!-- text end -->
              </label>
              <!-- label end -->
            </div>
            <!-- setting end -->
            <!-- setting start -->
            <div class="setting" [ngClass]="{'disabled': designCode !=='ASME'}">
              <!-- input radio start -->
              <input type="radio" name="designCode" id="asme" value="ASME" [ngModel]="designCode"
                (ngModelChange)="onDesignCodeChange($event)">
              <!-- input radio end -->

              <!-- label start -->
              <label for="asme">
                <!-- circle start -->
                <div class="circle"></div>
                <!-- circle end -->

                <!-- text start -->
                <div class="text">ASME</div>
                <!-- text end -->
              </label>
              <!-- label end -->
            </div>
            <!-- setting end -->
          </div>
          <!-- setting-block end -->
        </div>
        <!-- settings-wrapepr end -->

      </div>
      <!-- design-code-container end -->

    </div>
    <!-- menu-settings-container end -->

    <!-- menu-switcher-container start -->
    <div class="menu-switcher" (click)="menuSwitchClose = !menuSwitchClose;">
      <img src="../../../../assets//icons/cogwheel_icon.png" alt="cogwheel_icon.png">
    </div>
    <!-- menu-switcher-container end -->
  </div>
  <!-- menu-wrapper end-->
</div>
<!-- duty-side-menu-comp end -->
