<!-- footer component start -->
<div class="footer-comp">

  <!-- content block start -->
<!--  <div class="content">    -->

<!--    &lt;!&ndash; chat bot start &ndash;&gt;-->
<!--    <div class="chat-bot">-->

<!--      &lt;!&ndash;popup start &ndash;&gt;-->
<!--      <div class="popup">-->
<!--        &lt;!&ndash; title start &ndash;&gt;-->
<!--        <div class="title">Welcome back!</div>-->
<!--        &lt;!&ndash; title end &ndash;&gt;-->
<!--        &lt;!&ndash; message start &ndash;&gt;-->
<!--        <div class="message">Tip: You can find help-->
<!--          under the menu item XY-->
<!--          To the topic...</div>-->
<!--        &lt;!&ndash; message end &ndash;&gt;-->
<!--      </div>-->
<!--      &lt;!&ndash; popup end &ndash;&gt;-->

<!--      &lt;!&ndash; bot image start &ndash;&gt;-->
<!--      <img src="../../../assets/images/zilo_assistant_window.png" alt="zilo_assistant_window.png" class="bot-image">-->
<!--      &lt;!&ndash; bot image end &ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash; chat bot end &ndash;&gt;-->

<!--    &lt;!&ndash; text content start &ndash;&gt;-->
<!--    <div class="text-content">-->
<!--      &lt;!&ndash; question start&ndash;&gt;-->
<!--      <div class="bold-text">You need help?</div>-->
<!--      &lt;!&ndash; question end&ndash;&gt;-->
<!--      &lt;!&ndash; chat nad phone start &ndash;&gt;-->
<!--      <div><span class="chat-text">Chat now</span> or call us +49 2662 500 44 0</div> -->
<!--      &lt;!&ndash; chat nad phone end &ndash;&gt;-->
<!--    </div>-->
<!--    &lt;!&ndash; text content end &ndash;&gt;-->

<!--  </div>-->
  <!-- content block end -->


</div>
<!-- footer component end -->
