<!-- custom-fluid-comp start -->
<div class="custom-fluid-comp" [ngClass]="{'cold-side': appSide==='cold'}">

  <!-- add-fluid-menu-container start -->
  <div class="add-fluid-menu-container">
    <!-- menu-button start -->
    <div class="menu-button" (click)="dropdownMenu = !dropdownMenu;">
      <svg xmlns="http://www.w3.org/2000/svg" fill="#003854" viewBox="0 -960 960 960">
        <path
          d="M240-400q-33 0-56-23t-24-57q0-33 24-56t56-24q33 0 57 24t23 56q0 33-23 57t-57 23Zm240 0q-33 0-56-23t-24-57q0-33 24-56t56-24q33 0 57 24t23 56q0 33-23 57t-57 23Zm240 0q-33 0-56-23t-24-57q0-33 24-56t56-24q33 0 57 24t23 56q0 33-23 57t-57 23Z" />
      </svg>
    </div>
    <!-- menu-button end -->

    <!-- dropdown-menu start -->
    <div class="dropdown-menu" *ngIf="dropdownMenu">
      <!-- item start -->
      <div class="item" (click)="dropdownMenu = false; modalWindow=true; vmCreateNewFluid($event)">Add custom fluid
      </div>
      <!-- item end -->
      <!-- item start -->
      <div class="item" (click)="dropdownMenu = false; vmCreateNewFluidFromCurrent($event)">Create fluid from
        current</div>
      <!-- item end -->
    </div>
    <!-- dropdown-menu end -->

  </div>
  <!-- add-fluid-menu-container end -->


  <!-- custom-fluid-modal-container start -->
  <div class="custom-fluid-modal-container" *ngIf="modalWindow">
    <!-- custom-fluid-modal-window start -->
    <div class="custom-fluid-modal-window">
      <!-- custom-fluid-modal-title start -->
      <div class="custom-fluid-modal-title">{{'dutyPage.customFluid'|translate}}</div>
      <!-- custom-fluid-modal-title end -->

      <!-- custom-fluid-modal-parameters-container start -->
      <div class="custom-fluid-modal-parameters-container">
        <!-- fluid name start -->
        <div class="fluid-name-block" [ngClass]="{'invalid': customFluid.invalid}">
          <!-- fluid-name-label start -->
          <label for="fluid-name-input" class="fluid-name-label">{{'dutyPage.name'|translate}}</label>
          <!-- fluid-name-label end -->

          <!-- fluid-name-input start -->
          <input type="text" class="fluid-name-input" id="fluid-name-input" [ngModel]="customFluid.name"
            (ngModelChange)="onCustomFluidParameterChange(customFluid,null,$event)">
          <!-- fluid-name-input end -->

        </div>
        <!-- fluid name end -->

        <!-- fluid-parameters-list start -->
        <div class="fluid-parameters-list">

          <!-- row-title start -->
          <div class="row-title">

            <!-- title start -->
            <div class="title">{{'dutyPage.inlet'|translate}}</div>
            <!-- title end -->

            <!-- title start -->
            <div class="title">{{'dutyPage.outlet'|translate}}</div>
            <!-- title end -->

          </div>
          <!-- row-title end -->

          <!-- row start -->
          <div class="row">
            <!-- label start -->
            <label for="temperature-inlet" class="parameter-label">{{'dutyPage.temperature'|translate}}</label>
            <!-- label end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.temperature.inlet.invalid}">

              <!-- input start -->
              <input type="number" id="temperature-inlet" name="temperature-inlet"
                [ngModel]="customFluid.temperature.inlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.temperature.inlet,'temperature',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="temperature-inlet-units" id="temperature-inlet-units" class="unit-select"
                [ngModel]="customFluid.temperature.inlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.temperature.inlet,'temperature',$event)">
                <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.temperature.outlet.invalid}">

              <!-- input start -->
              <input type="number" id="temperature-outlet" name="temperature-outlet"
                [ngModel]="customFluid.temperature.outlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.temperature.outlet,'temperature',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="temperature-outlet-units" id="temperature-outlet-units" class="unit-select"
                [ngModel]="customFluid.temperature.outlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.temperature.outlet,'temperature',$event)">
                <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

          </div>
          <!-- row end -->

          <!-- row start -->
          <div class="row row-signle-param" *ngIf="!!customFluid.pressure">
            <!-- label start -->
            <label for="pressure-inlet" class="parameter-label">{{'dutyPage.pressure'|translate}}</label>
            <!-- label end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.pressure.inlet.invalid}">

              <!-- input start -->
              <input type="number" id="pressure-inlet" name="pressure-inlet"
                [ngModel]="customFluid.pressure.inlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.pressure.inlet,'pressure',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="pressure-inlet-units" id="pressure-inlet-units" class="unit-select"
                [ngModel]="customFluid.pressure.inlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.pressure.inlet,'pressure',$event)">
                <option *ngFor="let unit of unitSystems.units.pressure" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

          </div>
          <!-- row end -->

          <!-- row start -->
          <div class="row">
            <!-- label start -->
            <label for="density-inlet" class="parameter-label">{{'dutyPage.density'|translate}}</label>
            <!-- label end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.density.inlet.invalid}">

              <!-- input start -->
              <input type="number" id="density-inlet" name="density-inlet" [ngModel]="customFluid.density.inlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.density.inlet,'density',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="density-inlet-units" id="density-inlet-units" class="unit-select"
                [ngModel]="customFluid.density.inlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.density.inlet,'density',$event)">
                <option *ngFor="let unit of unitSystems.units.density" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.density.outlet.invalid}">

              <!-- input start -->
              <input type="number" id="density-outlet" name="density-outlet"
                [ngModel]="customFluid.density.outlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.density.outlet,'density',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="density-outlet-units" id="density-outlet-units" class="unit-select"
                [ngModel]="customFluid.density.outlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.density.outlet,'density',$event)">
                <option *ngFor="let unit of unitSystems.units.density" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

          </div>
          <!-- row end -->

          <!-- row start -->
          <div class="row">
            <!-- label start -->
            <label for="viscosity-inlet" class="parameter-label">{{'dutyPage.viscosity'|translate}}</label>
            <!-- label end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.viscosity.inlet.invalid}">

              <!-- input start -->
              <input type="number" id="viscosity-inlet" name="viscosity-inlet"
                [ngModel]="customFluid.viscosity.inlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.viscosity.inlet,'viscosity',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="viscosity-inlet-units" id="viscosity-inlet-units" class="unit-select"
                [ngModel]="customFluid.viscosity.inlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.viscosity.inlet,'viscosity',$event)">
                <option *ngFor="let unit of unitSystems.units.viscosity" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.viscosity.outlet.invalid}">

              <!-- input start -->
              <input type="number" id="viscosity-outlet" name="viscosity-outlet"
                [ngModel]="customFluid.viscosity.outlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.viscosity.outlet,'viscosity',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="viscosity-outlet-units" id="viscosity-outlet-units" class="unit-select"
                [ngModel]="customFluid.viscosity.outlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.viscosity.outlet,'viscosity',$event)">
                <option *ngFor="let unit of unitSystems.units.viscosity" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

          </div>
          <!-- row end -->

          <!-- row start -->
          <div class="row">
            <!-- label start -->
            <label for="thermal-conductivity-inlet"
              class="parameter-label">{{'dutyPage.thermalConductivity'|translate}}</label>
            <!-- label end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.thermal_conductivity.inlet.invalid}">

              <!-- input start -->
              <input type="number" id="thermal-conductivity-inlet" name="thermal-conductivity-inlet"
                [ngModel]="customFluid.thermal_conductivity.inlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.thermal_conductivity.inlet,'thermal_conductivity',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="thermal-conductivity-inlet-units" id="thermal-conductivity-inlet-units" class="unit-select"
                [ngModel]="customFluid.thermal_conductivity.inlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.thermal_conductivity.inlet,'thermal_conductivity',$event)">
                <option *ngFor="let unit of unitSystems.units.thermal_conductivity" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.thermal_conductivity.outlet.invalid}">

              <!-- input start -->
              <input type="number" id="thermal-conductivity-outlet" name="thermal-conductivity-outlet"
                [ngModel]="customFluid.thermal_conductivity.outlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.thermal_conductivity.outlet,'thermal_conductivity',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="thermal-conductivity-outlet-units" id="thermal-conductivity-outlet-units"
                class="unit-select" [ngModel]="customFluid.thermal_conductivity.outlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.thermal_conductivity.outlet,'thermal_conductivity',$event)">
                <option *ngFor="let unit of unitSystems.units.thermal_conductivity" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

          </div>
          <!-- row end -->

          <!-- row start -->
          <div class="row">
            <!-- label start -->
            <label for="specific-heat-cap-inlet"
              class="parameter-label">{{'dutyPage.specificHeatCapacity'|translate}}</label>
            <!-- label end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.specific_heat_cap.inlet.invalid}">

              <!-- input start -->
              <input type="number" id="specific-heat-cap-inlet" name="specific-heat-cap-inlet"
                [ngModel]="customFluid.specific_heat_cap.inlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.specific_heat_cap.inlet,'specific_heat_cap',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="specific-heat-cap-inlet-units" id="specific-heat-cap-inlet-units" class="unit-select"
                [ngModel]="customFluid.specific_heat_cap.inlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.specific_heat_cap.inlet,'specific_heat_cap',$event)">
                <option *ngFor="let unit of unitSystems.units.specific_heat_cap" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.specific_heat_cap.outlet.invalid}">

              <!-- input start -->
              <input type="number" id="specific-heat-cap-outlet" name="specific-heat-cap-outlet"
                [ngModel]="customFluid.specific_heat_cap.outlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.specific_heat_cap.outlet,'specific_heat_cap',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="specific-heat-cap-outlet-units" id="specific-heat-cap-outlet-units" class="unit-select"
                [ngModel]="customFluid.specific_heat_cap.outlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.specific_heat_cap.outlet,'specific_heat_cap',$event)">
                <option *ngFor="let unit of unitSystems.units.specific_heat_cap" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

          </div>
          <!-- row end -->

          <!-- row start -->
          <div class="row">
            <!-- label start -->
            <label for="fluid_group" class="parameter-label">{{'dutyPage.fluidGroup'|translate}}</label>
            <!-- label end -->

            <!-- parameter start -->
            <div class="parameter-select" [ngClass]="{'invalid': customFluid.fluid_group.invalid}">

              <!-- select start -->
              <select name="fluid_group" id="fluid_group" [ngModel]="customFluid.fluid_group.value"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.fluid_group,'fluid_group',$event)">
                <option [ngValue]="1">{{'dutyPage.group1'|translate}}</option>
                <option [ngValue]="2">{{'dutyPage.group2'|translate}}</option>
              </select>
              <!-- select end -->

            </div>
            <!-- parameter end -->

          </div>
          <!-- row end -->

        </div>
        <!-- fluid-parameters-list end -->

      </div>
      <!-- custom-fluid-modal-parameters-container end -->

      <!-- buttons-container start -->
      <div class="buttons-container">

        <!-- button start -->
        <button class="button" (click)="vmCloseModalWindow()">{{'commonLabels.cancelButton'|translate}}</button>
        <!-- button end -->
        <!-- button start -->
        <button class="button" (click)="saveCustomFluid()">OK</button>
        <!-- button end -->

      </div>
      <!-- buttons-container end -->

    </div>
    <!-- custom-fluid-modal-window end -->
  </div>
  <!-- custom-fluid-modal-container end -->

  <!-- custom-fluid-modal-container start -->
  <div class="custom-fluid-modal-container" *ngIf="modalWindowAddTemperature">
    <!-- custom-fluid-modal-window start -->
    <div class="custom-fluid-modal-window modal-temperature">
      <!-- custom-fluid-modal-title start -->
      <div class="custom-fluid-modal-title">{{'dutyPage.customFluidAddTemperature'|translate}}</div>
      <!-- custom-fluid-modal-title end -->

      <!-- custom-fluid-modal-parameters-container start -->
      <div class="custom-fluid-modal-parameters-container">

        <!-- fluid-parameters-list start -->
        <div class="fluid-parameters-list">

          <!-- row start -->
          <div class="row">
            <!-- label start -->
            <label for="temperature-inlet" class="parameter-label">{{'dutyPage.temperature'|translate}}</label>
            <!-- label end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.temperature.inlet.invalid}">

              <!-- input start -->
              <input type="number" id="temperature-inlet" name="temperature-inlet"
                [ngModel]="customFluid.temperature.inlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.temperature.inlet,'temperature',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="temperature-inlet-units" id="temperature-inlet-units" class="unit-select"
                [ngModel]="customFluid.temperature.inlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.temperature.inlet,'temperature',$event)">
                <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.temperature.outlet.invalid}">

              <!-- input start -->
              <input type="number" id="temperature-outlet" name="temperature-outlet"
                [ngModel]="customFluid.temperature.outlet.entered"
                (ngModelChange)="onCustomFluidParameterChange(customFluid.temperature.outlet,'temperature',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="temperature-outlet-units" id="temperature-outlet-units" class="unit-select"
                [ngModel]="customFluid.temperature.outlet.physicalUnitId"
                (ngModelChange)="onCustomFluidUnitChange(customFluid.temperature.outlet,'temperature',$event)">
                <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->

          </div>
          <!-- row end -->
          <!-- row start -->
          <div class="row" *ngIf="appType==='GA'">
            <!-- label start -->
            <label for="pressure-inlet" class="parameter-label">{{'dutyPage.inletPressure'|translate}}</label>
            <!-- label end -->

            <!-- parameter start -->
            <div class="parameter" [ngClass]="{'invalid': customFluid.pressure.inlet.invalid}">

              <!-- input start -->
              <input type="number" id="pressure-inlet" name="pressure-inlet"
                     [ngModel]="customFluid.pressure.inlet.entered"
                     (ngModelChange)="onCustomFluidParameterChange(customFluid.pressure.inlet,'pressure',$event)">
              <!-- input end -->

              <!-- unit-select start -->
              <select name="pressure-inlet-units" id="temperature-inlet-units" class="unit-select"
                      [ngModel]="customFluid.pressure.inlet.physicalUnitId"
                      (ngModelChange)="onCustomFluidUnitChange(customFluid.pressure.inlet,'pressure',$event)">
                <option *ngFor="let unit of unitSystems.units.pressure" [ngValue]="unit.physicalUnitId">
                  {{unit.unitSign}}</option>
              </select>
              <!-- unit-select end -->

            </div>
            <!-- parameter end -->
            <div class="parameter" style="width: 235px">
            </div>
          </div>
          <!-- row end -->

        </div>
        <!-- fluid-parameters-list end -->

      </div>
      <!-- custom-fluid-modal-parameters-container end -->

      <!-- buttons-container start -->
      <div class="buttons-container">

        <!-- button start -->
        <button class="button" (click)="vmCloseModalWindow()">{{'commonLabels.cancelButton'|translate}}</button>
        <!-- button end -->
        <!-- button start -->
        <button class="button" (click)="vmGetFluidProperties()">OK</button>
        <!-- button end -->

      </div>
      <!-- buttons-container end -->

    </div>
    <!-- custom-fluid-modal-window end -->
  </div>
  <!-- custom-fluid-modal-container end -->

</div>
<!-- custom-fluid-comp end -->
