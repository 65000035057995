import { Injectable } from '@angular/core';
import { MatSnackBar, MatSnackBarDismiss } from '@angular/material/snack-bar';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class SnackBarService {

  constructor(private snackbar: MatSnackBar) {
    // empty
  }

  /**
   * Method to open snackbar.
   * Returns observable after dismised.
   * @param message Message to show. Default message `'Something goes wrong. Try again.'`.
   * @param duration Duration of message. Value must be in seconds. Default 3 seconds.
   * @param panelClass Class of snackbar. Default 'snackbar-custom'.
   * @returns Observable<MatSnackBarDismiss>
   */
  public open(message?: string, duration: number = 3, panelClass: string = 'snackbar-custom'): Observable<MatSnackBarDismiss> {
    const msg = message || "Something goes wrong. Try again.";

    return this.snackbar.open(msg, 'Close', { panelClass: panelClass, duration: duration * 1000 }).afterDismissed();
  }

  public error(message?: any, duration: number = 20, panelClass: string = 'snackbar-error'): Observable<MatSnackBarDismiss> {
    let msg = "Something goes wrong. Try again.";
    if (message){
      if(typeof message === 'string'){
        msg = message;
      }else if(typeof message.error === 'string'){
        msg = message.error;
      }else if(typeof message.message === 'string'){
        msg = message.message;
      }
    }

    return this.snackbar.open(msg, 'Close', { panelClass: panelClass, duration: duration * 1000 }).afterDismissed();
  }
}
