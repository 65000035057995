import { Component, OnInit, OnChanges, SimpleChanges, Input, Output, EventEmitter, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { HttpErrorResponse } from '@angular/common/http';
import { forkJoin } from 'rxjs';

import { NestedTreeControl } from '@angular/cdk/tree';
import { MatTreeNestedDataSource } from '@angular/material/tree';

import { IFluid, IFluidClass, ITranslation } from 'src/app/services/interfaces';
import { IEvaporation, TFluidSpecialProps, TParameter, TPhysicalUnits, TPhysicalUnitsArray } from '../models';

@Component({
  selector: 'app-evaporation',
  templateUrl: './evaporation.component.html',
  styleUrls: ['./evaporation.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EvaporationComponent implements OnInit, OnChanges {
  private physicalPropsList: string[] = ['mass_flow_rate', 'sat_pressure', 'temperature'];
  private fluidSpecialProps: TFluidSpecialProps = null;

  public unitSystems: TPhysicalUnitsArray = null;
  /**
  * Default unit systems for each `physical property`
  */
  public defaultUnitSystems: { [key: string]: number } = {};

  public physicalUnits: TPhysicalUnits = null;

  public fluid: IFluid & { name: string; selectable: boolean; } = {
    name: null,
    selectable: null,
    fluidClassId: null,
    fluidDescId: null,
    fluidId: null,
    fluidNameId: null,
    isActive: null,
    isCustom: null
  };

  public parameters: IEvaporation = {
    liquid: {
      name: null,
      selectable: null,
      fluidClassId: null,
      fluidDescId: null,
      fluidId: null,
      fluidNameId: null,
      isActive: null,
      isCustom: null
    },
    massFlowRate: {
      value: {
        userUnits: null,
        commonUnits: null
      },
      entered: false,
      calculated: false,
      unitSystemId: null
    },
    satTemp: {
      value: {
        userUnits: null,
        commonUnits: null
      },
      entered: false,
      calculated: false,
      unitSystemId: null
    },
    inletTemp: {
      value: {
        userUnits: null,
        commonUnits: null
      },
      entered: false,
      calculated: false,
      unitSystemId: null
    },
    satPressure: {
      value: {
        userUnits: null,
        commonUnits: null
      },
      entered: false,
      calculated: false,
      unitSystemId: null
    },
    fluidProps: null
  };

  public treeMenu: boolean = false;
  public treeControl = new NestedTreeControl<any>(node => node.children);
  public treeData = new MatTreeNestedDataSource<any>();

  @Input()
  public side: 'warm' | 'cold' = null;
  @Input()
  public appType: string = null;
  @Input()
  public existingParams: any = null;
  @Input()
  public otherSideParams: any = null;
  @Input()
  public heatExchangeRate: TParameter = null;
  @Input()
  public overdesign: number = null;
  @Input()
  public validation: { valid: boolean } = null;
  @Input()
  public extraDuty: boolean = false;

  @Output()
  public parametersEntered: EventEmitter<IEvaporation> = new EventEmitter();
  @Output()
  public parametersCalculated: EventEmitter<IEvaporation> = new EventEmitter();

  constructor(private stateService: StateService, private apiService: ApiService, private snackBarService: SnackBarService, private changeDetection: ChangeDetectorRef) { };

  public ngOnInit(): void {

    const userUnitSystem: string = this.stateService.get('user').unitSystem;

    this.apiService.getAllUnitSystemData(this.physicalPropsList).subscribe({
      next: (_unitSystems) => {
        this.unitSystems = _unitSystems;

        // Create default unit system for each prop
        // If list of systems have system saved in user profile, use it as default
        // if not use 1 unit system.
        this.physicalPropsList.forEach((_propName: string) => {
          this.defaultUnitSystems[_propName] = !!_unitSystems[_propName].find((_x) => _x.unitSystemId === +userUnitSystem) ? +userUnitSystem : 1;
        });

        // Save default unit system for each parameter.
        this.parameters.massFlowRate.unitSystemId = this.defaultUnitSystems.mass_flow_rate;
        this.parameters.satTemp.unitSystemId = this.defaultUnitSystems.temperature;
        this.parameters.inletTemp.unitSystemId = this.defaultUnitSystems.temperature;
        this.parameters.satPressure.unitSystemId = this.defaultUnitSystems.sat_pressure;

        if (this.existingParams && !this.fluid.name) {
          const params: IEvaporation = this.prepareParams(this.existingParams);
          this.parameters = params;

          this.onLiquidSelect(params.liquid);
        };

        this.changeDetection.markForCheck();

      },
      error: (error: HttpErrorResponse) => {
        this.snackBarService.open();
      }
    });

    this.apiService.getUnitSystemData(userUnitSystem, this.physicalPropsList).subscribe({
      next: (props) => {
        this.physicalUnits = props;
        this.changeDetection.markForCheck();

        if (this.existingParams && !this.fluid.name) {
          const params: IEvaporation = this.prepareParams(this.existingParams);
          this.parameters = params;

          this.onLiquidSelect(params.liquid);
        };

      },
      error: (error: HttpErrorResponse) => {
        this.snackBarService.open();
      }
    });
  };
  public ngOnChanges(changes: SimpleChanges) {
    // If fluid(application) type exist and its first chnage
    // get fluid options.
    if (changes.appType?.currentValue && changes.appType?.firstChange) {
      this.getLiquidOptions();
    };

    if (changes?.heatExchangeRate) {
      this.validate();
    };

    if (changes.validation?.currentValue.valid) {
      this.calculateParameters();
    };

  };
  /** Methos for tree menu */
  public hasChild(_: number, node: IFluid & { name: string; selectable: boolean; children: any[] }) {
    return !!node.children && node.children.length > 0
  };
  /** Method to select liquid by click on tree item. And get fluid physical props.*/
  public onLiquidSelect(node: IFluid & { name: string; selectable: boolean; }): void {
    if (node.selectable) {
      this.fluid = node;
      this.treeMenu = false;
      this.parameters.liquid = this.fluid;

      forkJoin({
        specific_heat_cap: this.apiService.getFluidPhysicalProperty(node.fluidId, 'specific_heat_cap'),
        enthalpy_vapor: this.apiService.getFluidPhysicalProperty(node.fluidId, 'enthalpy_vapor'),
        enthalpy_liquid: this.apiService.getFluidPhysicalProperty(node.fluidId, 'enthalpy_liquid'),
        sat_pressure: this.apiService.getFluidPhysicalProperty(node.fluidId, 'sat_pressure'),
      }).subscribe({
        next: (resp) => {
          this.fluidSpecialProps = resp;
          this.parameters.fluidProps = resp;
          this.treeControl.collapseAll();

          if (!!this.parameters.satTemp.value.commonUnits) {
            this.calculateSaturationParameters('satTemp');
          } else if (!!this.parameters.satPressure.value.commonUnits) {
            this.calculateSaturationParameters('satPressure');
          };


          this.emitParametersEnteredEvent();
        },
        error: (error: HttpErrorResponse) => {
          this.snackBarService.open();
        }
      });

    };

  };
  public onParameterChange(paramName: string, value: number) {

    if (!!value || value === 0) {
      this.parameters[paramName].value.userUnits = value;
      this.parameters[paramName].entered = true;
      this.parameters[paramName].calculated = false;
    } else {
      this.parameters[paramName].value.userUnits = null;
      this.parameters[paramName].entered = false;
      this.parameters[paramName].calculated = false;
    };

    // Convert params to common units
    this.parameters.massFlowRate.value.commonUnits = this.convertToSI(this.parameters.massFlowRate.value.userUnits, 'mass_flow_rate', this.parameters.massFlowRate.unitSystemId);
    this.parameters.satPressure.value.commonUnits = this.convertToSI(this.parameters.satPressure.value.userUnits, 'sat_pressure', this.parameters.satPressure.unitSystemId);
    this.parameters.inletTemp.value.commonUnits = this.convertToSI(this.parameters.inletTemp.value.userUnits, 'temperature', this.parameters.inletTemp.unitSystemId);
    this.parameters.satTemp.value.commonUnits = this.convertToSI(this.parameters.satTemp.value.userUnits, 'temperature', this.parameters.satTemp.unitSystemId);

    if ((paramName === 'satPressure' || paramName === 'satTemp') && !!value && !!this.fluidSpecialProps) {
      this.calculateSaturationParameters(paramName);
    };

    this.emitParametersEnteredEvent();
    this.validate();

  };
  public onUnitChange(paramName: string, propName: string, systemId: number): void {
    this.parameters[paramName].unitSystemId = systemId;
    if (this.parameters[paramName].value.commonUnits) {
      this.parameters[paramName].value.userUnits = Number(this.convertToUser(this.parameters[paramName].value.commonUnits, propName, this.parameters[paramName].unitSystemId).toFixed(1));
    };

  };
  private validate() {
    const enteredParams = [this.parameters.massFlowRate.entered, this.parameters.inletTemp.entered, this.parameters.satTemp.entered].filter((x) => !!x).length;


    if (this.extraDuty) {
      if (this.validation.valid) {
        this.parametersCalculated.emit(this.parameters);
      };
    } else {
      if (enteredParams === 2) {
        this.calculateParameters();
      };
    };

  };
  private findTableTempByValue(value: number, propName: string): number {

    const fluidPropData = this.fluidSpecialProps[propName];

    const allTableValues = fluidPropData.map((_x) => _x.propertyValue);
    // Table min
    const tableMinValue = Math.min.apply(Math, allTableValues);
    // Table max
    const tableMaxValue = Math.max.apply(Math, allTableValues);

    const tableRecord = fluidPropData.find((_x) => _x.propertyValue === value);
    let result = null;

    if (!!tableRecord) {
      result = tableRecord.dependency1Value;
    } else if (value >= tableMinValue && value <= tableMaxValue) {
      // Find closest temperatures for entered value
      // Find closest temperatures for entered value
      const closestMinTemp = this.findClosestNumberSmaller(value, allTableValues);
      const closestMaxTemp = this.findClosestNumberBigger(value, allTableValues);

      const closestMinRecord = fluidPropData.find((_x) => _x.propertyValue === closestMinTemp);
      const closestMaxRecord = fluidPropData.find((_x) => _x.propertyValue === closestMaxTemp);

      result = closestMinRecord.dependency1Value + (value - closestMinRecord.propertyValue) * (closestMaxRecord.dependency1Value - closestMinRecord.dependency1Value) / (closestMaxRecord.propertyValue - closestMinRecord.propertyValue);
    } else if (value > tableMaxValue || value < tableMinValue) {
      const closestPressure = this.findClosestNumber(value, allTableValues);
      result = fluidPropData.find((_x) => _x.propertyValue === closestPressure).dependency1Value;
    };
    return result;

  };
  private findTableValueByTemp(temperature: number, propName: string) {
    const satPressureData = this.fluidSpecialProps[propName];

    // List of all temperatures in table
    const allTableTemp = satPressureData.map((_x) => _x.dependency1Value);
    // Table min temperature
    const tableMinTemp = Math.min.apply(Math, allTableTemp);
    // Table max temperature
    const tableMaxTemp = Math.max.apply(Math, allTableTemp);


    let temperatureTableRecord = satPressureData.find((_x) => _x.dependency1Value === temperature);
    let result = null;

    // 1. If record exist, take value.
    // 2. If record was no found in step 1, check if entered value is in RANGE of table temperatures.
    //    If true, find its value with linear interpolation.
    // 3. If value was not found in previous steps, check if entered value is out of range of table temperatures.
    //    If true, find closest temperature and take its value.

    if (!!temperatureTableRecord) {
      result = temperatureTableRecord.propertyValue;
    } else if (temperature >= tableMinTemp && temperature <= tableMaxTemp) {
      // Find closest temperatures for entered value
      const closestMinTemp = this.findClosestNumberSmaller(temperature, allTableTemp);
      const closestMaxTemp = this.findClosestNumberBigger(temperature, allTableTemp);

      const closestMinTempPressure = satPressureData.find((_x) => _x.dependency1Value === closestMinTemp).propertyValue;
      const closestMaxTempPressure = satPressureData.find((_x) => _x.dependency1Value === closestMaxTemp).propertyValue;

      result = closestMinTempPressure + (temperature - closestMinTemp) * (closestMaxTempPressure - closestMinTempPressure) / (closestMaxTemp - closestMinTemp);
    } else if (temperature > tableMaxTemp || temperature < tableMinTemp) {
      const closestTemp = this.findClosestNumber(temperature, allTableTemp);
      result = satPressureData.find((_x) => _x.dependency1Value === closestTemp).propertyValue;
    };

    return result;
  };
  private findClosestNumberSmaller(x: number, arr: number[]): number | null {
    const filteredArr = arr.filter((k) => k < x);
    const indexArr = filteredArr.map((k) => Math.abs(k - x));
    const min = Math.min(...indexArr);
    return filteredArr[indexArr.indexOf(min)];
  }

  private findClosestNumberBigger(x: number, arr: number[]): number | null {
    const filteredArr = arr.filter((k) => k > x);
    const indexArr = filteredArr.map((k) => Math.abs(k - x));
    const min = Math.min(...indexArr);
    return filteredArr[indexArr.indexOf(min)];
  }
  private findClosestNumber(x, arr) {
    const indexArr = arr.map((k) => {
      return Math.abs(k - x)
    })
    const min = Math.min.apply(Math, indexArr)
    return arr[indexArr.indexOf(min)]
  };
  private calculateParameters() {

    const massFlowRate: number = this.parameters.massFlowRate.value.commonUnits;
    const satTemp: number = this.parameters.satTemp.value.commonUnits;
    const inletTemp: number = this.parameters.inletTemp.value.commonUnits;
    const heatExchangeRate: number = this.heatExchangeRate.value.commonUnits;

    if (!this.extraDuty && !this.parameters.massFlowRate.entered && (!!heatExchangeRate || heatExchangeRate === 0) && (!!satTemp || satTemp === 0) && (!!inletTemp || inletTemp === 0)) {
      const enthalpyVapor: number = this.findTableValueByTemp(satTemp, 'enthalpy_vapor');
      const enthalpyLiquid: number = this.findTableValueByTemp(satTemp, 'enthalpy_liquid');


      const inletTempSHC: number = this.findTableValueByTemp(inletTemp, 'specific_heat_cap');
      const satTempSHC: number = this.findTableValueByTemp(satTemp, 'specific_heat_cap');

      let specificHeatCapacityRange: number[] = null;
      let specificHeatCapacity: number = null;

      let temperatures = [satTemp, inletTemp].sort((a, b) => a - b);
      specificHeatCapacityRange = this.fluidSpecialProps['specific_heat_cap'].filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
      specificHeatCapacityRange = [...specificHeatCapacityRange, satTempSHC, inletTempSHC];

      // Calculate avarage value
      specificHeatCapacity = specificHeatCapacityRange.reduce((acc, next) => acc + next, 0) / specificHeatCapacityRange.length;

      const result: number = heatExchangeRate / (specificHeatCapacity * (satTemp - inletTemp) + (enthalpyVapor - enthalpyLiquid));

      this.parameters.massFlowRate.value.commonUnits = result;
      this.parameters.massFlowRate.value.userUnits = this.convertToUser(result, 'mass_flow_rate', this.parameters.massFlowRate.unitSystemId);
      this.parameters.massFlowRate.value.userUnits = Number(this.parameters.massFlowRate.value.userUnits.toFixed(1));

      this.parameters.massFlowRate.entered = false;
      this.parameters.massFlowRate.calculated = true;

    };

    if (!this.extraDuty && !this.parameters.inletTemp.entered && (!!heatExchangeRate || heatExchangeRate === 0) && (!!massFlowRate || massFlowRate === 0) && (!!satTemp || satTemp === 0)) {

      const enthalpyVapor: number = this.findTableValueByTemp(satTemp, 'enthalpy_vapor');
      const enthalpyLiquid: number = this.findTableValueByTemp(satTemp, 'enthalpy_liquid');
      const satTempSHC: number = this.findTableValueByTemp(satTemp, 'specific_heat_cap');

      // Calculate inlet temperature for first time.
      let _inletTemp: number = satTemp - Math.abs(heatExchangeRate - massFlowRate * (enthalpyVapor - enthalpyLiquid)) / (massFlowRate * satTempSHC);
      const inletTempSHC: number = this.findTableValueByTemp(_inletTemp, 'specific_heat_cap');

      let specificHeatCapacityRange: number[] = null;
      let specificHeatCapacity: number = null;

      let temperatures = [satTemp, _inletTemp].sort((a, b) => a - b);
      specificHeatCapacityRange = this.fluidSpecialProps['specific_heat_cap'].filter((_item) => _item.dependency1Value > temperatures[0] && _item.dependency1Value < temperatures[1]).map((_item) => _item.propertyValue);
      specificHeatCapacityRange = [...specificHeatCapacityRange, satTempSHC, inletTempSHC];

      // Calculate avarage value
      specificHeatCapacity = specificHeatCapacityRange.reduce((acc, next) => acc + next, 0) / specificHeatCapacityRange.length;

      _inletTemp = satTemp - Math.abs(heatExchangeRate - massFlowRate * (enthalpyVapor - enthalpyLiquid)) / (massFlowRate * specificHeatCapacity);

      this.parameters.inletTemp.value.commonUnits = _inletTemp;
      this.parameters.inletTemp.value.userUnits = this.convertToUser(_inletTemp, 'temperature', this.parameters.inletTemp.unitSystemId);
      this.parameters.inletTemp.value.userUnits = Number(this.parameters.inletTemp.value.userUnits.toFixed(1));

      this.parameters.inletTemp.entered = false;
      this.parameters.inletTemp.calculated = true;
    }

    if (this.validation.valid) {
      this.parametersCalculated.emit(this.parameters);
    };
  };
  /**
   * Method to calculate `Saturation Temperature or Pressure` when one of parameters exist.
   * @param paramName Name of parameter that was changed.
   */
  private calculateSaturationParameters(paramName: string): void {
    // 1. If Saturation Temperature changed, we can calculate Saturation Pressure.
    // 2. If Saturation Pressure changed, we can calculate Saturation Temperature.
    if (paramName === 'satTemp') {
      const result: number = this.findTableValueByTemp(this.parameters.satTemp.value.commonUnits, 'sat_pressure');

      this.parameters.satPressure.value.commonUnits = result;
      this.parameters.satPressure.value.userUnits = this.convertToUser(result, 'sat_pressure', this.parameters.satPressure.unitSystemId);
      this.parameters.satPressure.value.userUnits = Number(this.parameters.satPressure.value.userUnits.toFixed(1));
      this.parameters.satPressure.entered = true;
      this.parameters.satPressure.calculated = false;
    };
    if (paramName === 'satPressure') {
      const result: number = this.findTableTempByValue(this.parameters.satPressure.value.commonUnits, 'sat_pressure');

      this.parameters.satTemp.value.commonUnits = result;
      this.parameters.satTemp.value.userUnits = this.convertToUser(result, 'temperature', this.parameters.satTemp.unitSystemId);
      this.parameters.satTemp.value.userUnits = Number(this.parameters.satTemp.value.userUnits.toFixed(1));
      this.parameters.satTemp.entered = true;
      this.parameters.satTemp.calculated = false;
    };

  };
  private getLiquidOptions() {
    // Get fluids, by parameters from step 2
    forkJoin({
      fluidClasses: this.apiService.getFluidClasses(),
      fluids: this.apiService.getFluids(this.appType),
    }).subscribe({
      next: ({ fluidClasses, fluids }) => {
        const fluidClassesIds = [];
        const fluidsIds = [];

        // Get IDs for each item, to get translations.
        fluidClasses.forEach((_x) => {
          fluidClassesIds.push(_x.classNameId);
        });

        fluids.forEach((_x) => {
          fluidsIds.push(_x.fluidNameId);
          fluidsIds.push(_x.fluidDescId);
        });

        // Get translations
        forkJoin({
          fluidClassesCurrentLangTranslation: this.apiService.getTranslation(fluidClassesIds, this.stateService.get('lang').toUpperCase()),
          fluidsCurrentLangTranslation: this.apiService.getTranslation(fluidsIds, this.stateService.get('lang').toUpperCase()),
          fluidClassesDefaultLangTranslation: this.apiService.getTranslation(fluidClassesIds, 'EN'),
          fluidsDefaultLangTranslation: this.apiService.getTranslation(fluidsIds, 'EN'),
        }).subscribe({
          next: (translatioNResponse) => {

            const translations = {
              current: [...translatioNResponse.fluidClassesCurrentLangTranslation, ...translatioNResponse.fluidsCurrentLangTranslation],
              default: [...translatioNResponse.fluidClassesDefaultLangTranslation, ...translatioNResponse.fluidsDefaultLangTranslation],
            };

            this.treeData.data = this.createTree(fluidClasses, fluids, translations);;


          },
          error: (error: HttpErrorResponse) => {
            this.snackBarService.open()
          }
        });


      },
      error: (error: HttpErrorResponse) => {
        this.snackBarService.open();
      }
    })
  };
  /** Method to create tree data for menu */
  private createTree(fluidClasses: IFluidClass[], fluids: IFluid[], translations: { current: ITranslation[]; default: ITranslation[] }) {
    let defaultFluid = null;

    // Find main categories.
    const mainCategories: IFluidClass[] = fluidClasses.filter((_x) => !_x.parentClassId && _x.isActive === 1);

    // All nodes
    const nodes = [];

    // Create node for each category
    mainCategories.forEach((_mainCategory: IFluidClass) => {
      const mainCategoryNode = {
        name: null,
        selectable: false,
        children: null
      };

      // Find translation on users language and in English as default language.
      const userLang = translations.current.find((_translation) => _translation.translationId === _mainCategory.classNameId)?.value;
      const defaultLang = translations.default.find((_translation) => _translation.translationId === _mainCategory.classNameId)?.value;

      mainCategoryNode.name = userLang || defaultLang;

      // Find all children for current category
      mainCategoryNode.children = findChildren(_mainCategory);

      // If no children found, dont save this node.
      if (mainCategoryNode.children && mainCategoryNode.children.length > 0) {
        nodes.push(Object.assign(mainCategoryNode, _mainCategory));
      };


    });

    /** Function to recirsivly find children */
    function findChildren(parentFluidClass: IFluidClass) {

      // 1. Find sub categories for parent category
      // 2. Find fluids for parent category
      const subCategories = fluidClasses.filter((_fluidClass) => _fluidClass.parentClassId === parentFluidClass.fluidClassId && _fluidClass.isActive === 1);
      const parentFluids = fluids.filter((_fluid) => _fluid.fluidClassId === parentFluidClass.fluidClassId && _fluid.isActive === 1);

      // If fluids and sub categories not found, return null
      if (parentFluids.length === 0 && subCategories.length === 0) {
        return null;
      };

      const childNodes = [];

      // If sub category found
      // Create node for each category and find its children
      if (subCategories.length > 0) {

        subCategories.forEach((_subCategory) => {

          const _node = {
            name: null,
            selectable: false,
            children: null
          };

          // Find translation on users language and in English as default language.
          const userLang = translations.current.find((_translation) => _translation.translationId === _subCategory.classNameId)?.value;
          const defaultLang = translations.default.find((_translation) => _translation.translationId === _subCategory.classNameId)?.value;

          _node.name = userLang || defaultLang;

          _node.children = findChildren(_subCategory);

          // If node dont have children, dont save it.
          if (_node.children && _node.children.length > 0) {
            childNodes.push(Object.assign(_node, _subCategory));
          };

        });

      };

      // If fluids found
      // Create node for each fluid
      if (parentFluids.length > 0) {

        parentFluids.forEach((_parentFluid) => {
          const _node = {
            name: null,
            selectable: true,
          };
          // Find translation on users language and in English as default language.
          const userLang = translations.current.find((_translation) => _translation.translationId === _parentFluid.fluidNameId)?.value;
          const defaultLang = translations.default.find((_translation) => _translation.translationId === _parentFluid.fluidNameId)?.value;

          _node.name = userLang || defaultLang;

          childNodes.push(Object.assign(_node, _parentFluid));
        });

      };

      // Steam is default fluid for Liquid Application
      // find its node and save
      if (childNodes.length > 0) {
        let defaultFluidTmp = childNodes.find((_childNode) => _childNode?.fluidId === 6);
        if (defaultFluidTmp)
          defaultFluid = defaultFluidTmp;
      };

      return childNodes;
    };

    // If no saved application found
    // select default fluid.
    if (!this.existingParams) {
      this.onLiquidSelect(defaultFluid);
    }

    return nodes;
  };
  /**
   * Method to emit event that parameters changed.
   */
  private emitParametersEnteredEvent() {
    this.parametersEntered.emit(this.parameters);
  };
  /**
    * Method to convert value `from users unit system to SI`.
    * @param value
    * @param propName physical property name. See `physicalPropsList property` for correct typing
    * @returns
    */
  public convertToSI(value: number, propName: string, systemId: number): number {
    const prop = this.unitSystems[propName].find((x) => x.unitSystemId === systemId);
    if (value === 0 || !value) {
      return value;
    };

    return prop.addFactor + (value * prop.multFactor)

  };
  /**
   * Method to convert value `from SI unit system to users unit system`.
   * @param value
   * @param propName physical property name. See `physicalPropsList property` for correct typing
   * @returns
   */
  public convertToUser(value: number, propName: string, systemId: number): number {
    const prop = this.unitSystems[propName].find((x) => x.unitSystemId === systemId);
    if (value === 0 || !value) {
      return value;
    };

    return (value - prop.addFactor) / prop.multFactor;

  }
  private prepareParams(params: any) {
    const result: IEvaporation = {
      fluidProps: null,
      liquid: Object.assign({}, params.liquid),
      massFlowRate: {
        value: {
          userUnits: this.convertToUser(params.massFlowRate.value, 'mass_flow_rate', this.parameters.massFlowRate.unitSystemId),
          commonUnits: params.massFlowRate.value
        },
        entered: params.massFlowRate.entered,
        calculated: params.massFlowRate.calculated,
        unitSystemId: this.parameters.massFlowRate.unitSystemId
      },
      satTemp: {
        value: {
          userUnits: this.convertToUser(params.satTemp.value, 'temperature', this.parameters.satTemp.unitSystemId),
          commonUnits: params.satTemp.value
        },
        entered: params.satTemp.entered,
        calculated: params.satTemp.calculated,
        unitSystemId: this.parameters.massFlowRate.unitSystemId
      },
      satPressure: {
        value: {
          userUnits: this.convertToUser(params.satPressure.value, 'sat_pressure', this.parameters.satPressure.unitSystemId),
          commonUnits: params.satPressure.value
        },
        entered: params.satPressure.entered,
        calculated: params.satPressure.calculated,
        unitSystemId: this.parameters.massFlowRate.unitSystemId
      },
      inletTemp: {
        value: {
          userUnits: this.convertToUser(params.inletTemp.value, 'temperature', this.parameters.inletTemp.unitSystemId),
          commonUnits: params.inletTemp.value
        },
        entered: params.inletTemp.entered,
        calculated: params.inletTemp.calculated,
        unitSystemId: this.parameters.massFlowRate.unitSystemId
      }
    };

    result.massFlowRate.value.userUnits = Number(result.massFlowRate.value.userUnits.toFixed(1));
    result.satPressure.value.userUnits = Number(result.satPressure.value.userUnits.toFixed(1));
    result.satTemp.value.userUnits = Number(result.satTemp.value.userUnits.toFixed(1));
    result.inletTemp.value.userUnits = Number(result.inletTemp.value.userUnits.toFixed(1));


    result.liquid.selectable = true;
    return result;
  };
}
