<!-- header start -->
<app-header>
  <app-usermenu></app-usermenu>
</app-header>
<!-- header end -->

<!-- duty component start -->
<div class="duty-comp extra-duty-comp ">
  <!-- main container start -->
  <div class="main-container">

    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">Back</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="7"></app-progressbar>
    <!-- progressbar end -->

    <!-- main title start -->
    <div class="main-title">
      Please enter your process parameters
    </div>
    <!-- main title end -->

    <!-- calculation section start -->
    <div class="calculation-section">

      <!-- main container start -->
      <div class="main-container">
        <!-- content container start -->
        <div class="content-container" *ngIf="unitSystems">

          <div class="side warm-side" [ngSwitch]="requirementApplication?.requirementValue.warm[0]">

            <!-- title start -->
            <div class="title">WARM SIDE</div>
            <!-- title end -->

            <app-liquid *ngSwitchCase="'LI'" [side]="'warm'" [extraDuty]="true"
              [liquidType]="requirementApplication.requirementValue.warm[0]"
              [existingParams]="existingRequirementForWarmSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('warm',$event)"
              (parametersCalculated)="onSideParametersCalculated('warm',$event)">
            </app-liquid>

            <app-gas *ngSwitchCase="'GA'" [side]="'warm'" [extraDuty]="true"
              [liquidType]="requirementApplication.requirementValue.warm[0]"
              [existingParams]="existingRequirementForWarmSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('warm',$event)"
              (parametersCalculated)="onSideParametersCalculated('warm',$event)">
            </app-gas>

            <app-condensation *ngSwitchCase="'CO'" [side]="'warm'" [extraDuty]="true"
              [liquidType]="requirementApplication.requirementValue.warm[0]"
              [existingParams]="existingRequirementForWarmSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" [application]="requirementApplication"
              (parametersEntered)="onSideParametersEntered('warm',$event)"
              (parametersCalculated)="onSideParametersCalculated('warm',$event)">
            </app-condensation>
          </div>

          <!-- middle column start -->
          <div class="middle-column">

            <!-- heat exchange rate start -->
            <div class="result-block" *ngIf="false">

              <!-- label start -->
              <label for="heatExchangeRate" class="result-block-label">Heat exchange rate</label>
              <!-- label end -->

              <!-- parameter input block start -->
              <div class="result-block-input-block" [ngClass]="{'calculated': heatExchangeRate.calculated}">

                <!-- input field start -->
                <input class="result-block-input-field" type="number" name="heatExchangeRate" id="heatExchangeRate"
                  [ngModel]="heatExchangeRate.value.userUnits"
                  (ngModelChange)="onHEorOverdesignEnter('heatExchangeRate',$event)">
                <!-- input field end -->

                <!-- parameter-unit-select start -->
                <select name="unit" id="unit" class="parameter-unit-select" [ngModel]="heatExchangeRate.unitSystemId"
                  (ngModelChange)="onUnitChange($event)">
                  <option *ngFor="let unit of unitSystems.heat_exchange_rate" [ngValue]="unit.unitSystemId">
                    {{unit.unitSign}}</option>
                </select>
                <!-- parameter-unit-select end -->

              </div>
              <!-- parameter input block end -->

            </div>
            <!-- heat exchange rate end -->

            <!-- btn-new start -->
            <button type="button" class="btn-continue" (click)="openDutyNamePopUp()">Calculate</button>
            <!-- btn-new end -->

          </div>
          <!-- middle column end -->

          <!-- cold side start -->
          <div class="side cold-side" [ngSwitch]="requirementApplication?.requirementValue.cold[0]">
            <!-- title start -->
            <div class="title">COLD SIDE</div>
            <!-- title end -->

            <app-liquid *ngSwitchCase="'LI'" [side]="'cold'" [extraDuty]="true"
              [liquidType]="requirementApplication.requirementValue.cold[0]"
              [existingParams]="existingRequirementForColdSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('cold',$event)"
              (parametersCalculated)="onSideParametersCalculated('cold',$event)">
            </app-liquid>
            <app-gas *ngSwitchCase="'GA'" [side]="'cold'" [extraDuty]="true"
              [liquidType]="requirementApplication.requirementValue.cold[0]"
              [existingParams]="existingRequirementForColdSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('cold',$event)"
              (parametersCalculated)="onSideParametersCalculated('cold',$event)">
            </app-gas>
            <app-evaporation *ngSwitchCase="'EV'" [side]="'cold'" [extraDuty]="true"
              [appType]="requirementApplication.requirementValue.cold[0]"
              [existingParams]="existingRequirementForColdSide" [heatExchangeRate]="heatExchangeRate"
              [validation]="enteredParamsValidated" (parametersEntered)="onSideParametersEntered('cold',$event)"
              (parametersCalculated)="onSideParametersCalculated('cold',$event)"></app-evaporation>
          </div>
          <!-- cold side end -->
        </div>
        <!-- content container end -->

        <!-- errors container start -->
        <div class="errors-container" *ngIf="requirementApplication">
          <app-duty-validation [warmAppType]="requirementApplication.requirementValue.warm[0]"
            [coldAppType]="requirementApplication.requirementValue.cold[0]" [parametersEntered]="parametersEntered"
            [parametersCalculated]="parametersCalculated" [heatExchangeRate]="heatExchangeRate"
            [overdesign]="overdesign" [designParameters]="designParameters" [extraDuty]="true"
            (enteredParamsValidated)="onEnteredParamsValidation($event)"
            (calculatedParamsValidated)="onCalculatedParamsValidation($event)"></app-duty-validation>
        </div>
        <!-- errors container end -->

      </div>
      <!-- main container end -->

    </div>
    <!-- calculation section end -->

  </div>
  <!-- main container end -->
</div>
<!-- duty component end -->

<!-- popup-duty-name start -->
<div class="popup-duty-name" *ngIf="dutyNamePopUpFlag">
  <!-- popup-content-container start -->
  <div class="popup-content-container">
    <!-- popup-title start -->
    <div class="popup-title">Duty Name</div>
    <!-- popup-title end -->

    <!-- input-duty-name start -->
    <input type="text" class="input-duty-name" id="input-duty-name" [(ngModel)]="dutyName">
    <!-- input-duty-name end -->

    <!-- confirm-btn start -->
    <button type="button" class="confirm-btn" (click)="saveRequest()">confirm</button>
    <!-- confirm-btn end -->

  </div>
  <!-- popup-content-container end -->
</div>
<!-- popup-duty-name end -->

<!-- popup-duty-warnings start -->
<div class="popup-duty-warnings" *ngIf="dutyWarningsPopUpFlag">
  <!-- popup-content-container start -->
  <div class="popup-content-container">
    <!-- popup-title start -->
    <div class="popup-title">Warnings</div>
    <!-- popup-title end -->

    <!-- warnings-container start -->
    <div class="warnings-container">
      <!-- warning start -->
      <div class="warning" *ngFor="let item of warnings">{{item.message}}</div>
      <!-- warning end -->
    </div>
    <!-- warnings-container end -->

    <!-- buttons-container start -->
    <div class="buttons-container">
      <!-- confirm-btn start -->
      <button type="button" class="btn" (click)="editLoadCase()">Edit load case</button>
      <!-- confirm-btn end -->
      <!-- confirm-btn start -->
      <button type="button" class="btn confirm-btn" (click)="navigateToSolutionFromWarnings()">Continue</button>
      <!-- confirm-btn end -->
    </div>
    <!-- buttons-container end -->

  </div>
  <!-- popup-content-container end -->
</div>
<!-- popup-duty-warnings end -->


<!-- footer start -->
<app-footer></app-footer>
<!-- footer end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="7"></app-zilo-hints>
<!-- zilo-hints end -->