import { Injectable } from '@angular/core';
import { Observable, of, Subscriber, Subject } from 'rxjs';

@Injectable()
export class IdleTimerService {
  private _expirationTime: number = null;
  private _timeoutID: number = null;
  private _subject: Subject<boolean> = null;

  private _eventHandler = this._updateTimeout.bind(this);

  constructor() {
    this._subject = new Subject();
  }

  /**
   * Method to start idle time, `default expiration time is 10 seconds.`
   * @param {number} expirationTime Expiration Time in `seconds`.
   */
  public start(expirationTime: number, track: boolean = true): Subject<boolean> {
    this._expirationTime = expirationTime;

    if (track) {
      this._tracker();
    }
    this._setTimeout();
    return this._subject;
  }

  /**
   * Method to stop timer.
   */
  public stop(): Observable<boolean> {
    clearTimeout(this._timeoutID);
    this._removeTracker();
    return of(true);
  }

  /**
   * Method to update timeout counter.
   */
  private _updateTimeout() {
    if (this._timeoutID) {
      clearTimeout(this._timeoutID);
    }

    this._setTimeout();
  }

  /**
   * Method to add events.
   */
  private _tracker() {
    window.addEventListener('mousemove', this._eventHandler);
    window.addEventListener('click', this._eventHandler);
    window.addEventListener('keyup', this._eventHandler);
    window.addEventListener('scroll', this._eventHandler);
  }

  /**
   * method to remove events.
   */
  private _removeTracker() {
    window.removeEventListener('mousemove', this._eventHandler);
    window.removeEventListener('click', this._eventHandler);
    window.removeEventListener('keyup', this._eventHandler);
    window.removeEventListener('scroll', this._eventHandler);
  }

  private _setTimeout() {
    if (this._timeoutID) {
      clearTimeout(this._timeoutID);
    }

    this._timeoutID = window.setTimeout(() => {
      this._onTimeout();
    }, this._expirationTime * 1000);
  }

  /**
   * Method will be called when time is out.
   */
  private _onTimeout() {

    this._removeTracker();
    clearTimeout(this._timeoutID);

    this._subject.next(true);
  }
}
