<!-- duty validation comp start -->
<div class="duty-validation-comp">
  <!-- Validation messages before calculation start-->
  <div class="error" *ngIf="liquidNotSelectedErrorMsg"><span>{{liquidNotSelectedErrorMsg}}</span></div>
  <div class="error" *ngIf="underdeterminedErrorNeedMoreParams">
    <span>
      {{'dutyValidationPage.underdeterminedErrorNeedMoreParams'|translate}}
    </span>
  </div>
  <div class="error" *ngIf="underdeterminedErrorDiffSides"><span>{{'dutyValidationPage.underdeterminedErrorDiffSides'|translate}}</span>
  </div>
  <div class="error" *ngIf="overdeterminedErrorTooManyParams"><span>{{'dutyValidationPage.overdeterminedErrorTooManyParams'|translate}}</span>
  </div>

  <!-- Condensation start -->
  <div class="error" *ngIf="saturationParamsNotEntered"><span>{{'dutyValidationPage.saturationParamsNotEntered'|translate}}</span>
  </div>
  <div class="error" *ngIf="outletTempWarmNotEntered"><span>{{'dutyValidationPage.outletTempWarmNotEntered'|translate}}</span>
  </div>
  <!-- Condensation end -->

  <!-- Gas start -->
  <div class="error" *ngIf="inletPressureWarmNotEntered"><span>{{'dutyValidationPage.inletPressureWarmNotEntered'|translate}}</span>
  </div>
  <div class="error" *ngIf="inletPressureColdNotEntered"><span>{{'dutyValidationPage.inletPressureColdNotEntered'|translate}}</span>
  </div>
  <!-- Gas end -->

  <!-- Validation messages before calculation end-->

  <!-- Validation messages after calculation -->
  <div class="error" *ngIf="warmMassFlowRateError"><span>{{'dutyValidationPage.warmMassFlowRateError'|translate}}</span>
  </div>
  <div class="error" *ngIf="coldMassFlowRateError"><span>{{'dutyValidationPage.coldMassFlowRateError'|translate}}</span>
  </div>

  <div class="error" *ngIf="warmMaxPressureDropError"><span>{{'dutyValidationPage.warmMaxPressureDropError'|translate}}</span>
  </div>
  <div class="error" *ngIf="coldMaxPressureDropError"><span>{{'dutyValidationPage.coldMaxPressureDropError'|translate}}</span>
  </div>

  <div class="error" *ngIf="warmFluidTempNotInRangeWarning">
    <span>
      {{parametersCalculated.warm.liquid.name}}{{'dutyValidationPage.fluidTempNotInRangeWarning'|translate}}
    </span>
  </div>
  <div class="error" *ngIf="coldFluidTempNotInRangeWarning">
    <span>
      {{parametersCalculated.cold.liquid.name}}{{'dutyValidationPage.fluidTempNotInRangeWarning'|translate}}
    </span>
  </div>
  <div class="error" *ngIf="notAcceptableTemperature">
    <span>
      {{'dutyValidationPage.tempNotAccept'|translate}} ({{parametersCalculated.warm.liquid.name}})
      {{parametersCalculated.warm.inletTemp.value.userUnits}} ->
      {{parametersCalculated.warm.outletTemp.value.userUnits}},
      {{'dutyValidationPage.cold'|translate}} ({{parametersCalculated.cold.liquid.name}}) {{parametersCalculated.cold.inletTemp.value.userUnits}} ->
      {{parametersCalculated.cold.outletTemp.value.userUnits}}
    </span>
  </div>
  <div class="error" *ngIf="notValidOverdesign">
    <span>{{'dutyValidationPage.notValidOverdesign'|translate}}</span>
  </div>

  <div class="error" *ngIf="notValidColdMaxTemperature">
    <span>{{'dutyValidationPage.notValidColdMaxTemperature'|translate}}</span>
  </div>

  <div class="error" *ngIf="notValidColdMaxPressure">
    <span>{{'dutyValidationPage.notValidColdMaxPressure'|translate}}</span>
  </div>

  <div class="error" *ngIf="notValidWarmMaxTemperature">
    <span>{{'dutyValidationPage.notValidWarmMaxTemperature'|translate}}</span>
  </div>

  <div class="error" *ngIf="notValidWarmMaxPressure">
    <span>{{'dutyValidationPage.notValidWarmMaxPressure'|translate}}</span>
  </div>

  <div class="error" *ngIf="notValidInletTemperatures">
    <span>{{'dutyValidationPage.notValidInletTemperatures'|translate}}</span>
  </div>

  <div class="error" *ngIf="notValidInletOutletTemperaturesCond">
    <span>{{'dutyValidationPage.notValidInletOutletTemperaturesCond'|translate}}</span>
  </div>

  <div class="error" *ngIf="notValidInletOutletTemperaturesEvap">
    <span>{{'dutyValidationPage.notValidInletOutletTemperaturesEvap'|translate}}</span>
  </div>

  <div class="error" *ngIf="notValidPrimaryOuletTempCondEvap">
    <span>{{'dutyValidationPage.notValidPrimaryOuletTempCondEvap'|translate}}</span>
  </div>

  <!-- Condensation\Evaporation list of temperature errors start -->
  <div class="error" *ngIf="validationsErrorsListOfTemp.length>0">
    <span class="block" *ngFor="let error of validationsErrorsListOfTemp">{{error}}</span>
  </div>
  <!-- Condensation\Evaporation list of temperature errors end -->

</div>
<!-- duty validation comp end -->
