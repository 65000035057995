import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ApiService, SnackBarService } from 'src/app/services';


import * as THREE from 'three';
import * as occtimportjs from 'occt-import-js';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-viewer3d',
  templateUrl: './viewer3d.component.html',
  styleUrls: ['./viewer3d.component.scss']
})
export class Viewer3dComponent implements OnInit {
  public showModal: boolean = false;

  @Input()
  public solutionId: number = null;
  @Input()
  public drawing: ArrayBuffer = null;
  @Output()
  public close: EventEmitter<boolean> = new EventEmitter();

  constructor() { };
  public ngOnInit(): void {
    this.vmLoad(this.drawing);
  };

  public vmSaveModel() {
    saveAs(new Blob([this.drawing], { type: "application/step;charset=utf-8" }), `solution_${this.solutionId}_drawing.stp`);
  };

  public onClose(): void {
    this.close.emit(true);
  };

  private async vmLoad(buffer: ArrayBuffer) {
    const div = document.getElementById('drawing-container');
    const width = 688;
    const height = 600;

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(width, height);
    renderer.setClearColor('#fff');

    div.appendChild(renderer.domElement);

    const camera = new THREE.PerspectiveCamera(45, width / height, 1.0, 100000.0);
    camera.position.set(5000.0, 1.0, 1.0);
    camera.up.set(0.0, 0.0, 1.0);
    camera.lookAt(new THREE.Vector3(0.0, 0.0, 0.0));

    const scene = new THREE.Scene();

    const ambientLight = new THREE.AmbientLight(0x444444);
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0x888888);
    directionalLight.position.set(
      camera.position.x,
      camera.position.y,
      camera.position.z
    );
    scene.add(directionalLight);

    const mainObject = new THREE.Object3D();
    await this.vmLoadGeometry(mainObject, buffer);
    scene.add(mainObject);

    // Variables to track mouse state
    let isMouseDown = false;
    let previousMousePosition = { x: 0, y: 0 };
    let isMouseOver = false;

    // Add event listeners for mouse interactions
    div.addEventListener('mouseenter', () => {
      isMouseOver = true;
    });

    div.addEventListener('mouseleave', () => {
      isMouseOver = false;
    });

    div.addEventListener('mousedown', (event) => {
      isMouseDown = true;
      previousMousePosition = { x: event.clientX, y: event.clientY };
    });

    div.addEventListener('mouseup', () => {
      isMouseDown = false;
    });

    div.addEventListener('mousemove', (event) => {
      if (isMouseDown) {
        const deltaX = event.clientX - previousMousePosition.x;
        const deltaY = event.clientY - previousMousePosition.y;

        mainObject.rotation.y += deltaY * 0.01; // Rotate left/right
        mainObject.rotation.z += deltaX * 0.01; // Rotate up/down

        previousMousePosition = { x: event.clientX, y: event.clientY };
        renderer.render(scene, camera); // Manually render the scene
      };
    });

    // Add event listener for mouse wheel to zoom in/out
    div.addEventListener('wheel', (event) => {
      if (isMouseOver) {
        event.preventDefault();
        if (event.deltaY < 0) {
          camera.fov = Math.max(10, camera.fov - 1); // Zoom in
        } else {
          camera.fov = Math.min(75, camera.fov + 1); // Zoom out
        }
        camera.updateProjectionMatrix();
        renderer.render(scene, camera);
      }
    });

    // Initial render
    renderer.render(scene, camera);

  };

  private async vmLoadGeometry(targetObject, buffer) {
    // init occt-import-js
    const occt = await occtimportjs();

    // read the imported step file
    let fileBuffer = new Uint8Array(buffer);
    let result = occt.ReadStepFile(fileBuffer, null);

    // process the geometries of the result
    for (let resultMesh of result.meshes) {
      let geometry = new THREE.BufferGeometry();

      geometry.setAttribute(
        'position',
        new THREE.Float32BufferAttribute(resultMesh.attributes.position.array, 3)
      );
      if (resultMesh.attributes.normal) {
        geometry.setAttribute(
          'normal',
          new THREE.Float32BufferAttribute(resultMesh.attributes.normal.array, 3)
        );
      }
      const index = Uint32Array.from(resultMesh.index.array);
      geometry.setIndex(new THREE.BufferAttribute(index, 1));

      let material = null;
      if (resultMesh.color) {
        const color = new THREE.Color(
          resultMesh.color[0],
          resultMesh.color[1],
          resultMesh.color[2]
        );
        material = new THREE.MeshPhongMaterial({ color: color });
      } else {
        material = new THREE.MeshPhongMaterial({ color: 0xcccccc });
      }

      const mesh = new THREE.Mesh(geometry, material);
      targetObject.add(mesh);
    }
  };
}


