import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { JwtModule } from "@auth0/angular-jwt";
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HttpClient, HttpClientModule } from '@angular/common/http';

// Components
import * as auth from './auth';
import { LanguageService } from './services/language.service';
import { SharedModule } from './shared/shared.module';
import { NewsComponent } from './news/news.component';

// import ngx-translate and the http loader
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';

// import @angular/material
import { MatSnackBarModule } from '@angular/material/snack-bar';

@NgModule({
  declarations: [
    AppComponent,
    auth.LoginComponent,
    auth.RegistrationComponent,
    auth.ResetPasswordComponent,
    auth.ForgotPasswordComponent,
    NewsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    // Material
    MatSnackBarModule,
    AppRoutingModule,
    HttpClientModule,
    SharedModule,
    JwtModule.forRoot({
      config: {
        tokenGetter: tokenGetter,
        allowedDomains: ['localhost:5001', "zilex.nikama.net", "zilex.de", "dev-zilex.nikama.net", "app.zilex.de"],
        disallowedRoutes: []
      }
    }),
    // ngx-translate and the loader module
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    })

  ],
  providers: [HttpClient],
  bootstrap: [AppComponent]
})
export class AppModule {
  constructor(private languageService: LanguageService) {
    this.languageService.setDefaultLang('en');
    this.languageService.applyAvailableLanguage();
  }
}

export function tokenGetter() {
  const value = localStorage.getItem("jwt");
  return value ? JSON.parse(value).value : null;
}

// required for AOT compilation
function HttpLoaderFactory(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http);
}
