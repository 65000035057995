import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, switchMap } from 'rxjs';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { INewItemResponse, INewRequirementsResponse, Role, User } from 'src/app/services/interfaces';

@Component({
  selector: 'app-evaporation-details',
  templateUrl: './evaporation-details.component.html',
  styleUrls: ['./evaporation-details.component.scss']
})
export class AssistantEvaporationDetailsComponent {
  private quotationId: number = null;
  private itemId: number = null;
  private user: User = null;
  private existingRequirement: INewRequirementsResponse = null;
  private item: INewItemResponse = null;

  public selectedValue: string = 'no'

  constructor(
    private stateService: StateService,
    private apiService: ApiService,
    private snackBarService: SnackBarService,
    private route: ActivatedRoute,
    private router: Router
  ) { };

  public ngOnInit(): void {
    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];
    this.user = this.stateService.get('user');

    forkJoin({
      items: this.apiService.getItems(this.quotationId),
      itemReqs: this.apiService.getItemRequirements(this.itemId)
    }).subscribe({
      next: ({ items, itemReqs }) => {

        this.existingRequirement = itemReqs.find((_req) => _req.requirementType === 'application');
        this.item = items.find((_x) => _x.itemId === this.itemId);

        if (this.existingRequirement && this.existingRequirement.requirementValue.sub_application_cold) {
          this.selectedValue = this.existingRequirement.requirementValue.sub_application_cold;
        };

      },
      error: (error) => {
        this.snackBarService.open();
      }
    });

  };
  public back() {
    // if (this.existingRequirement.requirementValue.warm[0] === 'CO') {
    //   this.router.navigate([this.quotationId, this.itemId, 'condensation_details'], { relativeTo: this.route.parent });
    // } else {
      this.router.navigate([this.quotationId, this.itemId, 'application'], { relativeTo: this.route.parent });
    // };
  };

  public skipGuide(): void {
    const observable = this.apiService.skipGuide(this.itemId).subscribe({
      next: (itemId: number) => {
        let url: string = null;
        if (this.user.roles.includes(Role.UserAdmin)) {
          url = 'useradmin/configurator';
        } else {
          url = 'user/configurator';
        }
        observable.unsubscribe();
        this.router.navigate([url, this.quotationId, this.itemId, 'duty']);
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });
  };

  public onContinue() {
    // Data for saving
    const data = Object.assign({}, this.existingRequirement.requirementValue);
    data.sub_application_cold = this.selectedValue === 'no' ? null : this.selectedValue;

    this.apiService.updateItemRequirements(this.existingRequirement.requirementId, { requirementType: "application", requirementValue: data }).pipe(
      switchMap(() => {
        return this.apiService.updateItem(this.itemId, { quotationId: this.quotationId, itemStatus: '4ev', itemType: 'ST', itemNumber: '1' });
      })
    ).subscribe({
      next: () => {
        this.router.navigate([this.quotationId, this.itemId, 'cleanability'], { relativeTo: this.route.parent });
      },
      error: (error) => {
        this.snackBarService.open();
      }
    });
  }
}
