import { Component } from '@angular/core';

@Component({
  selector: 'app-assistant-main-page',
  templateUrl: './assistant-index.component.html',
  styleUrls: ['./assistant-index.component.scss']
})
export class AssistantMainPageComponent {

}
