import { Pipe, PipeTransform } from '@angular/core';

/**
 * Pipe to round numbers and format them acording to `users locale settings`.
 * Use `localeNumber` to get rounded and formated value.
 * If decimals required use `localeNumber:number`, to get rounded and formated value with decimals.
 * Example `2.1|localeNumber` will return rounded and formated value `2`.
 * Example `2.1|localeNumber:2` will return rounded and formated value with 2 decimals `2.10`.
 */
@Pipe({
  name: 'localeNumber'
})
export class LocaleNumberPipe implements PipeTransform {
  private userLocale: string;

  constructor() {
    this.userLocale = navigator.language;
  };

  transform(value: number, decimalPlaces: number = 0): string {
    // Round the number to the specified number of decimal places
    const factor = Math.pow(10, decimalPlaces);
    const roundedValue = Math.round(value * factor) / factor;

    // Format the rounded number according to the user's locale
    return new Intl.NumberFormat(this.userLocale, {
      minimumFractionDigits: decimalPlaces,
      maximumFractionDigits: decimalPlaces
    }).format(roundedValue);
  }
}