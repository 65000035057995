import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { INewRequirementsResponse, Role, User } from 'src/app/services/interfaces';
import { TPhysicalUnitsArray } from '../../duty/applications';
import { forkJoin, switchMap } from 'rxjs';

@Component({
  selector: 'app-design-parameters',
  templateUrl: './design-parameters.component.html',
  styleUrls: ['./design-parameters.component.scss']
})
export class AssistantDesignParametersComponent implements OnInit {
  private quotationId: number = null;
  private itemId: number = null
  private user: User = null;
  private existingRequirement: INewRequirementsResponse = null;

  private physicalPropsList: string[] = ['pressure', 'temperature'];
  public units: TPhysicalUnitsArray = null;

  public parameters = {
    warm: {
      pressure: {
        commonUnits: null,
        userUnits: null,
        unitSystemId: null
      },
      temperature: {
        commonUnits: null,
        userUnits: null,
        unitSystemId: null
      }
    },
    cold: {
      pressure: {
        commonUnits: null,
        userUnits: null,
        unitSystemId: null
      },
      temperature: {
        commonUnits: null,
        userUnits: null,
        unitSystemId: null
      }
    }
  }

  public noParamsEnetered: boolean = false;
  public temperatureIncorrect: boolean = false;
  public pressureIncorrect: boolean = false;

  constructor(private stateService: StateService, private apiService: ApiService, private snackBarService: SnackBarService, private route: ActivatedRoute, private router: Router) { }

  public ngOnInit() {
    this.user = this.stateService.get('user');
    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];

    const observable = forkJoin({
      units: this.apiService.getAllUnitSystemData(['pressure', 'temperature']),
      itemReqs: this.apiService.getItemRequirements(this.itemId)
    }).subscribe({
      next: ({ units, itemReqs }) => {
        this.units = units;

        // Create default unit system for each prop
        // If list of systems have system saved in user profile, use it as default
        // if not use 1 unit system.
        const defaultUnitSystems: { [key: string]: number } = {};
        this.physicalPropsList.forEach((_propName: string) => {
          defaultUnitSystems[_propName] = !!units[_propName].find((_x) => _x.unitSystemId === +this.user.unitSystem) ? +this.user.unitSystem : 1;
        });

        // Use users unit system as default
        this.parameters.warm.pressure.unitSystemId = defaultUnitSystems.pressure;
        this.parameters.warm.temperature.unitSystemId = defaultUnitSystems.temperature;
        this.parameters.cold.pressure.unitSystemId = defaultUnitSystems.pressure;
        this.parameters.cold.temperature.unitSystemId = defaultUnitSystems.temperature;


        observable.unsubscribe();

        this.existingRequirement = itemReqs.find((_req) => _req.requirementType === 'design_parameters');

        if (this.existingRequirement) {
          // this.existingRequirement.requirementValue = JSON.parse(this.existingRequirement.requirementValue);

          const warm = this.existingRequirement.requirementValue.warm;
          const cold = this.existingRequirement.requirementValue.cold;

          // WARM PARAMS
          this.parameters.warm.pressure.commonUnits = warm.max_pressure;
          this.parameters.warm.pressure.userUnits = this.convertToUser(warm.max_pressure, 'pressure', this.parameters.warm.pressure.unitSystemId).toFixed(1);
          this.parameters.warm.temperature.commonUnits = warm.max_temperature;
          this.parameters.warm.temperature.userUnits = this.convertToUser(warm.max_temperature, 'temperature', this.parameters.warm.temperature.unitSystemId).toFixed(1);

          // COLD PARAMS
          this.parameters.cold.pressure.commonUnits = cold.max_pressure;
          this.parameters.cold.pressure.userUnits = this.convertToUser(cold.max_pressure, 'pressure', this.parameters.cold.pressure.unitSystemId).toFixed(1);
          this.parameters.cold.temperature.commonUnits = cold.max_temperature;
          this.parameters.cold.temperature.userUnits = this.convertToUser(cold.max_temperature, 'temperature', this.parameters.cold.temperature.unitSystemId).toFixed(1);


        };

      },
      error: () => {
        this.snackBarService.open();
      }
    });
  };

  public onInputChange(side: string, paramName: string, value: number): void {

    if (!!value || value === 0) {
      this.parameters[side][paramName].userUnits = +value;
      this.parameters[side][paramName].commonUnits = this.convertToSI(+value, paramName, this.parameters[side][paramName].unitSystemId);
    } else {
      this.parameters[side][paramName].userUnits = null;
      this.parameters[side][paramName].commonUnits = null;
    }

  };

  public onUnitChange(side: string, propName: string, newSystemId: number) {
    // Save new unit system selected for current parameter.
    // If parameter have SI value,  convert it to value in unit selected by user
    // If not dont do anything.
    this.parameters[side][propName].unitSystemId = newSystemId;

    if (this.parameters[side][propName].commonUnits) {
      this.parameters[side][propName].userUnits = Number(this.convertToUser(this.parameters[side][propName].commonUnits, propName, this.parameters[side][propName].unitSystemId).toFixed(1));
    };
  };

  public back() {
    this.router.navigate([this.quotationId, this.itemId, 'country'], { relativeTo: this.route.parent });
  };
  public skipGuide(): void {

    const observable = this.apiService.skipGuide(this.itemId).subscribe({
      next: (itemId: number) => {
        let url: string = null;
        if (this.user.roles.includes(Role.UserAdmin)) {
          url = 'useradmin/configurator';
        } else {
          url = 'user/configurator';
        }
        observable.unsubscribe();
        this.router.navigate([url, this.quotationId, this.itemId, 'duty']);
      },
      error: () => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    })

  };

  public onContinue() {

    // Reset validation errors
    this.noParamsEnetered = false;
    this.temperatureIncorrect = false;
    this.pressureIncorrect = false;

    if (!this.parameters.warm.pressure.commonUnits || !this.parameters.warm.temperature.commonUnits || !this.parameters.cold.pressure.commonUnits || !this.parameters.cold.temperature.commonUnits) {
      this.noParamsEnetered = true;
      return;
    };

    const data = {
      warm: {
        max_pressure: this.parameters.warm.pressure.commonUnits,
        max_temperature: this.parameters.warm.temperature.commonUnits
      },
      cold: {
        max_pressure: this.parameters.cold.pressure.commonUnits,
        max_temperature: this.parameters.cold.temperature.commonUnits
      }
    };

    // Check if enetered values.
    if (data.warm.max_temperature > 300 || data.cold.max_temperature > 300) {
      this.temperatureIncorrect = true;
      return;
    }
    if (data.warm.max_pressure > 5000000 || data.cold.max_pressure > 5000000) {
      this.pressureIncorrect = true;
      return;
    }

    let apiCall = null;

    if (this.existingRequirement) {
      apiCall = this.apiService.updateItemRequirements(this.existingRequirement.requirementId, { requirementType: 'design_parameters', requirementValue: data });
    } else {
      apiCall = this.apiService.saveItemRequirements(this.itemId, { requirementType: 'design_parameters', requirementValue: data });
    }

    const observable = apiCall.pipe(switchMap(() => {
      return this.apiService.updateItem(this.itemId, { quotationId: this.quotationId, itemStatus: '2', itemType: 'ST', itemNumber: '1' });
    })).subscribe({
      next: () => {
        this.router.navigate([this.quotationId, this.itemId, 'he_type'], { relativeTo: this.route.parent });
      },
      error: (error) => {
        this.snackBarService.open();
        observable.unsubscribe();
      }
    });

  };

  /**
   * Method to convert value `from users unit system to SI`.
   * @param value
   * @param propName physical property name. See `physicalPropsNameList property` for correct typing
   * @returns
   */
  private convertToSI(value: number, propName: string, unitSystemId: number): number {
    const prop = this.units[propName].find((x) => x.unitSystemId === unitSystemId);
    if (value === 0 || !value) {
      return value;
    };

    return prop.addFactor + (value * prop.multFactor)

  };

  /**
   * Method to convert value `from SI unit system to users unit system`.
   * @param value
   * @param propName physical property name. See `physicalPropsNameList property` for correct typing
   * @returns
   */
  private convertToUser(value: number, propName: string, unitSystemId: number): number {
    const prop = this.units[propName].find((x) => x.unitSystemId === unitSystemId);
    if (value === 0 || !value) {
      return value;
    };

    return (value - prop.addFactor) / prop.multFactor;

  };
}
