import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { forkJoin, interval, mergeMap, switchMap, timeInterval } from 'rxjs';
import { ApiService, SnackBarService, StateService } from 'src/app/services';
import { IAutoSelectedOption, INewItemResponse, Role } from 'src/app/services/interfaces';
import { environment } from "../../../environments/environment";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-options',
  templateUrl: './options.component.html',
  styleUrls: ['./options.component.scss']
})
export class OptionsComponent implements OnInit {
  private quotationId: number = null;
  private itemId: number = null;
  private item: INewItemResponse = null;
  private physicalPropsNameList: string[] = ['length', 'weight', 'area', 'pressure_drop'];

  public userCurrency: string = null;
  public physicalPropsUserUnitSystemData: { [key: string]: { unitSign: string; uniTitle: string; addFactor: number; multFactor: number; } } = null;
  public stGroups: { name: string; mainOption: IAutoSelectedOption; options: IAutoSelectedOption[]; }[] = null;
  public pheGroups: { name: string; mainOption: IAutoSelectedOption; options: IAutoSelectedOption[]; }[] = null;

  public resultsExpiredFlag: boolean = false;
  public changeRequirements: boolean = false;
  public searchInProgressFlag: boolean = true;

  public images: { [key: number]: string } = {};

  public sortDirection: number = 1;

  public view360Modal: boolean = false;
  public modelIdFor360View: number = null;
  public modelNameFor360View: string = null;

  // Send inquiry
  public sendInquiryModal: boolean = false;
  public inquiryCreatedModal: boolean = false;
  public sendInquiryFormNotes: FormGroup = null;


  constructor(
    private apiService: ApiService,
    private stateService: StateService,
    private route: ActivatedRoute,
    private snackBarService: SnackBarService,
    private router: Router,
    private formBuilder: FormBuilder
  ) { };

  public ngOnInit(): void {
    this.quotationId = +this.route.snapshot.params['quotationId'];
    this.itemId = +this.route.snapshot.params['itemId'];

    const userUnitSystem: string = this.stateService.get('user').unitSystem;
    const userCurrency: string = this.stateService.get('user').idCurrency;

    if (userCurrency === 'USD') {
      this.userCurrency = '$';
    } else if (userCurrency === 'EUR') {
      this.userCurrency = '€';
    }

    const observable = forkJoin({
      unitSystemProps: this.apiService.getUnitSystemData(userUnitSystem, this.physicalPropsNameList),
      items: this.apiService.getItems(this.quotationId)
    }).subscribe({
      next: ({ unitSystemProps, items }) => {
        this.physicalPropsUserUnitSystemData = unitSystemProps;
        this.item = items.find((_x) => _x.itemId === this.itemId);
        this.getOptions();
        observable.unsubscribe();
      }
    })


  };
  public vmGoToHomePage(): void {
    const user = this.stateService.get('user');
    let baseRole: string = null;
    // Find base role of user
    if (user.roles.includes(Role.UserAdmin)) {
      baseRole = "useradmin";
    } else if (user.roles.includes(Role.User)) {
      baseRole = "user";
    };

    this.router.navigate([`${baseRole}/home`]);
  }
  public back() {
    const user = this.stateService.get('user');
    let baseRole: string = null;
    // Find base role of user
    if (user.roles.includes(Role.UserAdmin)) {
      baseRole = "useradmin";
    } else if (user.roles.includes(Role.User)) {
      baseRole = "user";
    };
    this.router.navigate([`${baseRole}/configurator`, this.quotationId, this.itemId, 'duty']);
  };

  public open360ViewModal(e: Event, option: IAutoSelectedOption): void {
    e.preventDefault();
    e.stopPropagation();

    this.modelIdFor360View = option.modelId;
    this.modelNameFor360View = option.modelName;
    this.view360Modal = true;
  }

  /**
    * Method to convert value `from SI unit system to users unit system`.
    * @param value
    * @param propName physical property name. See `physicalPropsNameList property` for correct typing
  */
  public convertUnitSystemFromSIToUser(value: number, propName: string): number {
    const prop = this.physicalPropsUserUnitSystemData[propName];
    if (value === 0 || !value) {
      return value;
    };

    return (value - prop.addFactor) / prop.multFactor;

  };

  public sortBy(sortBy: string, groups: { name: string; mainOption: IAutoSelectedOption; options: IAutoSelectedOption[]; }[]) {
    this.sortDirection = this.sortDirection === -1 ? 1 : -1;

    groups.forEach((_group) => {

      let allOptions = [_group.mainOption, ..._group.options];

      allOptions.forEach((_option) => {

        if (sortBy === 'price') {
          _option.sort = _option.price;
        } else if (sortBy === 'time') {
          _option.sort = _option.deliveryTimeDays;
        } else if (sortBy === 'shellPressure') {
          _option.sort = _option.pressureDropShellSide;
        } else if (sortBy === 'tubePressure') {
          _option.sort = _option.pressureDropTubeSide;
        } else if (sortBy === 'excessArea') {
          _option.sort = _option.excessArea;
        } else if (sortBy === 'area') {
          _option.sort = _option.area;
        } else if (sortBy === 'shellDiameter') {
          _option.sort = _option.shellDiameter;
        } else if (sortBy === 'weight') {
          _option.sort = _option.weight;
        } else if (sortBy === 'size') {
          _option.sort = _option.width + _option.height;
        }

      });

      allOptions = allOptions.sort((a, b) => {
        if (this.sortDirection === -1) {
          return a.sort < b.sort ? 1 : -1;
        } else {
          return a.sort > b.sort ? 1 : -1;
        }
      });

      _group.mainOption = allOptions[0];
      _group.options = allOptions.slice(1);

    });

    groups = groups.sort((a, b) => {
      if (this.sortDirection === -1) {
        return a.mainOption.sort < b.mainOption.sort ? 1 : -1;
      } else {
        return a.mainOption.sort > b.mainOption.sort ? 1 : -1;
      }
    });

  };

  public vmCloseSendInquiryModal(): void {
    this.sendInquiryModal = false;
    this.sendInquiryFormNotes = null;
  };

  public vmOpenSendInquiryModal(): void {
    this.sendInquiryModal = true;

    // Crate form group
    this.sendInquiryFormNotes = this.formBuilder.group({
      notes: [null, Validators.required]
    });

  };

  public vmSendInquiry() {
    if (this.sendInquiryFormNotes.invalid) {
      this.sendInquiryFormNotes.markAsDirty();
      return;
    };

    this.apiService.sendInquire(this.quotationId, this.sendInquiryFormNotes.value['notes']).subscribe({
      next: (response) => {
        this.vmCloseSendInquiryModal();
        this.inquiryCreatedModal = true;
      },
      error: (error) => {
        this.snackBarService.open('Error sending inquiry');
      }
    });

  };

  public onOptionClick(option: IAutoSelectedOption) {
    let solutionId: number = null;

    let observable = this.apiService.createSolution(this.itemId, option.id).pipe(switchMap((solutionResponse: number) => {
      solutionId = solutionResponse;
      return this.apiService.updateItem(this.itemId, { quotationId: this.quotationId, itemStatus: '9', itemNumber: this.item.itemNumber, itemType: this.item.itemType });
    })).subscribe({
      next: (itemResp) => {
        observable.unsubscribe();
        const user = this.stateService.get('user');
        let baseRole: string = null;
        // Find base role of user
        if (user.roles.includes(Role.UserAdmin)) {
          baseRole = "useradmin";
        } else if (user.roles.includes(Role.User)) {
          baseRole = "user";
        };
        this.router.navigate([`${baseRole}/configurator`, this.quotationId, this.itemId, 'solution', solutionId]);
      },
      error: (error: HttpErrorResponse) => {
        if (typeof error.error === 'string') {
          this.snackBarService.open(error.error);
        } else if (error.error.title) {
          this.snackBarService.open(error.error.title);
        } else {
          this.snackBarService.open();
        };
        observable.unsubscribe();
      }
    });

  };

  private getOptions() {

    const intervalObservable = interval(2000).pipe(
      timeInterval(),
      mergeMap(() => this.apiService.getAutoSelectedOptions(this.itemId))
    ).subscribe({
      next: (selectedOptionsResp) => {
        // Reset flags
        this.resultsExpiredFlag = false;
        this.changeRequirements = false;
        this.searchInProgressFlag = false;


        if (selectedOptionsResp.status === 'Completed' && selectedOptionsResp.foundSolutions.length > 0) {
          intervalObservable.unsubscribe();

          const stModels = selectedOptionsResp.foundSolutions.filter((_model) => _model.heType === 'st');
          const pheModels = selectedOptionsResp.foundSolutions.filter((_model) => _model.heType === 'phe');


          if (stModels.length > 0 && pheModels.length > 0) {
            this.stGroups = this.groupOptions(stModels, 3);
            this.pheGroups = this.groupOptions(pheModels, 3);
          } else if (stModels.length > 0) {
            this.stGroups = this.groupOptions(stModels, 5);
          } else if (pheModels.length > 0) {
            this.pheGroups = this.groupOptions(pheModels, 5);
          };


          const observable = this.apiService.updateItem(this.itemId, { quotationId: this.quotationId, itemStatus: '8', itemNumber: this.item.itemNumber, itemType: this.item.itemType }).subscribe({
            error: (error) => {
              this.snackBarService.open();
              observable.unsubscribe();
              throw error;
            }
          });

        } else if (selectedOptionsResp.status === 'Completed' && selectedOptionsResp.foundSolutions.length === 0) {
          intervalObservable.unsubscribe();
          this.changeRequirements = true;
        } else if (selectedOptionsResp.status === 'Stopped') {
          intervalObservable.unsubscribe();
          this.changeRequirements = true;
        } else if (selectedOptionsResp.status === 'Error') {
          intervalObservable.unsubscribe();
          this.snackBarService.open(selectedOptionsResp.message, 20, 'snackbar-error');
          this.changeRequirements = true;
        } else if (selectedOptionsResp.status === 'Processing') {
          this.searchInProgressFlag = true;
        } else {
          intervalObservable.unsubscribe();
          this.changeRequirements = true;
        };

      },
      error: (error: HttpErrorResponse) => {
        intervalObservable.unsubscribe();
        this.searchInProgressFlag = false;

        if (error.status === 404 && error.statusText === 'Not Found') {
          this.resultsExpiredFlag = true;
        } else {
          this.snackBarService.open();
          throw error;
        };
      }
    });


  };

  public getImagesForOptions(ids: number[]): void {
    ids.forEach((_id: number) => {
      this.images[_id] = `${environment.base}/public_images/models/${_id}/1.png`;
    });
  };

  private groupOptions(optionsData: IAutoSelectedOption[], amount: number = 3) {
    let listOfModelsNames: string[] = optionsData.map((_option: IAutoSelectedOption) => _option.modelName);
    listOfModelsNames = [...new Set(listOfModelsNames)];

    let groups: { name: string; mainOption: IOption; options: IOption[]; }[] = [];

    listOfModelsNames.forEach((_modelName: string) => {

      // Get option for current model
      // And sort them by price
      let modelOptions = optionsData.filter((_option: IAutoSelectedOption) => _option.modelName === _modelName).map((option) => {
        // Copy option
        const _option: IOption = Object.assign({ recommend: false, parsedModelName: null, cheapest: false }, option);

        // Convert number values to Users Unit System
        _option.width = this.convertUnitSystemFromSIToUser(_option.width, 'length');
        _option.width = this.vmAdjustValuePrecision(_option.width, 3);

        _option.height = this.convertUnitSystemFromSIToUser(_option.height, 'length');
        _option.height = this.vmAdjustValuePrecision(_option.height, 3);

        _option.length = this.convertUnitSystemFromSIToUser(_option.length, 'length');
        _option.length = this.vmAdjustValuePrecision(_option.length, 3);

        _option.shellDiameter = this.convertUnitSystemFromSIToUser(_option.shellDiameter, 'length');
        _option.shellDiameter = Number(_option.shellDiameter.toFixed(1));

        _option.weight = this.convertUnitSystemFromSIToUser(_option.weight, 'weight');
        _option.weight = this.vmAdjustValuePrecision(_option.weight, 3);

        _option.area = this.convertUnitSystemFromSIToUser(_option.area, 'area');
        _option.area = this.vmAdjustValuePrecision(_option.area, 2);

        _option.excessArea = this.vmAdjustValuePrecision(_option.excessArea, 2);

        if (!!_option.pressureDropShellSide || _option.pressureDropShellSide === 0) {
          _option.pressureDropShellSide = this.convertUnitSystemFromSIToUser(_option.pressureDropShellSide, 'pressure_drop');
          _option.pressureDropShellSide = this.vmAdjustValuePrecision(_option.pressureDropShellSide, 3);
        };

        if (!!_option.pressureDropTubeSide || _option.pressureDropTubeSide === 0) {
          _option.pressureDropTubeSide = this.convertUnitSystemFromSIToUser(_option.pressureDropTubeSide, 'pressure_drop');
          _option.pressureDropTubeSide = this.vmAdjustValuePrecision(_option.pressureDropTubeSide, 3);
        };

        _option.deliveryTime = !(_option.deliveryTimeDays % 7) ? `${Math.floor(_option.deliveryTimeDays / 7)} weeks` : `${_option.deliveryTimeDays} days`;
        _option.price = Math.round(_option.price);

        // Parse model Name
        const parsedModelName: string[] = _option.modelName.split(' ');

        _option.parsedModelName = parsedModelName;
        return _option;
      });

      // Take first option in array as main option after sort.
      const mainOption = modelOptions[0];

      // Save other option, except first.
      const options = modelOptions.slice(1, 6);
      const group = {
        name: _modelName,
        mainOption,
        showAllOptions: false,
        options
      };
      groups.push(group);

    });

    // Sort groups by price
    groups = groups.sort((a, b) => {
      return a.mainOption.price > b.mainOption.price ? 1 : -1;
    });

    // Select the cheapest option from all groups.
    groups[0].mainOption.cheapest = true;

    // Show selected amount of grouped models
    groups = groups.slice(0, amount);

    let allOptions: IOption[] = [];
    let ids: number[] = [];

    groups.forEach((next) => {
      // Get modelId from all options
      ids.push(next.mainOption.modelId);
      next.options.forEach((_option) => ids.push(_option.modelId));

      // Get all options from all groups
      allOptions.push(next.mainOption);
      allOptions = [...allOptions, ...next.options];

    });

    // find cheapest US option and mark it as recommended.
    const usOptions = allOptions.filter((_option) => _option.tubeDesign === 'US').sort((a, b) => {
      return a.price > b.price ? 1 : -1;
    });

    if (usOptions.length > 0) {
      usOptions[0].recommend = true;
    }

    // Filter ids from duplicates and null values.
    ids = [... new Set(ids)].filter((_x) => _x);

    // Get images for prepared option groups
    this.getImagesForOptions(ids);

    return groups;
  };

  /**
   * Method to round value.
   * @param value
   * @param precision
   * @param maxDecimals
   * @returns
   */
  private vmAdjustValuePrecision(value: number, precision: number, maxDecimals: number = null) {
    function vmGetNumberOfSignificantDigitsAfterZero(value: number, precision: number, maxDecimals: number): number {
      if (value === 0 || !isFinite(value) || isNaN(value)) {
        return 0
      }

      const lvAbsValue: number = Math.abs(value);
      const lvLog: number = Math.trunc(Math.log10(lvAbsValue));
      let lvResult: number = precision - lvLog;

      if (Math.trunc(lvAbsValue) > 0) {
        lvResult--;
      };
      if (lvResult < 0) {
        lvResult = 0;
      };

      if (maxDecimals !== null && lvResult > maxDecimals) {
        lvResult = maxDecimals;
      };

      return lvResult;
    };


    const lvNumberOfSignificantDigitsAfterZero: number = vmGetNumberOfSignificantDigitsAfterZero(value, precision, maxDecimals);

    const lvMultKoef: number = Math.pow(10, lvNumberOfSignificantDigitsAfterZero);

    let lvResult: number = lvMultKoef * value;
    lvResult = Math.round(lvResult);
    lvResult = lvResult / lvMultKoef;

    return lvResult;
  };
}


interface IOption extends IAutoSelectedOption {
  parsedModelName: string[];
  recommend: boolean;
  cheapest: boolean;
}
