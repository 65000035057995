<!-- component start -->
<div class="he-type-comp">
  <!-- main container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">Back</button>
      <button class="skip margin-left-auto" (click)="skipGuide()">Skip Assistant</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="3"></app-progressbar>
    <!-- progressbar end -->

    <!-- main title start -->
    <div class="main-title">
      Do you prefer a shell and tube or a plate heat exchanger?
    </div>
    <!-- main title end -->

  </div>
  <!-- main container end -->

  <!-- he type section start -->
  <div class="he-type-section">
    <!-- he type list start -->
    <div class="he-type-list">
      <!-- item start -->
      <div class="item" [ngClass]="{'active': heatExchangerType ==='ST'}" (click)="selectHEExchanger('ST')">

        <!-- icon block start -->
        <div class="item-icon-block">
          <img src="../../../assets/images/he_type_st.png" alt="he_type_st.png">
        </div>
        <!-- icon block end -->

        <!-- item title start -->
        <div class="item-title">Shell & Tube</div>
        <!-- item title end -->

      </div>
      <!-- item end -->

      <!-- item start -->
      <div class="item" [ngClass]="{'active': heatExchangerType ==='PH'}" (click)="selectHEExchanger('PH')">

        <!-- icon block start -->
        <div class="item-icon-block">
          <img src="../../../assets/images/he_type_plate.png" alt="he_type_plate.png">
        </div>
        <!-- icon block end -->

        <!-- item title start -->
        <div class="item-title">Plate Hex</div>
        <!-- item title end -->

      </div>
      <!-- item end -->

      <!-- item start -->
      <div class="item" [ngClass]="{'active': heatExchangerType ==='NA'}" (click)="selectHEExchanger('NA')">

        <!-- icon block start -->
        <div class="item-icon-block">
          <img src="../../../assets/images/he_type_undecided.png" alt="he_type_undecided.png">
        </div>
        <!-- icon block end -->

        <!-- item title start -->
        <div class="item-title">Undecided</div>
        <!-- item title end -->

      </div>
      <!-- item end -->
    </div>
    <!-- he type list end -->
  </div>
  <!-- he type section end -->

  <!-- warning message start -->
  <div class="warning-message" *ngIf="warningMessage">
    Please make your choice
  </div>
  <!-- warning message end -->

  <!-- btn-new start -->
  <button type="button" class="btn" (click)="onContinue()">continue</button>
  <!-- btn-new end -->

</div>
<!-- component end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="3"></app-zilo-hints>
<!-- zilo-hints end -->