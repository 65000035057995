import {Component, OnInit, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit{
  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    const script = this.renderer.createElement('script');
    script.src = 'https://cdn.customgpt.ai/js/chat.js';
    script.defer = true;
    script.onload = () => {
      (window as any).CustomGPT.init({
        p_id: '48468',
        p_key: '0794e632b985c761b7a367a49d2b2dd9'
      });
    };
    this.renderer.appendChild(document.body, script);
  }
}
