<!-- assistant-evaporation-details start -->
<div class="assistant-evaporation-details">
  <!-- main-container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">{{'journey.back'|translate}}</button>
      <button class="skip margin-left-auto" (click)="skipGuide()">{{'journey.skipAssistant'|translate}}</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="4"></app-progressbar>
    <!-- progressbar end -->

    <!-- page-title start -->
    <div class="page-title">{{'evapDetailsPage.title'|translate}}</div>
    <!-- page-title end -->

    <!-- parameters-container start -->
    <div class="parameters-container">

      <!-- parameter start -->
      <div class="parameter">

        <!-- label start -->
        <label for="clean_steam">{{'commonLabels.yes'|translate}}</label>
        <!-- label end -->

        <!-- input-block start -->
        <div class="input-block">
          <input type="radio" name="controlled" value="clean_steam" id="clean_steam"
            [(ngModel)]="selectedValue">
          <label for="clean_steam"></label>
        </div>
        <!-- input-block end -->

      </div>
      <!-- parameter end -->

      <!-- parameter start -->
      <div class="parameter">

        <!-- label start -->
        <label for="no">{{'commonLabels.no'|translate}}</label>
        <!-- label end -->

        <!-- input-block start -->
        <div class="input-block">
          <input type="radio" name="controlled" value="no" id="no" [(ngModel)]="selectedValue">
          <label for="no"></label>
        </div>
        <!-- input-block end -->

      </div>
      <!-- parameter end -->

    </div>
    <!-- parameters-container end -->

    <!-- btn-new start -->
    <button type="button" class="btn" (click)="onContinue()">{{'journey.continue'|translate}}</button>
    <!-- btn-new end -->

  </div>
  <!-- main-container end -->
</div>
<!-- assistant-evaporation-details end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="'4ev'"></app-zilo-hints>
<!-- zilo-hints end -->
