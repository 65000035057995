import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StateService } from 'src/app/services';
import { Role, User } from 'src/app/services/interfaces';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  private user: User = null;


  constructor(private stateService: StateService, private router: Router) {
    // empty
  }
  public ngOnInit(): void {
    this.user = this.stateService.restore('user');
  }

  public navigate(e: Event): void {

    e.preventDefault();

    if (!this.user) {
      this.router.navigate(["login"]);
    } else if (this.user.roles.includes(Role.UserAdmin)) {
      this.router.navigate(["/useradmin/home"]);
    } else if (this.user.roles.includes(Role.User)) {
      this.router.navigate(["/user/home"]);
    } else if (this.user.roles.includes(Role.Administrator)) {
      throw new Error('Administrator role not supported.');
    }

  }


}
