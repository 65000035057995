<!-- liquid-comp start -->
<div class="liquid-comp" [ngClass]="{'cold-side': side==='cold'}" *ngIf="!!unitSystems">
  <!-- parameter start -->
  <div class="parameter-combobox">

    <!-- label start -->
    <label for="liquid-{{side}}" class="parameter-label">{{'dutyPage.fluid'|translate}}</label>
    <!-- label end -->

    <!-- parameter-input-container start -->
    <div class="parameter-input-container">

      <!-- parameter input block start -->
      <div class="parameter-input-block">

        <!-- input field start -->
        <input class="parameter-input-field" type="text" name="liquid-{{side}}" id="liquid-{{side}}"
          [ngModel]="liquid.name" [disabled]="true" />
        <!-- input field end -->

        <!-- actions and units block start -->
        <div class="actions-and-units-block">

          <!-- open icon start -->
          <div class="open-icon" (click)="treeMenu = !treeMenu">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
              <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
            </svg>
          </div>
          <!-- open icon end -->

        </div>
        <!-- actions and units block end -->

      </div>
      <!-- parameter input block end -->

      <!-- add-fluid-button start -->
      <div class="add-fluid-button" (click)="switchCustomFluidModal()">
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 0 24 24" width="24px" fill="#e8eaed">
          <path d="M0 0h24v24H0V0z" fill="none" />
          <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z" />
        </svg>
      </div>
      <!-- add-fluid-button end -->

      <!-- dropdown tree menu start -->
      <div class="dropdown-tree-menu" *ngIf="treeMenu">
        <mat-tree [dataSource]="treeData" [treeControl]="treeControl" class="example-tree">
          <!-- This is the tree node template for leaf nodes -->
          <!-- There is inline padding applied to this node using styles.
          This padding value depends on the mat-icon-button width. -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="onLiquidSelect(node)">
            {{node.name}}
          </mat-tree-node>
          <!-- This is the tree node template for expandable nodes -->
          <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle
            (click)="onLiquidSelect(node)">
            <div class="mat-tree-node">
              <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name">
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'folder_open' : 'folder'}}
                </mat-icon>
              </button>
              {{node.name}}
            </div>
            <!-- There is inline padding applied to this div using styles.
                This padding value depends on the mat-icon-button width.  -->
            <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
              <ng-container matTreeNodeOutlet></ng-container>
            </div>
          </mat-nested-tree-node>
        </mat-tree>
      </div>
      <!-- dropdown tree menu end -->

    </div>
    <!-- parameter-input-container end -->

  </div>
  <!-- parameter end-->

  <!-- parameter start -->
  <div class="parameter">

    <!-- label start -->
    <label for="massFlowRate-{{side}}" class="parameter-label">{{'dutyPage.massFlowRate'|translate}}</label>
    <!-- label end -->

    <!-- mass flow rate parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.massFlowRate.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="massFlowRate-{{side}}" id="massFlowRate-{{side}}"
        [ngModel]="parameters.massFlowRate.value.userUnits" (ngModelChange)="onParameterChange('massFlowRate',$event)">
      <!-- input field end -->

      <!-- parameter-unit-select start -->
      <select name="massFlowRate-unit-{{side}}" id="massFlowRate-unit-{{side}}" class="parameter-unit-select"
        [ngModel]="parameters.massFlowRate.physicalUnitId"
        (ngModelChange)="onUnitChange('massFlowRate','mass_flow_rate',$event)">
        <option *ngFor="let unit of unitSystems.units.mass_flow_rate" [ngValue]="unit.physicalUnitId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- mass flow rate parameter input block end -->

  </div>
  <!-- parameter end-->

  <!-- parameter start -->
  <div class="parameter">

    <!-- label start -->
    <label for="inletTemperature-{{side}}" class="parameter-label">{{'dutyPage.inletTemperature'|translate}}</label>
    <!-- label end -->

    <!-- inlet temperatureparameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.inletTemp.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="inletTemperature-{{side}}" id="inletTemperature-{{side}}"
        [ngModel]="parameters.inletTemp.value.userUnits" (ngModelChange)="onParameterChange('inletTemp',$event)">
      <!-- input field end -->
      <!-- parameter-unit-select start -->
      <select name="inletTemperature-unit-{{side}}" id="inletTemperature-unit-{{side}}" class="parameter-unit-select"
        [ngModel]="parameters.inletTemp.physicalUnitId" (ngModelChange)="onUnitChange('inletTemp','temperature',$event)">
        <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- inlet temperatureparameter input block end -->

  </div>
  <!-- parameter end-->

  <!-- outlet temperature parameter start -->
  <div class="parameter" *ngIf="!extraDuty">

    <!-- label start -->
    <label for="outletTemperature-{{side}}" class="parameter-label">{{'dutyPage.outletTemperature'|translate}}</label>
    <!-- label end -->

    <!-- parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.outletTemp.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="outletTemperature-{{side}}"
        id="outletTemperature-{{side}}" [ngModel]="parameters.outletTemp.value.userUnits"
        (ngModelChange)="onParameterChange('outletTemp',$event)">
      <!-- input field end -->

      <!-- parameter-unit-select start -->
      <select name="outletTemperature-unit-{{side}}" id="outletTemperature-unit-{{side}}" class="parameter-unit-select"
        [ngModel]="parameters.outletTemp.physicalUnitId"
        (ngModelChange)="onUnitChange('outletTemp','temperature',$event)">
        <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- parameter input block end -->

  </div>
  <!-- outlet temperature parameter end-->

  <!-- max pressure drop parameter start -->
  <div class="parameter" *ngIf="!extraDuty">

    <!-- label start -->
    <label for="maxPressureDrop-{{side}}" class="parameter-label">{{'dutyPage.maxPressureDrop'|translate}}</label>
    <!-- label end -->

    <!-- parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.maxPressureDrop.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="maxPressureDrop-{{side}}" id="maxPressureDrop-{{side}}"
        [ngModel]="parameters.maxPressureDrop.value.userUnits"
        (ngModelChange)="onParameterChange('maxPressureDrop',$event)">
      <!-- input field end -->
      <!-- parameter-unit-select start -->
      <select name="maxPressureDrop-unit-{{side}}" id="maxPressureDrop-unit-{{side}}" class="parameter-unit-select"
        [ngModel]="parameters.maxPressureDrop.physicalUnitId"
        (ngModelChange)="onUnitChange('maxPressureDrop','pressure',$event)">
        <option *ngFor="let unit of unitSystems.units.pressure" [ngValue]="unit.physicalUnitId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- parameter input block end -->

  </div>
  <!-- max pressure drop parameter end-->
</div>
<!-- liquid-comp end -->

<!-- custom-fluid-modal-container start -->
<div class="custom-fluid-modal-container" *ngIf="customFluidModal">
  <!-- custom-fluid-modal-window start -->
  <div class="custom-fluid-modal-window">
    <!-- custom-fluid-modal-title start -->
    <div class="custom-fluid-modal-title">{{'dutyPage.customFluid'|translate}}</div>
    <!-- custom-fluid-modal-title end -->

    <!-- custom-fluid-modal-parameters-container start -->
    <div class="custom-fluid-modal-parameters-container">

      <!-- fluid name start -->
      <div class="fluid-name-block" [ngClass]="{'invalid': customFluid.invalid}">
        <!-- fluid-name-label start -->
        <label for="fluid-name-input" class="fluid-name-label">{{'dutyPage.name'|translate}}</label>
        <!-- fluid-name-label end -->

        <!-- fluid-name-input start -->
        <input type="text" class="fluid-name-input" id="fluid-name-input" [ngModel]="customFluid.name"
          (ngModelChange)="onCustomFluidParameterChange(customFluid,null,$event)">
        <!-- fluid-name-input end -->

      </div>
      <!-- fluid name end -->

      <!-- fluid-parameters-list start -->
      <div class="fluid-parameters-list">

        <!-- row-title start -->
        <div class="row-title">

          <!-- title start -->
          <div class="title">{{'dutyPage.inlet'|translate}}</div>
          <!-- title end -->

          <!-- title start -->
          <div class="title">{{'dutyPage.outlet'|translate}}</div>
          <!-- title end -->

        </div>
        <!-- row-title end -->

        <!-- row start -->
        <div class="row">
          <!-- label start -->
          <label for="temperature-inlet" class="parameter-label">{{'dutyPage.temperature'|translate}}</label>
          <!-- label end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.temperature.inlet.invalid}">

            <!-- input start -->
            <input type="number" id="temperature-inlet" name="temperature-inlet"
              [ngModel]="customFluid.temperature.inlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.temperature.inlet,'temperature',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="temperature-inlet-units" id="temperature-inlet-units" class="unit-select"
              [ngModel]="customFluid.temperature.inlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.temperature.inlet,'temperature',$event)">
              <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.temperature.outlet.invalid}">

            <!-- input start -->
            <input type="number" id="temperature-outlet" name="temperature-outlet"
              [ngModel]="customFluid.temperature.outlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.temperature.outlet,'temperature',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="temperature-outlet-units" id="temperature-outlet-units" class="unit-select"
              [ngModel]="customFluid.temperature.outlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.temperature.outlet,'temperature',$event)">
              <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

        </div>
        <!-- row end -->

        <!-- row start -->
        <div class="row">
          <!-- label start -->
          <label for="density-inlet" class="parameter-label">{{'dutyPage.density'|translate}}</label>
          <!-- label end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.density.inlet.invalid}">

            <!-- input start -->
            <input type="number" id="density-inlet" name="density-inlet" [ngModel]="customFluid.density.inlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.density.inlet,'density',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="density-inlet-units" id="density-inlet-units" class="unit-select"
              [ngModel]="customFluid.density.inlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.density.inlet,'density',$event)">
              <option *ngFor="let unit of unitSystems.units.density" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.density.outlet.invalid}">

            <!-- input start -->
            <input type="number" id="density-outlet" name="density-outlet"
              [ngModel]="customFluid.density.outlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.density.outlet,'density',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="density-outlet-units" id="density-outlet-units" class="unit-select"
              [ngModel]="customFluid.density.outlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.density.outlet,'density',$event)">
              <option *ngFor="let unit of unitSystems.units.density" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

        </div>
        <!-- row end -->

        <!-- row start -->
        <div class="row">
          <!-- label start -->
          <label for="viscosity-inlet" class="parameter-label">{{'dutyPage.viscosity'|translate}}</label>
          <!-- label end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.viscosity.inlet.invalid}">

            <!-- input start -->
            <input type="number" id="viscosity-inlet" name="viscosity-inlet"
              [ngModel]="customFluid.viscosity.inlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.viscosity.inlet,'viscosity',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="viscosity-inlet-units" id="viscosity-inlet-units" class="unit-select"
              [ngModel]="customFluid.viscosity.inlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.viscosity.inlet,'viscosity',$event)">
              <option *ngFor="let unit of unitSystems.units.viscosity" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.viscosity.outlet.invalid}">

            <!-- input start -->
            <input type="number" id="viscosity-outlet" name="viscosity-outlet"
              [ngModel]="customFluid.viscosity.outlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.viscosity.outlet,'viscosity',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="viscosity-outlet-units" id="viscosity-outlet-units" class="unit-select"
              [ngModel]="customFluid.viscosity.outlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.viscosity.outlet,'viscosity',$event)">
              <option *ngFor="let unit of unitSystems.units.viscosity" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

        </div>
        <!-- row end -->

        <!-- row start -->
        <div class="row">
          <!-- label start -->
          <label for="thermal-conductivity-inlet" class="parameter-label">{{'dutyPage.thermalConductivity'|translate}}</label>
          <!-- label end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.thermal_conductivity.inlet.invalid}">

            <!-- input start -->
            <input type="number" id="thermal-conductivity-inlet" name="thermal-conductivity-inlet"
              [ngModel]="customFluid.thermal_conductivity.inlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.thermal_conductivity.inlet,'thermal_conductivity',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="thermal-conductivity-inlet-units" id="thermal-conductivity-inlet-units" class="unit-select"
              [ngModel]="customFluid.thermal_conductivity.inlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.thermal_conductivity.inlet,'thermal_conductivity',$event)">
              <option *ngFor="let unit of unitSystems.units.thermal_conductivity" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.thermal_conductivity.outlet.invalid}">

            <!-- input start -->
            <input type="number" id="thermal-conductivity-outlet" name="thermal-conductivity-outlet"
              [ngModel]="customFluid.thermal_conductivity.outlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.thermal_conductivity.outlet,'thermal_conductivity',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="thermal-conductivity-outlet-units" id="thermal-conductivity-outlet-units" class="unit-select"
              [ngModel]="customFluid.thermal_conductivity.outlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.thermal_conductivity.outlet,'thermal_conductivity',$event)">
              <option *ngFor="let unit of unitSystems.units.thermal_conductivity" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

        </div>
        <!-- row end -->

        <!-- row start -->
        <div class="row">
          <!-- label start -->
          <label for="specific-heat-cap-inlet" class="parameter-label">{{'dutyPage.specificHeatCapacity'|translate}}</label>
          <!-- label end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.specific_heat_cap.inlet.invalid}">

            <!-- input start -->
            <input type="number" id="specific-heat-cap-inlet" name="specific-heat-cap-inlet"
              [ngModel]="customFluid.specific_heat_cap.inlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.specific_heat_cap.inlet,'specific_heat_cap',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="specific-heat-cap-inlet-units" id="specific-heat-cap-inlet-units" class="unit-select"
              [ngModel]="customFluid.specific_heat_cap.inlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.specific_heat_cap.inlet,'specific_heat_cap',$event)">
              <option *ngFor="let unit of unitSystems.units.specific_heat_cap" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

          <!-- parameter start -->
          <div class="parameter" [ngClass]="{'invalid': customFluid.specific_heat_cap.outlet.invalid}">

            <!-- input start -->
            <input type="number" id="specific-heat-cap-outlet" name="specific-heat-cap-outlet"
              [ngModel]="customFluid.specific_heat_cap.outlet.entered"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.specific_heat_cap.outlet,'specific_heat_cap',$event)">
            <!-- input end -->

            <!-- unit-select start -->
            <select name="specific-heat-cap-outlet-units" id="specific-heat-cap-outlet-units" class="unit-select"
              [ngModel]="customFluid.specific_heat_cap.outlet.physicalUnitId"
              (ngModelChange)="onCustomFluidUnitChange(customFluid.specific_heat_cap.outlet,'specific_heat_cap',$event)">
              <option *ngFor="let unit of unitSystems.units.specific_heat_cap" [ngValue]="unit.physicalUnitId">
                {{unit.unitSign}}</option>
            </select>
            <!-- unit-select end -->

          </div>
          <!-- parameter end -->

        </div>
        <!-- row end -->

        <!-- row start -->
        <div class="row">
          <!-- label start -->
          <label for="fluid_group" class="parameter-label">{{'dutyPage.fluidGroup'|translate}}</label>
          <!-- label end -->

          <!-- parameter start -->
          <div class="parameter-select" [ngClass]="{'invalid': customFluid.fluid_group.invalid}">

            <!-- select start -->
            <select name="fluid_group" id="fluid_group" [ngModel]="customFluid.fluid_group.value"
              (ngModelChange)="onCustomFluidParameterChange(customFluid.fluid_group,'fluid_group',$event)">
              <option [ngValue]="1">{{'dutyPage.group1'|translate}}</option>
              <option [ngValue]="2">{{'dutyPage.group2'|translate}}</option>
            </select>
            <!-- select end -->

          </div>
          <!-- parameter end -->

        </div>
        <!-- row end -->

      </div>
      <!-- fluid-parameters-list end -->

    </div>
    <!-- custom-fluid-modal-parameters-container end -->

    <!-- buttons-container start -->
    <div class="buttons-container">

      <!-- button start -->
      <button class="button" (click)="switchCustomFluidModal()">{{'commonLabels.cancelButton'|translate}}</button>
      <!-- button end -->
      <!-- button start -->
      <button class="button" (click)="saveCustomFluid()">OK</button>
      <!-- button end -->

    </div>
    <!-- buttons-container end -->

  </div>
  <!-- custom-fluid-modal-window end -->
</div>
<!-- custom-fluid-modal-container end -->
