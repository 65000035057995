import { IFluid } from "src/app/services/interfaces";

export interface ILiquid {
  massFlowRate: TParameter;
  inletTemp: TParameter;
  outletTemp: TParameter;
  maxPressureDrop: TParameter;
  liquid: IFluid & { name: string; selectable: boolean; };
  fluidProps: TFluidSpecialProps;
};
export interface IGas {
  massFlowRate: TParameter;
  inletPressure: TParameter;
  inletTemp: TParameter;
  outletTemp: TParameter;
  maxPressureDrop: TParameter;
  liquid: IFluid & { name: string; selectable: boolean; };
  fluidProps: TFluidSpecialProps;
};
export interface ICondensation {
  massFlowRate: TParameter;
  satTemp: TParameter;
  outletTemp: TParameter;
  satPressure: TParameter;
  liquid: IFluid & { name: string; selectable: boolean; };
  fluidProps: TFluidSpecialProps;
};

export interface IEvaporation {
  massFlowRate: TParameter;
  inletTemp: TParameter;
  satTemp: TParameter;
  satPressure: TParameter;
  liquid: IFluid & { name: string; selectable: boolean; };
  fluidProps: TFluidSpecialProps;
};

export type TParameter = {
  value: {
    userUnits: any;
    commonUnits: any;
  };
  entered: boolean;
  calculated: boolean;
  physicalUnitId: number;
};

export type TPhysicalUnits = { [key: string]: { unitSign: string; unitTitle: string; addFactor: number; multFactor: number; physicalUnitId: number; }[] };
export type TPhysicalUnitSystems = { [key: string]: { unitSign: string; unitTitle: string; addFactor: number; multFactor: number; unitSystemId: number; physicalUnitId: number; }[] };

export type TFluidSpecialProps = {
  [key: string]: TFluidProps[]
};

export type TFluidProps = {
  dependency1Value: number,
  dependency2Value: number,
  fluidId: number,
  fluidPropValueId: number,
  isActive: number,
  physicalPropertyId: string,
  propertyValue: number,
  dateInsert: string,
  dateUpdate: string
};
