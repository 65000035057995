import { Component, OnInit, ViewChild } from '@angular/core';
import { ApiService, SnackBarService } from 'src/app/services';

import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ISubuser } from 'src/app/services/interfaces';

@Component({
  selector: 'app-accounts-management',
  templateUrl: './accounts-management.component.html',
  styleUrls: ['./accounts-management.component.scss']
})
export class AccountsManagementComponent implements OnInit {
  private subusers: ISubuser[] = null;

  public displayedColumns: string[] = ['active', 'name', 'surname', 'phone'];
  public dataSource: MatTableDataSource<IExtendedSubuser> = null;

  /**User data from axisting user or new user */
  public selectedUser: IExtendedSubuser = null;

  public validationError: boolean = false;
  public emptyUserNameError: boolean = false;
  public emptyUserNameMsg: string = null;
  public userExistError: boolean = false;

  @ViewChild(MatSort) sort: MatSort;

  constructor(private apiService: ApiService, private snackBarService: SnackBarService) {
    // empty
  }

  public ngOnInit() {
    this.getSubusers();
  };

  /**
   * Method to prevent table checkbox state change on click.
   * Checkbox state must equal to `signActivity` in user data.
  */
  public onActiveCheckboxChange(e: MatCheckboxChange, user: IExtendedSubuser) {
    if (user.signActivity === 'Y') {
      e.source.checked = true;
    } else {
      e.source.checked = false;
    };

  };

  public onTableRowClick(row: IExtendedSubuser) {
    // Save copy of user object.
    this.selectedUser = Object.assign({}, row);

  };

  public onEditSave() {

    // Reset validation flags
    this.validationError = false;
    this.emptyUserNameError = false;
    this.emptyUserNameMsg = null;
    this.userExistError = false;


    // If Name or Surename empty show error
    if (!this.selectedUser.name) {
      this.validationError = true;
      this.emptyUserNameError = true;
      this.emptyUserNameMsg = 'Please enter Name';
      return;
    };
    if (!this.selectedUser.surname) {
      this.validationError = true;
      this.emptyUserNameError = true;
      this.emptyUserNameMsg = 'Please enter Surname';
      return;
    };

    // Check if current selected user present in the list of current subusers.
    const userExist = this.subusers.some((_subuser) => _subuser.userId === this.selectedUser.userId);
    // Filter subusers which is not current user
    const _subusers = this.subusers.filter((_subuser) => _subuser.userId !== this.selectedUser.userId);

    if (_subusers.some((_subuser) => _subuser.name === this.selectedUser.name && _subuser.surname === this.selectedUser.surname)) {
      this.validationError = true;
      this.userExistError = true;
      return;
    };

    // If checked false, this user not active
    // Change value of signActivity
    if (!this.selectedUser.checked) {
      this.selectedUser.signActivity = 'N';
    } else {
      this.selectedUser.signActivity = 'Y';
    }

    const data = Object.assign({}, this.selectedUser);
    delete data.checked;

    let apiCallObservable = null;


    if (userExist) {
      apiCallObservable = this.apiService.updateSubuser(data);
    } else {
      delete data.userId;
      apiCallObservable = this.apiService.saveSubuser(data);
    }

    apiCallObservable.subscribe({
      next: () => {
        // Reset selected user
        // Get new list of subusers
        this.selectedUser = null;
        this.getSubusers();
      },
      error: (error) => {
        this.snackBarService.open();
      }
    });

  };

  public onEditCancel() {
    // Reset validation flags
    this.validationError = false;
    this.emptyUserNameError = false;
    this.emptyUserNameMsg = null;
    this.userExistError = false;

    // Reset selected user
    this.selectedUser = null;
  };

  public addNewUser() {
    // Create empty user.
    this.selectedUser = {
      userId: null,
      name: null,
      surname: null,
      dateBirthday: null,
      signActivity: 'Y',
      phone: null,
      checked: true,
    }

  };

  private getSubusers() {
    const observable = this.apiService.getUserSubusers().subscribe({
      next: (subUsersResp: ISubuser[]) => {
        this.subusers = subUsersResp;

        const extendedSubusers: IExtendedSubuser[] = subUsersResp.map((_subuser) => {
          return Object.assign({ checked: _subuser.signActivity === 'Y' }, _subuser);
        });

        this.dataSource = new MatTableDataSource(extendedSubusers);
        this.dataSource.sort = this.sort;

        observable.unsubscribe();
      }
    });

  };
}

interface IExtendedSubuser extends ISubuser {
  checked: boolean;
}
