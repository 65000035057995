<div class="condensation-comp" [ngClass]="{'cold-side': side==='cold'}" *ngIf="!!unitSystems">
  <!-- parameter start -->
  <div class="parameter-combobox">

    <!-- label start -->
    <label for="fluid-{{side}}" class="parameter-label">Vapor</label>
    <!-- label end -->

    <!-- parameter input block start -->
    <div class="parameter-input-block">

      <!-- input field start -->
      <input class="parameter-input-field" type="text" name="fluid-{{side}}" id="fluid-{{side}}" [ngModel]="fluid.name"
        [disabled]="true" />
      <!-- input field end -->

      <!-- actions and units block start -->
      <div class="actions-and-units-block">

        <!-- open icon start -->
        <div class="open-icon" (click)="treeMenu = !treeMenu">
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 -960 960 960">
            <path d="M480-345 240-585l56-56 184 184 184-184 56 56-240 240Z" />
          </svg>
        </div>
        <!-- open icon end -->

      </div>
      <!-- actions and units block end -->

    </div>
    <!-- parameter input block end -->

    <!-- dropdown tree menu start -->
    <div class="dropdown-tree-menu" *ngIf="treeMenu">
      <mat-tree [dataSource]="treeData" [treeControl]="treeControl" class="example-tree">
        <!-- This is the tree node template for leaf nodes -->
        <!-- There is inline padding applied to this node using styles.
        This padding value depends on the mat-icon-button width. -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle (click)="onLiquidSelect(node, 'warm')">
          {{node.name}}
        </mat-tree-node>
        <!-- This is the tree node template for expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodeToggle
          (click)="onLiquidSelect(node, 'warm')">
          <div class="mat-tree-node">
            <button mat-icon-button [attr.aria-label]="'Toggle ' + node.name">
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'folder_open' : 'folder'}}
              </mat-icon>
            </button>
            {{node.name}}
          </div>
          <!-- There is inline padding applied to this div using styles.
              This padding value depends on the mat-icon-button width.  -->
          <div [class.example-tree-invisible]="!treeControl.isExpanded(node)" role="group">
            <ng-container matTreeNodeOutlet></ng-container>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </div>
    <!-- dropdown tree menu end -->


  </div>
  <!-- parameter end-->

  <!-- parameter start -->
  <div class="parameter" *ngIf="!extraDuty">

    <!-- label start -->
    <label for="massFlowRate-{{side}}" class="parameter-label">Mass flow rate</label>
    <!-- label end -->

    <!-- mass flow rate parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.massFlowRate.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="massFlowRate-{{side}}" id="massFlowRate-{{side}}"
             [ngModel]="parameters.massFlowRate.value.userUnits"
             (ngModelChange)="onParameterChange('massFlowRate',$event)">
      <!-- input field end -->

      <!-- parameter-unit-select start -->
      <select name="massFlowRate-unit" id="massFlowRate-unit" class="parameter-unit-select"
        [ngModel]="parameters.massFlowRate.physicalUnitId"
        (ngModelChange)="onUnitChange('massFlowRate','mass_flow_rate',$event)">
        <option *ngFor="let unit of unitSystems.units.mass_flow_rate" [ngValue]="unit.physicalUnitId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- mass flow rate parameter input block end -->

  </div>
  <!-- parameter end-->

  <!-- parameter start -->
  <div class="parameter">

    <!-- label start -->
    <label for="satPressure-{{side}}" class="parameter-label">Saturation pressure</label>
    <!-- label end -->

    <!-- mass flow rate parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.satPressure.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="satPressure-{{side}}" id="satPressure-{{side}}"
        [ngModel]="parameters.satPressure.value.userUnits" (ngModelChange)="onParameterChange('satPressure',$event)">
      <!-- input field end -->

      <!-- parameter-unit-select start -->
      <select name="satPressure-unit" id="satPressure-unit" class="parameter-unit-select"
        [ngModel]="parameters.satPressure.physicalUnitId" (ngModelChange)="onUnitChange('satPressure','sat_pressure',$event)">
        <option *ngFor="let unit of unitSystems.units.sat_pressure" [ngValue]="unit.physicalUnitId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- mass flow rate parameter input block end -->

  </div>
  <!-- parameter end-->

  <!-- parameter start -->
  <div class="parameter">

    <!-- label start -->
    <label for="satTemp-{{side}}" class="parameter-label">Saturation temperature</label>
    <!-- label end -->

    <!-- mass flow rate parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.satTemp.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="satTemp-{{side}}" id="satTemp-{{side}}"
        [ngModel]="parameters.satTemp.value.userUnits" (ngModelChange)="onParameterChange('satTemp',$event)">
      <!-- input field end -->

      <!-- parameter-unit-select start -->
      <select name="satTemp-unit" id="satTemp-unit" class="parameter-unit-select"
        [ngModel]="parameters.satTemp.physicalUnitId" (ngModelChange)="onUnitChange('satTemp','temperature',$event)">
        <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- mass flow rate parameter input block end -->

  </div>
  <!-- parameter end-->

  <!-- parameter start -->
  <div class="parameter">

    <!-- label start -->
    <label for="outletTemp-{{side}}" class="parameter-label">Outlet temperature</label>
    <!-- label end -->

    <!-- mass flow rate parameter input block start -->
    <div class="parameter-input-block" [ngClass]="{'calculated': parameters.outletTemp.calculated}">

      <!-- input field start -->
      <input class="parameter-input-field" type="number" name="outletTemp-{{side}}" id="outletTemp-{{side}}"
             [disabled]="application.requirementValue.cold[0]==='EV' || (OrientationName==='HO' && extraDuty)"
        [ngModel]="parameters.outletTemp.value.userUnits" (ngModelChange)="onParameterChange('outletTemp',$event)">
      <!-- input field end -->

      <!-- parameter-unit-select start -->
      <select name="outletTemp-unit" id="outletTemp-unit" class="parameter-unit-select"
        [ngModel]="parameters.outletTemp.physicalUnitId"
        (ngModelChange)="onUnitChange('outletTemp','temperature',$event)">
        <option *ngFor="let unit of unitSystems.units.temperature" [ngValue]="unit.physicalUnitId">
          {{unit.unitSign}}</option>
      </select>
      <!-- parameter-unit-select end -->

    </div>
    <!-- mass flow rate parameter input block end -->

  </div>
  <!-- parameter end-->

</div>
