import {Component, OnInit} from '@angular/core';
import {NgForm} from '@angular/forms';
import {Router} from '@angular/router';

import {AuthService, StateService} from '../../services';
import {IRegistrationSimpleCredentials} from 'src/app/services/interfaces';
import { RecaptchaModule, RecaptchaFormsModule } from 'ng-recaptcha';

@Component({
  selector: 'app-registration-simple',
  templateUrl: './registration-simple.component.html',
  styleUrls: ['./registration-simple.component.scss']
})
export class RegistrationSimpleComponent implements OnInit {

  public lang: string = null;
  public formCredentials: IRegistrationSimpleCredentials = {
    name: null,
    surname: null,
    mail: null,
    companyName: null,
    idLang: null,
    captchaResponse: null
  };
  public apiErrorMessage: string = null;
  public allFieldsRequired: boolean = false;
  public completeCaptcha: boolean = false;
  public registrationSuccessful: boolean = false;
  public emailNotValid: boolean = null;
  public emailRegexp: RegExp = /^[^\s@]+@[^\s@]+\.[^\s@]+$/gm;
  public captchaResponse: string | null = null;

  constructor(
    private authService: AuthService,
    private stateService: StateService,
    private router: Router
  ) {
    // empty
  };

  public ngOnInit(): void {
    this.lang = this.stateService.restore('deviceLang');
  };
  /**
   * This method need to prevent trigger cancel button when enter `enter key`.
   * @param event
   */
  public preventEnterKeyTigger(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  }
  /** Method to select new language, when user change it in menu. */
  public vmOnLanguageChange(value: string) {
    this.lang = this.stateService.restore('deviceLang');
  };


  /**
   * Method to submit registration form.
   * @param form
   */
  public submit(form: NgForm) {
    this.resetFlags();

    if (!this.captchaResponse) {
      this.completeCaptcha = true;
      return;
    }

    this.formCredentials.captchaResponse = this.captchaResponse;

    // Get language selected on device.
    // Use English as fallback if deviceLang will be empty
    this.formCredentials.idLang = this.stateService.restore('deviceLang') || 'en';

    if (!this.allFormFieldsFilled()) {
      this.allFieldsRequired = true;
    };

    if (this.formCredentials.mail) {
      this.emailNotValid = !this.formCredentials.mail.match(this.emailRegexp);
    };

    if (form.valid) {
      const payload = Object.assign({}, this.formCredentials);


      this.authService.registrationSimple(payload).subscribe({
        next: () => {
          this.registrationSuccessful = true;
        },
        error: (error) => {
          if (typeof error.error === "object") {
            let message = '';
            Object.keys(error.error.errors).forEach((_key: string) => {

              error.error.errors[_key].forEach((_str) => {
                message = `${message} ${_str}`;
              })

            });
            this.apiErrorMessage = message;

          } else {
            this.apiErrorMessage = error.error;
          }

        }
      });
    }

  };

  /**
   * Method to navigate to login page, by click on `cancel button`.
   */
  public cancel(e: PointerEvent): void {
    e.preventDefault();
    this.router.navigate(["/login"]);
  };

  private resetFlags(): void {
    this.allFieldsRequired = false;
    this.completeCaptcha = false;
    this.emailNotValid = null;
  };

  /**
   * Method to check that all fields have values.
   */
  private allFormFieldsFilled(): boolean {

    const relevantFields = Object.entries(this.formCredentials)
      .filter(([key, _]) => key !== 'discount')
      .map(([_, value]) => value);

    return relevantFields.every(item => item !== null && item !== undefined);

  };

  onCaptchaResolved(captchaResponse: string): void {
    console.log('CAPTCHA resolved with response:', captchaResponse);
    this.captchaResponse = captchaResponse; // Save CAPTCHA token
  }

};

