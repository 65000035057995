<!-- component start -->
<div class="he-type-comp">
  <!-- main container start -->
  <div class="main-container">
    <!-- toolbar start -->
    <app-toolbar>
      <button class="back-btn" (click)="back()">{{'journey.back'|translate}}</button>
      <button class="skip margin-left-auto" (click)="skipGuide()">{{'journey.skipAssistant'|translate}}</button>
    </app-toolbar>
    <!-- toolbar end -->

    <!-- progressbar start -->
    <app-progressbar [progressStep]="3"></app-progressbar>
    <!-- progressbar end -->

    <!-- main title start -->
    <div class="main-title">
      {{'heTypePage.title'|translate}}
    </div>
    <!-- main title end -->

  </div>
  <!-- main container end -->

  <!-- he type section start -->
  <div class="he-type-section" [ngClass]="{'disabled-overlay': quotationOrdered}">
    <!-- he type list start -->
    <div class="he-type-list">
      <!-- item start -->
      <div class="item" [ngClass]="{'active': heatExchangerType === item.abrState}"
        (click)="selectHEExchanger(item.abrState)" *ngFor="let item of productTypes">

        <!-- icon block start -->
        <div class="item-icon-block">
          <img src="../../../assets/images/he_type_st.png" alt="he_type_st.png" *ngIf="item.abrState==='ST'">
          <img src="../../../assets/images/he_type_plate.png" alt="he_type_plate.png" *ngIf="item.abrState==='PH'">
        </div>
        <!-- icon block end -->

        <!-- item title start -->
        <div class="item-title">{{item.title}}</div>
        <!-- item title end -->

      </div>
      <!-- item end -->

      <!-- item start -->
      <div class="item" [ngClass]="{'active': heatExchangerType ==='NA'}" (click)="selectHEExchanger('NA')">

        <!-- icon block start -->
        <div class="item-icon-block">
          <img src="../../../assets/images/he_type_undecided.png" alt="he_type_undecided.png">
        </div>
        <!-- icon block end -->

        <!-- item title start -->
        <div class="item-title">{{'heTypePage.undecided'|translate}}</div>
        <!-- item title end -->

      </div>
      <!-- item end -->
    </div>
    <!-- he type list end -->
  </div>
  <!-- he type section end -->

  <!-- validation message and continue button start -->
  <div [ngClass]="{'disabled-overlay': quotationOrdered}">
    <!-- warning message start -->
    <div class="warning-message" *ngIf="warningMessage">
      {{'journey.makeYourChoice'|translate}}
    </div>
    <!-- warning message end -->

    <!-- btn-new start -->
    <button type="button" class="btn" (click)="onContinue()">{{'journey.continue'|translate}}</button>
    <!-- btn-new end -->
  </div>
  <!-- validation message and continue button end -->



</div>
<!-- component end -->

<!-- zilo-hints start -->
<app-zilo-hints [itemStatus]="3"></app-zilo-hints>
<!-- zilo-hints end -->
