import { Component, Input, OnInit } from '@angular/core';
import { forkJoin, switchMap } from 'rxjs';
import { ApiService, StateService } from 'src/app/services';

@Component({
  selector: 'app-zilo-hints',
  templateUrl: './zilo-hints.component.html',
  styleUrls: ['./zilo-hints.component.scss']
})
export class ZiloHintsComponent implements OnInit {
  public hintMessage: string = null;

  @Input()
  public itemStatus: number | string = null;

  constructor(private stateService: StateService, private apiService: ApiService) { };

  public ngOnInit(): void {
    const lang: string = this.stateService.get('lang').toUpperCase();

    this.apiService.getZiloHint(this.itemStatus).pipe(
      switchMap((hintResponse) => {
        return forkJoin({
          currentLang: this.apiService.getTranslation([hintResponse.hintTextId], lang),
          defaultLang: this.apiService.getTranslation([hintResponse.hintTextId], 'EN')
        });
      }),
    ).subscribe({
      next: (translations) => {
        if (translations.currentLang.length > 0) {
          this.hintMessage = translations.currentLang[0].value;
        } else {
          this.hintMessage = translations.defaultLang[0].value;
        };
      }
    });

  };

};
