<!-- news component start -->
<div class="news-comp">

  <!-- header start -->
  <app-header>
  </app-header>
  <!-- header end -->

  <!-- main contianer for profress bar start -->
  <div class="main-container">
    <!-- progressbar start -->
    <app-progressbar></app-progressbar>
    <!-- progressbar end -->
  </div>
  <!-- main contianer for profress bar end -->

  <!-- main container for articel start -->
  <div class="main-container">

    <!--article start-->
    <div class="article" *ngIf="news">

      <!-- articel image start -->
      <img src="https://dev-zilex.nikama.net/{{news.newsImage}}" alt="image" class="article-img">
      <!-- articel image end -->

      <span class="article-date">{{news.newsDateLocal}}</span>
      <span class="article-title">{{news.newsTitle}}</span>
      <span [innerHTML]="news.newsText"></span>
    </div>
    <!--article end-->

  </div>
  <!-- main container for article end -->


</div>
<!-- news component end -->