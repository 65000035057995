<!-- reset password component start -->
<div class="reset-password-comp">
  <!-- header start -->
  <app-header>
    <app-language-menu></app-language-menu>
  </app-header>
  <!-- header end -->

  <!-- main container start -->
  <div class="main-container">
    <!-- progress bar start -->
    <app-progressbar></app-progressbar>
    <!-- progress bar end -->

    <!-- forgot password form start -->
    <form #resetPasswordForm="ngForm" class="reset-password" (submit)="submitResetPassword(resetPasswordForm)">
      <!-- title start -->
      <div class="reset-password_title">{{'resetPasswordPage.title' | translate}}</div>
      <!-- title end -->


      <!-- form content start -->
      <div class="reset-password-content">

        <!-- field start -->
        <div class="field">
          <label for="password">{{'resetPasswordPage.password' | translate}}:</label>
          <input type="password" name="password" id="password" [(ngModel)]="password"
            pattern="^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*.]).{8,}$" required>
        </div>
        <!-- field end -->
        <!-- field start -->
        <div class="field">
          <label for="confirmPassword">{{'resetPasswordPage.confirmPassword' | translate}}:</label>
          <input type="password" name="confirmPassword" id="confirmPassword" [(ngModel)]="confirmPassword"
            pattern="^(?=.*\d)(?=.*[a-zA-Z])(?=.*[!@#$%^&*.]).{8,}$" required>
        </div>
        <!-- field end -->

        <!-- buttons block start -->
        <div class="buttons-block">
          <!-- button start -->
          <button class="btn btn-cancel" (click)="cancel();">{{'resetPasswordPage.cancelButton' | translate}}</button>
          <!-- button end -->
          <!-- button start -->
          <button type="submit" class="btn btn-register">OK</button>
          <!-- button end -->
        </div>
        <!-- buttons block end -->

      </div>
      <!-- form content end -->

      <!-- form validation errors start -->
      <div class="reset-password-validation-errors">
        <!-- invalid message start -->
        <div class="invalidMsg" *ngIf="allFieldsRequired">
          {{'registration.form.validationMsg.allFieldsRequired'|translate}}</div>
        <!-- invalid message end -->

        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="passwordsNotEqual">
          {{'registration.form.validationMsg.passwordsNotEqual'|translate}}</div>
        <!-- not equal passwords message end -->

        <!-- not equal passwords message start -->
        <div class="invalidMsg" *ngIf="passwordNotValid">
          {{'registration.form.validationMsg.passwordNotValid1'|translate}} <br />
          {{'registration.form.validationMsg.passwordNotValid2'|translate}}</div>
        <!-- not equal passwords message end -->
      </div>
      <!-- form validation errors end -->


    </form>
    <!-- forgot password form end -->

  </div>
  <!-- main container end -->

</div>
<!-- reset password component end -->